import constants from "./actionTypes";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { getTagRelatedContentApi } from "../../services/ApiCalls";
import { fetchTagRelatedContentSuccess } from "./actions";

function* fetchTagRelatedContentSaga(action) {
  try {
    const tagRelatedContent = yield call(
      getTagRelatedContentApi,
      action.payload
    );
    yield put(fetchTagRelatedContentSuccess(tagRelatedContent));
  } catch (e) {
    console.log(e);
  }
}

// COMBINE ALL OF THE FUNCTION ABOVE IN ONE FUNCTION
function* tagRelatedContentSaga() {
  yield all([
    takeLatest(constants.FETCH_TAG_RELATED_CONTENT, fetchTagRelatedContentSaga),
  ]);
}

// EXPORT THAT FUNCTION AS A DEFAULT EXPORT
export default tagRelatedContentSaga;
