import constants from "./actionTypes";

// INITIAL STATE OF THIS REDUCER
const initState = {
  products: [],
  productCategory: [],
  singleProduct: [],
  selectedContentTypeOrderProduct: [],
  pagination: {
    currentPage: "",
    pageCount: "",
    perPage: "",
    totlCount: "",
  },
  loading: false,
  authClientProducts: [],
  authClientProductsPagination: {
    currentPage: "",
    pageCount: "",
    perPage: "",
    totlCount: "",
  },
  productByCatalogNoPagination: [],
  authClientProductsNoPag: [],
};

// REDUCER FUNCTION
export default (state = initState, action) => {
  const { payload, type } = action;
  switch (type) {
    // SET PAGINATION TO BE THE PAYLOAD RECIVED FROM API
    case constants.SET_PAGINATION:
      return {
        ...state,
        pagination: payload,
      };

    case constants.FETCH_AUTH_CLIENT_PRODUCTS_NO_PAGINATION_SUCCESS:
      return { ...state, authClientProductsNoPag: payload };
    case constants.SET_PAGINATION_FOR_PRODUCTS_BY_AUTH_CLIENT:
      return {
        ...state,
        authClientProductsPagination: payload,
      };
    case constants.GET_PRODUCTS_BY_CATALOG_ID_NO_PAG_SUCCESS:
      return {
        ...state,
        productByCatalogNoPagination: payload,
      };
    case constants.FETCH_PRODUCT_LIST_BY_CATEGORY_SLUG:
      return {
        ...state,
        loading: true,
      };

    case constants.FETCH_PRODUCT_LIST_BY_CATEGORY_SLUG_SUCCESS:
      return {
        ...state,
        productCategory: payload[0].data[0],
        products: payload[1].data,
        loading: false,
      };

    case constants.SET_PAGINATION_FOR_AUTH_USER_BLOGS:
      return {
        ...state,
        authClientProductsPagination: payload,
      };
    case constants.ACTIVATE_PRODUCT_LOADER:
      return {
        ...state,
        loading: true,
      };

    // TRIGGER PRODUCT FETCHING BY AUTH CLIENT
    case constants.FETCH_PRODUCTS_BY_AUTH_CLIENT:
      return {
        ...state,
        loading: true,
      };
    // SET AUTH CLIENT PRODUCTS
    case constants.FETCH_PRODUCTS_BY_AUTH_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        authClientProducts: payload,
      };
    //  IF NONE OF THE CASE ABOVE IS TRIGGERED RETURN DEFAULT STATE
    default:
      return {
        ...state,
      };
  }
};
