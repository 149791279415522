import constants from "./actionTypes";
import { all, call, put, takeLatest, select } from "redux-saga/effects";
import {
  getUserProfileByUsernameApi,
  getCatalogsProductsByUserIdApi,
  getCatalogsProductsByInstanceApi,
  getBlogsByUserIdApi,
  updateLicenceClientProfileApi,
  updateLicenceUserProfileApi,
  licenceUsersUpdatePasswordApi,
  changeUserProfileNameApi,
  changeUserProfilePhoneApi,
} from "../../services/ApiCalls";
import {
  fetchProfileSuccess,
  fetchProfileCatalogsProductsSuccess,
  setPagination,
  setPaginationBlogs,
  fetchProfileBlogsSuccess,
} from "./actions";
import { getAuthUser } from "../auth/actions";
import { NotificationManager } from "react-notifications";

function* fetchProfileSaga(action) {
  try {
    const profile = yield call(getUserProfileByUsernameApi, action.payload);
    if (profile.length > 0) {
      yield put(fetchProfileSuccess(profile[0]));
    }
  } catch (e) {
    console.log(e);
  }
}

// SAGA FOR HANDLING LICENCE CLIENT PROFILE UPDATE
// RECIVES TYPE AND PAYLOAD  FROM ACTION
// TYPE : constants.LICENCE_CLIENT_UPDATE_PROFILE
// PAYLOAD : LICENCE CLIENT PROFILE DATA
function* licenceClientUpdateProfileSaga(action) {
  try {
    const state = yield select();
    yield call(updateLicenceClientProfileApi, action.payload);
    yield put(getAuthUser(action.payload));
    NotificationManager.success(
      state.translation.messages.updatedProfile[1],
      "",
      2000
    );
    // console.log("profileUpdate", profileUpdate);
  } catch (e) {
    console.log(e);
  }
}
// SAGA TO UPDATE SALES USER NAME
// RECIVES TYPE AND PAYLOAD  FROM ACTION
// TYPE : constants.UPDATE_USER_PROFILE_NAME
// PAYLOAD : RECIVE UPDATED USER NAME
function* updateUserProfileNameSaga(action) {
  try {
    const state = yield select();
    yield call(changeUserProfileNameApi, action.payload);
    NotificationManager.success(
      state.translation.messages.updatedProfile[1],
      "",
      3000
    );
    // console.log("updateName", updateName);
    //   const userLicences = yield call(getSalesUsresLicencesApi, action.payload);
    //   console.log("userLicences", userLicences);
  } catch (e) {
    console.log("e", e);
  }
}
function* updateUserProfilePhoneSaga(action) {
  try {
    const state = yield select();
    yield call(changeUserProfilePhoneApi, action.payload);
    NotificationManager.success(
      state.translation.messages.updatedProfile[1],
      "",
      3000
    );
    // console.log("updateName", updateName);
    //   const userLicences = yield call(getSalesUsresLicencesApi, action.payload);
    //   console.log("userLicences", userLicences);
  } catch (e) {
    console.log("e", e);
  }
}
// SAGA FOR HANDLING LICENCE CLIENT OR USER PASSWORD UPDATE
// RECIVES TYPE AND PAYLOAD  FROM ACTION
// TYPE : constants.LICENCE_USERS_UPDATE_PASSWORD
// PAYLOAD : LICENCE CLIENT OR USER PASSWORD DATA
function* licenceUserUpdatePasswordSaga(action) {
  try {
    yield call(licenceUsersUpdatePasswordApi, action.payload);
    // console.log("updatePassword", updatePassword);
  } catch (e) {
    console.log(e);
  }
}

// SAGA FOR HANDLING LICENCE USER PROFILE UPDATE
// RECIVES TYPE AND PAYLOAD  FROM ACTION
// TYPE : constants.LICENCE_USER_UPDATE_PROFILE
// PAYLOAD : LICENCE USER PROFILE DATA
function* licenceUserUpdateProfileSaga(action) {
  try {
    const state = yield select();

    yield call(updateLicenceUserProfileApi, action.payload);
    yield put(getAuthUser(action.payload));
    NotificationManager.success(
      state.translation.messages.updatedProfile[1],
      "",
      2000
    );

    // console.log("profileUpdate", profileUpdate);
  } catch (e) {
    console.log(e);
  }
}

function* fetchProfileCatalogsProductsSaga(action) {
  try {
    const profileCatalogsProducts = yield call(
      getCatalogsProductsByInstanceApi,
      // getCatalogsProductsByUserIdApi,
      action.payload
    );
    
    const setPaginationObj = {
      currentPage: parseInt(
        profileCatalogsProducts.headers["x-pagination-current-page"]
      ),
      pageCount: parseInt(
        profileCatalogsProducts.headers["x-pagination-page-count"]
      ),
      perPage: parseInt(
        profileCatalogsProducts.headers["x-pagination-per-page"]
      ),
      totlCount: parseInt(
        profileCatalogsProducts.headers["x-pagination-total-count"]
      ),
    };

    yield put(
      fetchProfileCatalogsProductsSuccess(profileCatalogsProducts.data)
    );

    yield put(setPagination(setPaginationObj));
  } catch (e) {
    console.log(e);
  }
}

function* fetchProfileBlogsSaga(action) {
  try {
    const profileBlogs = yield call(getBlogsByUserIdApi, action.payload);

    const setPaginationObj = {
      currentPage: parseInt(profileBlogs.headers["x-pagination-current-page"]),
      pageCount: parseInt(profileBlogs.headers["x-pagination-page-count"]),
      perPage: parseInt(profileBlogs.headers["x-pagination-per-page"]),
      totlCount: parseInt(profileBlogs.headers["x-pagination-total-count"]),
    };

    yield put(fetchProfileBlogsSuccess(profileBlogs.data));

    yield put(setPaginationBlogs(setPaginationObj));
  } catch (e) {
    console.log(e);
  }
}

// COMBINE ALL OF THE FUNCTION ABOVE IN ONE FUNCTION
function* profileSaga() {
  yield all([
    takeLatest(constants.FETCH_PROFILE, fetchProfileSaga),
    takeLatest(constants.UPDATE_USER_PROFILE_NAME, updateUserProfileNameSaga),
    takeLatest(constants.UPDATE_USER_PROFILE_PHONE, updateUserProfilePhoneSaga),

    takeLatest(
      constants.LICENCE_USERS_UPDATE_PASSWORD,
      licenceUserUpdatePasswordSaga
    ),
    takeLatest(
      constants.LICENCE_USER_UPDATE_PROFILE,
      licenceUserUpdateProfileSaga
    ),
    takeLatest(
      constants.FETCH_PROFILE_CATALOGS_PRODUCTS,
      fetchProfileCatalogsProductsSaga
    ),
    takeLatest(constants.FETCH_PROFILE_BLOGS, fetchProfileBlogsSaga),
    takeLatest(
      constants.LICENCE_CLIENT_UPDATE_PROFILE,
      licenceClientUpdateProfileSaga
    ),
  ]);
}

// EXPORT THAT FUNCTION AS A DEFAULT EXPORT
export default profileSaga;
