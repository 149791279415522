import { all, takeLatest, call, put, select } from "redux-saga/effects";
import constants from "./actionTypes";
import {
  loginUserApi,
  registerLicenceUserApi,
  registerLicenceClientApi,
  loginLicenceUserApi,
  getAuthUserApi,
  getLicenceTemplateIdApi,
  getLicenceLanguageIdApi,
  logoutUserApi,
  loginSocialAuthUserApi,
  loginSalesUserApi,
  registerSalesUserApi,
  requesetPasswordResetApi,
  passwordResetApi,
  validateUserAccountApi,
} from "../../services/ApiCalls";
import {
  loginLicenceUserSuccess,
  loginLicenceUserFail,
  loginUserSuccess,
  getAuthUserSuccess,
  getLicenceTempateIdSuccess,
  getLicenceLanguageIdSuccess,
  loginUserFail,
  logoutUserSuccess,
  socialAuthLoginUser,
  loginSalesUserSuccess,
  loginSalesUserFail,
  registerSalesUserSuccess,
  registerSalesUserFail,
  registerLicenceUserFail,
  registerLicenceUserSuccess,
  registerLicenceClientFail,
  registerLicenceClientSuccess,
} from "./actions";

import { activateModal, closeModal } from "../layout/actions";

import { auth, googleProvider, facebookProvider } from "../../../src/firebase";
import { NotificationManager } from "react-notifications";
import setAuthToken from "./utills";

const licence = (state) => state.layout.licenceDetails;
const translation = (state) => state.translation.messages;

// SAGA FOR HANDLING USER LOGIN
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.LOGIN_USER
// PAYLOAD : DATA THAT USER ENTERD IN THE INPUT FIEALDS
function* loginUserSaga(action) {
  try {
    const credentials = action.payload;
    const licenceDetails = yield select(licence);
    const loginUser = yield call(loginUserApi, credentials);
    if (loginUser.id) {
      setAuthToken(loginUser.auth_key);
      yield put(loginUserSuccess(loginUser));
      NotificationManager.success(licenceDetails.sign_in_msg, "", 3000);
      yield put(closeModal("loginModal"));
    }
  } catch (e) {
    if (e.response.data.message) {
      NotificationManager.error(e.response.data.message, "", 3000);
    } else if (e.response.data[0]) {
      NotificationManager.error(e.response.data[0].message, "", 3000);
    }
    yield put(loginUserFail());

    // console.log(e.message, "erorr");
  }
}

// SAGA FOR HANDLING LICENCE USER REGISTRATION
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.REGISTER_LICENCE_USER
// PAYLOAD : DATA THAT USER ENTERD IN THE INPUT FIEALDS
function* registerLicenceUserSaga(action) {
  try {
    const credentials = action.payload;
    const registerUser = yield call(registerLicenceUserApi, credentials);
    const licenceDetails = yield select(licence);
    NotificationManager.success(licenceDetails.register_msg, "", 3000);
    yield put(registerLicenceUserSuccess());
    yield put(closeModal("registerModal"));
    // console.log(registerUser, "from registerUserSaga");
  } catch (e) {
    const translationDetails = yield select(translation);
    NotificationManager.error(translationDetails.accountExist[1], "", 3000);
    yield put(registerLicenceUserFail(e.message));
    yield put(activateModal("registerModal"));
    console.log(e.message, "erorr");
  }
}

// SAGA FOR HANDLING LICENCE CLIENT REGISTRATION
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.REGISTER_LICENCE_CLIENT
// PAYLOAD : DATA THAT CLIENT ENTERD IN THE INPUT FIEALDS
function* registerLicenceClientSaga(action) {
  try {
    const credentials = action.payload;
    const registerUser = yield call(registerLicenceClientApi, credentials);
    const licenceDetails = yield select(licence);
    NotificationManager.success(licenceDetails.client_register_msg, "", 3000);
    yield put(registerLicenceClientSuccess());
    yield put(closeModal("registerModal"));
    // console.log("registerUser", registerUser);
  } catch (e) {
    const translationDetails = yield select(translation);
    NotificationManager.error(translationDetails.accountExist[1], "", 3000);
    yield put(registerLicenceClientFail(e.message));
    yield put(activateModal("registerModal"));
    console.log(e.message, "erorr");
  }
}

// SAGA FOR HANDLING LICENCE USER LOGIN
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.LOGIN_LICENCE_USER
// PAYLOAD : DATA THAT USER ENTERD IN THE INPUT FIEALDS
function* loginLicenceUserSaga(action) {
  try {
    const user = yield call(loginLicenceUserApi, action.payload);
    const licenceDetails = yield select(licence);
    if (user.id) {
      setAuthToken(user.auth_key);
      yield put(loginLicenceUserSuccess(user));
      NotificationManager.success(licenceDetails.sign_in_msg, "", 2000);
      yield put(closeModal("loginModal"));
    }
    // console.log(user, "user from loginLicenceUser");
  } catch (e) {
    // console.log(e.message, "error from loginLicenceUser");
    if (e.response.data.message) {
      NotificationManager.error(e.response.data.message, "", 2000);
    } else if (e.response.data[0]) {
      NotificationManager.error(e.response.data[0].message, "", 2000);
    }
    yield put(loginLicenceUserFail());
  }
}

// SAGA FOR HANDLING USER AUTHENTICATION
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.GET_AUTH_USER
// PAYLOAD : TOKEN FROM THE LOCAL STORAGE
function* getAuthUserSaga(action) {
  try {
    const authUser = yield call(getAuthUserApi, action.payload);
    // console.log("authUser", authUser);
    if (authUser.id) {
      yield put(getAuthUserSuccess(authUser));
    }
  } catch (e) {}
}

// SAGA FOR HANDLING USER AUTHENTICATION
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.LOGOUT_USER
// PAYLOAD : AUTH_KEY FROM THE LOCAL STORAGE
function* logoutuserSaga(action) {
  try {
    const logoutUser = yield call(logoutUserApi, action.payload);
    // const state = yield select();

    localStorage.removeItem("token");
    yield put(logoutUserSuccess());
    NotificationManager.success(logoutUser.message, "", 2000);
    // window.location.href = `${window.location.origin}`;
    // console.log("logoutUser", logoutUser);
  } catch (e) {
    console.log(e.message, "error from getAuthUserSaga");
  }
}

function* getLicenceTemplateIdSaga(action) {
  try {
    const templateId = yield call(getLicenceTemplateIdApi);
    if (templateId) {
      yield put(getLicenceTempateIdSuccess(templateId));
    }
  } catch (e) {
    yield put(getLicenceTempateIdSuccess(1000000));
  }
}

function* getLicenceLanguageIdSaga(action) {
  try {
    const languageId = yield call(getLicenceLanguageIdApi);
    if (languageId) {
      yield put(getLicenceLanguageIdSuccess(languageId));
    }
  } catch (e) {
    yield put(getLicenceLanguageIdSuccess("Ušao u catch blok"));
  }
}

export function* signInWithGoogleSaga() {
  try {
    const authUser = yield auth.signInWithPopup(googleProvider);
    const payload = {
      email: authUser.additionalUserInfo.profile.email,
      name: authUser.additionalUserInfo.profile.given_name,
      surname: authUser.additionalUserInfo.profile.family_name,
      google_id: authUser.additionalUserInfo.profile.id,
      google_access_token: authUser.credential.accessToken,
      social_name: authUser.additionalUserInfo.profile.name,
    };
    yield put(socialAuthLoginUser(payload));
    // console.log("payload", payload);
    // console.log("authUser", authUser);

    // yield getSnapshotFromUserAuth(user);
  } catch (error) {
    // yield put(signInFailure(error));
  }
}

export function* signInWithFacebookSaga() {
  try {
    const authUser = yield auth.signInWithPopup(facebookProvider);
    const payload = {
      email: authUser.additionalUserInfo.profile.email,
      name: authUser.additionalUserInfo.profile.first_name,
      surname: authUser.additionalUserInfo.profile.last_name,
      facebook_id: authUser.additionalUserInfo.profile.id,
      facebook_access_token: authUser.credential.accessToken,
      social_name: authUser.additionalUserInfo.profile.name,
    };
    yield put(socialAuthLoginUser(payload));

    // console.log("authUser", authUser);
    // yield getSnapshotFromUserAuth(user);
  } catch (error) {
    // yield put(signInFailure(error));
  }
}
// SAGA FOR SOCIAL AUTH LOGIN
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.SOCIAL_AUTH_LOGIN_USER
// PAYLOAD : AUTH USER DATA
export function* socialAuthLoginSaga(action) {
  try {
    const authUser = yield call(loginSocialAuthUserApi, action.payload);
    const licenceDetails = yield select(licence);
    yield put(loginLicenceUserSuccess(authUser));
    setAuthToken(authUser.auth_key);
    NotificationManager.success(licenceDetails.sign_in_msg, "", 2000);
    yield put(closeModal("loginModal"));
    // console.log("authUser", authUser);
    // yield getSnapshotFromUserAuth(user);
  } catch (error) {
    console.log("error", error);
    // yield put(lg(error));
  }
}

// SAGA TO HANDLE SALES USER LOGIN
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.LOGIN_SALES_USER
// PAYLOAD : SALES USER DATA
export function* loginSalesUserSaga(action) {
  try {
    const authUser = yield call(loginSalesUserApi, action.payload);
    const licenceDetails = yield select(licence);
    yield put(loginSalesUserSuccess(authUser));
    setAuthToken(authUser.auth_key);
    yield put(closeModal("loginModal"));
    NotificationManager.success(licenceDetails.sign_in_msg, "", 2000);
    // window.location.href = `${window.location.origin}/profile/${authUser.id}`;
    // window.location.href = `${window.location.origin}`;
  } catch (error) {
    if (error.response.data.message) {
      NotificationManager.error(error.response.data.message, "", 2000);
    } else if (error.response.data[0]) {
      NotificationManager.error(error.response.data[0].message, "", 2000);
    }
    // console.log("error", error.response.data);

    yield put(loginSalesUserFail());
  }
}

// SAGA TO HANDLE SALES USER REGISTRATION
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.REGISTER_SALES_USER
// PAYLOAD : SALES USER DATA
export function* registerSalesUserSaga(action) {
  try {
    const authUser = yield call(registerSalesUserApi, action.payload);
    yield put(registerSalesUserSuccess(authUser));
    setAuthToken(authUser.auth_key);
    const licenceDetails = yield select(licence);
    NotificationManager.success(licenceDetails.register_msg, "", 3000);
    yield put(closeModal("registerModal"));
    // yield put(activateModal("loginModal"));
  } catch (error) {
    const translationDetails = yield select(translation);
    // console.log("error", error);
    // if (error.response.data.message) {
    //   NotificationManager.error(error.response.data.message, "", 2000);
    // } else if (error.response.data[0]) {
    //   NotificationManager.error(error.response.data[0].message, "", 2000);
    // }
    NotificationManager.error(translationDetails.accountExist[1], "", 3000);
    yield put(registerSalesUserFail(error.message));
    yield put(activateModal("registerModal"));
    // console.log("error", error.response.data);

    // yield put(loginSalesUserFail());
  }
}

// SAGA TO HANDLE PASSWORD RESET REQUEST
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.REQUESET_PASSWORD_RESET
// PAYLOAD : EMAIL TO SED RESET PASSWORD LINK
export function* requestPasswordResetSaga(action) {
  try {
    const requestNewPassword = yield call(
      requesetPasswordResetApi,
      action.payload
    );
    // NotificationManager.success(requestNewPassword.message, "", 3000);
  } catch (error) {
    console.log("error", error);

    // yield put(loginSalesUserFail());
  }
}

// SAGA TO HANDLE USER PASSWORD RESET
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.RESET_PASSWORD
// PAYLOAD : EMAIL TO SED RESET PASSWORD LINK
export function* resetPasswordSaga(action) {
  try {
    const resetPassword = yield call(passwordResetApi, action.payload);
    NotificationManager.success(resetPassword.data.message, "", 3000);
    yield put(closeModal("resetPasswordModal"));
    yield put(activateModal("loginModal"));

    // console.log("resetPassword", resetPassword);
  } catch (error) {
    console.log("error", error);

    // yield put(loginSalesUserFail());
  }
}

// SAGA TO HANDLE USER ACCOUNT VALIDATION
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.VALIDATE_USER_ACCOUNT
// PAYLOAD : ACITVATION_TOKEN AND EMAIL
export function* validateUserAccountSaga(action) {
  try {
    const state = yield select();
    const validateAccount = yield call(validateUserAccountApi, action.payload);
    // NotificationManager.success(validateAccount.message, "", 3000);
    NotificationManager.success(
      state.translation.messages.confirmRegistration[3],
      "",
      2000
    );

    // yield put(activateModal("loginModal"));
    // console.log("validateAccount", validateAccount);
  } catch (error) {
    console.log("error", error);

    // yield put(loginSalesUserFail());
  }
}

// COMBINE ALL OF THE FUNCTION ABOVE IN ONE FUNCTION
function* authSaga() {
  yield all([
    takeLatest(constants.LOGIN_USER, loginUserSaga),
    takeLatest(constants.REQUESET_PASSWORD_RESET, requestPasswordResetSaga),
    takeLatest(constants.REGISTER_LICENCE_USER, registerLicenceUserSaga),
    takeLatest(constants.REGISTER_LICENCE_CLIENT, registerLicenceClientSaga),
    takeLatest(constants.LOGIN_LICENCE_USER, loginLicenceUserSaga),
    takeLatest(constants.GET_AUTH_USER, getAuthUserSaga),
    takeLatest(constants.LOGOUT_USER, logoutuserSaga),
    takeLatest(constants.VALIDATE_USER_ACCOUNT, validateUserAccountSaga),
    takeLatest(constants.RESET_PASSWORD, resetPasswordSaga),
    takeLatest(constants.GET_LICENCE_TEMPLATE, getLicenceTemplateIdSaga),
    takeLatest(constants.GET_LICENCE_LANGUAGE, getLicenceLanguageIdSaga),
    takeLatest(constants.SIGN_IN_WITH_GOOGLE, signInWithGoogleSaga),
    takeLatest(constants.SIGN_IN_WITH_FACEBOOK, signInWithFacebookSaga),
    takeLatest(constants.SOCIAL_AUTH_LOGIN_USER, socialAuthLoginSaga),
    takeLatest(constants.LOGIN_SALES_USER, loginSalesUserSaga),
    takeLatest(constants.REGISTER_SALES_USER, registerSalesUserSaga),
  ]);
}
// EXPORT THAT FUNCTION AS A DEFAULT EXPORT
export default authSaga;
