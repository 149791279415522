const ACTIVATE_EDIT_MODE = "ACTIVATE_EDIT_MODE";
const DEACTIVATE_EDIT_MODE = "DEACTIVATE_EDIT_MODE";
const ACTIVATE_MODAL = "ACTIVATE_MODAL";
const CLOSE_MODAL = "CLOSE_MODAL";
const SET_NAVIGATION_MODAL_STEP = "SET_NAVIGATION_MODAL_STEP";
const SET_USE_NEW_CAT = "SET_USE_NEW_CAT";
const SET_CONTENT_TYPE_ID = "SET_CONTENT_TYPE_ID";
const REMOVE_USE_NEW_CAT = "REMOVE_USE_NEW_CAT";
const SET_SHOW_EDIT_CAT_LANDING_SCREEN = "SET_SHOW_EDIT_CAT_LANDING_SCREEN";
const SET_EDIT_MODAL_STEP = "SET_EDIT_MODAL_STEP";
const GET_LICENCE_DETAILS = "GET_LICENCE_DETAILS";
const GET_LICENCE_DETAILS_SUCCESS = "GET_LICENCE_DETAILS_SUCCESS";
const GET_LICENCE_DETAILS_FAIL = "GET_LICENCE_DETAILS_FAIL";
const SET_ACTIVE_AUTH_USER_MODAL = "SET_ACTIVE_AUTH_USER_MODAL";
const REDIRECT_TO_SINGLE_PLANNER = "REDIRECT_TO_SINGLE_PLANNER";
const CLOSE_ALL_MODALS = "CLOSE_ALL_MODALS";
const SET_ACTIVE_MODERATION_MODAL = "SET_ACTIVE_MODERATION_MODAL";
const SET_ACTIVE_USER_MGM_MODAL = "SET_ACTIVE_USER_MGM_MODAL";
const SET_ACTIVE_SITE_MGM_MODAL = "SET_ACTIVE_SITE_MGM_MODAL";
const SET_HEADER_HEIGHT = "SET_HEADER_HEIGHT";
const GET_MODERATION_NUMBERS = "GET_MODERATION_NUMBERS";
const GET_MODERATION_NUMBERS_SUCCESS = "GET_MODERATION_NUMBERS_SUCCESS";
const GET_MODERATION_NUMBERS_FAIL = "GET_MODERATION_NUMBERS_FAIL";
const SET_ACTIVE_INFO_BTN = "SET_ACTIVE_INFO_BTN";
const CHANGE_LOGIN_REGISTRATION_STATUS = "CHANGE_LOGIN_REGISTRATION_STATUS";
const CHANGE_LOGIN_REGISTRATION_STATUS_SUCCESS =
  "CHANGE_LOGIN_REGISTRATION_STATUS_SUCCESS";
const CHANGE_LOGIN_REGISTRATION_STATUS_FAIL =
  "CHANGE_LOGIN_REGISTRATION_STATUS_FAIL";
const CHANGE_SEARCH_STATUS = "CHANGE_SEARCH_STATUS";
const CHANGE_SEARCH_STATUS_SUCCESS = "CHANGE_SEARCH_STATUS_SUCCESS";
const CHANGE_SEARCH_STATUS_FAIL = "CHANGE_SEARCH_STATUS_FAIL";
const SET_BACKEND_USERS_MGM_STEP = "SET_BACKEND_USERS_MGM_STEP";
const SET_USE_NEW_USER = "SET_USE_NEW_USER";

export default {
  ACTIVATE_EDIT_MODE,
  DEACTIVATE_EDIT_MODE,
  ACTIVATE_MODAL,
  CLOSE_MODAL,
  SET_NAVIGATION_MODAL_STEP,
  SET_USE_NEW_CAT,
  SET_CONTENT_TYPE_ID,
  REMOVE_USE_NEW_CAT,
  SET_SHOW_EDIT_CAT_LANDING_SCREEN,
  SET_EDIT_MODAL_STEP,
  GET_LICENCE_DETAILS,
  GET_LICENCE_DETAILS_SUCCESS,
  GET_LICENCE_DETAILS_FAIL,
  SET_ACTIVE_AUTH_USER_MODAL,
  REDIRECT_TO_SINGLE_PLANNER,
  CLOSE_ALL_MODALS,
  SET_HEADER_HEIGHT,
  SET_ACTIVE_MODERATION_MODAL,
  GET_MODERATION_NUMBERS,
  GET_MODERATION_NUMBERS_SUCCESS,
  GET_MODERATION_NUMBERS_FAIL,
  SET_ACTIVE_USER_MGM_MODAL,
  SET_ACTIVE_SITE_MGM_MODAL,
  SET_ACTIVE_INFO_BTN,
  CHANGE_LOGIN_REGISTRATION_STATUS,
  CHANGE_LOGIN_REGISTRATION_STATUS_SUCCESS,
  CHANGE_LOGIN_REGISTRATION_STATUS_FAIL,
  CHANGE_SEARCH_STATUS,
  CHANGE_SEARCH_STATUS_SUCCESS,
  CHANGE_SEARCH_STATUS_FAIL,
  SET_BACKEND_USERS_MGM_STEP,
  SET_USE_NEW_USER,
};
