const GET_SINGLE_ARTICLE = "GET_SINGLE_ARTICLE";
const SET_PAGINATION = "SET_PAGINATION";
const GET_SINGLE_ARTICLE_SUCCESS = "GET_SINGLE_ARTICLE_SUCCESS";
const GET_SINGLE_ARTICLE_FAIL = "GET_SINGLE_ARTICLE_FAIL";
const UPDATE_ARTICLE = "UPDATE_ARTICLE";
const UPDATE_ARTICLE_SUCCESS = "UPDATE_ARTICLE_SUCCESS";
const UPDATE_ARTICLE_FAIL = "UPDATE_ARTICLE_FAIL";
const SET_SELECTED_CONTENT_TYPE_ORDER_ARTICLE =
  "SET_SELECTED_CONTENT_TYPE_ORDER_ARTICLE";
const FETCH_ARTICLES = "FETCH_ARTICLES";
const FETCH_ARTICLES_SUCCESS = "FETCH_ARTICLES_SUCCESS";
const FETCH_ARTICLES_FAIL = "FETCH_ARTICLES_FAIL";
const FETCH_ARTICLE_LIST_BY_CATEGORY_SLUG =
  "FETCH_ARTICLE_LIST_BY_CATEGORY_SLUG";
const FETCH_ARTICLE_LIST_BY_CATEGORY_SLUG_SUCCESS =
  "FETCH_ARTICLE_LIST_BY_CATEGORY_SLUG_SUCCESS";
const FETCH_ARTICLE_LIST_BY_CATEGORY_SLUG_FAIL =
  "FETCH_ARTICLE_LIST_BY_CATEGORY_SLUG_FAIL";
const GET_LICENCE_USER_ARTICLES = "GET_LICENCE_USER_ARTICLES";
const GET_LICENCE_USER_ARTICLES_SUCCESS = "GET_LICENCE_USER_ARTICLES_SUCCESS";
const GET_LICENCE_USER_ARTICLES_FAIL = "GET_LICENCE_USER_ARTICLES_FAIL";
const ACTIVATE_ARTICLES_LOADER = "ACTIVATE_ARTICLES_LOADER";
const SET_PAGINATION_FOR_AUTH_USER_ARTICLES =
  "SET_PAGINATION_FOR_AUTH_USER_ARTICLES";

export default {
  GET_SINGLE_ARTICLE,
  GET_SINGLE_ARTICLE_SUCCESS,
  GET_SINGLE_ARTICLE_FAIL,
  UPDATE_ARTICLE,
  UPDATE_ARTICLE_SUCCESS,
  UPDATE_ARTICLE_FAIL,
  SET_SELECTED_CONTENT_TYPE_ORDER_ARTICLE,
  FETCH_ARTICLES,
  FETCH_ARTICLES_SUCCESS,
  FETCH_ARTICLES_FAIL,
  FETCH_ARTICLE_LIST_BY_CATEGORY_SLUG,
  FETCH_ARTICLE_LIST_BY_CATEGORY_SLUG_SUCCESS,
  FETCH_ARTICLE_LIST_BY_CATEGORY_SLUG_FAIL,
  GET_LICENCE_USER_ARTICLES,
  GET_LICENCE_USER_ARTICLES_SUCCESS,
  GET_LICENCE_USER_ARTICLES_FAIL,
  SET_PAGINATION,
  SET_PAGINATION_FOR_AUTH_USER_ARTICLES,
  ACTIVATE_ARTICLES_LOADER,
};
