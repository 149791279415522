import constants from "./actionTypes";

const initState = {
  allLicences: [],
  licences: [],
  partnerLicences: [],
  loader: false,
  newLicenceData: {},
  licencesPagination: {
    currentPage: "",
    pageCount: "",
    perPage: "",
    totlCount: "",
  },
  domainPrices: [],
  exchangeRate: {},
};

export default (state = initState, action) => {
  const { payload, type } = action;
  switch (type) {
    case constants.GET_ALL_LICENCES:
      return {
        ...state,
        loader: true,
      };
    case constants.GET_ALL_LICENCES_SUCCESS:
      return {
        ...state,
        allLicences: payload,
      };

    case constants.GET_ALL_PARTNERS_LICENCES:
      return {
        ...state,
        loader: true,
      };
    case constants.GET_ALL_PARTNERS_LICENCES_SUCCESS:
      return {
        ...state,
        partnerLicences: payload,
      };
    case constants.GET_SALES_USER_LICENCES:
      return {
        ...state,
        loader: true,
      };
    case constants.UPDATE_NEW_LICENCE_DATA_SUCCESS:
      return {
        ...state,
        newLicenceData: payload,
      };
    case constants.GET_NEW_LICENCE_DATA_SUCCESS:
      return {
        ...state,
        newLicenceData: payload,
      };
    case constants.ACTIVATE_LICENCE_LOADER:
      return {
        ...state,
        loader: true,
      };
    case constants.CLOSE_LICENCE_LOADER:
      return {
        ...state,
        loader: false,
      };
    case constants.GET_SALES_USER_LICENCES_SUCCESS:
      return {
        ...state,
        loader: false,
        licences: payload,
      };
    case constants.SET_USER_LICENCES_PAGINATION:
      return {
        ...state,
        licencesPagination: payload,
      };
    case constants.GET_DOMAIN_PRICES:
      return {
        ...state,
        loader: true,
      };
    case constants.GET_DOMAIN_PRICES_SUCCESS:
      return {
        ...state,
        loader: false,
        domainPrices: payload,
      };
    case constants.GET_EXCHANGE_RATE:
      return {
        ...state,
        loader: true,
      };
    case constants.GET_EXCHANGE_RATE_SUCCESS:
      return {
        ...state,
        loader: false,
        exchangeRate: payload,
      };
    case constants.GET_EXCHANGE_RATE_FAIL:
      return {
        ...state,
        loader: false,
      };
    default:
      return {
        ...state,
      };
  }
};
