import constants from "./actionTypes";

// ACTION FOR GETTING SINGLE ARTICLE
// RECEVE ARTICLE ID FROM THE URL
export const getSingleArticle = (payload) => ({
  payload,
  type: constants.GET_SINGLE_ARTICLE,
});
// ACTION FOR GETTING SINGLE ARTICLE SUCCESS
// RECEVE ARTICLE DATA FROM THE SERVER AS A PAYLOAD
export const getSingleArticleSuccess = (payload) => ({
  payload,
  type: constants.GET_SINGLE_ARTICLE_SUCCESS,
});
// ACTION FOR GETTING SINGLE ARTICLE FAIL
// RECEVE ERROR STATUS AND THE MESSAGE AS A PAYLOAD
export const getSingleArticleFail = (payload) => ({
  payload,
  type: constants.GET_SINGLE_ARTICLE_FAIL,
});
// ACTION FOR TRIGGERING ARTICLE UPDATE
// RECEVE ARTICLE DATA THAT USER EDITED FROM THE EDIT MODE SCREEN
export const updateArticle = (payload) => ({
  payload,
  type: constants.UPDATE_ARTICLE,
});
// ACTION FOR ARTICLE UPDATE SUCCESS
// RECEVE ARTICLE DATA FROM THE SERVER AS A PAYLOAD
export const updateArticleSuccess = (payload) => ({
  payload,
  type: constants.UPDATE_ARTICLE_SUCCESS,
});
// ACTION FOR ARTICLE UPDATE FAIL
// RECEVE ERROR STATUS AND THE MESSAGE AS A PAYLOAD
export const updateArticleFail = (payload) => ({
  payload,
  type: constants.UPDATE_ARTICLE_FAIL,
});
// ACTION SETTING ARTICLE CONTENT TYPE ORDER
// RECIVE ARTICLE CONTENT TYPE IN ORDER AS A PAYLOAD FROM SERVER
export const setSelectedContentTypeOrderArticle = (payload) => ({
  payload,
  type: constants.SET_SELECTED_CONTENT_TYPE_ORDER_ARTICLE,
});

// ACTION FOR SETTING PAGINATION
// RECIVES HEDERS FROM THE SERVER RESPONSE TO FETCHING BLOGS AS A PAYOAD
export const setPagination = (payload) => ({
  payload,
  type: constants.SET_PAGINATION,
});
// ACTION FOR FETCHING ARTICLES
export const fetchArticles = () => ({
  type: constants.FETCH_ARTICLES,
});
// SUCCESS ACTION FOR FETCHING ARTICLES
// RECEVE ARTICLE DATA WITH PAGINATION OBJECT FROM THE SERVER AS A PAYLOAD
export const fetchArticlesSuccess = (payload) => ({
  type: constants.FETCH_ARTICLES_SUCCESS,
  payload,
});
// FAIL ACTION FOR FETCHING ARTICLES
// RECEVE ERROR STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const fetchArticlesFail = (payload) => ({
  type: constants.FETCH_ARTICLES_FAIL,
  payload,
});
// ACTION FOR FETCHING ARTICLES BY SLUG
// RECEVE SLUG NAME FROM THE URL AS A PAYLOAD
export const fetchArticleListByCategorySlug = (payload) => ({
  payload,
  type: constants.FETCH_ARTICLE_LIST_BY_CATEGORY_SLUG,
});
// SUCCESS ACTION FOR FETCHING ARTICLES BY SLUG
// RECEVE ARTICLE DATA WITH PAGINATION OBJECT FROM THE SERVER AS A PAYLOAD
export const fetchArticleListByCategorySlugSuccess = (payload) => ({
  payload,
  type: constants.FETCH_ARTICLE_LIST_BY_CATEGORY_SLUG_SUCCESS,
});
// FAIL ACTION FOR FETCHING ARTICLES
// RECEVE ERROR STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const fetchArticleListByCategorySlugFail = (payload) => ({
  payload,
  type: constants.FETCH_ARTICLE_LIST_BY_CATEGORY_SLUG_FAIL,
});

// ACTION FOR GETING LICENCE USER ARTICLES
// RECIVE AUTH USER ID AS A PAYLOAD
export const getAuthUserArticles = (payload) => ({
  payload,
  type: constants.GET_LICENCE_USER_ARTICLES,
});
// ACTION FOR GETING LICENCE USER ARTICLES SUCCESS
// RECEVE ARTICLE DATA FROM THE SERVER AS A PAYLOAD
export const getAuthUserArticlesSuccess = (payload) => ({
  payload,
  type: constants.GET_LICENCE_USER_ARTICLES_SUCCESS,
});
// ACTION FOR GETING LICENCE USER ARTICLES FAIL
// RECEVE ERROR STATUS AND THE MESSAGE AS A PAYLOAD
export const getAuthUserArticlesFail = (payload) => ({
  payload,
  type: constants.GET_LICENCE_USER_ARTICLES_FAIL,
});

// ACTION FOR SETTING PAGINATION OF AUTH USER ARTICLES
// RECIVES HEDERS FROM THE SERVER RESPONSE TO FETCHING ARTICLES THAT AUTH USER CREATED AS A PAYOAD
export const setPaginationForAuthUserArticles = (payload) => ({
  payload,
  type: constants.SET_PAGINATION_FOR_AUTH_USER_ARTICLES,
});

// ACTION FOR ACIVATING ARTICLES LOADER
export const activateArticlesLoader = () => ({
  type: constants.ACTIVATE_ARTICLES_LOADER,
});
