import constants from "./actionTypes";

// INITIAL STATE OF THIS REDUCER
const initState = {
  loader: false,
  licenceTags: [],
  singleBlog: [],
};

// REDUCER FUNCTION
export default (state = initState, action) => {
  const { payload, type } = action;
  switch (type) {
    // SET PAGINATION TO BE THE PAYLOAD RECIVED FROM API
    case constants.GET_SINGLE_BLOG_SUCCESS:
      return {
        ...state,
        singleBlog: payload,
      };
    case constants.GET_SINGLE_BLOG_FAIL:
      return {
        ...state,
        singleBlog: {},
        loader: false,
      };
    // ACTIVATE LOADER WHEN LICENCE USER TRY TO CREATE BLOG
    case constants.LICENCE_USER_CREATE_BLOG:
      return {
        ...state,
        loader: true,
      };
    // DEACTIVATE LOADER IF BLOG IS SUCCESSFULY CREATED BY LICENCE USER
    case constants.LICENCE_USER_CREATE_BLOG_SUCCESS:
      return {
        ...state,
        loader: false,
      };
    case constants.ACTIVATE_SINGLE_LOADER:
      return {
        ...state,
        loader: true,
      };
    // ACTIVATE LOADER WHEN GETTING LICENCE TAGS
    case constants.GET_LICENCE_TAGS:
      return {
        ...state,
        // loader: true,
      };
    // DEACTIVATE LOADER AFTER SUCCESS TAGS FETCH
    case constants.GET_LICENCE_TAGS_SUCCESS:
      return {
        ...state,
        loader: false,
        licenceTags: payload,
      };

    case constants.GET_SINGLE_BLOG_BY_SLUG:
      return {
        ...state,
        loader: true,
      };

    case constants.GET_SINGLE_BLOG_BY_SLUG_SUCCESS:
      return {
        ...state,
        singleBlog: payload,
        loader: false,
      };

    case constants.GET_SINGLE_BLOG_BY_SLUG_FAIL:
      return {
        ...state,
        loader: false,
      };
    //  IF NONE OF THE CASE ABOVE IS TRIGGERED RETURN DEFAULT STATE
    default:
      return {
        ...state,
      };
  }
};
