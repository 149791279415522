const FETCH_SINGLE_PRODUCT = "FETCH_SINGLE_PRODUCT";
const FETCH_SINGLE_PRODUCT_SUCCESS = "FETCH_SINGLE_PRODUCT_SUCCESS";
const FETCH_SINGLE_PRODUCT_FAIL = "FETCH_SINGLE_PRODUCT_FAIL";
const LICENCE_CLIENT_CREATE_PRODUCT = "LICENCE_CLIENT_CREATE_PRODUCT";
const LICENCE_CLIENT_CREATE_PRODUCT_SUCCESS =
  "LICENCE_CLIENT_CREATE_PRODUCT_SUCCESS";
const LICENCE_CLIENT_CREATE_PRODUCT_FAIL = "LICENCE_CLIENT_CREATE_PRODUCT_FAIL";
const LICENCE_CLIENT_CREATE_PRODUCT_DRAFT =
  "LICENCE_CLIENT_CREATE_PRODUCT_DRAFT";
const LICENCE_CLIENT_CREATE_PRODUCT_DRAFT_SUCCESS =
  "LICENCE_CLIENT_CREATE_PRODUCT_DRAFT_SUCCESS";
const LICENCE_CLIENT_CREATE_PRODUCT_DRAFT_FAIL =
  "LICENCE_CLIENT_CREATE_PRODUCT_DRAFT_FAIL";
const LICENCE_CLIENT_UPDATE_PRODUCT_DRAFT =
  "LICENCE_CLIENT_UPDATE_PRODUCT_DRAFT";
const LICENCE_CLIENT_UPDATE_PRODUCT_DRAFT_SUCCESS =
  "LICENCE_CLIENT_UPDATE_PRODUCT_DRAFT_SUCCESS";
const LICENCE_CLIENT_UPDATE_PRODUCT_DRAFT_FAIL =
  "LICENCE_CLIENT_UPDATE_PRODUCT_DRAFT_FAIL";
const LICENCE_CLIENT_PUBLISH_PRODUCT_DRAFT =
  "LICENCE_CLIENT_PUBLISH_PRODUCT_DRAFT";
const LICENCE_CLIENT_PUBLISH_PRODUCT_DRAFT_SUCCESS =
  "LICENCE_CLIENT_PUBLISH_PRODUCT_DRAFT_SUCCESS";
const LICENCE_CLIENT_PUBLISH_PRODUCT_DRAFT_FAIL =
  "LICENCE_CLIENT_PUBLISH_PRODUCT_DRAFT_FAIL";
const LICENCE_CLIENT_UPDATE_PRODUCT = "LICENCE_CLIENT_UPDATE_PRODUCT";
const LICENCE_CLIENT_UPDATE_PRODUCT_SUCCESS =
  "LICENCE_CLIENT_UPDATE_PRODUCT_SUCCESS";
const LICENCE_CLIENT_UPDATE_PRODUCT_FAIL = "LICENCE_CLIENT_UPDATE_PRODUCT_FAIL";
const DELETE_PRODUCT = "DELETE_PRODUCT";
const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
const DELETE_PRODUCT_FAIL = "DELETE_PRODUCT_DRAFT_FAIL";
const LINK_PRODUCT_TO_PLANNER = "LINK_PRODUCT_TO_PLANNER";
const LINK_PRODUCT_TO_PLANNER_SUCCESS = "LINK_PRODUCT_TO_PLANNER_SUCCESS";
const LINK_PRODUCT_TO_PLANNER_FAIL = "LINK_PRODUCT_TO_PLANNER_FAIL";
const ADMIN_CREATE_PRODUCT = "ADMIN_CREATE_PRODUCT";
const ADMIN_CREATE_PRODUCT_SUCCESS = "ADMIN_CREATE_PRODUCT_SUCCESS";
const ADMIN_CREATE_PRODUCT_FAIL = "ADMIN_CREATE_PRODUCT_FAIL";
const ADMIN_UPDATE_PRODUCT = "ADMIN_UPDATE_PRODUCT";
const ADMIN_UPDATE_PRODUCT_SUCCESS = "ADMIN_UPDATE_PRODUCT_SUCCESS";
const ADMIN_UPDATE_PRODUCT_FAIL = "ADMIN_UPDATE_PRODUCT_FAIL";
const APPROVE_PRODUCT_MODERATION = "APPROVE_PRODUCT_MODERATION";
const APPROVE_PRODUCT_MODERATION_SUCCESS = "APPROVE_PRODUCT_MODERATION_SUCCESS";
const APPROVE_PRODUCT_MODERATION_FAIL = "APPROVE_PRODUCT_MODERATION_FAIL";
const ADMIN_DISAPPROVE_PRODUCT_MODERATION =
  "ADMIN_DISAPPROVE_PRODUCT_MODERATION";
const ADMIN_DISAPPROVE_PRODUCT_MODERATION_SUCCESS =
  "ADMIN_DISAPPROVE_PRODUCT_MODERATION_SUCCESS";
const ADMIN_DISAPPROVE_PRODUCT_MODERATION_FAIL =
  "ADMIN_DISAPPROVE_PRODUCT_MODERATION_FAIL";
const REQUEST_FOR_EDIT_PRODUCT_MODERATION =
  "REQUEST_FOR_EDIT_PRODUCT_MODERATION";
const REQUEST_FOR_EDIT_PRODUCT_MODERATION_SUCCESS =
  "REQUEST_FOR_EDIT_PRODUCT_MODERATION_SUCCESS";
const REQUEST_FOR_EDIT_PRODUCT_MODERATION_FAIL =
  "REQUEST_FOR_EDIT_PRODUCT_MODERATION_FAIL";
const ACTIVATE_SINGLE_PRODUCT_LOADER = "ACTIVATE_SINGLE_PRODUCT_LOADER";

export default {
  FETCH_SINGLE_PRODUCT,
  FETCH_SINGLE_PRODUCT_SUCCESS,
  FETCH_SINGLE_PRODUCT_FAIL,
  LICENCE_CLIENT_CREATE_PRODUCT,
  LICENCE_CLIENT_CREATE_PRODUCT_SUCCESS,
  LICENCE_CLIENT_CREATE_PRODUCT_FAIL,
  LICENCE_CLIENT_CREATE_PRODUCT_DRAFT,
  LICENCE_CLIENT_CREATE_PRODUCT_DRAFT_SUCCESS,
  LICENCE_CLIENT_CREATE_PRODUCT_DRAFT_FAIL,
  LICENCE_CLIENT_UPDATE_PRODUCT_DRAFT,
  LICENCE_CLIENT_UPDATE_PRODUCT_DRAFT_SUCCESS,
  LICENCE_CLIENT_UPDATE_PRODUCT_DRAFT_FAIL,
  LICENCE_CLIENT_PUBLISH_PRODUCT_DRAFT,
  LICENCE_CLIENT_PUBLISH_PRODUCT_DRAFT_SUCCESS,
  LICENCE_CLIENT_PUBLISH_PRODUCT_DRAFT_FAIL,
  LICENCE_CLIENT_UPDATE_PRODUCT,
  LICENCE_CLIENT_UPDATE_PRODUCT_SUCCESS,
  LICENCE_CLIENT_UPDATE_PRODUCT_FAIL,
  DELETE_PRODUCT,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAIL,
  LINK_PRODUCT_TO_PLANNER,
  LINK_PRODUCT_TO_PLANNER_SUCCESS,
  LINK_PRODUCT_TO_PLANNER_FAIL,
  ADMIN_CREATE_PRODUCT,
  ADMIN_CREATE_PRODUCT_SUCCESS,
  ADMIN_CREATE_PRODUCT_FAIL,
  ADMIN_UPDATE_PRODUCT,
  ADMIN_UPDATE_PRODUCT_SUCCESS,
  ADMIN_UPDATE_PRODUCT_FAIL,
  APPROVE_PRODUCT_MODERATION,
  APPROVE_PRODUCT_MODERATION_SUCCESS,
  APPROVE_PRODUCT_MODERATION_FAIL,
  ADMIN_DISAPPROVE_PRODUCT_MODERATION,
  ADMIN_DISAPPROVE_PRODUCT_MODERATION_SUCCESS,
  ADMIN_DISAPPROVE_PRODUCT_MODERATION_FAIL,
  REQUEST_FOR_EDIT_PRODUCT_MODERATION,
  REQUEST_FOR_EDIT_PRODUCT_MODERATION_SUCCESS,
  REQUEST_FOR_EDIT_PRODUCT_MODERATION_FAIL,
  ACTIVATE_SINGLE_PRODUCT_LOADER,
};
