const GET_ALL_PLANNERS = "GET_ALL_PLANNERS";
const GET_ALL_PLANNERS_SUCCESS = "GET_ALL_PLANNERS_SUCCESS";
const GET_ALL_PLANNERS_FAIL = "GET_ALL_PLANNERS_FAIL";
const SET_SHOW_PLANNER_OPTIONS = "SHOW_PLANNER_OPTIONS";
const DELETE_PLANNER = "DELETE_PLANNER";
const DELETE_PLANNER_SUCCESS = "DELETE_PLANNER_SUCCESS";
const DELETE_PLANNER_FAIL = "DELETE_PLANNER_FAIL";
const ADD_NEW_PLANNER = "ADD_NEW_PLANNER";
const ADD_NEW_PLANNER_SUCCESS = "ADD_NEW_PLANNER_SUCCESS";
const ADD_NEW_PLANNER_FAIL = "ADD_NEW_PLANNER_FAIL";
const UPDATE_PLANNER = "UPDATE_PLANNER";
const UPDATE_PLANNER_SUCCESS = "UPDATE_PLANNER_SUCCESS";
const UPDATE_PLANNER_FAIL = "UPDATE_PLANNER_FAIL";
const GET_PROFILE_PUBLIC_PLANNERS = "GET_PROFILE_PUBLIC_PLANNERS";
const GET_PROFILE_PUBLIC_PLANNERS_SUCCESS =
  "GET_PROFILE_PUBLIC_PLANNERS_SUCCESS";
const GET_PROFILE_PUBLIC_PLANNERS_FAIL = "GET_PROFILE_PUBLIC_PLANNERS_FAIL";
const PUBLIC_PLANNER = "PUBLIC_PLANNER";
const PUBLIC_PLANNER_SUCCESS = "PUBLIC_PLANNER_SUCCESS";
const PUBLIC_PLANNER_FAIL = "PUBLIC_PLANNER_FAIL";
export default {
  GET_ALL_PLANNERS,
  GET_ALL_PLANNERS_SUCCESS,
  GET_ALL_PLANNERS_FAIL,
  SET_SHOW_PLANNER_OPTIONS,
  DELETE_PLANNER,
  DELETE_PLANNER_SUCCESS,
  DELETE_PLANNER_FAIL,
  ADD_NEW_PLANNER,
  ADD_NEW_PLANNER_SUCCESS,
  ADD_NEW_PLANNER_FAIL,
  UPDATE_PLANNER,
  UPDATE_PLANNER_SUCCESS,
  UPDATE_PLANNER_FAIL,
  GET_PROFILE_PUBLIC_PLANNERS,
  GET_PROFILE_PUBLIC_PLANNERS_SUCCESS,
  GET_PROFILE_PUBLIC_PLANNERS_FAIL,
  PUBLIC_PLANNER,
  PUBLIC_PLANNER_SUCCESS,
  PUBLIC_PLANNER_FAIL,
};
