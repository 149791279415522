import constants from "./actionTypes";

// ACTION FOR FETCHING SINGLE ARTICLE
// RECEVE NEW BLOG DATA AS A PAYLOAD
export const fetchSingleArticle = (payload) => ({
  payload,
  type: constants.FETCH_SINGLE_ARTICLE,
});

// SUCCESS ACTION FOR FETCHING SINGLE ARTICLE
// RECEVE NEW BLOG DATA AS A PAYLOAD
export const fetchSingleArticleSuccess = (payload) => ({
  payload,
  type: constants.FETCH_SINGLE_ARTICLE_SUCCESS,
});

// FAIL ACTION FOR FETCHING SINGLE ARTICLE
// RECEVE NEW BLOG DATA AS A PAYLOAD
export const fetchSingleArticleFail = (payload) => ({
  payload,
  type: constants.FETCH_SINGLE_ARTICLE_FAIL,
});

// ACTION FOR CREATING ARTICLE DRAFT
// RECEVE ARTICLE DRAFT DATA AS A PAYLOAD
export const createArticleDraft = (payload) => ({
  payload,
  type: constants.CREATE_ARTICLE_DRAFT,
});

// SUCCESS ACTION FOR CREATING ARTICLE DRAFT
// RECEVE SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const createArticleDraftSuccess = (payload) => ({
  payload,
  type: constants.CREATE_ARTICLE_DRAFT_SUCCESS,
});

// FAIL ACTION FOR CREATING ARTICLE DRAFT
// RECEVE FAIL STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const createArticleDraftFail = (payload) => ({
  payload,
  type: constants.CREATE_ARTICLE_DRAFT_FAIL,
});

// ACTION FOR UPDATING ARTICLE DRAFT
// RECEVE ARTICLE DRAFT DATA AS A PAYLOAD
export const updateArticleDraft = (payload) => ({
  payload,
  type: constants.UPDATE_ARTICLE_DRAFT,
});

// SUCCESS ACTION FOR UPDATING ARTICLE DRAFT
// RECEVE SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const updateArticleDraftSuccess = (payload) => ({
  payload,
  type: constants.UPDATE_ARTICLE_DRAFT_SUCCESS,
});

// FAIL ACTION FOR UPDATING ARTICLE DRAFT
// RECEVE FAIL STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const updateArticleDraftFail = (payload) => ({
  payload,
  type: constants.UPDATE_ARTICLE_DRAFT_FAIL,
});

// ACTION FOR CREATING ARTICLE BY ADMIN
// RECEVE ARTICLE DATA THAT ADMIN ENTERD IN THE FORM AS A PAYLOAD
export const adminCreateArticle = (payload) => ({
  payload,
  type: constants.ADMIN_CREATE_ARTICLE,
});

// SUCCESS ACTION FOR CREATING ARTICLE BY ADMIN
// RECEVE SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const adminCreateArticleSuccess = (payload) => ({
  payload,
  type: constants.ADMIN_CREATE_ARTICLE_SUCCESS,
});

// FAIL ACTION FOR CREATING ARTICLE BY ADMIN
// RECEVE FAIL STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const adminCreateArticleFail = (payload) => ({
  payload,
  type: constants.ADMIN_CREATE_ARTICLE_FAIL,
});
// ACTION FOR UPDATING ARTICLE BY ADMIN
// RECEVE ARTICLE DATA THAT ADMIN ENTERD IN THE FORM AS A PAYLOAD
export const adminUpdateArticle = (payload) => ({
  payload,
  type: constants.ADMIN_UPDATE_ARTICLE,
});

// SUCCESS ACTION FOR UPDATING ARTICLE BY ADMIN
// RECEVE SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const adminUpdateArticleSuccess = (payload) => ({
  payload,
  type: constants.ADMIN_UPDATE_ARTICLE_SUCCESS,
});

// FAIL ACTION FOR UPDATING ARTICLE BY ADMIN
// RECEVE FAIL STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const adminUpdateArticleFail = (payload) => ({
  payload,
  type: constants.ADMIN_UPDATE_ARTICLE_FAIL,
});

// ACTION FOR DELETING ARTICLE BY ADMIN
// RECEVE ARTICLE ID THAT NEEDS TO BE DELETED AS A PAYLOAD
export const adminDeleteArticle = (payload) => ({
  payload,
  type: constants.ADMIN_DELETE_ARTICLE,
});

// SUCCESS ACTION FOR DELETING ARTICLE BY ADMIN
// RECEVE SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const adminDeleteArticleSuccess = (payload) => ({
  payload,
  type: constants.ADMIN_DELETE_ARTICLE_SUCCESS,
});

// FAIL ACTION FOR DELETING ARTICLE BY ADMIN
// RECEVE FAIL STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const adminDeleteArticleFail = (payload) => ({
  payload,
  type: constants.ADMIN_DELETE_ARTICLE_FAIL,
});

// ACTION FOR PUBLISHING ARTICLE DRAFT BY ADMIN
// RECEVE ARTICLE ID THAT NEEDS TO BE PUBLISHED AS A PAYLOAD
export const publishArticleDraft = (payload) => ({
  payload,
  type: constants.PUBLISH_ARTICLE_DRAFT,
});

// SUCCESS ACTION FOR PUBLISHING ARTICLE DRAFT BY ADMIN
// RECEVE SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const publishArticleDraftSuccess = (payload) => ({
  payload,
  type: constants.PUBLISH_ARTICLE_DRAFT_SUCCESS,
});

// FAIL ACTION FOR PUBLISHING ARTICLE DRAFT BY ADMIN
// RECEVE FAIL STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const publishArticleDraftFail = (payload) => ({
  payload,
  type: constants.PUBLISH_ARTICLE_DRAFT_FAIL,
});

// ACTION FOR ACTIVATING SINGLE ARTICLE MODAL
export const activateSingleArticleLoader = () => ({
  type: constants.ACTIVATE_SINGLE_ARTICLE_LOADER,
});
