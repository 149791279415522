import constants from "./actionTypes";

const initState = {
  pageWidgets: { one: [], two: [], three: [] },
  loader: false,
  themeWidgetStyle: {},
  serviceMails: [],
  serviceMailsLoader: false,
  widgetContentData: {},
  widgetModalStep: "landingScreen",
  widgetContentTypeCats: [],
  widgetSettingsData: {},
  widgetSettingsDataLoader: false,
  widgetsInfoData: [],
  formMode: "",
};

export default (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case constants.RESET_PAGE_WIDGETS:
      return {
        ...state,
        pageWidgets: { one: [], two: [], three: [] },
      };
    case constants.SET_WIDGET_FORM_MODE:
      return {
        ...state,
        formMode: payload,
      };
    case constants.GET_WIDGETS_INFO_DATA_SUCCESS:
      return {
        ...state,
        widgetsInfoData: payload,
      };
    case constants.GET_EDIT_WIDGET_CONTENT_DATA:
      return {
        ...state,
        widgetSettingsDataLoader: true,
      };
    case constants.GET_EDIT_WIDGET_CONTENT_DATA_SUCCESS:
      return {
        ...state,
        widgetSettingsData: payload,
        widgetSettingsDataLoader: false,
      };
    case constants.RESET_WIDGET_CONTENT_TYPE_CATS:
      return {
        ...state,
        widgetContentTypeCats: [],
      };
    case constants.GET_WIDGET_CONTET_TYPE_CATS_SUCCESS:
      return {
        ...state,
        widgetContentTypeCats: payload,
      };
    case constants.SET_WIDGET_MODAL_STEP:
      return {
        ...state,
        widgetModalStep: payload,
      };
    case constants.GET_PAGE_WIDGETS:
      return {
        ...state,
        loader: true,
      };
    case constants.SET_WIDGET_CONTENT_DATA:
      return {
        ...state,
        widgetContentData: payload,
      };
    case constants.GET_LICENCE_SERVICE_MAILS_SUCCESS:
      return {
        ...state,
        serviceMailsLoader: false,
        serviceMails: payload,
      };
    case constants.GET_LICENCE_SERVICE_MAILS:
      return {
        ...state,
        serviceMailsLoader: true,
      };
    case constants.GET_PAGE_WIDGETS_SUCCESS:
      return {
        ...state,
        pageWidgets: payload,
        loader: false,
      };
    case constants.GET_PAGE_WIDGETS_FAIL:
      return {
        ...state,
        loader: false,
        // pageWidgets: { one: [], two: [], three: [] },
      };
    case constants.SET_THEME_WIDGET_STYLE:
      return {
        ...state,
        themeWidgetStyle: payload,
      };
    default:
      return {
        ...state,
      };
  }
};
