import axios from "axios";
import store from "../store";
import { logoutUserSuccess } from "../store/auth/actions";
import { closeAllModals, activateModal } from "../store/layout/actions";
import { NotificationManager } from "react-notifications";

const api = axios.create({
  // baseURL: "http://api.guru-new.local/v1",
  baseURL: "https://guruapi.netplus.rs/v1",

  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Response interceptor for API calls
api.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    // console.log("error", error);
    if (error.response.status === 511) {
      const state = store.getState();
      localStorage.removeItem("token");
      await store.dispatch(logoutUserSuccess());
      await store.dispatch(closeAllModals());
      await store.dispatch(activateModal("loginModal"));
      NotificationManager.info(
        state.translation.messages.expiredSession[1],
        "",
        3000
      );
    }
    return Promise.reject(error);
  }
);

export default api;
