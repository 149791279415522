import constants from "./actionTypes";

// ACTION FOR GETING ALL PLANNERS FROM SERVER
export const getAllPlanners = (payload) => ({
  type: constants.GET_ALL_PLANNERS,
  payload,
});
// ACTION FOR SUCCESSFULY FETCHED PLANNERS FROM SERVER
// RECIVE PLANNERS DATA FROM THE SERVER AS A PAYLOAD
export const getAllPlannersSuccess = (payload) => ({
  type: constants.GET_ALL_PLANNERS_SUCCESS,
  payload,
});
// ACTION FOR FAILD FETCHED OF PLANNERS FROM SERVER
// RECIVE MESSAGE AND STATUS CODE FROM THE SERVER AS A PAYLOAD
export const getAllPlannersFail = (payload) => ({
  type: constants.GET_ALL_PLANNERS_FAIL,
  payload,
});

export const publicPlanner = (payload) => ({
  type: constants.PUBLIC_PLANNER,
  payload,
});
// ACTION FOR SUCCESSFULY FETCHED PLANNERS FROM SERVER
// RECIVE PLANNERS DATA FROM THE SERVER AS A PAYLOAD
export const publicPlannerSuccess = (payload) => ({
  type: constants.PUBLIC_PLANNER_SUCCESS,
  payload,
});
// ACTION FOR FAILD FETCHED OF PLANNERS FROM SERVER
// RECIVE MESSAGE AND STATUS CODE FROM THE SERVER AS A PAYLOAD
export const gpublicPlannerFail = (payload) => ({
  type: constants.PUBLIC_PLANNER_FAIL,
  payload,
});
// // ACTION FOR DEFAULT SETTING OF THE PLANNER OPTIONS
// // RECIVED OBJECT HAVE PROPERTYS OF PLANNER ID AND VALUES FALSE
// // THIS IS USED TO CONDITIONALLY SHOW THE OPTIONS POPUP THAT USER IS ACTIVATING
// // BY CLICKING ON THE THREE DOT IMAGE IN THE LISTING
// export const setShowPlannersOptions = (payload) => ({
//   type: constants.SET_SHOW_PLANNER_OPTIONS,
//   payload,
// });
// ACTION TO TRIGGER PLANNER DELETE
// RECIVES PLANNER ID AS A PYLOAD THAT NEEDS TO BE DELETED FROM THE SERVER
export const deletePlanner = (payload) => ({
  type: constants.DELETE_PLANNER,
  payload,
});
// ACTION FOR SUCCESS PLANNER DELETE
// RECIVES SUCCES STATUS CODE AND MESSAGE FROM THE SERVER
export const deletePlannerSuccess = (payload) => ({
  type: constants.DELETE_PLANNER_SUCCESS,
  payload,
});
// ACTION FOR FAIL PLANNER DELETE
// RECIVES EROR STATUS CODE AND MESSAGE FROM THE SERVER
export const deletePlannerFail = (payload) => ({
  type: constants.DELETE_PLANNER_FAIl,
  payload,
});
// // ACTION FOR ADDING NEW PLANNER
// // RECIVES TITLE AND DESCRITION THAT USER ENTERED IN THE FORM
export const addNewPlanner = (payload) => ({
  type: constants.ADD_NEW_PLANNER,
  payload,
});
// ACTION FOR ADDING NEW PLANNER SUCCESS
// RECIVES NEW PLANNER DATA FROM THE SERVER AS A PYLOAD
export const addNewPlannerSuccess = (payload) => ({
  type: constants.ADD_NEW_PLANNER_SUCCESS,
  payload,
});
// ACTION FOR ADDING NEW PLANNER FAIL
// RECIVES ERROR STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const addNewPlannerFail = (payload) => ({
  type: constants.ADD_NEW_PLANNER_FAIL,
  payload,
});
// // ACTION FOR UPDATING PLANNER
// // RECIVES TITLE, DESCRITION AND PLANNER ID NEEDS TO BE UPDATE
export const updatePlanner = (payload) => ({
  type: constants.UPDATE_PLANNER,
  payload,
});
// ACTION FOR UPDATING PLANNER SUCCESS
// RECIVES UPDATE PLANNER DATA FROM THE SERVER AS A PYLOAD
export const updatePlannerSuccess = (payload) => ({
  type: constants.UPDATE_PLANNER_SUCCESS,
  payload,
});
// ACTION FOR UPDATING PLANNER FAIL
// RECIVES ERROR STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const updatePlannerFail = (payload) => ({
  type: constants.UPDATE_PLANNER_FAIL,
  payload,
});

// ACTION FOR GETTION PROFILE PUBLICK PLANNERS
// RECIVES USER ID AS A PAYLOAD
export const getProfilePlanners = (payload) => ({
  type: constants.GET_PROFILE_PUBLIC_PLANNERS,
  payload,
});
// SUCCESS ACTION FOR GETTION PROFILE PUBLICK PLANNERS
// USER PUBLIC PLANNERS DATA FROM THE SERVER AS A PAYLOAD
export const getProfilePlannersSuccess = (payload) => ({
  type: constants.GET_PROFILE_PUBLIC_PLANNERS_SUCCESS,
  payload,
});
// FAIL ACTION FOR GETTION PROFILE PUBLICK PLANNERS
// RECIVES ERROR STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const getProfilePlannersFail = (payload) => ({
  type: constants.GET_PROFILE_PUBLIC_PLANNERS_FAIL,
  payload,
});
