import constants from "./actionTypes";

// SUCCESS ACTION FOR GETTING ALL LICENCES
// RECIVE ALL LICENCES FROM THE SERVER AS A PAYLOAD
export const getAllLicences = (payload) => ({
  type: constants.GET_ALL_LICENCES,
  payload,
});

export const getAllLicencesSuccess = (payload) => ({
  type: constants.GET_ALL_LICENCES_SUCCESS,
  payload,
});

export const getAllPartnersLicences = (payload) => ({
  type: constants.GET_ALL_PARTNERS_LICENCES,
  payload,
});

export const getAllLicencesPartnersSuccess = (payload) => ({
  type: constants.GET_ALL_PARTNERS_LICENCES_SUCCESS,
  payload,
});

export const getAllLicencesFail = (payload) => ({
  type: constants.GET_ALL_LICENCES_FAIL,
  payload,
});

// ACTION TO TRIGGER GETTING AUTH USER LICENCES
// LICENCE FIELDS THAT NEED TO BE FETCHED FROM DATABASE
export const getSalesUserLicences = (payload) => ({
  type: constants.GET_SALES_USER_LICENCES,
  payload,
});
// SUCCESS ACTION FOR GETTING AUTH USER LICENCES
// RECIVE ALL SALES USER LICENCES FROM THE SERVER AS A PAYLOAD
export const getSalesUserLicencesSuccess = (payload) => ({
  type: constants.GET_SALES_USER_LICENCES_SUCCESS,
  payload,
});

// FAIL ACTION FOR TRIGGER GETTING AUTH USER LICENCES
// RECIVE ERROR STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLAOD
export const getSalesUserLicencesFail = (payload) => ({
  type: constants.GET_SALES_USER_LICENCES_FAIL,
  payload,
});

// ACTION TO UPDATE LICENCE LOGO
// RCIVE NEW LICECE LOGO IMAGE AS A PAYLOAD
export const updateLicenceLogo = (payload) => ({
  type: constants.UPDATE_LICENCE_LOGO,
  payload,
});
// SUCCESS ACTION TO UPDATE LICENCE LOGO
// RECIVE SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLAOD
export const updateLicenceLogoSuccess = (payload) => ({
  type: constants.UPDATE_LICENCE_LOGO_SUCCESS,
  payload,
});

// FAIL ACTION TO UPDATE LICENCE LOGO
// RECIVE ERROR STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLAOD
export const updateLicenceLogoFail = (payload) => ({
  type: constants.UPDATE_LICENCE_LOGO_FAIL,
  payload,
});

// ACTION TO UPDATE LICENCE DESCRIPTION
// RCIVE NEW LICECE DESCRIPTION AS A PAYLOAD
export const updateLicenceDescription = (payload) => ({
  type: constants.UPDATE_LICENCE_DESCRIPTION,
  payload,
});
// SUCCESS ACTION TO UPDATE LICENCE DESCRIPTION
// RECIVE SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLAOD
export const updateLicenceDescriptionSuccess = (payload) => ({
  type: constants.UPDATE_LICENCE_DESCRIPTION_SUCCESS,
  payload,
});

// FAIL ACTION TO UPDATE LICENCE DESCRIPTION
// RECIVE ERROR STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLAOD
export const updateLicenceDescriptionFail = (payload) => ({
  type: constants.UPDATE_LICENCE_DESCRIPTION_FAIL,
  payload,
});

// ACTION TO UPDATE ANDROID APP LINK AS PODRSKA
// RECIVE NEW LICECE ID AND APP LINK AS A PAYLOAD
export const addAndroidAppLink = (payload) => ({
  type: constants.ADD_ANDROID_APP_LINK,
  payload,
});
// SUCCESS ACTION TO UPDATE ANDROID APP LINK AS PODRSKA
// RECIVE SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLAOD
export const addAndroidAppLinkSuccess = (payload) => ({
  type: constants.ADD_ANDROID_APP_LINK_SUCCESS,
  payload,
});

// FAIL ACTION TO UPDATE ANDROID APP LINK AS PODRSKA
// RECIVE ERROR STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLAOD
export const addAndroidAppLinkFail = (payload) => ({
  type: constants.ADD_ANDROID_APP_LINK_FAIL,
  payload,
});

// ACTION TO UPDATE ANDROID APP LINK AS PODRSKA
// RECIVE NEW LICECE ID AND APP LINK AS A PAYLOAD
export const createSslCert = (payload) => ({
  type: constants.CREATE_SSL_CERTIFICATE,
  payload,
});
// SUCCESS ACTION TO UPDATE ANDROID APP LINK AS PODRSKA
// RECIVE SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLAOD
export const createSslCertSuccess = (payload) => ({
  type: constants.CREATE_SSL_CERTIFICATE_SUCCESS,
  payload,
});

// FAIL ACTION TO UPDATE ANDROID APP LINK AS PODRSKA
// RECIVE ERROR STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLAOD
export const createSslCertFail = (payload) => ({
  type: constants.CREATE_SSL_CERTIFICATE_FAIL,
  payload,
});

// ACTION TO UPDATE LICENCE ADDRESS
// RCIVE NEW LICENCE ADDRESS AS A PAYLOAD
export const updateLicenceAddress = (payload) => ({
  type: constants.UPDATE_LICENCE_ADDRESS,
  payload,
});
// SUCCESS ACTION TO UPDATE LICENCE ADDRESS
// RECIVE SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLAOD
export const updateLicenceAddressSuccess = (payload) => ({
  type: constants.UPDATE_LICENCE_ADDRESS_SUCCESS,
  payload,
});

// FAIL ACTION TO UPDATE LICENCE ADDRESS
// RECIVE ERROR STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLAOD
export const updateLicenceAddressFail = (payload) => ({
  type: constants.UPDATE_LICENCE_ADDRESS_FAIL,
  payload,
});

// ACTION TO UPDATE LICENCE PHONE NUMBER
// RCIVE NEW LICENCE PHONE NUMBER AS A PAYLOAD
export const updateLicencePhone = (payload) => ({
  type: constants.UPDATE_LICENCE_PHONE,
  payload,
});
// SUCCESS ACTION TO UPDATE LICENCE PHONE NUMBER
// RECIVE SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLAOD
export const updateLicencePhoneSuccess = (payload) => ({
  type: constants.UPDATE_LICENCE_PHONE_SUCCESS,
  payload,
});

// FAIL ACTION TO UPDATE LICENCE PHONE NUMBER
// RECIVE ERROR STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLAOD
export const updateLicencePhoneFail = (payload) => ({
  type: constants.UPDATE_LICENCE_PHONE_FAIL,
  payload,
});

export const createVirtualHost = (payload) => ({
  type: constants.CREATE_VIRTUAL_HOST,
  payload,
});
export const createVirtualHostSuccess = (payload) => ({
  type: constants.CREATE_VIRTUAL_HOST_SUCCESS,
  payload,
});
export const createVirtualHostFail = (payload) => ({
  type: constants.CREATE_VIRTUAL_HOST_FAIL,
  payload,
});

// ACTION TO UPDATE LICENCE STATUS
// RCIVE NEW LICENCE STATUS AS A PAYLOAD
export const updateLicenceStatus = (payload) => ({
  type: constants.UPDATE_LICENCE_STATUS,
  payload,
});
// SUCCESS ACTION TO UPDATE LICENCE STATUS
// RECIVE SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLAOD
export const updateLicenceStatusSuccess = (payload) => ({
  type: constants.UPDATE_LICENCE_STATUS_SUCCESS,
  payload,
});

// FAIL ACTION TO UPDATE LICENCE STATUS
// RECIVE ERROR STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLAOD
export const updateLicenceStatusFail = (payload) => ({
  type: constants.UPDATE_LICENCE_STATUS_FAIL,
  payload,
});

// ACTION FOR SETTING USER LICENCES PAGINATION
// RECIVE PAGINATION OBJ AS A PAYLOAD
export const setUserLicencesPagination = (payload) => ({
  type: constants.SET_USER_LICENCES_PAGINATION,
  payload,
});

// ACTION UPDATING NEW LICENCE DATA
// RECIVE OBJECT KEY AND VALUE THAT NEEDS TO BE UPDATED FROM THE LOCAL STORAGE
export const updateNewLicenceData = (payload) => ({
  type: constants.UPDATE_NEW_LICENCE_DATA,
  payload,
});

// ACTION UPDATING NEW LICENCE DATA SUCCESS
// RECIVE UPDATED OBJECT FROM THE LOCAL STORAGE
export const updateNewLicenceDataSuccess = (payload) => ({
  type: constants.UPDATE_NEW_LICENCE_DATA_SUCCESS,
  payload,
});

// ACTION GETTING NEW LICENCE DATA
export const getNewLicenceData = (payload) => ({
  type: constants.GET_NEW_LICENCE_DATA,
  payload,
});

// ACTION GETTING NEW LICENCE DATA SUCCESS
// RECIVE OBJECT WITH NEW LICENCE DATA FROM THE LOCAL STORAGE
export const getNewLicenceDataSuccess = (payload) => ({
  type: constants.GET_NEW_LICENCE_DATA_SUCCESS,
  payload,
});

// ACTION TO CHECK IF TITLE OF APP IS UNIQUE
// RCIVE NEW TITLE OF APP AS A PAYLOAD
export const createLicenceSubDomail = (payload) => ({
  type: constants.CEATE_LICENCE_SUB_DOMAIN,
  payload,
});
// SUCCESS ACTION TO CHECK IF TITLE OF APP IS UNIQUE
// RECIVE SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLAOD
export const createLicenceSubDomailSuccess = (payload) => ({
  type: constants.CEATE_LICENCE_SUB_DOMAIN_SUCCESS,
  payload,
});

// FAIL ACTION TO CHECK IF TITLE OF APP IS UNIQUE
// RECIVE ERROR STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLAOD
export const createLicenceSubDomailFail = (payload) => ({
  type: constants.CEATE_LICENCE_SUB_DOMAIN_FAIL,
  payload,
});

// ACTION FOR CHECKING IF DOMAIN IS AVAILABLE
// RECEIVE DOMAIN AS A PAYLOAD
export const createLicenceDomain = (payload) => ({
  type: constants.CREATE_LICENCE_DOMAIN,
  payload,
});

// SUCCESS ACTION FOR CHECKING IF DOMAIN IS AVAILABLE
// RECEIVES SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const createLicenceDomainSuccess = (payload) => ({
  type: constants.CREATE_LICENCE_DOMAIN_SUCCESS,
  payload,
});

// FAIL ACTION FOR CHECKING IF DOMAIN IS AVAILABLE
// RECEIVES FAIL STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const createLicenceDomainFail = (payload) => ({
  type: constants.CREATE_LICENCE_DOMAIN_FAIL,
  payload,
});

// ACTION FOR CREATING NEW LICENCE BY SALES USER
// RCIVE NEW LICENCE DATA THAT SALES USER ENTERED IN THE FIELDS
export const createLicence = (payload) => ({
  type: constants.CREATE_LICENCE,
  payload,
});
// SUCCESS ACTION FOR CREATING NEW LICENCE BY SALES USER
// RECIVE SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLAOD
export const createLicenceSuccess = (payload) => ({
  type: constants.CREATE_LICENCE_SUCCESS,
  payload,
});

// FAIL ACTION FOR CREATING NEW LICENCE BY SALES USER
// RECIVE ERROR STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLAOD
export const createLicenceFail = (payload) => ({
  type: constants.CREATE_LICENCE_FAIL,
  payload,
});

// ACTION FOR ACTIVATION LICENCE LOADER
export const activateLicenceLoader = () => ({
  type: constants.ACTIVATE_LICENCE_LOADER,
});

// ACTION FOR CLOSING LICENCE LOADER
export const closeLicenceLoader = (payload) => ({
  type: constants.CLOSE_LICENCE_LOADER,
  payload,
});

// ACTION FOR GETTING ALL DOMAIN PRICES
export const getDomainPrices = () => ({
  type: constants.GET_DOMAIN_PRICES,
});

// SUCCESS ACTION FOR GETTING ALL DOMAIN PRICES
export const getDomainPricesSuccess = (payload) => ({
  type: constants.GET_DOMAIN_PRICES_SUCCESS,
  payload,
});

// ACTION FOR CHECKING DOMAIN AVAILABILITY
export const checkDomain = (payload) => ({
  type: constants.CHECK_DOMAIN,
  payload,
});

// ACTION FOR GETTING DOMAIN
export const getDomain = (payload) => ({
  type: constants.GET_DOMAIN,
  payload,
});

// ACTION FOR GETTING DOMAIN INVOICE
export const getDomainInvoice = (payload) => ({
  type: constants.GET_DOMAIN_INVOICE,
  payload,
});

// ACTION FOR GETTING EUR EXCHANGE RATE
export const getExchangeRate = () => ({
  type: constants.GET_EXCHANGE_RATE,
});

// ACTION FOR GETTING EUR EXCHANGE RATE
export const getExchangeRateSuccess = (payload) => ({
  type: constants.GET_EXCHANGE_RATE_SUCCESS,
  payload,
});

export const getExchangeRateFail = (payload) => ({
  type: constants.GET_EXCHANGE_RATE_FAIL,
  payload,
});

// ACTION TO SEND MAIL FROM PODRSKA TO LICENCE USER
// RECIVE NEW LICECE ID AND APP LINK AS A PAYLOAD
export const sendMailToLicenceUser = (payload) => ({
  type: constants.SEND_MAIL_TO_LICENCE_USER,
  payload,
});
// SUCCESS ACTION TO SEND MAIL FROM PODRSKA TO LICENCE USER
// RECIVE SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLAOD
export const sendMailToLicenceUserSuccess = (payload) => ({
  type: constants.SEND_MAIL_TO_LICENCE_USER_SUCCESS,
  payload,
});

// FAIL ACTION TO SEND MAIL FROM PODRSKA TO LICENCE USER
// RECIVE ERROR STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLAOD
export const sendMailToLicenceUserFail = (payload) => ({
  type: constants.SEND_MAIL_TO_LICENCE_USER_FAIL,
  payload,
});

// ACTION TO SEND MAIL FROM PODRSKA TO LICENCE USER
// RECIVE NEW LICECE ID AND APP LINK AS A PAYLOAD
export const buildPaidNativeApp = (payload) => ({
  type: constants.BUILD_PAID_NATIVE_APP,
  payload,
});
// SUCCESS ACTION TO SEND MAIL FROM PODRSKA TO LICENCE USER
// RECIVE SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLAOD
export const buildPaidNativeAppSuccess = (payload) => ({
  type: constants.BUILD_PAID_NATIVE_APP_SUCCESS,
  payload,
});

// FAIL ACTION TO SEND MAIL FROM PODRSKA TO LICENCE USER
// RECIVE ERROR STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLAOD
export const buildPaidNativeAppFail = (payload) => ({
  type: constants.BUILD_PAID_NATIVE_APP_FAIL,
  payload,
});
