import constants from "./actionTypes";

// INITIAL STATE OF THIS REDUCER
const initState = {
  loader: false,
  singleProduct: [],
};

// REDUCER FUNCTION
export default (state = initState, action) => {
  const { payload, type } = action;
  switch (type) {
    case constants.LICENCE_CLIENT_CREATE_PRODUCT_DRAFT:
      return {
        ...state,
        loader: true,
      };
    case constants.ACTIVATE_SINGLE_PRODUCT_LOADER:
      return {
        ...state,
        loader: true,
      };
    case constants.FETCH_SINGLE_PRODUCT:
      return {
        ...state,
        loader: true,
      };

    case constants.FETCH_SINGLE_PRODUCT_SUCCESS:
      return {
        ...state,
        singleProduct: payload,
        loader: false,
      };

    case constants.FETCH_SINGLE_PRODUCT_FAIL:
      return {
        ...state,
        singleProduct: {},

        loader: false,
      };

    //  IF NONE OF THE CASE ABOVE IS TRIGGERED RETURN DEFAULT STATE
    default:
      return {
        ...state,
      };
  }
};
