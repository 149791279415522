import constants from "./actionTypes";

// INITIAL STATE OF THIS REDUCER
const initState = {
  articles: [],
  articleCategory: [],
  singleArticle: [],
  selectedContentTypeOrderArticle: [],
  pagination: {
    currentPage: "",
    pageCount: "",
    perPage: "",
    totlCount: "",
  },
  loading: false,
  authUserArticles: [],
  authUserArticlesPagination: {
    currentPage: "",
    pageCount: "",
    perPage: "",
    totlCount: "",
  },
};

// REDUCER FUNCTION
export default (state = initState, action) => {
  const { payload, type } = action;
  switch (type) {
    //  SET THE SINGLE ARTICLE DATA FROM THE SERVER AS A PAYLOAD
    case constants.GET_SINGLE_ARTICLE_SUCCESS:
      return {
        ...state,
        singleArticle: payload,
      };
    case constants.SET_PAGINATION_FOR_AUTH_USER_ARTICLES:
      return {
        ...state,
        authUserArticlesPagination: payload,
      };
    //  SET ARTICLE CONTENT TYPE ORDER TO BE THE PAYLOAD
    case constants.SET_SELECTED_CONTENT_TYPE_ORDER_ARTICLE:
      return {
        ...state,
        selectedContentTypeOrderArticle: payload,
      };
    // SET ARTICLES TO BE THE PAYLOAD RECIVED FROM API
    case constants.FETCH_ARTICLES_SUCCESS:
      return {
        ...state,
        articles: payload,
      };

    case constants.ACTIVATE_ARTICLES_LOADER:
      return {
        ...state,
        loader: true,
      };
    // SET PAGINATION TO BE THE PAYLOAD RECIVED FROM API
    case constants.GET_LICENCE_USER_ARTICLES:
      return {
        ...state,
        loader: true,
      };
    case constants.GET_LICENCE_USER_ARTICLES_SUCCESS:
      return {
        ...state,
        loader: false,
        authUserArticles: payload,
      };
    // SET PAGINATION TO BE THE PAYLOAD RECIVED FROM API
    case constants.SET_PAGINATION:
      return {
        ...state,
        pagination: payload,
      };

    case constants.FETCH_ARTICLE_LIST_BY_CATEGORY_SLUG:
      return {
        ...state,
        loading: true,
      };

    case constants.FETCH_ARTICLE_LIST_BY_CATEGORY_SLUG_SUCCESS:
      return {
        ...state,
        articleCategory: payload[0].data[0],
        articles: payload[1].data,
        loading: false,
      };
    //  IF NONE OF THE CASE ABOVE IS TRIGGERED RETURN DEFAULT STATE
    default:
      return {
        ...state,
      };
  }
};
