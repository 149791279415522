const FETCH_SINGLE_ARTICLE = "FETCH_SINGLE_ARTICLE";
const FETCH_SINGLE_ARTICLE_SUCCESS = "FETCH_SINGLE_ARTICLE_SUCCESS";
const FETCH_SINGLE_ARTICLE_FAIL = "FETCH_SINGLE_ARTICLE_FAIL";
const CREATE_ARTICLE_DRAFT = "CREATE_ARTICLE_DRAFT";
const CREATE_ARTICLE_DRAFT_SUCCESS = "CREATE_ARTICLE_DRAFT_SUCCESS";
const CREATE_ARTICLE_DRAFT_FAIL = "CREATE_ARTICLE_DRAFT_FAIL";
const UPDATE_ARTICLE_DRAFT = "UPDATE_ARTICLE_DRAFT";
const UPDATE_ARTICLE_DRAFT_SUCCESS = "UPDATE_ARTICLE_DRAFT_SUCCESS";
const UPDATE_ARTICLE_DRAFT_FAIL = "UPDATE_ARTICLE_DRAFT_FAIL";
const ADMIN_CREATE_ARTICLE = "ADMIN_CREATE_ARTICLE";
const ADMIN_CREATE_ARTICLE_SUCCESS = "ADMIN_CREATE_ARTICLE_SUCCESS";
const ADMIN_CREATE_ARTICLE_FAIL = "ADMIN_CREATE_ARTICLE_FAIL";
const ADMIN_UPDATE_ARTICLE = "ADMIN_UPDATE_ARTICLE";
const ADMIN_UPDATE_ARTICLE_SUCCESS = "ADMIN_UPDATE_ARTICLE_SUCCESS";
const ADMIN_UPDATE_ARTICLE_FAIL = "ADMIN_UPDATE_ARTICLE_FAIL";
const ADMIN_DELETE_ARTICLE = "ADMIN_DELETE_ARTICLE";
const ADMIN_DELETE_ARTICLE_SUCCESS = "ADMIN_DELETE_ARTICLE_SUCCESS";
const ADMIN_DELETE_ARTICLE_FAIL = "ADMIN_DELETE_ARTICLE_FAIL";
const PUBLISH_ARTICLE_DRAFT = "PUBLISH_ARTICLE_DRAFT";
const PUBLISH_ARTICLE_DRAFT_SUCCESS = "PUBLISH_ARTICLE_DRAFT_SUCCESS";
const PUBLISH_ARTICLE_DRAFT_FAIL = "PUBLISH_ARTICLE_DRAFT_FAIL";
const ACTIVATE_SINGLE_ARTICLE_LOADER = "ACTIVATE_SINGLE_ARTICLE_LOADER";

export default {
  FETCH_SINGLE_ARTICLE,
  FETCH_SINGLE_ARTICLE_SUCCESS,
  FETCH_SINGLE_ARTICLE_FAIL,
  CREATE_ARTICLE_DRAFT,
  CREATE_ARTICLE_DRAFT_SUCCESS,
  CREATE_ARTICLE_DRAFT_FAIL,
  UPDATE_ARTICLE_DRAFT,
  UPDATE_ARTICLE_DRAFT_SUCCESS,
  UPDATE_ARTICLE_DRAFT_FAIL,
  ADMIN_CREATE_ARTICLE,
  ADMIN_CREATE_ARTICLE_SUCCESS,
  ADMIN_CREATE_ARTICLE_FAIL,
  ADMIN_UPDATE_ARTICLE,
  ADMIN_UPDATE_ARTICLE_SUCCESS,
  ADMIN_UPDATE_ARTICLE_FAIL,
  ADMIN_DELETE_ARTICLE,
  ADMIN_DELETE_ARTICLE_SUCCESS,
  ADMIN_DELETE_ARTICLE_FAIL,
  PUBLISH_ARTICLE_DRAFT,
  PUBLISH_ARTICLE_DRAFT_SUCCESS,
  PUBLISH_ARTICLE_DRAFT_FAIL,
  ACTIVATE_SINGLE_ARTICLE_LOADER,
};
