import constants from "./actionTypes";
import { all, call, takeLatest, put } from "redux-saga/effects";
import {
  getAllLicencesApi,
  getSalesUsresLicencesApi,
  salesUserUpdateLicenceLogoApi,
  salesUserUpdateLicenceLogoFromStockApi,
  salesUserUpdateLicenceDescriptionApi,
  adminAddAndroidAppLinkApi,
  salesUserUpdateLicenceLocationApi,
  salesUserUpdateLicencePhoneApi,
  salesUserUpdateLicenceStatusApi,
  createLicenceSubDomainApi,
  createLicenceDomainApi,
  sendMailToLicenceUserApi,
  createLicenceApi,
  getDomainPricesApi,
  checkDomainApi,
  getDomainApi,
  getDomainInvoiceApi,
  getExchangeRateApi,
  createVirtualHostApi,
  createSslCertificateApi,
  getAllPartnersLicencesApi,
} from "../../services/ApiCalls";
import {
  getAllLicencesSuccess,
  getSalesUserLicencesSuccess,
  getNewLicenceDataSuccess,
  setUserLicencesPagination,
  updateNewLicenceDataSuccess,
  getSalesUserLicences,
  closeLicenceLoader,
  getDomainPricesSuccess,
  getExchangeRateSuccess,
  getExchangeRateFail,
  createVirtualHostSuccess,
  getAllLicencesPartnersSuccess,
} from "./actions";
import { NotificationManager } from "react-notifications";
import { checkIsOutdated } from "./utills";

// SAGA FOR GETTING SALES USER LICENCES
// RECIVES TYPE AND PAYLOAD  FROM ACTION
// TYPE : constants.GET_SALES_USER_LICENCES
// PAYLOAD : EXPANDS FIELD
function* getSalesUserLicencesSaga(action) {
  try {
    const userLicences = yield call(getSalesUsresLicencesApi, action.payload);
    const setPaginationObj = {
      currentPage: parseInt(userLicences.headers["x-pagination-current-page"]),
      pageCount: parseInt(userLicences.headers["x-pagination-page-count"]),
      perPage: parseInt(userLicences.headers["x-pagination-per-page"]),
      totlCount: parseInt(userLicences.headers["x-pagination-total-count"]),
    };
    yield put(setUserLicencesPagination(setPaginationObj));

    yield put(getSalesUserLicencesSuccess(userLicences.data));
  } catch (e) {}
}

// SAGA FOR GETTING ALLLICENCES FOR SUPER ADMIN
// RECIVES TYPE AND PAYLOAD  FROM ACTION
// TYPE : constants.GET_ALL_LICENCES
// PAYLOAD : EXPANDS FIELD
function* getAllLicencesSaga(action) {
  try {
    const allLicences = yield call(getAllLicencesApi, action.payload);
    const setPaginationObj = {
      currentPage: parseInt(allLicences.headers["x-pagination-current-page"]),
      pageCount: parseInt(allLicences.headers["x-pagination-page-count"]),
      perPage: parseInt(allLicences.headers["x-pagination-per-page"]),
      totlCount: parseInt(allLicences.headers["x-pagination-total-count"]),
    };
    yield put(setUserLicencesPagination(setPaginationObj));

    yield put(getAllLicencesSuccess(allLicences.data));
  } catch (e) {}
}

// SAGA FOR GETTING ALL PARTNERS LICENCES FOR SUPER ADMIN
// RECIVES TYPE AND PAYLOAD  FROM ACTION
// TYPE : constants.GET_ALL_PARTNERS_LICENCES
// PAYLOAD : EXPANDS FIELD
function* getAllPartnersLicencesSaga(action) {
  try {
    const allLicences = yield call(getAllPartnersLicencesApi, action.payload);
    const setPaginationObj = {
      currentPage: parseInt(allLicences.headers["x-pagination-current-page"]),
      pageCount: parseInt(allLicences.headers["x-pagination-page-count"]),
      perPage: parseInt(allLicences.headers["x-pagination-per-page"]),
      totlCount: parseInt(allLicences.headers["x-pagination-total-count"]),
    };
    yield put(setUserLicencesPagination(setPaginationObj));

    yield put(getAllLicencesPartnersSuccess(allLicences.data));
  } catch (e) {}
}

// SAGA TO UPDATE LICENCE LOGO
// RECIVES TYPE AND PAYLOAD  FROM ACTION
// TYPE : constants.UPDATE_LICENCE_LOGO
// PAYLOAD : NEW LICENCE LOGO
function* updateLicenceLogoSaga(action) {
  try {
    const updateLogo = action.payload.icon.download_location
      ? yield call(salesUserUpdateLicenceLogoFromStockApi, action.payload)
      : yield call(salesUserUpdateLicenceLogoApi, action.payload);
    NotificationManager.success(updateLogo.message, "", 2000);

    //   const userLicences = yield call(getSalesUsresLicencesApi, action.payload);
    //   console.log("userLicences", userLicences);
  } catch (e) {}
}

// SAGA TO UPDATE LICENCE DESCRIPTION
// RECIVES TYPE AND PAYLOAD  FROM ACTION
// TYPE : constants.UPDATE_LICENCE_DESCRIPTION
// PAYLOAD : NEW LICENCE DESCRIPTION
function* updateLicenceDescriptionSaga(action) {
  try {
    const updateDescription = yield call(
      salesUserUpdateLicenceDescriptionApi,
      action.payload
    );
    NotificationManager.success(updateDescription.message, "", 2000);

    //   const userLicences = yield call(getSalesUsresLicencesApi, action.payload);
    //   console.log("userLicences", userLicences);
  } catch (e) {
    console.log("e", e);
  }
}

// SAGA SEND MAIL FROM SUPERADMIN TO SALES USER
// RECIVES TYPE AND PAYLOAD  FROM ACTION
// TYPE : constants.SEND_MAIL_TO_LICENCE_USER
// PAYLOAD : LICENCE_ID
function* sendMailToLicenceUserSaga(action) {
  try {
    const sendMail = yield call(sendMailToLicenceUserApi, action.payload);
    console.log("sendMail", sendMail);

    NotificationManager.success(sendMail.message, "", 2000);
  } catch (error) {
    console.log("error", error);
    console.log("OVDE USAO");
  }
}

// SAGA TO ADD LICENCE ANDROID APP LINK
// RECIVES TYPE AND PAYLOAD  FROM ACTION
// TYPE : constants.ADD_ANDROID_APP_LINK
// PAYLOAD : APP_LINK and LICENCE_ID
function* addAndroidAppLinkSaga(action) {
  console.log("AKCIJAAAAAA", action);
  try {
    const addAppLink = yield call(adminAddAndroidAppLinkApi, action.payload);
    console.log("ACTION PAYLOAD IZ SAGE", action.payload);

    NotificationManager.success(addAppLink.message, "", 2000);
  } catch (e) {
    console.log("LINK APP ERROR SAGA");
    console.log("e", e);
  }
}

// SAGA TO UPDATE LICENCE ADDRESS
// RECIVES TYPE AND PAYLOAD  FROM ACTION
// TYPE : constants.UPDATE_LICENCE_ADDRESS
// PAYLOAD : NEW LICENCE ADDRESS
function* updateLicenceAddressSaga(action) {
  try {
    // console.log("action", action);
    const updateAddress = yield call(
      salesUserUpdateLicenceLocationApi,
      action.payload
    );
    NotificationManager.success(updateAddress.message, "", 2000);
  } catch (e) {
    console.log("error", e);
  }
}

// SAGA TO UPDATE LICENCE PHONE
// RECIVES TYPE AND PAYLOAD  FROM ACTION
// TYPE : constants.UPDATE_LICENCE_PHONE
// PAYLOAD : NEW LICENCE PHONE
function* updateLicencePhoneSaga(action) {
  try {
    const updatePhone = yield call(
      salesUserUpdateLicencePhoneApi,
      action.payload
    );

    //   const userLicences = yield call(getSalesUsresLicencesApi, action.payload);
    //   console.log("userLicences", userLicences);
    NotificationManager.success(updatePhone.message, "", 2000);
  } catch (e) {
    console.log("error", e);
  }
}

// SAGA TO CREATE VIRTUAL HOST AS SUPERADMIN
// RECIVES TYPE AND PAYLOAD  FROM ACTION
// TYPE : constants.CREATE_VIRTUAL_HOST
// PAYLOAD : LICENCE_ID
function* createVirtualHostSaga(action) {
  try {
    const crVirtualHost = yield call(createVirtualHostApi, action.payload);
    console.log("crVirtualHost", crVirtualHost);

    NotificationManager.success(crVirtualHost.message, "", 2000);
  } catch (error) {
    console.log("error", error);
    console.log("OVDE USAO");
  }
}

// SAGA TO CREATE SSL CERRIFICATE AS SUPER ADMIN
// RECIVES TYPE AND PAYLOAD  FROM ACTION
// TYPE : constants.CREATE_SSL_CERTIFICATE
// PAYLOAD : LICENCE_ID
function* createSslCertificateSaga(action) {
  try {
    const createSslCert = yield call(createSslCertificateApi, action.payload);
    console.log("createSslCert", createSslCert);

    NotificationManager.success(createSslCert.message, "", 2000);
  } catch (error) {
    console.log("error", error);
    console.log("OVDE USAO");
  }
}

// SAGA TO UPDATE LICENCE STATUS
// RECIVES TYPE AND PAYLOAD  FROM ACTION
// TYPE : constants.UPDATE_LICENCE_STATUS
// PAYLOAD : UPDATED LICENCE STATUS
function* updateLicenceStatusSaga(action) {
  try {
    const updateStatus = yield call(
      salesUserUpdateLicenceStatusApi,
      action.payload
    );
    const payload = {
      expands: [
        "title_of_android_app",
        "title_of_app_unique",
        "phone",
        "location",
        "language",
        "status",
        "licence_type",
        "VHost_status",
        "android_app_link",
        "ssl_status",
        "user",
      ],
      page: 1,
    };
    const userLicences = yield call(getSalesUsresLicencesApi, payload);
    yield put(getSalesUserLicencesSuccess(userLicences.data));

    //   const userLicences = yield call(getSalesUsresLicencesApi, action.payload);
    //   console.log("userLicences", userLicences);
    NotificationManager.success(updateStatus.message, "", 2000);
  } catch (e) {
    console.log("error", e);
  }
}

// SAGA FOR UPDATING NEW LICENE DATA FROM THE LOCAL STORAGE
// RECIVES TYPE AND PAYLOAD  FROM ACTION
// TYPE : constants.UPDATE_NEW_LICENCE_DATA
// PAYLOAD : OBJECT KEY AND VALUE THAT NEEDS TO BE UPDATED FROM THE LOCAL STORAGE
function* updateNewLicenceDataSaga(action) {
  try {
    if (action.payload.name === "address") {
      localStorage.setItem(
        "newLicenceData",
        JSON.stringify(action.payload.value)
      );
      yield put(updateNewLicenceDataSuccess(action.payload.value));
    } else {
      let newLicenceData = JSON.parse(localStorage.getItem("newLicenceData"));
      newLicenceData = {
        ...newLicenceData,
        [action.payload.name]: action.payload.value,
        lastUpdate: Date.now(),
      };
      localStorage.setItem("newLicenceData", JSON.stringify(newLicenceData));
      yield put(updateNewLicenceDataSuccess(newLicenceData));
    }

    // console.log("newLIcenceData", newLicenceData);
  } catch (e) {
    console.log("error", e);
  }
}

// SAGA FOR GETTING NEW LICENE DATA FROM THE LOCAL STORAGE
// RECIVES TYPE AND PAYLOAD  FROM ACTION
// TYPE : constants.GET_NEW_LICENCE_DATA
// PAYLOAD : OBJECT WITH NEW LICENE DATA FROM THE LOCAL STORAGE
function* getNewLicenceDataSaga(action) {
  try {
    let newLicenceData = JSON.parse(localStorage.getItem("newLicenceData"));
    if (newLicenceData && checkIsOutdated(newLicenceData.lastUpdate)) {
      yield put(getNewLicenceDataSuccess(newLicenceData));
    } else if (newLicenceData) {
      NotificationManager.info("Smisliti poruku", "", 2000);
      localStorage.removeItem("newLicenceData");
      yield put(getNewLicenceDataSuccess({}));
    } else {
      localStorage.removeItem("newLicenceData");
      yield put(getNewLicenceDataSuccess({}));
    }

    // console.log("newLIcenceData", newLicenceData);
  } catch (e) {
    console.log("error", e);
  }
}

// SAGA FOR CREATING UNIQUE LICENCE SUB DOMAIN
// RECIVES TYPE AND PAYLOAD  FROM ACTION
// TYPE : constants.CREATE_LICENCE_SUB_DOMAIN
// PAYLOAD : TITLE OF APP
function* createLicenceSubDomainSaga(action) {
  try {
    const subdomain = yield call(createLicenceSubDomainApi, action.payload);

    let newLicenceData = JSON.parse(localStorage.getItem("newLicenceData"));
    newLicenceData = {
      ...newLicenceData,
      title_of_app_unique: subdomain,
      lastUpdate: Date.now(),
    };
    localStorage.setItem("newLicenceData", JSON.stringify(newLicenceData));
    yield put(updateNewLicenceDataSuccess(newLicenceData));

    // console.log("newLIcenceData", newLicenceData);
  } catch (e) {
    console.log("error", e);
  }
}

// SAGA FOR CREATING UNIQUE LICENCE DOMAIN
// RECEIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.CREATE_LICENCE_DOMAIN
// PAYLOAD : TITLE OF APP UNIQUE / DOMAIN
function* createLicenceDomainSaga(action) {
  try {
    const domain = yield call(createLicenceDomainApi, action.payload);
    let newLicenceData = JSON.parse(localStorage.getItem("newLicenceData"));
    newLicenceData = {
      ...newLicenceData,
      confirmedDomain: domain.domainStatus,
      domainMessage: domain.domainMessage,
      lastUpdate: Date.now(),
    };
    localStorage.setItem("newLicenceData", JSON.stringify(newLicenceData));
    yield put(updateNewLicenceDataSuccess(newLicenceData));
    // console.log("newLIcenceData", newLicenceData);
  } catch (e) {
    console.log("error", e);
  }
}

// SAGA FOR CREATING UNIQUE LICENCE DOMAIN
// RECEIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.CREATE_LICENCE_DOMAIN
// PAYLOAD : TITLE OF APP UNIQUE / DOMAIN
function* checkDomainSaga(action) {
  try {
    const domain = yield call(checkDomainApi, action.payload);
    let newLicenceData = JSON.parse(localStorage.getItem("newLicenceData"));
    newLicenceData = {
      ...newLicenceData,
      isDomainFree: domain.isDomainFree,
      acceptedDomain: domain.acceptedDomain,
      lastUpdate: Date.now(),
    };
    localStorage.setItem("newLicenceData", JSON.stringify(newLicenceData));
    yield put(updateNewLicenceDataSuccess(newLicenceData));
    // console.log("newLIcenceData", newLicenceData);
  } catch (e) {
    console.log("error", e);
  }
}

// SAGA FOR CREATING UNIQUE LICENCE DOMAIN
// RECEIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.CREATE_LICENCE_DOMAIN
// PAYLOAD : TITLE OF APP UNIQUE / DOMAIN
function* getDomainSaga(action) {
  try {
    const domain = yield call(getDomainApi, action.payload);
    let newLicenceData = JSON.parse(localStorage.getItem("newLicenceData"));
    newLicenceData = {
      ...newLicenceData,
      refNo: domain.refNo,
      lastUpdate: Date.now(),
    };
    localStorage.setItem("newLicenceData", JSON.stringify(newLicenceData));
    yield put(updateNewLicenceDataSuccess(newLicenceData));
    // console.log("newLIcenceData", newLicenceData);
  } catch (e) {
    console.log("error", e);
  }
}

// SAGA FOR CREATING UNIQUE LICENCE DOMAIN
// RECEIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.CREATE_LICENCE_DOMAIN
// PAYLOAD : TITLE OF APP UNIQUE / DOMAIN
function* getDomainPricesSaga() {
  try {
    const domainPrices = yield call(getDomainPricesApi);
    yield put(getDomainPricesSuccess(domainPrices));
  } catch (e) {
    console.log("error", e);
  }
}

// SAGA FOR CREATING UNIQUE LICENCE DOMAIN
// RECEIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.CREATE_LICENCE_DOMAIN
// PAYLOAD : TITLE OF APP UNIQUE / DOMAIN
function* getDomainInvoiceSaga(action) {
  try {
    const invoice = yield call(getDomainInvoiceApi, action.payload);
    // const firs22tInvoice = yield call(getDomainInvoiceApi, action.payload);

    // const invoice = {
    //   ...firstInvoice,
    //   items: items.filter(
    //     (item) => item.product === newLicenceData.acceptedDomain
    //   ),
    // };

    console.log("invoice", invoice);
    let newLicenceData = JSON.parse(localStorage.getItem("newLicenceData"));
    newLicenceData = {
      ...newLicenceData,

      domainInvoice: invoice.domainInvoice,

      // domainInvoice: {
      //     ...domainInvoice,
      //     items: items.filter(
      //       (item) => item.product === newLicenceData.acceptedDomain
      //     ),
      //   },

      domainPrice: invoice.domainInvoice.to_pay,
      // totalPrice: invoice.domainInvoice.to_pay + newLicenceData.licencePrice,
      totalPrice: 10,
      lastUpdate: Date.now(),
    };

    // console.log("newLicenceData", newLicenceData);
    // if (newLicenceData && newLicenceData.domainInvoice.items) {
    //   console.log(
    //     "FLITRIRAN ITEM",
    //     newLicenceData.domainInvoice.items.filter(
    //       (item) => item.product === newLicenceData.acceptedDomain
    //     )
    //   );
    // }

    localStorage.setItem("newLicenceData", JSON.stringify(newLicenceData));
    yield put(updateNewLicenceDataSuccess(newLicenceData));
    // console.log("newLIcenceData", newLicenceData);
  } catch (e) {
    console.log("error", e);
  }
}

// SAGA THAT HANDLE NEW LICENCE CREATION
// RECIVES TYPE AND PAYLOAD  FROM ACTION
// TYPE : constants.CREATE_LICENCE
// PAYLOAD : NEW LICENCE DATA THAT SALES USER ENTERED IN THE FIELDS
function* createLicenceSaga(action) {
  try {
    yield call(createLicenceApi, action.payload);
    const payload = {
      expands: [
        "title_of_android_app",
        "title_of_app_unique",
        "phone",
        "location",
        "language",
        "status",
        "licence_type",
        "VHost_status",
        "android_app_link",
        "ssl_status",
        "user",
      ],
      page: 1,
    };
    yield put(getSalesUserLicences(payload));
    // localStorage.removeItem("newLicenceData");
    localStorage.removeItem("templateData");

    // console.log("newLIcenceData", newLicenceData);
  } catch (e) {
    yield put(closeLicenceLoader());
    console.log("error", e.response.data.message);
  }
}

// SAGA FOR GETTING SALES USER LICENCES
// RECIVES TYPE AND PAYLOAD  FROM ACTION
// TYPE : constants.GET_SALES_USER_LICENCES
// PAYLOAD : EXPANDS FIELD
function* getExchangeRateSaga() {
  try {
    const exchangeRates = yield call(getExchangeRateApi);
    if (exchangeRates.data.status === "ok") {
      yield put(getExchangeRateSuccess(exchangeRates.data.result));
    }
  } catch (e) {
    yield put(getExchangeRateFail());
  }
}

function* licenceSaga() {
  yield all([
    takeLatest(constants.GET_ALL_LICENCES, getAllLicencesSaga),
    takeLatest(constants.GET_ALL_PARTNERS_LICENCES, getAllPartnersLicencesSaga),
    takeLatest(constants.GET_SALES_USER_LICENCES, getSalesUserLicencesSaga),
    takeLatest(constants.UPDATE_LICENCE_LOGO, updateLicenceLogoSaga),
    takeLatest(constants.UPDATE_LICENCE_ADDRESS, updateLicenceAddressSaga),
    takeLatest(constants.UPDATE_LICENCE_PHONE, updateLicencePhoneSaga),
    takeLatest(constants.CREATE_VIRTUAL_HOST, createVirtualHostSaga),
    takeLatest(constants.UPDATE_LICENCE_STATUS, updateLicenceStatusSaga),
    takeLatest(constants.UPDATE_NEW_LICENCE_DATA, updateNewLicenceDataSaga),
    takeLatest(constants.GET_NEW_LICENCE_DATA, getNewLicenceDataSaga),
    takeLatest(constants.ADD_ANDROID_APP_LINK, addAndroidAppLinkSaga),
    takeLatest(constants.CEATE_LICENCE_SUB_DOMAIN, createLicenceSubDomainSaga),
    takeLatest(constants.CREATE_LICENCE_DOMAIN, createLicenceDomainSaga),
    takeLatest(constants.CREATE_LICENCE, createLicenceSaga),
    takeLatest(constants.GET_DOMAIN_PRICES, getDomainPricesSaga),
    takeLatest(constants.GET_DOMAIN_INVOICE, getDomainInvoiceSaga),
    takeLatest(constants.CHECK_DOMAIN, checkDomainSaga),
    takeLatest(constants.GET_DOMAIN, getDomainSaga),
    takeLatest(constants.GET_EXCHANGE_RATE, getExchangeRateSaga),
    takeLatest(constants.CREATE_SSL_CERTIFICATE, createSslCertificateSaga),
    takeLatest(constants.SEND_MAIL_TO_LICENCE_USER, sendMailToLicenceUserSaga),
    takeLatest(
      constants.UPDATE_LICENCE_DESCRIPTION,
      updateLicenceDescriptionSaga
    ),
  ]);
}

export default licenceSaga;
