import constants from "./actionTypes";

// ACTION FOR FETCHING CATALOGS BY THE AUTH CLIENT
// RECIVE PRODUCTS DATA FROM THE SERVER AS A PAYLOAD
export const fetchCatalogsByAuthClient = (payload) => ({
  payload,
  type: constants.FETCH_CATALOGS_BY_AUTH_CLIENT,
});
// ACTION FOR FETCHING CATALOGS BY THE AUTH CLIENT SUCCESS
// RECIVE CATALOGS DATA FROM THE SERVER AS A PAYLOAD
export const fetchCatalogsByAuthClientSuccess = (payload) => ({
  payload,
  type: constants.FETCH_CATALOGS_BY_AUTH_CLIENT_SUCCESS,
});
// ACTION FOR FETCHING CATALOGS BY THE AUTH CLIENT FAIL
// RECIVE ERROR MESSAGE AND THE STATUS CODE AS A PAYLOAD
export const fetchCatalogsByAuthClientFail = (payload) => ({
  payload,
  type: constants.FETCH_CATALOGS_BY_AUTH_CLIENT_FAIL,
});

// ACTION FOR SETTING PAGINATION OF AUTH USER BLOGS
// RECIVES HEDERS FROM THE SERVER RESPONSE TO FETCHING BLOGS THAT AUTH USER CREATED AS A PAYOAD
export const setPaginationForAuthClientCatalogs = (payload) => ({
  payload,
  type: constants.SET_PAGINATION_FOR_AUTH_CLIENT_CATALOGS,
});

// ACTION FOR CREATING CATALOG BY THE AUTH CLIENT
// RECIVE CATALOG DATA THAT CLIENT ENTERED IN THE FORM
export const licenceUserCreateCatalog = (payload) => ({
  payload,
  type: constants.LICENCE_USER_CREATE_CATALOG,
});
// ACTION FOR CREATING CATALOG BY THE AUTH CLIENT SUCCESS
// RECIVE SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const licenceUserCreateCatalogSuccess = (payload) => ({
  payload,
  type: constants.LICENCE_USER_CREATE_CATALOG_SUCCESS,
});
// ACTION FOR CREATING CATALOG BY THE AUTH CLIENT FAIL
// RECIVE ERROR STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const licenceUserCreateCatalogFail = (payload) => ({
  payload,
  type: constants.LICENCE_USER_CREATE_CATALOG_FAIL,
});

// ACTION FOR UPDATING CATALOG BY THE AUTH CLIENT
// RECIVE CATALOG DATA THAT CLIENT ENTERED IN THE FORM
export const licenceUserUpdateCatalog = (payload) => ({
  payload,
  type: constants.LICENCE_USER_UPDATE_CATALOG,
});
// ACTION FOR UPDATING CATALOG BY THE AUTH CLIENT SUCCESS
// RECIVE SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const licenceUserUpdateCatalogSuccess = (payload) => ({
  payload,
  type: constants.LICENCE_USER_UPDATE_CATALOG_SUCCESS,
});
// ACTION FOR UPDATING CATALOG BY THE AUTH CLIENT FAIL
// RECIVE ERROR STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const licenceUserUpdateCatalogFail = (payload) => ({
  payload,
  type: constants.LICENCE_USER_UPDATE_CATALOG_FAIL,
});

// ACTION FOR UPDATING LICENCE CLIENT CATALOGS NO PAGINATION
export const getLicenceClientCatalogsNoPagination = (payload) => ({
  type: constants.GET_USER_CATALOGS_NO_PAGINATION,
  payload,
});
// ACTION FOR UPDATING LICENCE CLIENT CATALOGS NO PAGINATION SUCCESS
// RECIVE SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const getLicenceClientCatalogsNoPaginationSuccess = (payload) => ({
  payload,
  type: constants.GET_USER_CATALOGS_NO_PAGINATION_SUCCESS,
});
// ACTION FOR UPDATING LICENCE CLIENT CATALOGS NO PAGINATION FAIL
// RECIVE ERROR STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const getLicenceClientCatalogsNoPaginationFail = (payload) => ({
  payload,
  type: constants.GET_USER_CATALOGS_NO_PAGINATION_FAIL,
});

// ACTION FOR SAVING CATALOG AS DRAFT
// RECIVE CATALOG DATA THAT CLIENT ENTERED IN THE FORM
export const saveCatalogAsDraft = (payload) => ({
  type: constants.SAVE_CATALOG_AS_DRAFT,
  payload,
});
// ACTION FOR SAVING CATALOG AS DRAFT SUCCESS
// RECIVE SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const saveCatalogAsDraftSuccess = (payload) => ({
  payload,
  type: constants.SAVE_CATALOG_AS_DRAFT_SUCCESS,
});
// ACTION FOR SAVING CATALOG AS DRAFT FAIL
// RECIVE ERROR STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const saveCatalogAsDraftFail = (payload) => ({
  payload,
  type: constants.SAVE_CATALOG_AS_DRAFT_FAIL,
});

// ACTION FOR PUBLISHING CATALOG DRAFT
// RECIVE CATALOG ID AS A PAYLOAD
export const publishCatalogDraft = (payload) => ({
  type: constants.PUBLISH_CATALOG_DRAFT,
  payload,
});
// ACTION FOR PUBLISHING CATALOG DRAFT SUCCESS
// RECIVE SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const publishCatalogDraftSuccess = (payload) => ({
  payload,
  type: constants.PUBLISH_CATALOG_DRAFT_SUCCESS,
});
// ACTION FOR PUBLISHING CATALOG DRAFT FAIL
// RECIVE ERROR STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const publishCatalogDraftFail = (payload) => ({
  payload,
  type: constants.PUBLISH_CATALOG_DRAFT_FAIL,
});

// ACTION FOR DISABLING CATALOG BY LICENCE CLIENT
// RECIVE CATALOG ID THAT NEEDS TO BE DESABLED
export const disableCatalogByLicenceClient = (payload) => ({
  type: constants.DISABLE_CATALOG_BY_LICENCE_CLIENT,
  payload,
});
// ACTION FOR DISABLING CATALOG BY LICENCE CLIENT SUCCESS
// RECIVE SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const disableCatalogByLicenceClientSuccess = (payload) => ({
  payload,
  type: constants.DISABLE_CATALOG_BY_LICENCE_CLIENT_SUCCESS,
});
// ACTION FOR DISABLING CATALOG BY LICENCE CLIENT FAIL
// RECIVE ERROR STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const disableCatalogByLicenceClientFail = (payload) => ({
  payload,
  type: constants.DISABLE_CATALOG_BY_LICENCE_CLIENT_FAIL,
});

// ACTION FOR UPDATING CATALOG DRAFT
// RECIVE CATALOG ID AN DATA AS A PAYLOAD
export const updateCatalogDraft = (payload) => ({
  type: constants.UPDATE_CATALOG_DRAFT,
  payload,
});
// ACTION FOR UPDATING CATALOG DRAFT SUCCESS
// RECIVE SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const updateCatalogDraftSuccess = (payload) => ({
  payload,
  type: constants.UPDATE_CATALOG_DRAFT_SUCCESS,
});
// ACTION FOR UPDATING CATALOG DRAFT FAIL
// RECIVE ERROR STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const updateCatalogDraftFail = (payload) => ({
  payload,
  type: constants.UPDATE_CATALOG_DRAFT_FAIL,
});

// ACTION FETCHING CATALOG BY SLUG
// RECIVE CATALOG ID AN DATA AS A PAYLOAD
export const fetchCatalogBySlug = (payload) => ({
  type: constants.FETCH_CATALOG_BY_SLUG,
  payload,
});
// ACTION FETCHING CATALOG BY SLUG SUCCESS
// RECIVE SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const fetchCatalogBySlugSuccess = (payload) => ({
  payload,
  type: constants.FETCH_CATALOG_BY_SLUG_SUCCESS,
});
// ACTION FETCHING CATALOG BY SLUG FAIL
// RECIVE ERROR STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const fetchCatalogBySlugFail = (payload) => ({
  payload,
  type: constants.FETCH_CATALOG_BY_SLUG_FAIL,
});

// ACTION FETCHING CATALOG PRODUCTS BY CATALOG ID
// RECIVE CATALOG ID AN DATA AS A PAYLOAD
export const fetchCatalogProductsByCatalogId = (payload) => ({
  type: constants.FETCH_CATALOG_PRODUCTS_BY_CATALOG_ID,
  payload,
});
// ACTION FETCHING CATALOG PRODUCTS BY CATALOG ID SUCCESS
// RECIVE SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const fetchCatalogProductsByCatalogIdSuccess = (payload) => ({
  payload,
  type: constants.FETCH_CATALOG_PRODUCTS_BY_CATALOG_ID_SUCCESS,
});
// ACTION FETCHING CATALOG PRODUCTS BY CATALOG ID FAIL
// RECIVE ERROR STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const fetchCatalogProductsByCatalogIdFail = (payload) => ({
  payload,
  type: constants.FETCH_CATALOG_PRODUCTS_BY_CATALOG_ID_FAIL,
});

// ACTION FOR SETTING CATALOG PRODUCTS PAGINATION
// RECIVES HEDERS FROM THE SERVER RESPONSE TO FETCHING BLOGS THAT AUTH USER CREATED AS A PAYOAD
export const setPaginationForCatalogProducts = (payload) => ({
  payload,
  type: constants.SET_PAGINATION_FOR_CATALOG_PRODUCTS,
});

// ACTION FETCHING LICENCE CATALOGS
export const getLicenceCatalogs = (payload) => ({
  type: constants.GET_LICENCE_CATALOGS,
  payload,
});
// SUCCESS ACTION FETCHING LICENCE CATALOGS
// RECIVE LICENCE CATALOGS FROM THE SERVER AS A PAYLOAD
export const getLicenceCatalogsSuccess = (payload) => ({
  payload,
  type: constants.GET_LICENCE_CATALOGS_SUCCESS,
});
// FAIL ACTION FETCHING LICENCE CATALOGS
// RECIVE ERROR STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const getLicenceCatalogsFail = (payload) => ({
  payload,
  type: constants.GET_LICENCE_CATALOGS_FAIL,
});

// ACTION FOR CREATING CATALOG BY ADMIN
// RECIVE CATALOG DATA THAT ADMIN ENTERED IN THE FORM
export const adminCreateCatalog = (payload) => ({
  payload,
  type: constants.ADMIN_CREATE_CATALOG,
});
// ACTION FOR CREATING CATALOG BY ADMIN SUCCESS
// RECIVE SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const adminCreateCatalogSuccess = (payload) => ({
  payload,
  type: constants.ADMIN_CREATE_CATALOG_SUCCESS,
});
// ACTION FOR CREATING CATALOG BY ADMIN FAIL
// RECIVE ERROR STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const adminCreateCatalogFail = (payload) => ({
  payload,
  type: constants.ADMIN_CREATE_CATALOG_FAIL,
});

// ACTION FOR CREATING CATALOG DRAFT BY ADMIN
// RECIVE CATALOG DATA THAT ADMIN ENTERED IN THE FORM
export const adminUpdateCatalog = (payload) => ({
  payload,
  type: constants.ADMIN_UPDATE_CATALOG,
});
// ACTION FOR CREATING CATALOG DRAFT BY ADMIN SUCCESS
// RECIVE SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const adminUpdateCatalogSuccess = (payload) => ({
  payload,
  type: constants.ADMIN_UPDATE_CATALOG_SUCCESS,
});
// ACTION FOR CREATING CATALOG DRAFT BY ADMIN FAIL
// RECIVE ERROR STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const adminUpdateCatalogFail = (payload) => ({
  payload,
  type: constants.ADMIN_UPDATE_CATALOG_FAIL,
});

// ACTION TO ACTIVATE CATALOG LOADER
export const activateCatalogLoader = () => ({
  type: constants.ACTIVATE_CATALOG_LOADER,
});

// ACTION FOR APPROVING CATALOG MODERATION BY ADMIN
// RECEVE CATALOG ID THAT NEEDS TO BE APPROVED AS A PAYLOAD
export const approveCatalogModeration = (payload) => ({
  payload,
  type: constants.APPROVE_CATALOG_MODERATION,
});
// SUCCESS FOR APPROVING CATALOG MODERATION BY ADMIN
// RECEVE SUCCESS STATUS AND THE MESSAGE AS A PAYLOAD
export const approveCatalogModerationSuccess = (payload) => ({
  payload,
  type: constants.APPROVE_CATALOG_MODERATION_SUCCESS,
});
// FAIL FOR APPROVING CATALOG MODERATION BY ADMIN
// RECEVE ERROR STATUS AND THE MESSAGE AS A PAYLOAD
export const approveCatalogModerationFail = (payload) => ({
  payload,
  type: constants.APPROVE_CATALOG_MODERATION_FAIL,
});

// ACTION FOR APPROVING CATALOG MODERATION BY ADMIN
// RECEVE CATALOG ID THAT NEEDS TO BE APPROVED AS A PAYLOAD
export const disapproveCatalogModeration = (payload) => ({
  payload,
  type: constants.ADMIN_DISAPPROVE_CATALOG_MODERATION,
});
// SUCCESS FOR APPROVING CATALOG MODERATION BY ADMIN
// RECEVE SUCCESS STATUS AND THE MESSAGE AS A PAYLOAD
export const disapproveCatalogModerationSuccess = (payload) => ({
  payload,
  type: constants.ADMIN_DISAPPROVE_CATALOG_MODERATION_SUCCESS,
});
// FAIL FOR APPROVING CATALOG MODERATION BY ADMIN
// RECEVE ERROR STATUS AND THE MESSAGE AS A PAYLOAD
export const disapproveCatalogModerationFail = (payload) => ({
  payload,
  type: constants.ADMIN_DISAPPROVE_CATALOG_MODERATION_FAIL,
});

// ACTION FOR REQUESTING EDIT ON CATALOG MODERATION
// RECEVE CATALOG ID THAT NEEDS TO BE EDITED AD REMARK MESSAGE AS A PAYLOAD
export const requestForEditCatalogModeration = (payload) => ({
  payload,
  type: constants.REQUEST_FOR_EDIT_CATALOG_MODERATION,
});
// SUCCESS FOR REQUESTING EDIT ON CATALOG MODERATION
// RECEVE SUCCESS STATUS AND THE MESSAGE AS A PAYLOAD
export const requestForEditCatalogModerationSuccess = (payload) => ({
  payload,
  type: constants.REQUEST_FOR_EDIT_CATALOG_MODERATION_SUCCESS,
});
// FAIL FOR REQUESTING EDIT ON CATALOG MODERATION
// RECEVE ERROR STATUS AND THE MESSAGE AS A PAYLOAD
export const requestForEditCatalogModerationFail = (payload) => ({
  payload,
  type: constants.REQUEST_FOR_EDIT_CATALOG_MODERATION_FAIL,
});
