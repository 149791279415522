import constants from "./actionTypes";

const initState = {
  loader: false,
  biddingItems: [],
  pagination: {
    currentPage: "",
    pageCount: "",
    perPage: "",
    totlCount: "",
  },
};

export default (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case constants.SET_BIDDING_PAGINATION:
      return {
        ...state,
        pagination: payload,
      };
    case constants.LICENCE_UPDATE_BIDDING_DURATION:
      return {
        ...state,
        // loader: true,
      };
    case constants.LICENCE_UPDATE_BIDDING_DURATION_SUCCESS:
      return {
        ...state,
        loader: false,
      };
    case constants.GET_BIDDING_ITEMS:
      return {
        ...state,
        loader: true,
      };
    case constants.GET_BIDDING_ITEMS_SUCCESS:
      return {
        ...state,
        loader: false,
        biddingItems: payload,
      };
    default:
      return { ...state };
  }
};
