import React, { useState, Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getLicenceTempateId,
  getLicenceLanguageId,
} from "./store/auth/actions";
import { updateLanguage } from "./store/translation/action";
// import { getTemplateName } from "./themes/utills";
import { v4 as uuidv4 } from "uuid";

// import firebase from "firebase/app";
// import "firebase/firestore";
// import "firebase/auth";

// const config = {
//   apiKey: "AIzaSyCzZzvPEOpliZbSlyH9lPODebzPlBXX-v0",
//   authDomain: "szs-firebase-test.firebaseapp.com",
//   databaseURL: "https://szs-firebase-test.firebaseio.com",
//   projectId: "szs-firebase-test",
//   storageBucket: "szs-firebase-test.appspot.com",
//   messagingSenderId: "5848604480",
//   appId: "1:5848604480:web:ff3f739044dbe4fa54e4a5",
//   measurementId: "G-FFGR8PMC1R",
// };

// firebase.initializeApp(config);

function App() {
  const dispatch = useDispatch();
  const templateId = useSelector((state) => state.auth.templateId);
  const languageId = useSelector((state) => state.auth.languageId);
  // const licenceDetails = useSelector((state) => state.layout.licenceDetails);
  const [currentTheme, setCurrentTheme] = useState([]);
  const [defaultLanguage, setDefaultLanguage] = useState({
    // country_iso_code: "sr",
    // id: 2,
    // name: "Srpski",
    locale: "",
    messages: "",
  });
  // const setedLanguage = useSelector((state) => state.translaition.locale);

  useEffect(() => {
    if (!templateId && !languageId) {
      dispatch(getLicenceTempateId());
      dispatch(getLicenceLanguageId());
    }
    async function loadTheme() {
      try {
        const theme = await import(
          `./themes/${templateId.template_name_unique}`
        );
        // const theme = await import(`./themes/3`);

        setCurrentTheme([theme.default]);
      } catch {
        const noTheme = await import("./NoTheme");
        // const theme = await import(`./themes/3`);

        setCurrentTheme([noTheme.default]);
      }
    }
    if (languageId) {
      const payload = {
        locale: languageId.country_iso_code,
        messages: languageId.country_iso_code,
      };
      setDefaultLanguage(payload);
      dispatch(updateLanguage(payload));
    }

    if (templateId) {
      loadTheme();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateId, languageId]);

  return (
    <div>
      {templateId ? (
        <Suspense fallback={<div></div>}>
          {currentTheme.map((Component) => (
            <Component key={uuidv4()} />
          ))}
        </Suspense>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWarp: "wrap",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <img src="/images/theme-loader.gif" alt="Loading" />
        </div>
      )}
    </div>
  );
}

export default App;
