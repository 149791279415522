import constants from "./actionTypes";

// ACTION FOR GETTING CURRENT PAGE WIDGETS
// RECIVE CURRENT PAGE DETAILS AS A PAYLOAD
export const getPageWidgets = (payload) => ({
  type: constants.GET_PAGE_WIDGETS,
  payload,
});

// SUCCESS FOR GETTING CURRENT PAGE WIDGETS
// RECIVE CURRENT PAGE WIDGET DATA FROM THE SERVER AS A PAYLOAD
export const getPageWidgetsSuccess = (payload) => ({
  type: constants.GET_PAGE_WIDGETS_SUCCESS,
  payload,
});

// SUCCESS ACTION FOR GETTING ALL LICENCE USERS
// RECIVE ERROR STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const getPageWidgetsFail = (payload) => ({
  type: constants.GET_PAGE_WIDGETS_FAIL,
  payload,
});

// ACTION FOR SETTING WIDGET STYLE BY THEME
// RECIVE OBJECT OF WIDGET STYLE DATA AS A PAYLOAD
export const setThemeWidgetStyle = (payload) => ({
  type: constants.SET_THEME_WIDGET_STYLE,
  payload,
});

// ACTION FOR CHANGING WIDGET POSITION BY ADMIN
// RECIVE NEW POSITION COLUMN NUMBER AND WIDGET ID AS A PAYLOAD
export const changeWidgetPosition = (payload) => ({
  type: constants.CHANGE_WIDGET_POSITION,
  payload,
});

// SUCCESS FOR CHANGING WIDGET POSITION BY ADMIN
// RECIVE SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const changeWidgetPositionSuccess = (payload) => ({
  type: constants.CHANGE_WIDGET_POSITION_SUCCESS,
  payload,
});

// SUCCESS ACTION FOR CHANGING WIDGET POSITION BY ADMIN
// RECIVE ERROR STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const changeWidgetPositionFail = (payload) => ({
  type: constants.CHANGE_WIDGET_POSITION_FAIL,
  payload,
});

// ACTION FOR GETTING LICENCE SERVICE MAILS
export const getLIcenceServiceMails = (payload) => ({
  type: constants.GET_LICENCE_SERVICE_MAILS,
  payload,
});

// SUCCESS FOR GETTING LICENCE SERVICE MAILS
// RECIVE LICENCE SERVICE MAILS DATA FROM THE SERVER AS A PAYLOAD
export const getLIcenceServiceMailsSuccess = (payload) => ({
  type: constants.GET_LICENCE_SERVICE_MAILS_SUCCESS,
  payload,
});

// FAIL ACTION FOR GETTING LICENCE SERVICE MAILS
// RECIVE ERROR STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const getLIcenceServiceMailsFail = (payload) => ({
  type: constants.GET_LICENCE_SERVICE_MAILS_FAIL,
  payload,
});

// ACTION FOR DELETING SERVICE MAIL
// RECIVE SERVICE EMAIL AS A PAYLOAD
export const deleteServiceMail = (payload) => ({
  type: constants.DELETE_LICENCE_SERVICE_MAIL,
  payload,
});

// SUCCESS FOR DELETING SERVICE MAIL
// RECIVE SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const deleteServiceMailSuccess = (payload) => ({
  type: constants.DELETE_LICENCE_SERVICE_MAIL_SUCCESS,
  payload,
});

// FAIL ACTION FOR DELETING SERVICE MAIL
// RECIVE ERROR STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const deleteServiceMailFail = (payload) => ({
  type: constants.DELETE_LICENCE_SERVICE_MAIL_FAIL,
  payload,
});

// ACTION FOR CREATING LICENCE SERVICE CONTACT EMAIL
// RECIVE SERVICE CONTACT EMAIL DATA AS A PAYLOAD
export const createContactService = (payload) => ({
  type: constants.CREATE_CONTACT_SERVICE_EMAIL,
  payload,
});

// SUCCESS FOR CREATING LICENCE SERVICE CONTACT EMAIL
// RECIVE SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const createContactServiceSuccess = (payload) => ({
  type: constants.CREATE_CONTACT_SERVICE_EMAIL_SUCCESS,
  payload,
});

// FAIL ACTION FOR CREATING LICENCE SERVICE CONTACT EMAIL
// RECIVE ERROR STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const createContactServiceFail = (payload) => ({
  type: constants.CREATE_CONTACT_SERVICE_EMAIL_FAIL,
  payload,
});

// ACTION FOR CREATING LICENCE WIDGET
// RECIVE NEW WIDGET DATA
export const createWidget = (payload) => ({
  type: constants.CREATE_WIDGET,
  payload,
});

// SUCCESS FOR CREATING LICENCE WIDGET
// RECIVE SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const createWidgetSuccess = (payload) => ({
  type: constants.CREATE_WIDGET_SUCCESS,
  payload,
});

// FAIL ACTION FOR CREATING LICENCE WIDGET
// RECIVE ERROR STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const createWidgetFail = (payload) => ({
  type: constants.CREATE_WIDGET_FAIL,
  payload,
});

// ACTION FOR SETTING WIDGET CONTENT DATA
// RECIVE WIDGET CONTENT DATA AS A PAYLOAD
export const setWidgetContentData = (payload) => ({
  type: constants.SET_WIDGET_CONTENT_DATA,
  payload,
});

// ACTION FOR SETTING WIDGET MODAL STEP
// RECIVE WIDGET MODAL STEP AS A PAYLOAD
export const setWidgetModalStep = (payload) => ({
  type: constants.SET_WIDGET_MODAL_STEP,
  payload,
});

// ACTION FOR GETTING WIDGET CONTENT TYPE CATS
export const getWidgetContentTypeCats = (payload) => ({
  type: constants.GET_WIDGET_CONTET_TYPE_CATS,
  payload,
});

// SUCCESS FOR GETTING WIDGET CONTENT TYPE CATS
// RECIVE CATS BY CONTENT TYPE AS A RESTPONS FROM THE SERVER
export const getWidgetContentTypeCatsSuccess = (payload) => ({
  type: constants.GET_WIDGET_CONTET_TYPE_CATS_SUCCESS,
  payload,
});

// FAIL ACTION FOR GETTING WIDGET CONTENT TYPE CATS
// RECIVE ERROR STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const getWidgetContentTypeCatsFail = (payload) => ({
  type: constants.GET_WIDGET_CONTET_TYPE_CATS_FAIL,
  payload,
});

// ACTION TO RESET WIDGET CONTENT TYPE CATS
export const resetWIdgetContentTypeCats = () => ({
  type: constants.RESET_WIDGET_CONTENT_TYPE_CATS,
});

// ACTION FOR GETTING WIDGET CONTENT TYPE CATS
export const getWIdgetSettingsData = (payload) => ({
  type: constants.GET_EDIT_WIDGET_CONTENT_DATA,
  payload,
});

// SUCCESS FOR GETTING WIDGET CONTENT TYPE CATS
// RECIVE CATS BY CONTENT TYPE AS A RESTPONS FROM THE SERVER
export const getWIdgetSettingsDataSuccess = (payload) => ({
  type: constants.GET_EDIT_WIDGET_CONTENT_DATA_SUCCESS,
  payload,
});

// FAIL ACTION FOR GETTING WIDGET CONTENT TYPE CATS
// RECIVE ERROR STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const getWIdgetSettingsDataFail = (payload) => ({
  type: constants.GET_EDIT_WIDGET_CONTENT_DATA_FAIL,
  payload,
});

// ACTION FOR GETTING WIDGETS INFO DATA
export const getWidgetInfoData = (payload) => ({
  type: constants.GET_WIDGETS_INFO_DATA,
  payload,
});

// SUCCESS FOR GETTING WIDGETS INFO DATA
// RECIVE WIDGETS INFO DATA FROM THE SERVER AS A PAYLOAD
export const getWidgetInfoDataSuccess = (payload) => ({
  type: constants.GET_WIDGETS_INFO_DATA_SUCCESS,
  payload,
});

// FAIL ACTION FOR GETTING WIDGETS INFO DATA
// RECIVE ERROR STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const getWidgetInfoDataFail = (payload) => ({
  type: constants.GET_WIDGETS_INFO_DATA_FAIL,
  payload,
});

// ACTION FOR UPDATING PAGE WIDGET
// REIVE UPDATED PAGE WIDGET DATA
export const updatePageWidget = (payload) => ({
  type: constants.UPDATE_PAGE_WIDGET,
  payload,
});

// SUCCESS FOR UPDATING PAGE WIDGET
// RECIVE SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYOAD
export const updatePageWidgetSuccess = (payload) => ({
  type: constants.UPDATE_PAGE_WIDGET_SUCCESS,
  payload,
});

// FAIL ACTION FOR UPDATING PAGE WIDGET
// RECIVE ERROR STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const updatePageWidgetFail = (payload) => ({
  type: constants.UPDATE_PAGE_WIDGET_FAIL,
  payload,
});

// ACTION FRO SETTING PAGE WIDGET FORM DATA MODE
// REIVE PAGE WIDGET FROM MODE AS A PAYLOAD
export const setWidgetFormMode = (payload) => ({
  type: constants.SET_WIDGET_FORM_MODE,
  payload,
});

// ACTION FOR RESETING PAGE WIDGETS
export const resetPageWidgets = () => ({
  type: constants.RESET_PAGE_WIDGETS,
});

// ACTION FOR SUBMITTING FORM WIDGET
// REIVE FORM WIDGET DATA THAT NEEDS TO BE SUBMITED
export const submitFormWIdget = (payload) => ({
  type: constants.SUBMIT_FORM_WIDGET,
  payload,
});

// SUCCESS FOR SUBMITTING FORM WIDGET
// RECIVE SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYOAD
export const submitFormWIdgetSuccess = (payload) => ({
  type: constants.SUBMIT_FORM_WIDGET_SUCCESS,
  payload,
});

// FAIL ACTION FOR SUBMITTING FORM WIDGET
// RECIVE ERROR STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const submitFormWIdgetFail = (payload) => ({
  type: constants.SUBMIT_FORM_WIDGET_FAIL,
  payload,
});

// ACTION FOR DELETING PAGE WIDGET
// REIVE FORM WIDGET DATA THAT NEEDS TO BE DELETED
export const adminDeletePageWidget = (payload) => ({
  type: constants.ADMIN_DELETE_PAGE_WIDGET,
  payload,
});

// SUCCESS FOR DELETING PAGE WIDGET
// RECIVE SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYOAD
export const adminDeletePageWidgetSuccess = (payload) => ({
  type: constants.ADMIN_DELETE_PAGE_WIDGET_SUCCESS,
  payload,
});

// FAIL ACTION FOR DELETING PAGE WIDGET
// RECIVE ERROR STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const adminDeletePageWidgetFail = (payload) => ({
  type: constants.ADMIN_DELETE_PAGE_WIDGET_FAIL,
  payload,
});
