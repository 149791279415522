import constants from "./actionTypes";
import { all, call, put, takeLatest, select } from "redux-saga/effects";
import {
  getSingleProductSlugApi,
  licenceClientCreateProductApi,
  licenceClientCreateProductDraftApi,
  licenceClientUpdateProductDraftApi,
  licenceClientPublishProductDraftApi,
  licenceClientUpdateProductApi,
  deleteProductApi,
  linkProductToPlannerApi,
  adminCreateProductApi,
  adminUpdateProductApi,
  approveProductModerationApi,
  disapproveProductModerationApi,
  requestForEditProductModerationApi,
} from "../../services/ApiCalls";
import {
  fetchSingleProductSuccess,
  // licenceClientUpdateProductDraft,
} from "./actions";
import {
  setActiveAuthUserModal,
  activateModal,
  redirectToSinglePlanner,
} from "../layout/actions";
import { NotificationManager } from "react-notifications";
import {
  fetchProductsByAuthClient,
  activateProductLoader,
} from "../products/actions";
import { getModerationNumbers } from "../layout/actions";

function* fetchSingleProductSaga(action) {
  console.log(action.payload);
  try {
    const singleProduct = yield call(getSingleProductSlugApi, action.payload);
    yield put(fetchSingleProductSuccess(singleProduct.data[0]));
  } catch (e) {
    console.log(e);
  }
}
// SAGA FOR CREATING PRODUCT BY LICENCE CLIENT
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.LICENCE_CLIENT_CREATE_PRODUCT
// PAYLOAD : NEW PRODUCT DATA THAT CLIENT INSERT IN THE FORM
function* licenceClientCreateProductSaga(action) {
  try {
    const state = yield select();
    yield call(licenceClientCreateProductApi, action.payload);
    NotificationManager.success(
      state.translation.messages.sendOnModerationMessage[1],
      "",
      3000
    );

    const payload = {
      author_id: action.payload.userId,
      content_type_id: 1,
      page: 1,
    };
    // console.log("newCatalog", newCatalog);
    // if (newCatalog.status === 200) {
    //   const payload = {
    //     author_id: action.payload.userId,
    //     content_type_id: 1,
    //     page: 1,
    //   };
    // yield put(disableCatalogByLicenceClientSuccess());
    yield put(fetchProductsByAuthClient(payload));

    // console.log("createdProduct", createdProduct);
  } catch (e) {
    console.log(e);
  }
}

// SAGA FOR CREATING PRODUCT BY LICENCE BACKEND USERS
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.ADMIN_CREATE_PRODUCT
// PAYLOAD : NEW PRODUCT DATA THAT BACKEND USER INSERT IN THE FORM
function* adminCreateProductSaga(action) {
  try {
    const state = yield select();
    const createdProduct = yield call(adminCreateProductApi, action.payload);
    NotificationManager.success(
      state.translation.messages.createProductSuccessMessage[1],
      "",
      3000
    );
    const payload = {
      content_type_id: 1,
      page: 1,
    };
    // console.log("newCatalog", newCatalog);
    // if (newCatalog.status === 200) {
    //   const payload = {
    //     author_id: action.payload.userId,
    //     content_type_id: 1,
    //     page: 1,
    //   };
    // yield put(disableCatalogByLicenceClientSuccess());
    yield put(fetchProductsByAuthClient(payload));

    console.log("createdProduct", createdProduct);
  } catch (e) {
    console.log(e);
  }
}

// SAGA FOR UPDATING PRODUCT BY LICENCE BACKEND USERS
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.ADMIN_UPDATE_PRODUCT
// PAYLOAD : UPDATED PRODUCT DATA THAT BACKEND USER INSERT IN THE FORM
function* adminUpdateProductSaga(action) {
  try {
    // console.log("action.payload", action.payload);
    const state = yield select();
    const updatedProduct = yield call(adminUpdateProductApi, action.payload);
    NotificationManager.success(
      state.translation.messages.updateProductSuccessMessage[1],
      "",
      3000
    );
    if (action.payload.fetchSingleContent) {
      yield put(fetchSingleProductSuccess(updatedProduct));
      NotificationManager.success(action.payload.message, "", 3000);
    } else {
      const payload = {
        content_type_id: 1,
        page: 1,
      };
      // console.log("newCatalog", newCatalog);
      // if (newCatalog.status === 200) {
      //   const payload = {
      //     author_id: action.payload.userId,
      //     content_type_id: 1,
      //     page: 1,
      //   };
      // yield put(disableCatalogByLicenceClientSuccess());
      yield put(fetchProductsByAuthClient(payload));
    }

    // console.log("updatedProduct", updatedProduct);
  } catch (e) {
    console.log(e);
  }
}
// SAGA FOR CREATING PRODUCT DRAFT BY LICENCE CLIENT
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.LICENCE_CLIENT_CREATE_PRODUCT
// PAYLOAD : DRAFT PRODUCT DATA THAT CLIENT INSERT IN THE FORM
function* licenceClientCreateProductDraftSaga(action) {
  try {
    const state = yield select();
    const createProductDraft = yield call(
      licenceClientCreateProductDraftApi,
      action.payload
    );
    NotificationManager.success(
      state.translation.messages.saveAsDraftProductSuccessMessage[1],
      "",
      3000
    );
    const payload = {
      author_id: action.payload.userId,
      content_type_id: 1,
      page: 1,
    };
    action.payload.adminRole && delete payload.author_id;

    // console.log("newCatalog", newCatalog);
    // if (newCatalog.status === 200) {
    //   const payload = {
    //     author_id: action.payload.userId,
    //     content_type_id: 1,
    //     page: 1,
    //   };
    // yield put(disableCatalogByLicenceClientSuccess());
    yield put(fetchProductsByAuthClient(payload));
    console.log("createProductDraft", createProductDraft);
  } catch (e) {
    console.log(e);
  }
}

// SAGA FOR PRODUCT DRAFT UPDATE BY LICENCE CLIENT
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.LICENCE_CLIENT_UPDATE_PRODUCT_DRAFT
// PAYLOAD : DRAFT PRODUCT DATA THAT CLIENT INSERT IN THE FORM
function* licenceClientUpdateProductDraftSaga(action) {
  try {
    const state = yield select();
    const updateDraft = yield call(
      licenceClientUpdateProductDraftApi,
      action.payload
    );
    NotificationManager.success(
      state.translation.messages.updateDraftProductSuccessMessage[1],
      "",
      3000
    );
    const payload = {
      author_id: action.payload.userId,
      content_type_id: 1,
      page: 1,
    };
    action.payload.adminRole && delete payload.author_id;

    // console.log("newCatalog", newCatalog);
    // if (newCatalog.status === 200) {
    //   const payload = {
    //     author_id: action.payload.userId,
    //     content_type_id: 1,
    //     page: 1,
    //   };
    // yield put(disableCatalogByLicenceClientSuccess());
    yield put(fetchProductsByAuthClient(payload));
    console.log("updateDraft", updateDraft);
  } catch (e) {
    console.log(e);
  }
}

// SAGA FOR ADDING SINGLE PRODUCT TI PLANNER
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.LINK_PRODUCT_TO_PLANNER
// PAYLOAD : PRODUCT ID AND PLANNER ID
function* linkProductToPlannerSaga(action) {
  try {
    const linkProductToPlanner = yield call(
      linkProductToPlannerApi,
      action.payload
    );
    if (linkProductToPlanner.status === 200) {
      yield put(setActiveAuthUserModal("plannerModals"));
      yield put(activateModal("authUserModal"));
      yield put(redirectToSinglePlanner(action.payload.planner));
      NotificationManager.success(linkProductToPlanner.message, "", 3000);
    }
    if (linkProductToPlanner.status === 202) {
      NotificationManager.info(linkProductToPlanner.message, "", 3000);
    }
  } catch (e) {
    console.log(e.message);
  }
}

// SAGA FOR PUBLISHING PRODUCT DRAFT BY LICENCE CLIENT
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.LICENCE_CLIENT_UPDATE_PRODUCT_DRAFT
// PAYLOAD : DRAFT PRODUCT DATA THAT CLIENT INSERT IN THE FORM
function* licenceClientPublishProductDraftSaga(action) {
  try {
    yield call(licenceClientUpdateProductDraftApi, action.payload);
    const publishDraft = yield call(
      licenceClientPublishProductDraftApi,
      action.payload
    );
    const payload = {
      author_id: action.payload.userId,
      content_type_id: 1,
      page: 1,
    };
    action.payload.adminRole && delete payload.author_id;

    // console.log("newCatalog", newCatalog);
    // if (newCatalog.status === 200) {
    //   const payload = {
    //     author_id: action.payload.userId,
    //     content_type_id: 1,
    //     page: 1,
    //   };
    // yield put(disableCatalogByLicenceClientSuccess());
    yield put(fetchProductsByAuthClient(payload));
    console.log("publishDraft", publishDraft);
  } catch (e) {
    console.log(e);
  }
}

// SAGA FOR PUBLISHING PRODUCT DRAFT BY LICENCE CLIENT
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.LICENCE_CLIENT_UPDATE_PRODUCT
// PAYLOAD :  PRODUCT DATA THAT CLIENT INSERT IN THE FORM
function* licenceClientUpdateProductSaga(action) {
  try {
    const state = yield select();
    const updateProduct = yield call(
      licenceClientUpdateProductApi,
      action.payload
    );
    NotificationManager.success(
      state.translation.messages.sendOnModerationMessage[1],
      "",
      3000
    );
    const payload = {
      author_id: action.payload.userId,
      content_type_id: 1,
      page: 1,
    };
    // console.log("newCatalog", newCatalog);
    // if (newCatalog.status === 200) {
    //   const payload = {
    //     author_id: action.payload.userId,
    //     content_type_id: 1,
    //     page: 1,
    //   };
    // yield put(disableCatalogByLicenceClientSuccess());
    yield put(fetchProductsByAuthClient(payload));
    console.log("updateProduct", updateProduct);
  } catch (e) {
    console.log(e);
  }
}

// SAGA FOR DELETING PRODUCT
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.DELETE_PRODUCT
// PAYLOAD :  PRODUCT ID AS A PAYLOAD
function* deleteProductSaga(action) {
  try {
    yield put(activateProductLoader());
    const deleteProduct = yield call(deleteProductApi, action.payload);
    const payload = {
      // author_id: action.payload.userId,
      content_type_id: 1,
      page: 1,
    };
    // console.log("newCatalog", newCatalog);
    // if (newCatalog.status === 200) {
    //   const payload = {
    //     author_id: action.payload.userId,
    //     content_type_id: 1,
    //     page: 1,
    //   };
    // yield put(disableCatalogByLicenceClientSuccess());
    yield put(fetchProductsByAuthClient(payload));
    console.log("deleteProduct", deleteProduct);
  } catch (e) {
    console.log(e);
  }
}

// SAGA FOR APPROVING SINGLE PRODUCT MODERATION
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.APPROVE_PRODUCT_MODERATION
// PAYLOAD : PRODUCT ID THAT NEEDS TO BE APPROVED
function* approveProductModerationSaga(action) {
  try {
    const state = yield select();
    // console.log("Dosao do adminUpdateBlogSaga", action.payload);
    // yield put(activateBlogsLoader());
    yield call(approveProductModerationApi, action.payload);
    NotificationManager.success(
      state.translation.messages.approvedContent[1],
      "",
      3000
    );
    const payload = {
      content_type_id: 1,
      status: "on_moderation",
      page: 1,
    };
    yield put(getModerationNumbers());
    yield put(fetchProductsByAuthClient(payload));
  } catch (e) {
    console.log(e.message);
  }
}

// SAGA FOR SENDING SINGLE PRODUCT MODERATION ON EDIT
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.REQUEST_FOR_EDIT_PRODUCT_MODERATION
// PAYLOAD : PRODUCT ID THAT NEEDS TO BE EDITED AND REMARK
function* requestForEditProductModerationSaga(action) {
  try {
    const state = yield select();
    // console.log("dosao do approveBlogModerationSaga");
    // // console.log("Dosao do adminUpdateBlogSaga", action.payload);
    // yield put(activateBlogsLoader());
    yield call(requestForEditProductModerationApi, action.payload);
    NotificationManager.success(
      state.translation.messages.requestForEditMessage[1],
      "",
      3000
    );
    const payload = {
      content_type_id: 1,
      status: "on_moderation",
      page: 1,
    };
    yield put(getModerationNumbers());

    yield put(fetchProductsByAuthClient(payload));
  } catch (e) {
    console.log(e.message);
  }
}

// SAGA FOR DISAPPROVING SINGLE PRODUCT MODERATION
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.ADMIN_DISAPPROVE_PRODUCT_MODERATION
// PAYLOAD : PRODUCT ID THAT NEEDS TO BE DISAPPROVED
function* disapproveProductModerationSaga(action) {
  try {
    const state = yield select();
    yield call(disapproveProductModerationApi, action.payload);
    NotificationManager.success(
      state.translation.messages.disapprovedContent[1],
      "",
      3000
    );
    const payload = {
      content_type_id: 1,
      status: "on_moderation",
      page: 1,
    };
    yield put(getModerationNumbers());

    yield put(fetchProductsByAuthClient(payload));
  } catch (e) {
    console.log(e.message);
  }
}

// COMBINE ALL OF THE FUNCTION ABOVE IN ONE FUNCTION
function* singleProductSaga() {
  yield all([
    takeLatest(constants.FETCH_SINGLE_PRODUCT, fetchSingleProductSaga),
    takeLatest(constants.LINK_PRODUCT_TO_PLANNER, linkProductToPlannerSaga),
    takeLatest(constants.DELETE_PRODUCT, deleteProductSaga),
    takeLatest(constants.ADMIN_CREATE_PRODUCT, adminCreateProductSaga),
    takeLatest(constants.ADMIN_UPDATE_PRODUCT, adminUpdateProductSaga),

    takeLatest(
      constants.LICENCE_CLIENT_UPDATE_PRODUCT,
      licenceClientUpdateProductSaga
    ),
    takeLatest(
      constants.LICENCE_CLIENT_PUBLISH_PRODUCT_DRAFT,
      licenceClientPublishProductDraftSaga
    ),
    takeLatest(
      constants.LICENCE_CLIENT_UPDATE_PRODUCT_DRAFT,
      licenceClientUpdateProductDraftSaga
    ),
    takeLatest(
      constants.LICENCE_CLIENT_CREATE_PRODUCT_DRAFT,
      licenceClientCreateProductDraftSaga
    ),
    takeLatest(
      constants.LICENCE_CLIENT_CREATE_PRODUCT,
      licenceClientCreateProductSaga
    ),
    takeLatest(
      constants.APPROVE_PRODUCT_MODERATION,
      approveProductModerationSaga
    ),
    takeLatest(
      constants.REQUEST_FOR_EDIT_PRODUCT_MODERATION,
      requestForEditProductModerationSaga
    ),
    takeLatest(
      constants.ADMIN_DISAPPROVE_PRODUCT_MODERATION,
      disapproveProductModerationSaga
    ),
  ]);
}

// EXPORT THAT FUNCTION AS A DEFAULT EXPORT
export default singleProductSaga;
