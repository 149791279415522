import constants from "./actionTypes";
import { all, call, put, takeLatest, select } from "redux-saga/effects";
import {
  getLoginLicenceClientCatalogsApi,
  licenceUserCreateCatalogApi,
  licenceUserUpdateCatalogApi,
  getUserCatalogsNoPaginationApi,
  saveCatalogAsDraftApi,
  publishCatalogDraftApi,
  disableCatalogByLicenceClientApi,
  updateCatalogDraftApi,
  fetchCatalogBySlugApi,
  fetchCatalogProductsByCatalogIdApi,
  getLicenceCatalogsApi,
  adminCreateCatalogApi,
  adminUpdateCatalogApi,
  approveCatalogModerationApi,
  disapproveCatalogModerationApi,
  requestForEditCatalogModerationApi,
} from "../../services/ApiCalls";
import {
  fetchCatalogsByAuthClientSuccess,
  setPaginationForAuthClientCatalogs,
  getLicenceClientCatalogsNoPaginationSuccess,
  disableCatalogByLicenceClientSuccess,
  fetchCatalogsByAuthClient,
  fetchCatalogBySlugSuccess,
  fetchCatalogProductsByCatalogIdSuccess,
  setPaginationForCatalogProducts,
} from "./actions";
import { getModerationNumbers } from "../layout/actions";
import { NotificationManager } from "react-notifications";

// SAGA FOR FETCHING CATALOGS BY AUTH CLIENT
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.FETCH_CATALOGS_BY_AUTH_CLIENT
// PAYLOAD : OBJECT WITH FILTER DATA
function* fetchCatalogByAuthClientSaga(action) {
  try {
    const authClientCatalogs = yield call(
      getLoginLicenceClientCatalogsApi,
      action.payload
    );
    const setPaginationObj = {
      currentPage: parseInt(
        authClientCatalogs.headers["x-pagination-current-page"]
      ),
      pageCount: parseInt(
        authClientCatalogs.headers["x-pagination-page-count"]
      ),
      perPage: parseInt(authClientCatalogs.headers["x-pagination-per-page"]),
      totlCount: parseInt(
        authClientCatalogs.headers["x-pagination-total-count"]
      ),
    };
    yield put(setPaginationForAuthClientCatalogs(setPaginationObj));

    yield put(fetchCatalogsByAuthClientSuccess(authClientCatalogs.data));
  } catch (e) {
    console.log(e.message);
  }
}

// SAGA FOR CREATING CATALOGS BY AUTH CLIENT
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.LICENCE_USER_CREATE_CATALOG
// PAYLOAD : NEW CATALOG DATA THAT USER ENTERED IN THE FORM
function* licenceUserCreateCatalogSaga(action) {
  try {
    const state = yield select();
    yield call(licenceUserCreateCatalogApi, action.payload);

    NotificationManager.success(
      state.translation.messages.sendOnModerationMessage[1],
      "",
      3000
    );
    // user_id
    const payload = {
      author_id: action.payload.userId,
      content_type_id: 1,
      page: 1,
    };
    // console.log("newCatalog", newCatalog);
    // if (newCatalog.status === 200) {
    //   const payload = {
    //     author_id: action.payload.userId,
    //     content_type_id: 1,
    //     page: 1,
    //   };
    // yield put(disableCatalogByLicenceClientSuccess());
    yield put(fetchCatalogsByAuthClient(payload));
    // console.log("newCatalog", newCatalog);
  } catch (e) {
    console.log(e.message);
  }
}

// SAGA FOR UPDATING CATALOGS BY AUTH CLIENT
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.LICENCE_USER_UPDATE_CATALOG
// PAYLOAD : CATALOG DATA THAT USER UPDATED IN THE FORM
function* licenceUserUpdateCatalogSaga(action) {
  try {
    const state = yield select();
    yield call(licenceUserUpdateCatalogApi, action.payload);
    NotificationManager.success(
      state.translation.messages.sendOnModerationMessage[1],
      "",
      3000
    );
    const payload = {
      author_id: action.payload.userId,
      content_type_id: 1,
      page: 1,
    };
    // console.log("newCatalog", newCatalog);
    // if (newCatalog.status === 200) {
    //   const payload = {
    //     author_id: action.payload.userId,
    //     content_type_id: 1,
    //     page: 1,
    //   };
    // yield put(disableCatalogByLicenceClientSuccess());
    yield put(fetchCatalogsByAuthClient(payload));
    // console.log("updateCatalog", updateCatalog);
  } catch (e) {
    console.log(e.message);
  }
}

// SAGA FOR GETTING LICENCE CATALOGS NO PAGINATION
// RECIVES TYPE FROM ACTION
// TYPE : constants.GET_USER_CATALOGS_NO_PAGINATION
function* getLicenceCatalogsNoPaginationSaga(action) {
  try {
    const licenceCatalogs = yield call(
      getUserCatalogsNoPaginationApi,
      action.payload
    );
    yield put(getLicenceClientCatalogsNoPaginationSuccess(licenceCatalogs));
  } catch (e) {
    console.log(e.message);
  }
}

// SAGA FOR GETTING LICENCE CATALOGS NO PAGINATION
// RECIVES TYPE FROM ACTION
// TYPE : constants.SAVE_CATALOG_AS_DRAFT
function* saveCatalogAsDraftSaga(action) {
  try {
    const state = yield select();
    yield call(saveCatalogAsDraftApi, action.payload);
    // yield put(getLicenceClientCatalogsNoPaginationSuccess(licenceCatalogs));
    NotificationManager.success(
      state.translation.messages.saveAsDraftCatalogSuccessMessage[1],
      "",
      3000
    );
    const payload = {
      author_id: action.payload.userId,
      content_type_id: 1,
      page: 1,
    };
    action.payload.adminRole && delete payload.author_id;

    // console.log("newCatalog", newCatalog);
    // if (newCatalog.status === 200) {
    //   const payload = {
    //     author_id: action.payload.userId,
    //     content_type_id: 1,
    //     page: 1,
    //   };
    // yield put(disableCatalogByLicenceClientSuccess());
    yield put(fetchCatalogsByAuthClient(payload));
  } catch (e) {
    console.log(e.message);
  }
}

// SAGA FOR PUBLISHING CATALOG DRAFT
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.SAVE_CATALOG_AS_DRAFT
// PAYLOAD : CATALOG ID THAT NEEDS TO BE PUBLISHED
function* publishCatalogDraftSaga(action) {
  try {
    yield call(updateCatalogDraftApi, action.payload);

    yield call(publishCatalogDraftApi, action.payload);
    const payload = {
      author_id: action.payload.userId,
      content_type_id: 1,
      page: 1,
    };
    action.payload.adminRole && delete payload.author_id;

    // console.log("newCatalog", newCatalog);
    // if (newCatalog.status === 200) {
    //   const payload = {
    //     author_id: action.payload.userId,
    //     content_type_id: 1,
    //     page: 1,
    //   };
    // yield put(disableCatalogByLicenceClientSuccess());
    yield put(fetchCatalogsByAuthClient(payload));
    // console.log("catalogDraft", catalogDraft);
    // yield put(getLicenceClientCatalogsNoPaginationSuccess(licenceCatalogs));
  } catch (e) {
    console.log(e.message);
  }
}

// SAGA FOR DISABLING CATALOG BY LICENCE CLIENT
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.DISABLE_CATALOG_BY_LICENCE_CLIENT
// PAYLOAD : CATALOG ID THAT NEEDS TO BE PUBLISHED
function* disableCatalogByLicenceClientSaga(action) {
  try {
    const disabledCatalog = yield call(
      disableCatalogByLicenceClientApi,
      action.payload
    );
    if (disabledCatalog.status === 200) {
      const payload = {
        // author_id: action.payload.userId,
        content_type_id: 1,
        page: 1,
      };
      yield put(disableCatalogByLicenceClientSuccess());
      yield put(fetchCatalogsByAuthClient(payload));
    }
    // console.log("disabledCatalog", disabledCatalog);
  } catch (e) {
    console.log(e.message);
  }
}

// SAGA FOR UPDATING CATALOG BY DRAFT
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.UPDATE_CATALOG_DRAFT
// PAYLOAD : CATALOG ID AND DATA AS A PAYLOAD
function* updateCatalogDraftSaga(action) {
  try {
    const state = yield select();
    yield call(updateCatalogDraftApi, action.payload);
    NotificationManager.success(
      state.translation.messages.updateDraftCatalogSuccessMessage[1],
      "",
      3000
    );
    const payload = {
      author_id: action.payload.userId,
      content_type_id: 1,
      page: 1,
    };
    action.payload.adminRole && delete payload.author_id;

    // console.log("updateCatalog", updateCatalog);
    // console.log("newCatalog", newCatalog);
    // if (newCatalog.status === 200) {
    //   const payload = {
    //     author_id: action.payload.userId,
    //     content_type_id: 1,
    //     page: 1,
    //   };
    // yield put(disableCatalogByLicenceClientSuccess());
    yield put(fetchCatalogsByAuthClient(payload));

    // console.log("updateCatalog", updateCatalog);
  } catch (e) {
    console.log(e.message);
  }
}

// SAGA FOR UPDATING CATALOG
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.ADMIN_UPDATE_CATALOG
// PAYLOAD : CATALOG ID AND DATA AS A PAYLOAD
function* adminUpdateCatalogSaga(action) {
  try {
    const state = yield select();
    yield call(adminUpdateCatalogApi, action.payload);
    NotificationManager.success(
      state.translation.messages.updateCatalogSuccessMessage[1],
      "",
      3000
    );
    const payload = {
      content_type_id: 1,
      page: 1,
    };
    // console.log("updateCatalog", updateCatalog);
    // console.log("newCatalog", newCatalog);
    // if (newCatalog.status === 200) {
    //   const payload = {
    //     author_id: action.payload.userId,
    //     content_type_id: 1,
    //     page: 1,
    //   };
    // yield put(disableCatalogByLicenceClientSuccess());
    yield put(fetchCatalogsByAuthClient(payload));

    // console.log("updateCatalog", updateCatalog);
  } catch (e) {
    console.log(e.message);
  }
}

// SAGA FOR UPDATING CATALOG BY DRAFT
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.GET_LICENCE_CATALOGS
// PAYLOAD : CATALOG ID AND DATA AS A PAYLOAD
function* getLicenceCatalogsSaga(action) {
  try {
    const licenceCatalogs = yield call(getLicenceCatalogsApi, action.payload);

    yield put(getLicenceClientCatalogsNoPaginationSuccess(licenceCatalogs));

    // console.log("licenceCatalogs", licenceCatalogs);
  } catch (e) {
    console.log(e.message);
  }
}

// SAGA FOR UPDATING CATALOG BY DRAFT
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.ADMIN_CREATE_CATALOG
// PAYLOAD : CATALOG ID AND DATA AS A PAYLOAD
function* adminCreateCatalogSaga(action) {
  try {
    const state = yield select();
    yield call(adminCreateCatalogApi, action.payload);
    NotificationManager.success(
      state.translation.messages.createCatalogSuccessMessage[1],
      "",
      3000
    );
    const payload = {
      content_type_id: 1,
      page: 1,
    };

    yield put(fetchCatalogsByAuthClient(payload));

    // console.log("categorie", categorie);
  } catch (e) {
    console.log(e.message);
  }
}

function* fetchCatalogBySlugSaga(action) {
  try {
    // console.log(action);
    const catalog = yield call(fetchCatalogBySlugApi, action.payload);
    yield put(fetchCatalogBySlugSuccess(catalog[0]));
  } catch (e) {
    console.log(e);
  }
}

function* fetchCatalogProductsByCatalogIdSaga(action) {
  try {
    // console.log(action);
    const catalogProducts = yield call(
      fetchCatalogProductsByCatalogIdApi,
      action.payload
    );
    const setPaginationObj = {
      currentPage: parseInt(
        catalogProducts.headers["x-pagination-current-page"]
      ),
      pageCount: parseInt(catalogProducts.headers["x-pagination-page-count"]),
      perPage: parseInt(catalogProducts.headers["x-pagination-per-page"]),
      totlCount: parseInt(catalogProducts.headers["x-pagination-total-count"]),
    };
    yield put(setPaginationForCatalogProducts(setPaginationObj));
    yield put(fetchCatalogProductsByCatalogIdSuccess(catalogProducts.data));
    // console.log(catalogProducts);
  } catch (e) {
    console.log(e);
  }
}

// SAGA FOR APPROVING SINGLE CATALOG MODERATION
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.APPROVE_CATALOG_MODERATION
// PAYLOAD : CATALOG ID THAT NEEDS TO BE APPROVED
function* approveCatalogModerationSaga(action) {
  try {
    // console.log("Dosao do adminUpdateBlogSaga", action.payload);
    // yield put(activateBlogsLoader());
    const state = yield select();
    const approveCatalog = yield call(
      approveCatalogModerationApi,
      action.payload
    );
    const payload = {
      content_type_id: 3,
      status: "on_moderation",
      page: 1,
    };
    yield put(getModerationNumbers());
    yield put(fetchCatalogsByAuthClient(payload));
    NotificationManager.success(
      state.translation.messages.approvedContent[1],
      "",
      3000
    );
  } catch (e) {
    console.log(e.message);
  }
}

// SAGA FOR SENDING SINGLE CATALOG MODERATION ON EDIT
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.REQUEST_FOR_EDIT_CATALOG_MODERATION
// PAYLOAD : CATALOG ID THAT NEEDS TO BE EDITED AND REMARK
function* requestForEditCatalogModerationSaga(action) {
  try {
    const state = yield select();
    // console.log("dosao do approveBlogModerationSaga");
    // // console.log("Dosao do adminUpdateBlogSaga", action.payload);
    // yield put(activateBlogsLoader());
    yield call(requestForEditCatalogModerationApi, action.payload);
    NotificationManager.success(
      state.translation.messages.requestForEditMessage[1],
      "",
      3000
    );
    const payload = {
      content_type_id: 3,
      status: "on_moderation",
      page: 1,
    };
    yield put(getModerationNumbers());

    yield put(fetchCatalogsByAuthClient(payload));
  } catch (e) {
    console.log(e.message);
  }
}

// SAGA FOR DISAPPROVING SINGLE CATALOG MODERATION
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.ADMIN_DISAPPROVE_CATALOG_MODERATION
// PAYLOAD : CATALOG ID THAT NEEDS TO BE DISAPPROVED
function* disapproveCatalogModerationSaga(action) {
  try {
    // console.log("dosao do disapproveBlogModerationSaga");
    // // console.log("Dosao do adminUpdateBlogSaga", action.payload);
    // yield put(activateBlogsLoader());
    const state = yield select();
    const disapproveCatalog = yield call(
      disapproveCatalogModerationApi,
      action.payload
    );
    const payload = {
      content_type_id: 3,
      status: "on_moderation",
      page: 1,
    };
    NotificationManager.success(
      state.translation.messages.disapprovedContent[1],
      "",
      3000
    );

    yield put(getModerationNumbers());

    yield put(fetchCatalogsByAuthClient(payload));
  } catch (e) {
    console.log(e.message);
  }
}

// COMBINE ALL OF THE FUNCTION ABOVE IN ONE FUNCTION
function* catalogSaga() {
  yield all([
    takeLatest(
      constants.FETCH_CATALOGS_BY_AUTH_CLIENT,
      fetchCatalogByAuthClientSaga
    ),
    takeLatest(
      constants.LICENCE_USER_CREATE_CATALOG,
      licenceUserCreateCatalogSaga
    ),
    takeLatest(
      constants.LICENCE_USER_UPDATE_CATALOG,
      licenceUserUpdateCatalogSaga
    ),
    takeLatest(
      constants.GET_USER_CATALOGS_NO_PAGINATION,
      getLicenceCatalogsNoPaginationSaga
    ),
    takeLatest(constants.SAVE_CATALOG_AS_DRAFT, saveCatalogAsDraftSaga),
    takeLatest(constants.PUBLISH_CATALOG_DRAFT, publishCatalogDraftSaga),
    takeLatest(constants.ADMIN_CREATE_CATALOG, adminCreateCatalogSaga),
    takeLatest(constants.UPDATE_CATALOG_DRAFT, updateCatalogDraftSaga),
    takeLatest(constants.ADMIN_UPDATE_CATALOG, adminUpdateCatalogSaga),
    takeLatest(constants.GET_LICENCE_CATALOGS, getLicenceCatalogsSaga),
    takeLatest(
      constants.APPROVE_CATALOG_MODERATION,
      approveCatalogModerationSaga
    ),
    takeLatest(
      constants.REQUEST_FOR_EDIT_CATALOG_MODERATION,
      requestForEditCatalogModerationSaga
    ),
    takeLatest(
      constants.ADMIN_DISAPPROVE_CATALOG_MODERATION,
      disapproveCatalogModerationSaga
    ),
    takeLatest(
      constants.DISABLE_CATALOG_BY_LICENCE_CLIENT,
      disableCatalogByLicenceClientSaga
    ),
    takeLatest(constants.FETCH_CATALOG_BY_SLUG, fetchCatalogBySlugSaga),
    takeLatest(
      constants.FETCH_CATALOG_PRODUCTS_BY_CATALOG_ID,
      fetchCatalogProductsByCatalogIdSaga
    ),
  ]);
}

// EXPORT THAT FUNCTION AS A DEFAULT EXPORT
export default catalogSaga;
