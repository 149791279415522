import constants from "./actionTypes";

// ACTION FOR TRIGGERING USER LOGIN
// RECEVE PAYLOAD FROM INPUT FIELDS THAT USER ENTERED
export const loginUser = (payload) => ({
  payload,
  type: constants.LOGIN_USER,
});

// ACTION FOR LOGIN USER SUCCESS
// RECEVE PAYLOAD FROM API RESPONSE WITH USER DATA
export const loginUserSuccess = (payload) => ({
  payload,
  type: constants.LOGIN_USER_SUCCESS,
});

// ACTION FOR LOGIN USER FAIL
// RECEVE PAYLOAD FROM API RESPONSE WITH FAIL STATUS CODE AND MESSAGE
export const loginUserFail = (payload) => ({
  payload,
  type: constants.LOGIN_USER_FAIL,
});

// ACTION FOR TRIGGERING LICENCE USER REGISTRATION
// RECEVE PAYLOAD FROM INPUT FIELDS THAT USER ENTERED
export const registerLicenceUser = (payload) => ({
  payload,
  type: constants.REGISTER_LICENCE_USER,
});

// ACTION FOR REGISTER USER FAIL
// RECEVE PAYLOAD FROM API RESPONSE WITH FAIL STATUS CODE AND MESSAGE
export const registerLicenceUserFail = (payload) => ({
  payload,
  type: constants.REGISTER_LICENCE_USER_FAIL,
});

// ACTION FOR TRIGGERING LICENCE USER REGISTRATION
// RECEVE PAYLOAD FROM API RESPONSE WITH USER DATA
export const registerLicenceUserSuccess = () => ({
  // payload,
  type: constants.REGISTER_LICENCE_USER_SUCCESS,
});

// ACTION FOR TRIGGERING LICENCE CLIENT REGISTRATION
// RECEVE PAYLOAD FROM INPUT FIELDS THAT CLIENT ENTERED
export const registerLicenceClient = (payload) => ({
  payload,
  type: constants.REGISTER_LICENCE_CLIENT,
});

// ACTION FOR LICENCE CLIENT REGISTRATION SUCCESS
// RECEVE PAYLOAD FROM API RESPONSE WITH CLIENT DATA
export const registerLicenceClientSuccess = () => ({
  // payload,
  type: constants.REGISTER_LICENCE_CLIENT_SUCCESS,
});

// ACTION FOR LICENCE CLIENT REGISTRATION FAIL
// RECEVE PAYLOAD FROM API RESPONSE WITH FAIL STATUS CODE AND MESSAGE
export const registerLicenceClientFail = (payload) => ({
  payload,
  type: constants.REGISTER_LICENCE_CLIENT_FAIL,
});

// ACTION FOR TRIGGERING LICENCE USER LOGIN
// RECEVE PAYLOAD FROM INPUT FIELDS THAT CLIENT ENTERED
export const loginLicenceUser = (payload) => ({
  payload,
  type: constants.LOGIN_LICENCE_USER,
});

// ACTION FOR LICENCE USER LOGIN SUCCESS
// RECEVE PAYLOAD FROM API RESPONSE WITH CLIENT DATA
export const loginLicenceUserSuccess = (payload) => ({
  payload,
  type: constants.LOGIN_LICENCE_USER_SUCCESS,
});

// ACTION FOR LICENCE USER LOGIN FAIL
// RECEVE PAYLOAD FROM API RESPONSE WITH FAIL STATUS CODE AND MESSAGE
export const loginLicenceUserFail = (payload) => ({
  payload,
  type: constants.LOGIN_LICENCE_USER_FAIL,
});
// ACTION FOR TRIGGERING USER AUTHENTICATION
// RECEVE TOKEN AS PAYLOAD IS IT IS SET IN LOCAL STORAGE
export const getAuthUser = (payload) => ({
  payload,
  type: constants.GET_AUTH_USER,
});

// ACTION FOR USER AUTHENTICATION SUCCESS
// RECEVE PAYLOAD FROM API RESPONSE WITH USER DATA
export const getAuthUserSuccess = (payload) => ({
  payload,
  type: constants.GET_AUTH_USER_SUCCESS,
});

// ACTION FOR USER AUTHENTICATION FAIL
// RECEVE PAYLOAD FROM API RESPONSE WITH FAIL STATUS CODE AND MESSAGE
export const getAuthUserFail = (payload) => ({
  payload,
  type: constants.GET_AUTH_USER_FAIL,
});
// ACTION FOR GETTING LICENCE TEMPLATE ID
export const getLicenceTempateId = () => ({
  type: constants.GET_LICENCE_TEMPLATE,
});
// SUCCESS ACTION FOR GETTING LICENCE TEMPLATE ID
// RECEVE LICENCE TEMPLATE ID THAT NEEDS TO BE RENDERED AS A RESPONSE FROM THE SERVER AS A PAYLOAD
export const getLicenceTempateIdSuccess = (payload) => ({
  payload,
  type: constants.GET_LICENCE_TEMPLATE_SUCCESS,
});
// FAIL ACTION FOR GETTING LICENCE TEMPLATE ID
// RECEVE ERROR STATUS CODE AND MESSAGE FROM SERVER AS A PAYLOAD
export const getLicenceTempateIdFail = (payload) => ({
  payload,
  type: constants.GET_LICENCE_TEMPLATE_FAIL,
});
export const getLicenceLanguageId = () => ({
  type: constants.GET_LICENCE_LANGUAGE,
});
// SUCCESS ACTION FOR GETTING LICENCE TEMPLATE ID
// RECEVE LICENCE TEMPLATE ID THAT NEEDS TO BE RENDERED AS A RESPONSE FROM THE SERVER AS A PAYLOAD
export const getLicenceLanguageIdSuccess = (payload) => ({
  payload,
  type: constants.GET_LICENCE_LANGUAGE_SUCCESS,
});
// FAIL ACTION FOR GETTING LICENCE TEMPLATE ID
// RECEVE ERROR STATUS CODE AND MESSAGE FROM SERVER AS A PAYLOAD
export const getLicenceLanguageIdFail = (payload) => ({
  payload,
  type: constants.GET_LICENCE_LANGUAGE_FAIL,
});

// ACTION FOR LICENCE USER LOGOUT
// RECEVE AUTH_KEY AS PAYLOAD
export const logoutUser = (payload) => ({
  payload,
  type: constants.LOGOUT_USER,
});

// SUCCESS ACTION FOR LICENCE USER LOGOUT
// RECEVE SUCCESS STATUS CODE AND AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const logoutUserSuccess = (payload) => ({
  payload,
  type: constants.LOGOUT_USER_SUCCESS,
});

// FAIL ACTION FOR LICENCE USER LOGOUT
// RECEVE FAIL STATUS CODE AND AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const logoutUserFail = (payload) => ({
  payload,
  type: constants.LOGOUT_USER_FAIL,
});

// FAIL ACTION FOR LICENCE USER LOGOUT
// RECEVE FAIL STATUS CODE AND AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const signInWithGoogle = () => ({
  type: constants.SIGN_IN_WITH_GOOGLE,
});

// FAIL ACTION FOR LICENCE USER LOGOUT
// RECEVE FAIL STATUS CODE AND AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const signInWithFacebook = () => ({
  type: constants.SIGN_IN_WITH_FACEBOOK,
});

// ACTION TO LOGIN USER FROM SOCIAL AUTH
// RECEVE USER INFO EXTRACTED FROM SOCIAL AUTH AS A PAYLOAD
export const socialAuthLoginUser = (payload) => ({
  payload,
  type: constants.SOCIAL_AUTH_LOGIN_USER,
});

// SUCCESS ACTION TO LOGIN USER FROM SOCIAL AUTH
// RECEVE SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const socialAuthLoginUserSuccess = (payload) => ({
  payload,
  type: constants.SOCIAL_AUTH_LOGIN_USER_SUCCESS,
});

// FAIL ACTION TO LOGIN USER FROM SOCIAL AUTH
// RECEVE FAIL STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const socialAuthLoginUserFail = (payload) => ({
  payload,
  type: constants.SOCIAL_AUTH_LOGIN_USER_FAIL,
});

// ACTION FOR TRIGGERING SALES USER LOGIN
// RECEVE PAYLOAD FROM INPUT FIELDS THAT USER ENTERED
export const loginSalesUser = (payload) => ({
  payload,
  type: constants.LOGIN_SALES_USER,
});

// ACTION FOR LOGIN SALES USER SUCCESS
// RECEVE PAYLOAD FROM API RESPONSE WITH USER DATA
export const loginSalesUserSuccess = (payload) => ({
  payload,
  type: constants.LOGIN_SALES_USER_SUCCESS,
});

// ACTION FOR LOGIN SALES USER FAIL
// RECEVE PAYLOAD FROM API RESPONSE WITH FAIL STATUS CODE AND MESSAGE
export const loginSalesUserFail = (payload) => ({
  payload,
  type: constants.LOGIN_SALES_USER_FAIL,
});

// ACTION FOR TRIGGERING SALES USER REGISTRATION
// RECEVE PAYLOAD FROM INPUT FIELDS THAT SALES USER ENTERED
export const registerSalesUser = (payload) => ({
  payload,
  type: constants.REGISTER_SALES_USER,
});

// ACTION FOR SALES USER REGISTRATION SUCCESS
// RECEVE PAYLOAD FROM API RESPONSE WITH SALES USER DATA
export const registerSalesUserSuccess = (payload) => ({
  payload,
  type: constants.REGISTER_SALES_USER_SUCCESS,
});

// ACTION FOR SALES USER REGISTRATION FAIL
// RECEVE PAYLOAD FROM API RESPONSE WITH FAIL STATUS CODE AND MESSAGE
export const registerSalesUserFail = (payload) => ({
  payload,
  type: constants.REGISTER_SALES_USER_FAIL,
});

// ACTION FOR REQUESETING NEW PASSWORD BY SALES USER REGISTRATION
// RECEVE EMAIL THAT USER ENTERD
export const requestNewPassword = (payload) => ({
  payload,
  type: constants.REQUESET_PASSWORD_RESET,
});

// SUCCESS ACTION FOR REQUESETING NEW PASSWORD BY SALES USER REGISTRATION
// RECEVE PAYLOAD FROM API RESPONSE WITH SALES USER DATA
export const requestNewPasswordSuccess = (payload) => ({
  payload,
  type: constants.REQUESET_PASSWORD_RESET_SUCCESS,
});

// FAIL ACTION FOR REQUESETING NEW PASSWORD BY SALES USER REGISTRATION
// RECEVE PAYLOAD FROM API RESPONSE WITH FAIL STATUS CODE AND MESSAGE
export const requestNewPasswordFail = (payload) => ({
  payload,
  type: constants.REQUESET_PASSWORD_RESET_FAIL,
});

// ACTION FOR RESETING USER PASSWORD
// RECEVE EMAIL RESET_PASSWORD_TOKEN AND NEW PASSWORD AS A PAYLOAD
export const resetPassword = (payload) => ({
  payload,
  type: constants.RESET_PASSWORD,
});

// SUCCESS ACTION FOR RESETING USER PASSWORD
// RECEVE SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER
export const resetPasswordSuccess = (payload) => ({
  payload,
  type: constants.RESET_PASSWORD_SUCCESS,
});

// FAIL ACTION FOR RESETING USER PASSWORD
// RECEVE PAYLOAD FROM API RESPONSE WITH FAIL STATUS CODE AND MESSAGE
export const resetPasswordFail = (payload) => ({
  payload,
  type: constants.RESET_PASSWORD_FAIL,
});

// ACTION FOR VALIDATING USER ACCOUNG
// RECEVE ACITVATION_TOKEN AND EMAIL AS A PAYLOAD
export const validateUserAccount = (payload) => ({
  payload,
  type: constants.VALIDATE_USER_ACCOUNT,
});

// SUCCESS ACTION FOR VALIDATING USER ACCOUNG
// RECEVE SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER
export const validateUserAccountSuccess = (payload) => ({
  payload,
  type: constants.VALIDATE_USER_ACCOUNT_SUCCESS,
});

// FAIL ACTION FOR VALIDATING USER ACCOUNG
// RECEVE PAYLOAD FROM API RESPONSE WITH FAIL STATUS CODE AND MESSAGE
export const validateUserAccountFail = (payload) => ({
  payload,
  type: constants.VALIDATE_USER_ACCOUNT_FAIL,
});
