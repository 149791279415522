import constants from "./actionTypes";

const initState = {
  activeListing: {
    showBlog: false,
    showPlanner: false,
    showProfile: false,
  },
};

export default (state = initState, action) => {
  const { type } = action;
  switch (type) {
    case constants.ACTIVATE_BLOG_LISTING:
      return {
        ...state,
        activeListing: {
          showBlog: true,
          showPlanner: false,
          showProfile: false,
        },
      };
    case constants.ACTIVATE_PLANNER_LISTING:
      return {
        ...state,
        activeListing: {
          showBlog: false,
          showPlanner: true,
          showProfile: false,
        },
      };
    case constants.ACTIVATE_PROFILE_LISTING:
      return {
        ...state,
        activeListing: {
          showBlog: false,
          showPlanner: false,
          showProfile: true,
        },
      };
    default:
      return { ...state };
  }
};
