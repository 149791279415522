import constants from "./actionTypes";
import { all, call, put, takeLatest, select } from "redux-saga/effects";
import {
  adminCreateCategorieApi,
  adminUpdateCategorieApi,
  adminDeleteCategorieApi,
  adminDeleteAttributeApi,
  adminDeleteAttributeCategoryLinkApi,
  adminUpdateAttributeApi,
  getLicenceCategoriesApi,
  getLicenceAttributesApi,
  getLicenceContentTypeInOrderApi,
  updateCategorieOrderApi,
  updateContentTypeOrderApi,
} from "../../services/ApiCalls";
import {
  getLicenceCatSuccess,
  getLicenceAttributesSuccess,
  getLicenceContentTypeInOrderSuccess,
  setSelectedContentTypeOrder,
  setSelectedContentTypeOrderSuccess,
  setNewCat,
  getLicenceContentTypeInOrder,
  deleteCurrentCatAttribute,
  getAllLicenceContentTypeInOrderSuccess,
} from "./actions";
import {
  closeModal,
  setContentTypeId,
  setUseNewCat,
  setNavigationModalStep,
} from "../layout/actions";
import { setSelectedContentTypeCategoriesBlog } from "../blogs/actions";
import { setSelectedContentTypeOrderArticle } from "../article/actions";
import { setCurrentCatAttributes } from "./utills";
import { NotificationManager } from "react-notifications";

// SAGA FOR CREATING CATEGORIE BY ADMIN
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.ADMIN_CERATE_CATEGORIE
// PAYLOAD : DATA THAT ADMIN ENTERED IN THE INPUT FIELDS
function* adminCreateCategorieSaga(action) {
  const state = yield select();
  const newCat = yield call(adminCreateCategorieApi, action.payload);
  if (action.payload.useNewCat) {
    yield put(closeModal("menu"));
    yield put(setNewCat(newCat));
    yield put(setContentTypeId(""));
    yield put(setUseNewCat());
    yield put(setNavigationModalStep(1));
  } else {
    yield put(closeModal("menu"));
    NotificationManager.success(
      state.translation.messages.createCategorySuccessMessage[1]
    );
    const newCategories = yield call(getLicenceContentTypeInOrderApi);
    yield put(getLicenceContentTypeInOrderSuccess(newCategories));
    const categories = yield call(getLicenceCategoriesApi, []);
    yield put(getLicenceCatSuccess(categories));
  }
  try {
  } catch (e) {
    console.log(e.message);
  }
}

// SAGA FOR UPDATING CATEGORIE BY ADMIN
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.FETCH_BLOGS
// PAYLOAD : DATA THAT ADMIN UPDATE IN THE INPUT FIELDS
function* adminDeleteCategorieSaga(action) {
  const state = yield select();

  yield call(adminDeleteCategorieApi, action.payload);
  NotificationManager.success(
    state.translation.messages.deleteCategorySuccessMessage[1]
  );

  yield put(setSelectedContentTypeOrderSuccess(action.payload));
  const newCategories = yield call(getLicenceContentTypeInOrderApi);
  // console.log(newCategories, "new categories nakon brisanja:)");
  const contentTypeOrderActive = newCategories?.map((categorie) => {
    return {
      ...categorie,
      content_type_categories: categorie?.content_type_categories?.filter(
        (item) => item.status === "active"
      ),
    };
  });

  const contentTypeOrderDeleted = newCategories?.map((categorie) => {
    return {
      ...categorie,
      content_type_categories: categorie?.content_type_categories?.filter(
        (item) => item.status !== "deleted"
      ),
    };
  });
  yield put(getLicenceContentTypeInOrderSuccess(contentTypeOrderActive));
  yield put(getAllLicenceContentTypeInOrderSuccess(contentTypeOrderDeleted));

  const categories = yield call(getLicenceCategoriesApi, []);
  yield put(getLicenceCatSuccess(categories));
  try {
  } catch (e) {
    console.log(e.message);
  }
}

// SAGA FOR DELETING ATTRIBUTE BY ADMIN
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.ADMIN_DELETE_ATTRIBUTE
// PAYLOAD : ATTRIBUTE ID
function* adminDeleteAttributeSaga(action) {
  try {
    const state = yield select();

    yield call(adminDeleteAttributeApi, action.payload.id);
    NotificationManager.success(
      state.translation.messages.deleteAttributeSuccessMessage[1]
    );

    const attributes = yield call(getLicenceAttributesApi);

    if (action.payload.selectedCat) {
      setCurrentCatAttributes(action.payload.selectedCat, attributes);
    } else {
      yield put(getLicenceAttributesSuccess(attributes));
    }
  } catch (e) {
    console.log(e.message);
  }
}

// SAGA FOR DELETING LINK BETWEEN ATTRIBUTE AND CATEGORY BY ADMIN
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.ADMIN_DELETE_ATTRIBUTE_CATEGORY_LINK
// PAYLOAD : ATTRIBUTE ID
function* adminDeleteAttributeCategoryLinkSaga(action) {
  try {
    const state = yield select();
    yield call(adminDeleteAttributeCategoryLinkApi, action.payload);
    NotificationManager.success(
      state.translation.messages.deleteAttributeCategoryLinkSuccessMessage[1]
    );
    yield put(deleteCurrentCatAttribute(action.payload));
  } catch (e) {
    console.log(e.message);
  }
}

// SAGA FOR UPDATING ATTRIBUTE BY ADMIN
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.ADMIN_UPDATE_ATTRIBUTE
// PAYLOAD : DATA THAT ADMIN UPDATED IN THE INPUT FIELDS
function* adminUpdateAttributeSaga(action) {
  try {
    const state = yield select();
    yield call(adminUpdateAttributeApi, action.payload);
    NotificationManager.success(
      state.translation.messages.updateAttributeSuccessMessage[1],
      "",
      2000
    );

    const attributes = yield call(getLicenceAttributesApi);
    yield put(getLicenceAttributesSuccess(attributes));
  } catch (e) {
    console.log(e.message);
  }
}

// SAGA FOR UPDATING CATEGORIE BY ADMIN
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.FETCH_BLOGS
// PAYLOAD : DATA THAT ADMIN UPDATE IN THE INPUT FIELDS

function* adminUpdateCategorieSaga(action) {
  const state = yield select();
  // if (typeof action.payload.image === "string") {
  //   action.payload.image = "";
  // }

  // const setContentType = () => {
  //   switch (newCategories.content_type_id) {
  //     case 1:
  //       return `/catalogs-products`;
  //     case 2:
  //       return `/articles`;
  //     case 3:
  //       return `/blogs`;
  //     default:
  //       return `/catalogs`;
  //   }
  // };

  yield call(adminUpdateCategorieApi, action.payload);
  yield put(closeModal("menu"));
  NotificationManager.success(
    state.translation.messages.updateCategorySuccessMessage[1]
  );
  // window.location.href = `${window.location.origin}`;
  const newCategories = yield call(getLicenceContentTypeInOrderApi);

  const contentTypeOrderActive = newCategories?.map((categorie) => {
    return {
      ...categorie,
      content_type_categories: categorie?.content_type_categories?.filter(
        (item) => item.status === "active"
      ),
    };
  });

  const contentTypeOrderDeleted = newCategories?.map((categorie) => {
    return {
      ...categorie,
      content_type_categories: categorie?.content_type_categories?.filter(
        (item) => item.status !== "deleted"
      ),
    };
  });
  yield put(getLicenceContentTypeInOrderSuccess(contentTypeOrderActive));
  yield put(getAllLicenceContentTypeInOrderSuccess(contentTypeOrderDeleted));

  const categories = yield call(getLicenceCategoriesApi, []);
  yield put(getLicenceCatSuccess(categories));

  // window.location.reload();

  try {
  } catch (e) {
    console.log(e.message);
  }
}

// SAGA FOR GETTING LICENCE CATEGORIES
// RECIVES TYPE FROM ACTION
// TYPE : constants.GET_LICENCE_CATEGORIES
// PAYLOAD : DATA THAT ADMIN UPDATE IN THE INPUT FIELDS
function* getLicenceCatSaga(action) {
  try {
    const categories = yield call(getLicenceCategoriesApi, action.payload);
    // console.log("licenceCategories", categories);

    yield put(getLicenceCatSuccess(categories));
  } catch (e) {
    console.log(e.message);
  }
}

// SAGA FOR GETTING LICENCE ATTRIBUTES
// RECIVES TYPE AND OPCIONALY PAYLOAD FROM ACTION
// TYPE : constants.GET_LICENCE_ATTRIBUTES
// PAYLOAD : OPCIONALY RECIVES CURRENT CAT AS A PAYLOAD
function* getLicenceAttributesSaga(action) {
  try {
    const attributes = yield call(getLicenceAttributesApi);

    if (action.payload) {
      setCurrentCatAttributes(action.payload, attributes);
    } else {
      yield put(getLicenceAttributesSuccess(attributes));
    }
  } catch (e) {
    console.log(e.message);
  }
}

// SAGA FOR GETTING LICENCE CONTENT TYPE IN ORDER
// RECIVES TYPE FROM ACTION
// TYPE : constants.GET_LICENCE_CONTENT_TYPE_IN_ORDER
function* getLicenceContentTypeInOrderSaga(action) {
  try {
    const contentTypeOrderFilter = yield call(getLicenceContentTypeInOrderApi);

    const contentTypeOrder = contentTypeOrderFilter?.map((categorie) => {
      return {
        ...categorie,
        content_type_categories: categorie?.content_type_categories?.filter(
          (item) => item.status === "active"
        ),
      };
    });

    yield put(getLicenceContentTypeInOrderSuccess(contentTypeOrder));

    if (action.payload) {
      if (action.payload === 2) {
        const selected = contentTypeOrder.filter(
          (content) => content.content_type_id === 2
        );
        yield put(setSelectedContentTypeOrderArticle(selected[0]));
      } else {
        const selected = contentTypeOrder.filter(
          (content) => content.content_type_id === 3
        );
        yield put(setSelectedContentTypeCategoriesBlog(selected[0]));
      }
    } else {
      yield put(setSelectedContentTypeOrder(contentTypeOrder[0]));
    }
  } catch (e) {
    console.log(e.message);
  }
}

function* getAllLicenceContentTypeInOrderSaga(action) {
  try {
    console.log(action.payload);
    const contentTypeOrderFilter = yield call(getLicenceContentTypeInOrderApi);

    const contentTypeOrder = contentTypeOrderFilter?.map((categorie) => {
      return {
        ...categorie,
        content_type_categories: categorie?.content_type_categories?.filter(
          (item) => item.status !== "deleted"
        ),
      };
    });

    console.log(contentTypeOrder, "contentytpeireddCDDDCDDDDD");

    yield put(getAllLicenceContentTypeInOrderSuccess(contentTypeOrder));
    if (action.payload) {
      if (action.payload === 2) {
        const selected = contentTypeOrder.filter(
          (content) => content.content_type_id === 2
        );
        yield put(setSelectedContentTypeOrderArticle(selected[0]));
      } else {
        const selected = contentTypeOrder.filter(
          (content) => content.content_type_id === 3
        );
        yield put(setSelectedContentTypeCategoriesBlog(selected[0]));
      }
    } else {
      yield put(setSelectedContentTypeOrder(contentTypeOrder[0]));
    }
  } catch (e) {
    console.log(e.message);
  }
}

// SAGA FOR UPDATING CATEGORIE ORDER
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.UPDATE_CATEGORIE_ORDER
// PAYLOAD : ARRAY OF CAT IDS IN NEW ORDER AS A PAYLOAD
function* updateCategorieOrderSaga(action) {
  try {
    const reorder = yield call(updateCategorieOrderApi, action.payload);
    // console.log("reorder", reorder);
    yield put(getLicenceContentTypeInOrder());
    NotificationManager.success(reorder.message, "", 3000);

    // yield put(getLicenceContentTypeInOrderSuccess(contentTypeOrder));
    // yield put(setSelectedContentTypeOrder(contentTypeOrder[0]));
    // console.log("action", action);
  } catch (e) {
    console.log(e.message);
  }
}

// SAGA FOR UPDATING CONTENT TYPE ORDER
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.UPDATE_CONTENT_TYPE_ORDER
// PAYLOAD : ARRAY OF CONTENT TYPE IDS IN NEW ORDER AS A PAYLOAD
function* updateContentTypeOrderSaga(action) {
  try {
    const reorder = yield call(updateContentTypeOrderApi, action.payload);
    // console.log("reorder", reorder);
    yield put(getLicenceContentTypeInOrder());
    NotificationManager.success(reorder.message, "", 3000);

    // yield put(getLicenceContentTypeInOrderSuccess(contentTypeOrder));
    // yield put(setSelectedContentTypeOrder(contentTypeOrder[0]));
    // console.log("contentTypeOrder", contentTypeOrder);
  } catch (e) {
    console.log(e.message);
  }
}

// COMBINE ALL OF THE FUNCTION ABOVE IN ONE FUNCTION
function* categorieSaga() {
  yield all([
    takeLatest(constants.ADMIN_CERATE_CATEGORIE, adminCreateCategorieSaga),
    takeLatest(constants.ADMIN_UPDATE_CATEGORIE, adminUpdateCategorieSaga),
    takeLatest(constants.ADMIN_DELETE_CATEGORIE, adminDeleteCategorieSaga),
    takeLatest(constants.ADMIN_DELETE_ATTRIBUTE, adminDeleteAttributeSaga),
    takeLatest(constants.ADMIN_UPDATE_ATTRIBUTE, adminUpdateAttributeSaga),
    takeLatest(
      constants.ADMIN_DELETE_ATTRIBUTE_CATEGORY_LINK,
      adminDeleteAttributeCategoryLinkSaga
    ),
    takeLatest(constants.GET_LICENCE_CATEGORIES, getLicenceCatSaga),
    takeLatest(constants.GET_LICENCE_ATTRIBUTES, getLicenceAttributesSaga),
    takeLatest(constants.UPDATE_CONTENT_TYPE_ORDER, updateContentTypeOrderSaga),
    takeLatest(constants.UPDATE_CATEGORIE_ORDER, updateCategorieOrderSaga),

    takeLatest(
      constants.GET_LICENCE_CONTENT_TYPE_IN_ORDER,
      getLicenceContentTypeInOrderSaga
    ),
    takeLatest(
      constants.GET_ALL_LICENCE_CONTENT_TYPE_IN_ORDER,
      getAllLicenceContentTypeInOrderSaga
    ),
  ]);
}

// EXPORT THAT FUNCTION AS A DEFAULT EXPORT
export default categorieSaga;
