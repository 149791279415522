const GET_PAGE_WIDGETS = "GET_PAGE_WIDGETS";
const GET_PAGE_WIDGETS_SUCCESS = "GET_PAGE_WIDGETS_SUCCESS";
const GET_PAGE_WIDGETS_FAIL = "GET_PAGE_WIDGETS_FAIL";
const SET_THEME_WIDGET_STYLE = "SET_THEME_WIDGET_STYLE";
const CHANGE_WIDGET_POSITION = "CHANGE_WIDGET_POSITION";
const CHANGE_WIDGET_POSITION_SUCCESS = "CHANGE_WIDGET_POSITION_SUCCESS";
const CHANGE_WIDGET_POSITION_FAIL = "CHANGE_WIDGET_POSITION_FAIL";
const GET_LICENCE_SERVICE_MAILS = "GET_LICENCE_SERVICE_MAILS";
const GET_LICENCE_SERVICE_MAILS_SUCCESS = "GET_LICENCE_SERVICE_MAILS_SUCCESS";
const GET_LICENCE_SERVICE_MAILS_FAIL = "GET_LICENCE_SERVICE_MAILS_FAIL";
const DELETE_LICENCE_SERVICE_MAIL = "DELETE_LICENCE_SERVICE_MAIL";
const DELETE_LICENCE_SERVICE_MAIL_SUCCESS =
  "DELETE_LICENCE_SERVICE_MAIL_SUCCESS";
const DELETE_LICENCE_SERVICE_MAIL_FAIL = "DELETE_LICENCE_SERVICE_MAIL_FAIL";
const CREATE_CONTACT_SERVICE_EMAIL = "CREATE_CONTACT_SERVICE_EMAIL";
const CREATE_CONTACT_SERVICE_EMAIL_SUCCESS =
  "CREATE_CONTACT_SERVICE_EMAIL_SUCCESS";
const CREATE_CONTACT_SERVICE_EMAIL_FAIL = "CREATE_CONTACT_SERVICE_EMAIL_FAIL";
const CREATE_WIDGET = "CREATE_WIDGET";
const CREATE_WIDGET_SUCCESS = "CREATE_WIDGET_SUCCESS";
const CREATE_WIDGET_FAIL = "CREATE_WIDGET_FAIL";
const SET_WIDGET_CONTENT_DATA = "SET_WIDGET_CONTENT_DATA";
const SET_WIDGET_MODAL_STEP = "SET_WIDGET_MODAL_STEP";
const GET_WIDGET_CONTET_TYPE_CATS = "GET_WIDGET_CONTET_TYPE_CATS";
const GET_WIDGET_CONTET_TYPE_CATS_SUCCESS =
  "GET_WIDGET_CONTET_TYPE_CATS_SUCCESS";
const GET_WIDGET_CONTET_TYPE_CATS_FAIL = "GET_WIDGET_CONTET_TYPE_CATS_FAIL";
const RESET_WIDGET_CONTENT_TYPE_CATS = "RESET_WIDGET_CONTENT_TYPE_CATS";
const GET_EDIT_WIDGET_CONTENT_DATA = "GET_EDIT_WIDGET_CONTENT_DATA";
const GET_EDIT_WIDGET_CONTENT_DATA_SUCCESS =
  "GET_EDIT_WIDGET_CONTENT_DATA_SUCCESS";
const GET_EDIT_WIDGET_CONTENT_DATA_FAIL = "GET_EDIT_WIDGET_CONTENT_DATA_FAIL";
const GET_WIDGETS_INFO_DATA = "GET_WIDGETS_INFO_DATA";
const GET_WIDGETS_INFO_DATA_SUCCESS = "GET_WIDGETS_INFO_DATA_SUCCESS";
const GET_WIDGETS_INFO_DATA_FAIL = "GET_WIDGETS_INFO_DATA_FAIL";
const UPDATE_PAGE_WIDGET = "UPDATE_PAGE_WIDGET";
const UPDATE_PAGE_WIDGET_SUCCESS = "UPDATE_PAGE_WIDGET_SUCCESS";
const UPDATE_PAGE_WIDGET_FAIL = "UPDATE_PAGE_WIDGET_FAIL";
const SET_WIDGET_FORM_MODE = "SET_WIDGET_FORM_MODE";
const RESET_PAGE_WIDGETS = "RESET_PAGE_WIDGETS";
const SUBMIT_FORM_WIDGET = "SUBMIT_FORM_WIDGET";
const SUBMIT_FORM_WIDGET_SUCCESS = "SUBMIT_FORM_WIDGET_SUCCESS";
const SUBMIT_FORM_WIDGET_FAIL = "SUBMIT_FORM_WIDGET_FAIL";
const ADMIN_DELETE_PAGE_WIDGET = "ADMIN_DELETE_PAGE_WIDGET";
const ADMIN_DELETE_PAGE_WIDGET_SUCCESS = "ADMIN_DELETE_PAGE_WIDGET_SUCCESS";
const ADMIN_DELETE_PAGE_WIDGET_FAIL = "ADMIN_DELETE_PAGE_WIDGET_FAIL";

export default {
  GET_PAGE_WIDGETS,
  GET_PAGE_WIDGETS_SUCCESS,
  GET_PAGE_WIDGETS_FAIL,
  SET_THEME_WIDGET_STYLE,
  CHANGE_WIDGET_POSITION,
  CHANGE_WIDGET_POSITION_SUCCESS,
  CHANGE_WIDGET_POSITION_FAIL,
  GET_LICENCE_SERVICE_MAILS,
  GET_LICENCE_SERVICE_MAILS_SUCCESS,
  GET_LICENCE_SERVICE_MAILS_FAIL,
  DELETE_LICENCE_SERVICE_MAIL,
  DELETE_LICENCE_SERVICE_MAIL_SUCCESS,
  DELETE_LICENCE_SERVICE_MAIL_FAIL,
  CREATE_CONTACT_SERVICE_EMAIL,
  CREATE_CONTACT_SERVICE_EMAIL_SUCCESS,
  CREATE_CONTACT_SERVICE_EMAIL_FAIL,
  CREATE_WIDGET,
  CREATE_WIDGET_SUCCESS,
  CREATE_WIDGET_FAIL,
  SET_WIDGET_CONTENT_DATA,
  SET_WIDGET_MODAL_STEP,
  GET_WIDGET_CONTET_TYPE_CATS,
  GET_WIDGET_CONTET_TYPE_CATS_SUCCESS,
  GET_WIDGET_CONTET_TYPE_CATS_FAIL,
  RESET_WIDGET_CONTENT_TYPE_CATS,
  GET_EDIT_WIDGET_CONTENT_DATA,
  GET_EDIT_WIDGET_CONTENT_DATA_SUCCESS,
  GET_EDIT_WIDGET_CONTENT_DATA_FAIL,
  GET_WIDGETS_INFO_DATA,
  GET_WIDGETS_INFO_DATA_SUCCESS,
  GET_WIDGETS_INFO_DATA_FAIL,
  UPDATE_PAGE_WIDGET,
  UPDATE_PAGE_WIDGET_SUCCESS,
  UPDATE_PAGE_WIDGET_FAIL,
  SET_WIDGET_FORM_MODE,
  RESET_PAGE_WIDGETS,
  SUBMIT_FORM_WIDGET,
  SUBMIT_FORM_WIDGET_SUCCESS,
  SUBMIT_FORM_WIDGET_FAIL,
  ADMIN_DELETE_PAGE_WIDGET,
  ADMIN_DELETE_PAGE_WIDGET_SUCCESS,
  ADMIN_DELETE_PAGE_WIDGET_FAIL,
};
