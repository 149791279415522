import axios from "axios";
import api from "./apiConfig";
// const baseUrl = " http://guruapi.netplus.rs/v1";
// const baseUrl = " http://api.guru-new.local/v1";

//  API FOR LOGIN USERS
export const loginUserApi = (credentials) => {
  return api
    .post(`/users/login-licence-user`, credentials)
    .then((data) => data.data);
};

//  API FOR USER REGISTRATION
export const registerLicenceUserApi = (credentials) => {
  return api
    .post(`/users/register-licence-front-user`, credentials)
    .then((data) => data.data);
};

//  API FOR LICENCE CLIENT REGISTRATION
export const registerLicenceClientApi = (credentials) => {
  let body = new FormData();

  const params = [
    "email",
    "password",
    "name",
    "phone",
    "bio",
    "avatar",
    "country",
    "city",
    "address",
  ];
  params.map((param) => body.append(param, credentials[param]));
  body.append("username", credentials.email);

  return api
    .post(`/users/register-licence-client`, body)
    .then((data) => data.data);
};

// API FOR GETING BLOGS
export const getBlogsApi = () => {
  return api.get(`/contents?filter[content_type_id]=3`).then((data) => ({
    data: data.data,
    headers: data.headers,
  }));
};

// API FOR GETING CATEGORY BY SLUG
export const getCategoryBySlugApi = (payload) => {
  // let pageCount = payload.page_count
  // page_count: payload.page_count
  return api
    .get(
      `/categories?filter[slug]=${payload.category_id}&filter[content_type_id]=${payload.content_type_id}`
    )
    .then((data) => ({
      data: data.data,
      headers: data.headers,
    }));
};

// API FOR GETING CATALOG BY SLUG
export const fetchCatalogBySlugApi = (slug) => {
  return api.get(`/catalogs?filter[slug]=${slug}`).then((data) => data.data);
};

// API FOR GETING CATALOG PRODUCTS BY CATALOG ID
export const fetchCatalogProductsByCatalogIdApi = (payload) => {
  return api
    .get(
      `/catalogs/get-catalog-products/${payload.catalog_id}?page=${payload.page}`
    )
    .then((data) => ({
      data: data.data,
      headers: data.headers,
    }));
};

// API FOR GETING TAG RELATED CONTENT
export const getTagRelatedContentApi = (payload) => {
  // console.log(payload);
  return api
    .get(`/contents/tag-related-content/${payload}`)
    .then((data) => data.data);
};

// API FOR GETING SINGLE ARTICLE BY SLUG
export const getSingleArticleSlugApi = (slug) => {
  return api
    .get(`/contents?filter[slug]=${slug}&filter[content_type_id]=2`)
    .then((data) => ({
      data: data.data,
      headers: data.headers,
    }));
};

// API FOR GETING SINGLE BLOG BY SLUG
export const getSingleBlogSlugApi = (slug) => {
  return api
    .get(`/contents?filter[slug]=${slug}&filter[content_type_id]=3`)
    .then((data) => ({
      data: data.data,
      headers: data.headers,
    }));
};

// API FOR GETING SINGLE PRODUCT BY SLUG
export const getSingleProductSlugApi = (slug) => {
  return api
    .get(`/contents?filter[slug]=${slug}&filter[content_type_id]=1`)
    .then((data) => ({
      data: data.data,
      headers: data.headers,
    }));
};

//API FOR GETTING USER PROFILE BY USERNAME
export const getUserProfileByUsernameApi = (username) => {
  return api
    .get(`/profiles?filter[username]=${username}&expand=locations`)
    .then((data) => data.data);
};

// API FOR GETING CONTENT BY CATEGORY ID
export const getCatalogsProductsByUserIdApi = (payload) => {
  // console.log(payload);
  return api
    .get(
      `/catalogs/get-user-catalog-products/${payload.user_id}?page=${payload.page}`
    )
    .then((data) => ({
      data: data.data,
      headers: data.headers,
    }));
};
//API FOR GETTING CONTENT BY USER ID, CATEGORY ID array
//Used for getting current instance content only.
export const getCatalogsProductsByInstanceApi = (payload) => {
  const categoryIds = payload.category_ids;
  return api
    .get(
      `/catalogs/get-user-catalog-products/${payload.user_id}?page=${payload.page}`
    )
    .then((data) => ({
      data: data.data.filter((product) =>
        categoryIds.includes(product.category_id)
      ),
      headers: data.headers,
    }));
};

// API FOR GETING CONTENT BY USER ID
export const getBlogsByUserIdApi = (payload) => {
  // console.log(payload);
  return api
    .get(
      `/contents?filter[author_id]=${payload.user_id}&filter[status]=approved&filter[content_type_id]=3&page=${payload.page}`
    )
    .then((data) => ({
      data: data.data,
      headers: data.headers,
    }));
};

// API FOR GETING CONTENT BY CATEGORY ID
export const getContentByCategoryIdApi = (payload) => {
  return api
    .get(
      `/contents?filter[category_id]=${payload.categoryId}&page=${payload.page}`
    )
    .then((data) => ({
      data: data.data,
      headers: data.headers,
    }));
};

// API FOR FETCHING LICENCE PRODUCTS AND CATALOGS BY THE CATEGORIE ID
export const fetchProductsAndCatalogsByCategorieIdApi = (payload) => {
  const pageCount = payload.page_count ? payload.page_count : "";
  return api
    .get(
      `/categories/get-category-catalog-product/${payload.categoryId}?page=${payload.page}&page_count=${pageCount}`
    )
    .then((data) => ({
      data: data.data,
      headers: data.headers,
      request: data.request,
    }));
};

// API FOR GETING BLOGS
export const getArticlesApi = () => {
  return api.get(`/contents?filter[content_type_id]=2`).then((data) => ({
    data: data.data,
    headers: data.headers,
  }));
};

// API FOR GETING SINGLE ARTICLE BY ID
export const getSingleArticleApi = (id) => {
  return api
    .get(`/contents?filter[content_type_id]=2&filter[id]=${id}`)
    .then((data) => data.data);
};

// API FOR GETING SINGLE BLOG BY ID
export const getSingleBlogApi = (id) => {
  return api
    .get(`/contents?filter[content_type_id]=3&filter[id]=${id}`)
    .then((data) => data.data);
};

//  API FOR LOGIN LICENCE USER
export const loginLicenceUserApi = (credentials) => {
  let body = new FormData();
  const params = ["email", "password"];
  params.map((param) => body.append(param, credentials[param]));

  return api.post(`/users/login-licence-user`, body).then((data) => data.data);
};

//  API FOR GETTING AUTH USER
export const getAuthUserApi = (token) => {
  return api.post(`/users/verify-token`).then((data) => data.data);
};

//  API FOR CREATING CATEGORIE
export const adminCreateCategorieApi = (payload, token) => {
  let body = new FormData();
  const params = [
    "name",
    "description",
    "parent_category_id",
    "content_type_id",
    "color",
    "status",
  ];

  params.map((param) => body.append(param, payload[param]));
  // eslint-disable-next-line  array-callback-return
  payload.attributes.map((attribute, i) => {
    body.append(`attributes[${i}][name]`, attribute.name);
    body.append(`attributes[${i}][description]`, attribute.description);
    body.append(`attributes[${i}][values]`, attribute.attributeValues);
    body.append(`attributes[${i}][status]`, attribute.status);
  });
  // eslint-disable-next-line  array-callback-return
  payload.attribute_connect.map((attribute, i) => {
    body.append(`attribute_connect[${i}][id]`, attribute.id);
    body.append(`attribute_connect[${i}][status]`, attribute.status);
  });
  payload.image.download_location
    ? body.append("stock_image", payload.image.download_location)
    : body.append("image", payload.image);

  return api
    .post(`/categories/admin-create-category`, body)
    .then((data) => data.data);
};

//  API FOR UPDATING CATEGORIE
export const adminUpdateCategorieApi = (payload, token) => {
  let body = new FormData();
  const params = [
    "name",
    "description",
    "parent_category_id",
    "content_type_id",
    "color",
    "status",
  ];

  params.map((param) => body.append(param, payload[param]));
  // eslint-disable-next-line  array-callback-return
  payload.attributes.map((attribute, i) => {
    body.append(`attributes[${i}][name]`, attribute.name);
    body.append(`attributes[${i}][description]`, attribute.description);
    body.append(`attributes[${i}][values]`, attribute.attributeValues);
    body.append(`attributes[${i}][status]`, attribute.status);
  });

  payload.attribute_connect.length > 0 &&
    // ? // eslint-disable-next-line  array-callback-return
    payload.attribute_connect.map((attribute, i) => {
      body.append(`attribute_connect[${i}][id]`, attribute.id);
      body.append(`attribute_connect[${i}][status]`, attribute.status);
    });
  // : body.append(`attribute_connect[]`, "");

  payload.image.download_location
    ? body.append("stock_image", payload.image.download_location)
    : body.append("image", payload.image);

  return api
    .post(`/categories/admin-update-category/${payload.selectedCatId}`, body)
    .then((data) => data.data);
};

//  API FOR DELETING CATEGORIE
export const adminDeleteCategorieApi = (payload) => {
  return api
    .post(`/categories/admin-delete-category/${payload}`)
    .then((data) => data.data);
};

//  API FOR DELETING ATTRIBUTE
export const adminDeleteAttributeApi = (payload) => {
  return api
    .post(`/categories/admin-delete-attribute/${payload}`)
    .then((data) => data.data);
};

//  API FOR UPDATING ATTRIBUTE
export const adminUpdateAttributeApi = (payload) => {
  let body = new FormData();
  const params = ["name", "description"];

  params.map((param) => body.append(param, payload[param]));
  // eslint-disable-next-line  array-callback-return

  payload.attributeValues.length > 0
    ? // eslint-disable-next-line  array-callback-return
      payload.attributeValues.map((attributeValue, i) => {
        body.append(`attributeValues[${i}]`, attributeValue);
      })
    : body.append(`attributeValues[]`, "");

  return api
    .post(`/categories/admin-update-attribute/${payload.id}`, body)
    .then((data) => data.data);
};

//  API FOR DELETING LINK BETWEEN ATTRIBUTE AND CATEGORY
export const adminDeleteAttributeCategoryLinkApi = (payload) => {
  return api
    .post(`/categories/admin-delete-attribute-category-link/${payload}`)
    .then((data) => data.data);
};

export const getLicenceCategoriesApi = (payload) => {
  let filters = "";
  // eslint-disable-next-line  array-callback-return
  Object.entries(payload).map((filter) => {
    if (filter[0] !== "page") {
      if (filters) {
        filters = `${filters}&filter[${filter[0]}]=${filter[1]}`;
      } else {
        filters = `?filter[${filter[0]}]=${filter[1]}`;
      }
    }
  });

  return api.get(`/categories${filters}`).then((data) => data.data);
};

export const getLicenceAttributesApi = () => {
  return api.get(`/attributes`).then((data) => data.data);
};

// API FOR GETING LICINCE CONTET TYPE IN ORDER
export const getLicenceContentTypeInOrderApi = (token) => {
  return api.get(`/licence-content-type-options`).then((data) => data.data);
};

// API FOR UPDATING ARTICLES
export const updateArticleApi = (payload, token) => {
  const body = new FormData();
  const params = [
    "category_id",
    "article_id",
    "title",
    "author_id",
    "content_text",
    "image",
    "comments_allowed",
    "tags",
    "date",
    "author",
    "created_at",
  ];
  // payload.new_gallery.map((img, i) => {
  //   body.append(`new_gallery[${i}]`, img.id);
  // });
  // eslint-disable-next-line  array-callback-return
  payload.video_gallery.map((video, i) => {
    body.append(`video_gallery[${i}]`, video.video_url);
  });
  // eslint-disable-next-line  array-callback-return
  payload.new_gallery.map((file, i) => {
    body.append(`new_gallery[${i}]`, file);
  });
  // eslint-disable-next-line  array-callback-return
  payload.old_gallery.map((img, i) => {
    body.append(`old_gallery[${i}]`, img);
  });
  params.map((param) => body.append(param, payload[param]));

  return api
    .post(`/contents/admin-update-article/${payload.article_id}`, body)
    .then((data) => data.data);
};

// LICENCE ADMIN UPDATE ARTICLE
export const adnimUpdateArticleApi = (payload, token) => {
  let body = new FormData();
  let tags = "";
  let idIndex = 0;
  let newImgIndex = 0;
  let stock_gallery_index = 0;

  body.append(`title`, payload.title);
  body.append(`content_text`, payload.content_text);
  body.append(`category_id`, payload.category_id);
  body.append(`comments_allowed`, payload.comments_allowed);
  body.append(`author_id`, payload.author_id);
  body.append(`created_at`, payload.created_at);
  body.append("gallery_position", payload.gallery_position);

  if (payload.image.preview) {
    if (payload.image.isStock) {
      body.append("stock_image", payload.image.download_location);
    } else if (payload.image.name) {
      // console.log("payload.image", payload.image);
      body.append(`image`, payload.image);
    }
  }

  payload.video_gallery &&
    // eslint-disable-next-line  array-callback-return
    payload.video_gallery.map((video, i) => {
      if (video.id) {
        body.append(`video_gallery[${i}]`, video.video_url);
      } else {
        body.append(`video_gallery[${i}]`, video);
      }
    });
  // eslint-disable-next-line array-callback-return
  payload.gallery &&
    // eslint-disable-next-line array-callback-return
    payload.gallery.map((img, i) => {
      if (img.name) {
        body.append(`gallery[${newImgIndex}]`, img);
        newImgIndex++;
        // eslint-disable-next-line array-callback-return
        return;
      }
      // eslint-disable-next-line array-callback-return
      if (img.isStock) {
        body.append(
          `stock_gallery[${stock_gallery_index}]`,
          img.download_location
        );
        stock_gallery_index++;
        // eslint-disable-next-line array-callback-return
        return;
        // eslint-disable-next-line array-callback-return
      } else {
        body.append(`old_gallery[${idIndex}]`, img.id);
        idIndex++;
      }
    });

  payload.tags &&
    // eslint-disable-next-line  array-callback-return
    payload.tags.map((tag) => {
      if (tags.length > 1) {
        tags = `${tags},${tag.name}`;
      } else {
        tags = tag.name;
      }
    });
  body.append(`tags`, tags);

  return api
    .post(`/contents/admin-update-article/${payload.articleId}`, body)
    .then((data) => data.data);
};

// LICENCE ADMIN UPDATE ARTICLE
export const adnimUpdatBlogApi = (payload, token) => {
  let body = new FormData();
  let tags = "";
  let idIndex = 0;
  let newImgIndex = 0;
  let stock_gallery_index = 0;
  const params = [
    "title",
    "category_id",
    "comments_allowed",
    "author_id",
    "created_at",
    "gallery_position",
  ];
  params.map((param) => payload[param] && body.append(param, payload[param]));
  body.append("content_text", payload.content_text);

  // body.append(`title`, payload.title);
  // body.append(`content_text`, payload.content_text);
  // body.append(`category_id`, payload.category_id);
  // body.append(`comments_allowed`, payload.comments_allowed);
  // body.append(`author_id`, payload.author_id);
  // body.append(`created_at`, payload.created_at);

  if (payload.image.preview) {
    if (payload.image.isStock) {
      body.append("stock_image", payload.image.download_location);
    } else if (payload.image.name) {
      // console.log("payload.image", payload.image);
      body.append(`image`, payload.image);
    }
  }

  payload.video_gallery &&
    // eslint-disable-next-line  array-callback-return
    payload.video_gallery.map((video, i) => {
      if (video.id) {
        body.append(`video_gallery[${i}]`, video.video_url);
      } else {
        body.append(`video_gallery[${i}]`, video);
      }
    });
  payload.gallery &&
    // eslint-disable-next-line  array-callback-return
    payload.gallery.map((img, i) => {
      if (img.name) {
        body.append(`gallery[${newImgIndex}]`, img);
        newImgIndex++;
        // eslint-disable-next-line array-callback-return
        return;
      }
      if (img.isStock) {
        body.append(
          `stock_gallery[${stock_gallery_index}]`,
          img.download_location
        );
        stock_gallery_index++;
        // eslint-disable-next-line array-callback-return
        return;
      } else {
        body.append(`old_gallery[${idIndex}]`, img.id);
        idIndex++;
      }
    });

  payload.tags &&
    // eslint-disable-next-line  array-callback-return
    payload.tags.map((tag) => {
      if (tags.length > 1) {
        tags = `${tags},${tag.name}`;
      } else {
        tags = tag.name;
      }
    });
  body.append(`tags`, tags);

  return api
    .post(`/contents/admin-update-blog/${payload.blogId}`, body)
    .then((data) => data.data);
};
// API FOR ADMIN UPDATING BLOGS
export const updatBlogApi = (payload, token) => {
  const body = new FormData();
  const params = [
    "category_id",
    "article_id",
    "title",
    "author_id",
    "content_text",
    "image",
    "comments_allowed",
    "tags",
    "date",
    "author",
    "created_at",
  ];
  // payload.new_gallery.map((img, i) => {
  //   body.append(`new_gallery[${i}]`, img.id);
  // });
  // eslint-disable-next-line  array-callback-return
  payload.video_gallery.map((video, i) => {
    body.append(`video_gallery[${i}]`, video.video_url);
  });
  // eslint-disable-next-line  array-callback-return
  payload.new_gallery.map((file, i) => {
    body.append(`new_gallery[${i}]`, file);
  });
  // eslint-disable-next-line  array-callback-return
  payload.old_gallery.map((img, i) => {
    body.append(`old_gallery[${i}]`, img);
  });
  params.map((param) => body.append(param, payload[param]));

  return api
    .post(`/contents/admin-update-blog/${payload.blogId}`, body)
    .then((data) => data.data);
};

// API FOR UPDATING CATEGORIE ORDER
export const updateCategorieOrderApi = (data, token) => {
  const body = data.newOrder;
  // console.log("data", data);
  return api
    .post(`/categories/update-category-order/${data.contentType}`, body)
    .then((data) => data.data);
};

// API FOR UPDATING CONTENT_TYPE ORDER
export const updateContentTypeOrderApi = (data, token) => {
  return api
    .post(`/categories/update-content-type-order`, data)
    .then((data) => data.data);
};

// API FOR GETTING LICENCE TEMPLATE ID
export const getLicenceTemplateIdApi = () => {
  return api.get(`/licences/get-template-id`).then((data) => data.data);
};
// API FOR GETTING LICENCE LANGUAGE ID
export const getLicenceLanguageIdApi = () => {
  return api.get(`/licences/get-language-id`).then((data) => data.data);
};
// API FOR GETTING LICENCE DETAILS (ICON, TERMS AND CONDITIONS SINGIN MESSAGE ...)
export const getLicenceDetailsApi = (payload) => {
  let expands = "";
  if (payload) {
    // eslint-disable-next-line  array-callback-return
    payload.map((expand) => {
      if (expands) {
        expands = `${expands},${expand}`;
      } else {
        expands = `?expand=${expand}`;
      }
    });
  }
  return api
    .get(`/licences/get-licence-details${expands}`)
    .then((data) => data.data);
};

// API FOR GETTING HOME PAGE POSITION ONE WIDGET CONTENT
export const getHomePagePositionOneApi = () => {
  return api
    .get(`/content-widgets/get-home-page-position-one`)
    .then((data) => data.data);
};

// API FOR GETTING HOME PAGE PRODUCT LIST
export const getHomePageProductListApi = () => {
  return api
    .get(`/contents/get-last-product-by-category`)
    .then((data) => data.data);
};

// API FOR GETTING ALL USER PLANNERS
export const getAllPlannersApi = (payload) => {
  let filters = "";
  payload &&
    // eslint-disable-next-line  array-callback-return
    Object.entries(payload).map((filter) => {
      if (filters) {
        filters = `${filters}&filter[${filter[0]}]=${filter[1]}`;
      } else {
        filters = `?filter[${filter[0]}]=${filter[1]}`;
      }
    });
  return api.get(`/planners${filters}`).then((data) => data.data);
};

// API FOR CREATING NEW PALANNER
export const createNewPlannerApi = (payload) => {
  let body = new FormData();
  const params = ["title", "description"];

  params.map((param) => body.append(param, payload[param]));
  return api.post(`/planners/create-planner`, body).then((data) => data.data);
};

// API FOR GETTING SINGLE PLANNER ITEMS
export const fetchSinglePlannerItemsApi = (plannerId) => {
  return api
    .get(`/planners/planner-categories/${plannerId}`)
    .then((data) => data.data);
};

// API FOR DELETING USER PLANNER
export const deletePlannerApi = (plannerId) => {
  return api
    .post(`/planners/delete-planner/${plannerId}`)
    .then((data) => data.data);
};
// API FOR DELETING USER PLANNER ITEM
export const deletePlannerItemApi = (payload) => {
  return api
    .post(
      `/planner-items/delete-planner-item/${payload.plannerItemId}/${payload.plannerId}`
    )
    .then((data) => data.data);
};
// API FOR UPDATING PALANNER
export const updatePlannerApi = (payload) => {
  let body = new FormData();
  const params = ["title", "description"];

  params.map((param) => body.append(param, payload[param]));
  return api
    .post(`/planners/update-planner/${payload.plannerId}`, body)
    .then((data) => data.data);
};
// API TO MOVE ONE PLANNER ITEM TO ANOTHER PLANNER
export const switchToAnotherPlannerApi = (payload) => {
  return api
    .post(
      `/planner-items/switch-to-another-planner/${payload.plannerItemId}/${payload.plannerId}`
    )
    .then((data) => data.data);
};

// API FOR ADDING NEW TAKS TO USER PLANNER
export const addNewTaskToPlannerApi = (payload) => {
  let body = new FormData();
  const params = [
    "title",
    "remark",
    "image",
    "scheduled_date",
    "address",
    "country",
    "city",
    "contact_phone",
    "contact_email",
    "category_id",
    "price",
    "currency_id",
  ];
  params.map((param) => body.append(param, payload[param]));

  return api.post(
    `/planner-items/create-planner-task/${payload.selectedPlanner}`,
    body
  );
};

// API FOR UPDATING PLANNER TASK
export const updatePlannerTaskApi = (payload) => {
  let body = new FormData();
  const params = [
    "title",
    "remark",
    "image",
    "scheduled_date",
    "address",
    "country",
    "city",
    "contact_phone",
    "contact_email",
    "category_id",
    "price",
    "currency_id",
  ];
  params.map((param) => body.append(param, payload[param]));

  return api.post(
    `/planner-items/update-planner-task/${payload.plannerItemId}`,
    body
  );
};
// API FOR GETTING LICENCE USER BLOGS
export const getLicenceUserBlogsApi = (payload) => {
  let filters = "";
  // eslint-disable-next-line  array-callback-return
  Object.entries(payload).map((filter) => {
    if (filter[0] !== "page" && filter[0] !== "page_count") {
      if (filters) {
        filters = `${filters}&filter[${filter[0]}]=${filter[1]}`;
      } else {
        filters = `filter[${filter[0]}]=${filter[1]}`;
      }
    }
  });

  if (payload.page) {
    filters = `${filters}&page=${payload.page}`;
  }
  if (payload.page_count) {
    filters = `${filters}&page_count=${payload.page_count}`;
  }

  return api.get(`/contents?${filters}`).then((data) => ({
    data: data.data,
    headers: data.headers,
  }));
};

// API FOR GETTING LICENCE USER ARTICLES
export const getLicenceUserArticlesApi = (payload) => {
  let filters = "";
  // eslint-disable-next-line  array-callback-return
  Object.entries(payload).map((filter) => {
    if (filter[0] !== "page" && filter[0] !== "page_count") {
      if (filters) {
        filters = `${filters}&filter[${filter[0]}]=${filter[1]}`;
      } else {
        filters = `filter[${filter[0]}]=${filter[1]}`;
      }
    }
  });

  if (payload.page) {
    filters = `${filters}&page=${payload.page}`;
  }

  if (payload.page_count) {
    filters = `${filters}&page_count=${payload.page_count}`;
  }

  return api.get(`/contents?${filters}`).then((data) => ({
    data: data.data,
    headers: data.headers,
  }));
};

// API FOR CREATING ARTICLE BY THE ADMIN
export const adminCreateArticleApi = (payload) => {
  let body = new FormData();
  let stock_gallery_index = 0;
  let gallery_index = 0;
  let tags = "";
  const params = [
    "title",
    "content_text",
    "image",
    "category_id",
    "comments_allowed",
    "author_id",
    "gallery_position",
  ];
  // eslint-disable-next-line  array-callback-return
  params.map((param) => {
    if (param === "image") {
      if (payload[param].isStock) {
        body.append(`stock_image`, payload[param].download_location);
      } else {
        body.append(`image`, payload[param]);
      }
    } else {
      body.append(param, payload[param]);
    }
  });
  // eslint-disable-next-line  array-callback-return
  payload.video_gallery.map((video, i) => {
    body.append(`video_gallery[${i}]`, video);
  });
  // eslint-disable-next-line  array-callback-return
  payload.gallery.map((img, i) => {
    if (img.isStock) {
      body.append(
        `stock_gallery[${stock_gallery_index}]`,
        img.download_location
      );
      stock_gallery_index++;
    } else {
      body.append(`gallery[${gallery_index}]`, img);
      gallery_index++;
    }
  });
  // eslint-disable-next-line  array-callback-return
  payload.tags.map((tag) => {
    if (tags.length > 1) {
      tags = `${tags},${tag.name}`;
    } else {
      tags = tag.name;
    }
  });
  body.append(`tags`, tags);

  // console.log("tags", tags);

  // console.log("body", body);
  return api
    .post(`/contents/admin-create-article`, body)
    .then((data) => data.data);
};

// API FOR CREATING BLOG BY THE ADMIN
export const adminCreateBlogApi = (payload) => {
  let body = new FormData();
  let stock_gallery_index = 0;
  let gallery_index = 0;
  let tags = "";
  const params = [
    "title",
    "content_text",
    "image",
    "category_id",
    "comments_allowed",
    "author_id",
    "gallery_position",
  ];
  // eslint-disable-next-line  array-callback-return
  params.map((param) => {
    if (param === "image") {
      if (payload[param].isStock) {
        body.append(`stock_image`, payload[param].download_location);
      } else {
        body.append(`image`, payload[param]);
      }
    } else {
      if (payload[param]) {
        body.append(param, payload[param]);
      }
    }
  });
  // eslint-disable-next-line  array-callback-return
  payload.video_gallery.map((video, i) => {
    body.append(`video_gallery[${i}]`, video);
  });
  // eslint-disable-next-line  array-callback-return
  payload.gallery.map((img, i) => {
    if (img.isStock) {
      body.append(
        `stock_gallery[${stock_gallery_index}]`,
        img.download_location
      );
      stock_gallery_index++;
    } else {
      body.append(`gallery[${gallery_index}]`, img);
      gallery_index++;
    }
  });
  // eslint-disable-next-line  array-callback-return
  payload.tags.map((tag) => {
    if (tags.length > 1) {
      tags = `${tags},${tag.name}`;
    } else {
      tags = tag.name;
    }
  });
  body.append(`tags`, tags);

  // console.log("tags", tags);

  // console.log("body", body);
  return api
    .post(`/contents/admin-create-blog`, body)
    .then((data) => data.data);
};

// API FOR FILTERING LICENCE USER BLOGS
export const getLicenceUserBlogsByFilterApi = (payload) => {
  return api
    .get(
      `/contents?filter[content_type_id]=3&filter[author_id]=${payload.authorId}`
    )
    .then((data) => data.data);
};
// API FOR CREATING BLOG BY LICENCE USER
export const licenceUserCreateBlogApi = (payload) => {
  let body = new FormData();
  let stock_gallery_index = 0;
  let gallery_index = 0;
  let tags = "";
  const params = [
    "mod_title",
    "mod_content_text",
    "mod_image",
    "mod_category_id",
    "comments_allowed",
    "gallery_position",
  ];
  // eslint-disable-next-line  array-callback-return
  params.map((param) => {
    if (param === "mod_image") {
      if (payload[param].isStock) {
        body.append(`stock_image`, payload[param].download_location);
      } else {
        body.append(`mod_image`, payload[param]);
      }
    } else {
      body.append(param, payload[param]);
    }
  });
  // eslint-disable-next-line  array-callback-return
  payload.video_gallery.map((video, i) => {
    body.append(`video_gallery[${i}]`, video);
  });
  // eslint-disable-next-line  array-callback-return
  payload.gallery.map((img, i) => {
    if (img.isStock) {
      body.append(
        `stock_gallery[${stock_gallery_index}]`,
        img.download_location
      );
      stock_gallery_index++;
    } else {
      body.append(`gallery[${gallery_index}]`, img);
      gallery_index++;
    }
  });
  // eslint-disable-next-line  array-callback-return
  payload.tags.map((tag) => {
    if (tags.length > 1) {
      tags = `${tags},${tag.name}`;
    } else {
      tags = tag.name;
    }
  });
  body.append(`tags`, tags);

  // console.log("tags", tags);

  // console.log("body", body);
  return api.post(`/contents/user-create-blog`, body).then((data) => data.data);
};

// API FOR GETTING LICENCE TAGS
export const getLicenceTagsApi = (payload) => {
  let filters = "";

  payload &&
    // eslint-disable-next-line  array-callback-return
    Object.entries(payload).map((filter) => {
      // console.log("filter", filter);
      if (filter[0] !== "page" && filter[1]) {
        if (filters) {
          filters = `${filters}&filter[${filter[0]}]=${filter[1]}`;
        } else {
          filters = `?filter[${filter[0]}]=${filter[1]}`;
        }
      }
    });

  return api.get(`/tags${filters}`).then((data) => data.data);
};

// DELETE BLOG BY LICENCE USER
export const licenceUserDeleteBlogApi = (payload) => {
  // console.log("payload", payload);
  return api
    .post(`/contents/user-delete-blog/${payload.blogId}`)
    .then((data) => data.data);
};

// DELETE BLOG BY LICENCE USER
export const adminDeleteArticleApi = (payload) => {
  // console.log("payload", payload);
  return api
    .post(`/contents/admin-delete-article/${payload.blogId}`)
    .then((data) => data.data);
};

export const licenceUserUpdateBlogApi = (payload) => {
  let body = new FormData();
  let tags = "";
  let idIndex = 0;
  let newImgIndex = 0;
  let stock_gallery_index = 0;

  body.append(`title`, payload.mod_title);
  body.append(`content_text`, payload.mod_content_text);
  body.append(`category_id`, payload.mod_category_id);
  body.append(`comments_allowed`, payload.comments_allowed);

  if (payload.mod_image.preview) {
    if (payload.mod_image.isStock) {
      body.append("stock_image", payload.mod_image.download_location);
    } else {
      body.append(`image`, payload.mod_image);
    }
  }
  // eslint-disable-next-line  array-callback-return
  payload.video_gallery.map((video, i) => {
    body.append(`video_gallery[${i}]`, video);
  });
  // eslint-disable-next-line  array-callback-return
  payload.gallery.map((img, i) => {
    if (img.name) {
      body.append(`gallery[${newImgIndex}]`, img);
      newImgIndex++;
      // eslint-disable-next-line array-callback-return
      return;
    }
    if (img.isStock) {
      body.append(
        `stock_gallery[${stock_gallery_index}]`,
        img.download_location
      );
      stock_gallery_index++;
      // eslint-disable-next-line array-callback-return
      return;
    } else {
      body.append(`old_gallery[${idIndex}]`, img.id);
      idIndex++;
    }
  });
  // eslint-disable-next-line  array-callback-return
  payload.tags.map((tag) => {
    if (tags.length > 1) {
      tags = `${tags},${tag.name}`;
    } else {
      tags = tag.name;
    }
  });
  body.append(`tags`, tags);

  return api
    .post(`/contents/user-update-blog/${payload.blogId}`, body)
    .then((data) => data.data);
};

export const getApprovedContentCommentsByContentId = (payload) => {
  return api
    .get(`/comments?filter[status]=approved&filter[content_id]=${payload}`)
    .then((data) => ({
      data: data.data,
    }));
};

//  API FOR POSTING CONTENT COMMENT
export const postContentComment = (comment) => {
  return api
    .post(`/comments/post-comment/${comment.content_id}`, comment)
    .then((data) => data.data);
};

// GET LOGIN LICENCE CLIENT PRODUCTS
export const getLoginLicenceClientProductsApi = (payload) => {
  let filters = "";
  // eslint-disable-next-line  array-callback-return
  Object.entries(payload).map((filter) => {
    if (filter[0] !== "page" && filter[0] !== "page_count") {
      if (filters) {
        filters = `${filters}&filter[${filter[0]}]=${filter[1]}`;
      } else {
        filters = `filter[${filter[0]}]=${filter[1]}`;
      }
    }
  });

  if (payload.page) {
    filters = `${filters}&page=${payload.page}`;
  }

  if (payload.page_count) {
    filters = `${filters}&page_count=${payload.page_count}`;
  }

  return api.get(`/contents?${filters}`).then((data) => ({
    data: data.data,
    headers: data.headers,
  }));
};

// GET LOGIN LICENCE CLIENT CATALOGS
export const getLoginLicenceClientCatalogsApi = (payload) => {
  let filters = "";
  // eslint-disable-next-line  array-callback-return
  Object.entries(payload).map((filter) => {
    if (
      filter[0] !== "page" &&
      filter[0] !== "content_type_id" &&
      filter[0] !== "page_count"
    ) {
      if (filters) {
        filters = `${filters}&filter[${
          filter[0] === "title" ? "name" : filter[0]
        }]=${filter[1]}`;
      } else {
        filters = `filter[${filter[0]}]=${filter[1]}`;
      }
    }
  });

  if (payload.page) {
    filters = `${filters}&page=${payload.page}`;
  }

  if (payload.page_count) {
    filters = `${filters}&page_count=${payload.page_count}`;
  }

  return api.get(`/catalogs?${filters}`).then((data) => ({
    data: data.data,
    headers: data.headers,
  }));
};

// LICENCE USER CREATE CATALOG API
export const licenceUserCreateCatalogApi = (payload) => {
  const body = new FormData();
  const params = [
    "name",
    "category_id",
    "description",
    "image",
    "created_at",
    "author_id",
  ];
  // eslint-disable-next-line  array-callback-return
  params.map((param) => {
    if (param === "image") {
      if (payload[param].isStock) {
        body.append("stock_image", payload[param].download_location);
      } else {
        body.append(param, payload[param]);
      }
    } else {
      body.append(param, payload[param]);
    }
  });
  // eslint-disable-next-line  array-callback-return
  payload.products &&
    payload.products.map((product, i) => {
      body.append(`products[${i}]`, product.id);
    });
  return api
    .post(`/catalogs/user-create-catalog`, body)
    .then((data) => data.data);
};

// LICENCE USER CREATE CATALOG API
export const adminCreateCatalogApi = (payload) => {
  const body = new FormData();
  const params = ["name", "category_id", "description", "image"];
  // eslint-disable-next-line  array-callback-return
  params.map((param) => {
    if (param === "image") {
      if (payload[param].isStock) {
        body.append("stock_image", payload[param].download_location);
      } else {
        body.append(param, payload[param]);
      }
    } else {
      body.append(param, payload[param]);
    }
  });
  // eslint-disable-next-line  array-callback-return
  if (payload.products) {
    payload.products.map((product, i) => {
      body.append(`products[${i}]`, product.id);
    });
  }
  return api
    .post(`/catalogs/admin-create-catalog`, body)
    .then((data) => data.data);
};

// API FROM GETTING PRODUCTS BY CATALOG ID
export const getProductsByCatalogNoPagApi = (catalogId) => {
  return api
    .get(`/catalogs/get-catalog-products-no-pagination/${catalogId}`)
    .then((data) => data.data);
};

// LICENCE USER UPDATE CATALOG API
export const licenceUserUpdateCatalogApi = (payload) => {
  const body = new FormData();
  const params = ["name", "category_id", "description", "image"];
  // eslint-disable-next-line  array-callback-return
  params.map((param) => {
    if (param === "image") {
      if (payload[param].name) {
        body.append(param, payload[param]);
        // eslint-disable-next-line array-callback-return
        return;
      } else if (payload[param].isStock) {
        body.append("stock_image", payload[param].download_location);
      }
    } else {
      body.append(param, payload[param]);
    }
  });
  // eslint-disable-next-line  array-callback-return
  payload.products.map((product, i) => {
    body.append(`products[${i}]`, product.id);
  });
  return api
    .post(`/catalogs/user-update-catalog/${payload.catalogId}`, body)
    .then((data) => data.data);
};

// LICENCE ADMIN UPDATE CATALOG API
export const adminUpdateCatalogApi = (payload) => {
  const body = new FormData();
  const params = [
    "name",
    "category_id",
    "description",
    "image",
    "created_at",
    "author_id",
  ];
  // eslint-disable-next-line  array-callback-return
  params.map((param) => {
    if (param === "image") {
      if (payload[param].name) {
        body.append(param, payload[param]);
        // eslint-disable-next-line array-callback-return
        return;
      } else if (payload[param].isStock) {
        body.append("stock_image", payload[param].download_location);
      }
    } else {
      body.append(param, payload[param]);
    }
  });
  // eslint-disable-next-line  array-callback-return
  payload.products.map((product, i) => {
    body.append(`products[${i}]`, product.id);
  });
  return api
    .post(`/catalogs/admin-update-catalog/${payload.catalogId}`, body)
    .then((data) => data.data);
};

// API FOR PUBLISHING BLOG DRAFT
export const publishBlogDraftApi = (payload) => {
  return api
    .post(`/moderations/publish-blog-draft/${payload.blogId}`)
    .then((data) => data.data);
};

// API FOR PUBLISHING BLOG DRAFT
export const publishArticleDraftApi = (payload) => {
  return api
    .post(`/moderations/publish-article-draft/${payload.articleId}`)
    .then((data) => data.data);
};

// API FOR GETTING USER CATALOGS NO PAGINATION API
export const getUserCatalogsNoPaginationApi = (payload) => {
  let filters = "";
  // eslint-disable-next-line  array-callback-return
  Object.entries(payload).map((filter) => {
    if (filters) {
      filters = `${filters}&${filter[0]}=${filter[1]}`;
    } else {
      filters = `?${filter[0]}=${filter[1]}`;
    }
  });
  return api
    .get(`/catalogs/get-user-catalogs-no-pagination${filters}`)
    .then((data) => data.data);
};

// SAVE CATALOG AS DRAFT API
export const saveCatalogAsDraftApi = (payload) => {
  const body = new FormData();
  const params = ["name", "category_id", "description", "image"];
  // eslint-disable-next-line  array-callback-return
  params.map((param) => {
    if (param === "image") {
      if (payload[param].isStock) {
        body.append("stock_image", payload[param].download_location);
      } else {
        body.append(param, payload[param]);
      }
    } else {
      body.append(param, payload[param]);
    }
  });
  payload.products &&
    // eslint-disable-next-line  array-callback-return
    payload.products.map((product, i) => {
      body.append(`products[${i}]`, product.id);
    });
  return api
    .post(`/catalogs/create-catalog-draft`, body)
    .then((data) => data.data);
};

// PUBLISH CATALOG DRAFT API
export const publishCatalogDraftApi = (payload) => {
  return api
    .post(`/moderations/publish-catalog-draft/${payload.catalogId}`)
    .then((data) => data.data);
};
// DISABLE CATALOG BY LICENCE CLIENT API
export const disableCatalogByLicenceClientApi = (payload) => {
  return api
    .post(`/catalogs/disable-catalog/${payload.blogId}`)
    .then((data) => data.data);
};

// UPDATE CATALOG DRAFT API
export const updateCatalogDraftApi = (payload) => {
  const body = new FormData();
  const params = ["name", "category_id", "description", "image"];
  // eslint-disable-next-line  array-callback-return
  params.map((param) => {
    if (param === "image") {
      if (payload[param].name) {
        body.append(param, payload[param]);
      } else if (payload[param].isStock) {
        body.append("stock_image", payload[param].download_location);
      }
    } else {
      body.append(param, payload[param]);
    }
  });
  // eslint-disable-next-line  array-callback-return
  payload.products.map((product, i) => {
    body.append(`products[${i}]`, product.id);
  });
  return api
    .post(`/catalogs/update-catalog-draft/${payload.catalogId}`, body)
    .then((data) => data.data);
};

// LICENCE USER CREATE PRODUCT API
export const licenceClientCreateProductApi = (payload) => {
  const body = new FormData();
  let gallery_stock_index = 0;
  let gallery_index = 0;
  const params = [
    "author_id",
    "category_id",
    "title",
    "description",
    "price",
    "currency_id",
    "image",
    "comments_allowed",
    "discount",
    "discount_type",
  ];
  let tags = "";
  // eslint-disable-next-line  array-callback-return
  params.map((param) => {
    if (param === "image") {
      if (payload[param].isStock) {
        body.append(`stock_image`, payload[param].download_location);
      } else {
        body.append(param, payload[param]);
      }
    }
    body.append(param, payload[param]);
  });
  // eslint-disable-next-line  array-callback-return
  payload.gallery.map((img, i) => {
    if (img.isStock) {
      body.append(
        `stock_gallery[${gallery_stock_index}]`,
        img.download_location
      );
      gallery_stock_index++;
    } else {
      body.append(`gallery[${gallery_index}]`, img);
      gallery_index++;
    }
  });
  // eslint-disable-next-line  array-callback-return
  payload.catalogs.map((catalog, i) => {
    body.append(`catalogs[${i}]`, catalog.id);
  });
  // eslint-disable-next-line  array-callback-return
  payload.video_gallery.map((video, i) => {
    body.append(`video_gallery[${i}]`, video);
  });
  // eslint-disable-next-line  array-callback-return
  payload.attribute_values.map((value, i) => {
    body.append(`attribute_values[${i}]`, value);
  });
  // eslint-disable-next-line  array-callback-return
  payload.tags.map((tag, i) => {
    if (tags) {
      tags = `${tags},${tag.name}`;
    } else {
      tags = tag.name;
    }
  });
  body.append(`tags`, tags);

  payload.address.map((singleAddress, i) => {
    body.append(`locations[${i}]`, singleAddress.id);
  });
  return api
    .post(`/contents/user-create-product`, body)
    .then((data) => data.data);
};

// ADMIN CREATE PRODUCT API
export const adminCreateProductApi = (payload) => {
  const body = new FormData();
  let gallery_stock_index = 0;
  let gallery_index = 0;
  const params = [
    "author_id",
    "category_id",
    "title",
    "description",
    "price",
    "currency_id",
    "image",
    "comments_allowed",
    "created_at",
    "discount",
    "discount_type",
  ];
  let tags = "";
  // eslint-disable-next-line  array-callback-return
  params.map((param) => {
    if (param === "image") {
      if (payload[param].isStock) {
        body.append(`stock_image`, payload[param].download_location);
      } else {
        body.append(param, payload[param]);
      }
    }
    body.append(param, payload[param]);
  });
  // eslint-disable-next-line  array-callback-return
  payload.gallery.map((img, i) => {
    if (img.isStock) {
      body.append(
        `stock_gallery[${gallery_stock_index}]`,
        img.download_location
      );
      gallery_stock_index++;
    } else {
      body.append(`gallery[${gallery_index}]`, img);
      gallery_index++;
    }
  });
  // eslint-disable-next-line  array-callback-return
  if (payload.catalogs) {
    payload.catalogs.map((catalog, i) => {
      body.append(`catalogs[${i}]`, catalog.id);
    });
  }
  // eslint-disable-next-line  array-callback-return
  payload.video_gallery.map((video, i) => {
    body.append(`video_gallery[${i}]`, video);
  });
  // eslint-disable-next-line  array-callback-return
  payload.attribute_values.map((value, i) => {
    body.append(`attribute_values[${i}]`, value);
  });
  // eslint-disable-next-line  array-callback-return
  payload.tags.map((tag, i) => {
    if (tags) {
      tags = `${tags},${tag.name}`;
    } else {
      tags = tag.name;
    }
  });
  body.append(`tags`, tags);

  payload.address.map((singleAddress, i) => {
    body.append(`locations[${i}]`, singleAddress.id);
  });

  return api
    .post(`/contents/admin-create-product`, body)
    .then((data) => data.data);
};

// LICENCE USER CREATE PRODUCT DRAFT API
export const licenceClientCreateProductDraftApi = (payload) => {
  const body = new FormData();
  let gallery_stock_index = 0;
  let gallery_index = 0;
  const params = [
    "author_id",
    "category_id",
    "title",
    "description",
    "price",
    "currency_id",
    "image",
    "comments_allowed",
    "discount",
    "discount_type",
  ];
  let tags = "";
  // eslint-disable-next-line  array-callback-return
  params.map((param) => {
    if (param === "image") {
      if (payload[param].isStock) {
        body.append("stock_image", payload[param].download_location);
        // eslint-disable-next-line  array-callback-return
        return;
      }
      if (payload[param]) {
        body.append(param, payload[param]);
      }
    } else {
      body.append(param, payload[param]);
    }
  });
  // eslint-disable-next-line  array-callback-return
  payload.gallery.map((img, i) => {
    if (img.isStock) {
      body.append(
        `stock_gallery[${gallery_stock_index}]`,
        img.download_location
      );
      gallery_stock_index++;
    } else {
      body.append(`gallery[${gallery_index}]`, img);
      gallery_index++;
    }
  });
  // eslint-disable-next-line  array-callback-return
  payload.catalogs.map((catalog, i) => {
    body.append(`catalogs[${i}]`, catalog.id);
  });
  // eslint-disable-next-line  array-callback-return
  payload.video_gallery.map((video, i) => {
    body.append(`video_gallery[${i}]`, video);
  });
  // eslint-disable-next-line  array-callback-return
  payload.attribute_values.map((value, i) => {
    body.append(`attribute_values[${i}]`, value);
  });
  // eslint-disable-next-line  array-callback-return
  payload.tags.map((tag, i) => {
    if (tags) {
      tags = `${tags},${tag.name}`;
    } else {
      tags = tag.name;
    }
  });
  payload.address.map((singleAddress, i) => {
    body.append(`locations[${i}]`, singleAddress.id);
  });

  body.append(`tags`, tags);

  return api
    .post(`/contents/create-product-draft`, body)
    .then((data) => data.data);
};

// LICENCE USER UPDATE PRODUCT DRAFT API
export const licenceClientUpdateProductDraftApi = (payload) => {
  const body = new FormData();
  let idIndex = 0;
  let newImgIndex = 0;
  let stock_gallery_index = 0;

  const params = [
    "author_id",
    "category_id",
    "title",
    "description",
    "price",
    "currency_id",
    "image",
    "comments_allowed",
    "discount",
    "discount_type",
  ];
  let tags = "";
  // eslint-disable-next-line  array-callback-return
  params.map((param) => {
    if (param === "image") {
      if (payload[param].isStock) {
        body.append("stock_image", payload[param].download_location);
        // eslint-disable-next-line  array-callback-return
        return;
      }
      if (payload[param].name) {
        body.append(param, payload[param]);
        // eslint-disable-next-line  array-callback-return
        return;
      } else {
        body.append(param, {});
      }
    } else {
      body.append(param, payload[param]);
    }
  });
  // eslint-disable-next-line  array-callback-return
  payload.gallery.map((img, i) => {
    if (img.name) {
      body.append(`new_gallery[${newImgIndex}]`, img);
      newImgIndex++;
      // eslint-disable-next-line  array-callback-return
      return;
    }
    if (img.isStock) {
      body.append(
        `stock_gallery[${stock_gallery_index}]`,
        img.download_location
      );
      stock_gallery_index++;
      // eslint-disable-next-line  array-callback-return
      return;
    } else {
      body.append(`old_gallery[${idIndex}]`, img.id);
      idIndex++;
    }
  });
  // eslint-disable-next-line  array-callback-return
  payload.catalogs.map((catalog, i) => {
    body.append(`catalogs[${i}]`, catalog.id);
  });
  // eslint-disable-next-line  array-callback-return
  payload.video_gallery.map((video, i) => {
    body.append(`video_gallery[${i}]`, video);
  });
  // eslint-disable-next-line  array-callback-return
  payload.attribute_values.map((value, i) => {
    body.append(`attribute_values[${i}]`, value);
  });
  // eslint-disable-next-line  array-callback-return
  payload.tags.map((tag, i) => {
    if (tags) {
      tags = `${tags},${tag.name}`;
    } else {
      tags = tag.name;
    }
  });
  body.append(`tags`, tags);

  payload.address.map((singleAddress, i) => {
    body.append(`locations[${i}]`, singleAddress.id);
  });

  return api
    .post(`/contents/update-product-draft/${payload.draftId}`, body)
    .then((data) => data.data);
};

// PUBLISH PRODUCT DRAFT API
export const licenceClientPublishProductDraftApi = (payload) => {
  return api
    .post(`/moderations/publish-product-draft/${payload.productId}`)
    .then((data) => data.data);
};

// LICENCE USER UPDATE PRODUCT API
export const licenceClientUpdateProductApi = (payload) => {
  const body = new FormData();
  let idIndex = 0;
  let newImgIndex = 0;
  let stock_gallery_index = 0;
  const params = [
    "author_id",
    "category_id",
    "title",
    "description",
    "price",
    "currency_id",
    "image",
    "comments_allowed",
    // "discount",
    // "discount_type",
  ];
  let tags = "";
  // eslint-disable-next-line  array-callback-return

  if (payload.discount === null) {
    body.append("discount", 0);
  } else {
    body.append("discount", payload.discount);
  }
  if (payload.discount_type === null) {
    body.append("discount_type", 0);
  } else {
    body.append("discount_type", payload.discount_type);
  }
  params.map((param) => {
    if (param === "image") {
      if (payload[param].isStock) {
        body.append("stock_image", payload[param].download_location);
        // eslint-disable-next-line  array-callback-return
        return;
      }
      if (payload[param].name) {
        body.append(param, payload[param]);
        // eslint-disable-next-line  array-callback-return
        return;
      } else {
        body.append(param, {});
      }
    } else {
      body.append(param, payload[param]);
    }
  });
  // eslint-disable-next-line  array-callback-return
  payload.gallery.map((img, i) => {
    if (img.name) {
      body.append(`new_gallery[${newImgIndex}]`, img);
      newImgIndex++;
      // eslint-disable-next-line  array-callback-return
      return;
    }
    if (img.isStock) {
      body.append(
        `stock_gallery[${stock_gallery_index}]`,
        img.download_location
      );
      stock_gallery_index++;
    } else {
      body.append(`old_gallery[${idIndex}]`, img.id);
      idIndex++;
    }
  });
  // eslint-disable-next-line  array-callback-return
  payload.catalogs.map((catalog, i) => {
    body.append(`catalogs[${i}]`, catalog.id);
  });
  // eslint-disable-next-line  array-callback-return
  payload.video_gallery.map((video, i) => {
    body.append(`video_gallery[${i}]`, video);
  });
  // eslint-disable-next-line  array-callback-return
  payload.attribute_values.map((value, i) => {
    body.append(`attribute_values[${i}]`, value);
  });
  // eslint-disable-next-line  array-callback-return
  payload.tags.map((tag, i) => {
    if (tags) {
      tags = `${tags},${tag.name}`;
    } else {
      tags = tag.name;
    }
  });
  body.append(`tags`, tags);

  payload.address.map((singleAddress, i) => {
    body.append(`locations[${i}]`, singleAddress.id);
  });
  return api
    .post(`/contents/user-update-product/${payload.productId}`, body)
    .then((data) => data.data);
};

// LICENCE ADMIN UPDATE PRODUCT API
export const adminUpdateProductApi = (payload) => {
  const body = new FormData();
  let idIndex = 0;
  let newImgIndex = 0;
  let stock_gallery_index = 0;
  const params = [
    "author_id",
    "category_id",
    "title",
    "description",
    "price",
    "currency_id",
    "image",
    "comments_allowed",
    "created_at",
    "discount",
    "discount_type",
  ];
  let tags = "";

  if (payload.image.preview) {
    if (payload.image.isStock) {
      body.append("stock_image", payload.image.download_location);
    } else if (payload.image.name) {
      // console.log("payload.image", payload.image);
      body.append(`image`, payload.image);
    }
  }
  // eslint-disable-next-line  array-callback-return
  params.map((param) => {
    if (param === "image") {
      if (payload[param].isStock) {
        body.append("stock_image", payload[param].download_location);
        // eslint-disable-next-line  array-callback-return
        return;
      }
      if (payload[param].name) {
        body.append(param, payload[param]);
        // eslint-disable-next-line  array-callback-return
        return;
      } else {
        body.append(param, {});
      }
    } else {
      body.append(param, payload[param]);
    }
  });
  // eslint-disable-next-line  array-callback-return
  payload.gallery &&
    // eslint-disable-next-line  array-callback-return
    payload.gallery.map((img, i) => {
      if (img.name) {
        body.append(`new_gallery[${newImgIndex}]`, img);
        newImgIndex++;
        // eslint-disable-next-line  array-callback-return
        return;
      }
      if (img.isStock) {
        body.append(
          `stock_gallery[${stock_gallery_index}]`,
          img.download_location
        );
        stock_gallery_index++;
      } else {
        body.append(`old_gallery[${idIndex}]`, img.id);
        idIndex++;
      }
    });
  payload.catalogs &&
    // eslint-disable-next-line  array-callback-return
    payload.catalogs.map((catalog, i) => {
      body.append(`catalogs[${i}]`, catalog.id);
    });
  // payload.video_gallery.map((video, i) => {
  //   body.append(`video_gallery[${i}]`, video);
  // });

  payload.video_gallery &&
    // eslint-disable-next-line  array-callback-return
    payload.video_gallery.map((video, i) => {
      if (video.id) {
        body.append(`video_gallery[${i}]`, video.video_url);
      } else {
        body.append(`video_gallery[${i}]`, video);
      }
    });

  payload.attribute_values &&
    // eslint-disable-next-line  array-callback-return
    payload.attribute_values.map((value, i) => {
      if (value.id) {
        body.append(`attribute_values[${i}]`, value.id);
      } else {
        body.append(`attribute_values[${i}]`, value);
      }
      // body.append(`attribute_values[${i}]`, value);
    });
  // eslint-disable-next-line  array-callback-return
  payload.tags.map((tag, i) => {
    if (tags) {
      tags = `${tags},${tag.name}`;
    } else {
      tags = tag.name;
    }
  });
  body.append(`tags`, tags);

  payload.address &&
    payload.address.map((singleAddress, i) => {
      body.append(`locations[${i}]`, singleAddress.id);
    });

  payload.content_locations &&
    payload.content_locations.map((singleLocation, i) => {
      body.append(`content_locations[${i}]`, singleLocation.id);
    });

  return api
    .post(`/contents/admin-update-product/${payload.productId}`, body)
    .then((data) => data.data);
};

// DELETE PRODUCT BY
export const deleteProductApi = (payload) => {
  // console.log("payload", payload);
  return api
    .post(`/contents/user-disable-product/${payload.blogId}`)
    .then((data) => data.data);
};

// API FOR CREATING BLOG DRAFT
export const licenceUserCreateBlogDraftApi = (payload) => {
  const body = new FormData();

  const params = [
    "mod_title",
    "mod_content_text",
    "mod_image",
    "mod_category_id",
    "comments_allowed",
    "author_id",
  ];
  let stock_gallery_index = 0;
  let gallery_index = 0;
  let tags = "";
  // eslint-disable-next-line  array-callback-return
  params.map((param) => {
    if (param === "mod_category_id" || param === "author_id") {
      if (payload[param]) {
        // eslint-disable-next-line  array-callback-return
        return body.append(param, payload[param]);
      } else {
        // eslint-disable-next-line  array-callback-return
        return;
      }
    }
    if (param === "mod_image") {
      if (payload[param].isStock) {
        body.append("stock_image", payload[param].download_location);
      } else if (payload[param]) {
        body.append(param, payload[param]);
      }
    } else {
      body.append(param, payload[param]);
    }
  });
  // eslint-disable-next-line  array-callback-return
  payload.gallery.map((img, i) => {
    if (img.isStock) {
      body.append(
        `stock_gallery[${stock_gallery_index}]`,
        img.download_location
      );
      stock_gallery_index++;
    } else {
      body.append(`gallery[${gallery_index}]`, img);
      gallery_index++;
    }
  });
  // eslint-disable-next-line  array-callback-return
  payload.video_gallery.map((video, i) => {
    body.append(`video_gallery[${i}]`, video);
  });
  // eslint-disable-next-line  array-callback-return
  payload.tags.map((tag, i) => {
    if (tags) {
      tags = `${tags},${tag.name}`;
    } else {
      tags = tag.name;
    }
  });
  body.append(`tags`, tags);

  return api
    .post(`/contents/create-blog-draft`, body)
    .then((data) => data.data);
};

// API FOR CREATING ARTICLE DRAFT
export const createArticleDraftApi = (payload) => {
  const body = new FormData();
  const params = [
    "mod_title",
    "mod_content_text",
    "mod_image",
    "mod_category_id",
    "comments_allowed",
    "author_id",
  ];
  let stock_gallery_index = 0;
  let gallery_index = 0;
  let tags = "";
  // eslint-disable-next-line  array-callback-return
  params.map((param) => {
    if (param === "mod_category_id" || param === "author_id") {
      if (payload[param]) {
        // eslint-disable-next-line  array-callback-return
        return body.append(param, payload[param]);
      } else {
        // eslint-disable-next-line  array-callback-return
        return;
      }
    }
    if (param === "mod_image") {
      if (payload[param].isStock) {
        body.append("stock_image", payload[param].download_location);
      } else if (payload[param]) {
        body.append(param, payload[param]);
      }
    } else {
      body.append(param, payload[param]);
    }
  });
  // eslint-disable-next-line  array-callback-return
  payload.gallery.map((img, i) => {
    if (img.isStock) {
      body.append(
        `stock_gallery[${stock_gallery_index}]`,
        img.download_location
      );
      stock_gallery_index++;
    } else {
      body.append(`gallery[${gallery_index}]`, img);
      gallery_index++;
    }
  });
  // eslint-disable-next-line  array-callback-return
  payload.video_gallery.map((video, i) => {
    body.append(`video_gallery[${i}]`, video);
  });
  // eslint-disable-next-line  array-callback-return
  payload.tags.map((tag, i) => {
    if (tags) {
      tags = `${tags},${tag.name}`;
    } else {
      tags = tag.name;
    }
  });
  body.append(`tags`, tags);

  return api
    .post(`/contents/create-article-draft`, body)
    .then((data) => data.data);
};

// API FOR UPDATING ARTICLE DRAFT
export const updateArticleDraftApi = (payload) => {
  const body = new FormData();
  let idIndex = 0;
  let newImgIndex = 0;
  let stock_gallery_index = 0;

  const params = [
    "mod_title",
    "mod_content_text",
    "mod_image",
    "mod_category_id",
    "comments_allowed",
    "author_id",
  ];
  let tags = "";
  // eslint-disable-next-line  array-callback-return
  params.map((param) => {
    if (param === "mod_category_id" || param === "author_id") {
      if (payload[param]) {
        // eslint-disable-next-line  array-callback-return
        return body.append(param, payload[param]);
      }
    }
    if (param === "mod_image") {
      if (payload[param].isStock) {
        body.append("stock_image", payload[param].download_location);
      } else if (payload[param]) {
        body.append(param, payload[param]);
      }
    } else {
      body.append(param, payload[param]);
    }
  });
  // eslint-disable-next-line  array-callback-return
  payload.gallery.map((img, i) => {
    if (img.name) {
      body.append(`new_gallery[${newImgIndex}]`, img);
      newImgIndex++;
      // eslint-disable-next-line  array-callback-return
      return;
    }
    if (img.isStock) {
      body.append(
        `stock_gallery[${stock_gallery_index}]`,
        img.download_location
      );
      stock_gallery_index++;
    } else {
      body.append(`old_gallery[${idIndex}]`, img.id);
      idIndex++;
    }
  });
  // eslint-disable-next-line  array-callback-return
  payload.video_gallery.map((video, i) => {
    body.append(`video_gallery[${i}]`, video);
  });
  // eslint-disable-next-line  array-callback-return
  payload.tags.map((tag, i) => {
    if (tags) {
      tags = `${tags},${tag.name}`;
    } else {
      tags = tag.name;
    }
  });
  body.append(`tags`, tags);

  return api
    .post(`/contents/update-article-draft/${payload.draftId}`, body)
    .then((data) => data.data);
};

// API FOR UPDATING BLOG DRAFT
export const licenceUserUpdateBlogDraftApi = (payload) => {
  const body = new FormData();
  let idIndex = 0;
  let newImgIndex = 0;
  let stock_gallery_index = 0;

  const params = [
    "mod_title",
    "mod_content_text",
    "mod_image",
    "mod_category_id",
    "comments_allowed",
    "author_id",
  ];
  let tags = "";
  // eslint-disable-next-line  array-callback-return
  params.map((param) => {
    if (param === "mod_category_id" || param === "author_id") {
      if (payload[param]) {
        return body.append(param, payload[param]);
      }
    }
    if (param === "mod_image") {
      if (payload[param].isStock) {
        body.append("stock_image", payload[param].download_location);
      } else if (payload[param]) {
        body.append(param, payload[param]);
      }
    } else {
      body.append(param, payload[param]);
    }
  });
  // eslint-disable-next-line  array-callback-return
  payload.gallery.map((img, i) => {
    if (img.name) {
      body.append(`new_gallery[${newImgIndex}]`, img);
      newImgIndex++;
      // eslint-disable-next-line  array-callback-return
      return;
    }
    if (img.isStock) {
      body.append(
        `stock_gallery[${stock_gallery_index}]`,
        img.download_location
      );
      stock_gallery_index++;
    } else {
      body.append(`old_gallery[${idIndex}]`, img.id);
      idIndex++;
    }
  });
  // eslint-disable-next-line  array-callback-return
  payload.video_gallery.map((video, i) => {
    body.append(`video_gallery[${i}]`, video);
  });
  // eslint-disable-next-line  array-callback-return
  payload.tags.map((tag, i) => {
    if (tags) {
      tags = `${tags},${tag.name}`;
    } else {
      tags = tag.name;
    }
  });
  body.append(`tags`, tags);

  return api
    .post(`/contents/update-blog-draft/${payload.draftId}`, body)
    .then((data) => data.data);
};

// UPDATE LICENCE CLIENT PROFILE
export const updateLicenceClientProfileApi = (payload) => {
  // console.log("payload", payload);
  const body = new FormData();
  const params = ["name", "email", "phone", "description", "gender", "avatar"];
  // eslint-disable-next-line  array-callback-return
  params.map((param) => {
    body.append(param, payload[param]);
  });
  // eslint-disable-next-line  array-callback-return
  payload.locations.map((location, i) => {
    body.append(`locations[${i}][country]`, location.country);
    body.append(`locations[${i}][city]`, location.city);
    body.append(`locations[${i}][address]`, location.address);
    body.append(`locations[${i}][primary_location]`, i === 0 ? 0 : 1);
  });

  return api
    .post(`/profiles/update-client-profile/${payload.clientId}`, body)
    .then((data) => data.data);
};

// UPDATE LICENCE USER PROFILE
export const updateLicenceUserProfileApi = (payload) => {
  const body = new FormData();
  // const params = ["name", "email", "surname", "gender", "birthdate"];
  const params = ["name", "username"];
  // eslint-disable-next-line  array-callback-return
  params.map((param) => {
    body.append(param, payload[param]);
  });

  if (payload.password) {
    body.append("password", payload.password);
  }

  if (payload.email) {
    body.append("email", payload.email);
  }

  return api
    .post(`/profiles/update-front-user-profile/${payload.clientId}`, body)
    .then((data) => data.data);
};

// UPDATE LICENCE USER AND CLIENT PASSWORD
export const licenceUsersUpdatePasswordApi = (payload) => {
  // console.log("payload", payload);
  const body = new FormData();
  const params = ["username", "password", "new_password"];
  // eslint-disable-next-line  array-callback-return
  params.map((param) => {
    body.append(param, payload[param]);
  });

  return api
    .post(`/users/change-password/${payload.clientId}`, body)
    .then((data) => data.data);
};

// LOGOUT AUTH USER
export const logoutUserApi = (payload) => {
  const body = new FormData();
  const params = ["auth_key"];
  // eslint-disable-next-line  array-callback-return
  params.map((param) => {
    body.append(param, payload[param]);
  });

  return api.post(`/users/logout-user`, body).then((data) => data.data);
};

// API FOR GETTING TAG RELATER CONTENT
export const linkProductToPlannerApi = (payload) => {
  return api
    .post(
      `/planner-items/link-product-to-planner/${payload.planner.id}/${payload.contentId}`
    )
    .then((data) => data.data);
};

// API FOR UPDATING USER PLANNER ITEM DATE AND TIME
export const updatePlannerItemDateAndTimeApi = (payload) => {
  let body = new FormData();

  body.append("scheduled_date", payload.scheduled_date);
  // console.log("payload", payload.scheduled_date);
  return api
    .post(`/planner-items/save-scheduled-date/${payload.plannerItemId}`, body)
    .then((data) => data.data);
};

// API FOR UPDATING USER PLANNER ITEM DATE AND TIME
export const savePlannerItemRemarkApi = (payload) => {
  let body = new FormData();

  body.append("remark", payload.remark);
  // console.log("payload", payload.scheduled_date);
  return api
    .post(
      `/planner-items/save-planner-item-note/${payload.plannerItemId}`,
      body
    )
    .then((data) => data.data);
};

// API FOR UPDATING USER PLANNER ITEM PRICE
export const changePlannerItemPriceApi = (payload) => {
  return api
    .post(
      `/planner-items/save-price-of-planner-item/${payload.plannerItemId}/${payload.price}/${payload.currencyId}`
    )
    .then((data) => data.data);
};

// API FOR UPDATING USER PLANNER CALCULATE PRICE SETTING
export const changeClaculatePriceApi = (payload) => {
  return api
    .post(
      `/planner-items/change-calculate-price/${payload.plannerItemId}/${payload.value}`
    )
    .then((data) => data.data);
};

// API FOR UPDATING USER SHOW PLANNER ITEM PRICE PUBLICLY STATUS
export const showPlannerItemPricePublicyApi = (payload) => {
  let body = new FormData();

  body.append("show_publicly", payload.show_publicly);
  return api
    .post(`/planner-items/show-price-publicly/${payload.plannerItemId}`, body)
    .then((data) => data.data);
};

// API FOR UPDATING USER SHOW PLANNER ITEM PUBLICLY STATUS
export const showPlannerItemPublicyApi = (payload) => {
  let body = new FormData();

  body.append("show_publicly", payload.show_publicly);
  return api
    .post(
      `/planner-items/show-planner-item-publicly/${payload.plannerItemId}`,
      body
    )
    .then((data) => data.data);
};

// API FOR UPDATING USER SHOW PLANNER ITEM PUBLICLY STATUS
export const togglePublicPlannerApi = (payload) => {
  return api
    .post(`/planners/toggle-public-planner/${payload.plannerItemId}`)
    .then((data) => data.data);
};

// API FOR GETTING AUTH CLIENT PRODUCTS WITH NO PAGINATION
export const getAuthClientProductsNoPaginationApi = (payload) => {
  let filters = "";
  // eslint-disable-next-line  array-callback-return
  Object.entries(payload).map((filter) => {
    if (filters) {
      filters = `${filters}&${filter[0]}=${filter[1]}`;
    } else {
      filters = `?${filter[0]}=${filter[1]}`;
    }
  });
  return api
    .get(`/contents/get-licence-products-no-pagination${filters}`)
    .then((data) => data.data);
};

// API FOR GETTING SEARCH TERM CONTENT
export const getUserSearchTermContentApi = (payload) => {
  let filters = "";
  // eslint-disable-next-line  array-callback-return
  Object.entries(payload).map((filter) => {
    // console.log("filter[1]", filter[1]);
    if (filter[1]) {
      if (filter[0] !== "page") {
        if (filters) {
          filters = `${filters}&filter[${filter[0]}]=${filter[1]}`;
        } else {
          filters = `?filter[${filter[0]}]=${filter[1]}`;
        }
      } else {
        filters = `${filters}&page=${filter[1]}`;
      }
    }
  });

  return api.get(`/contents${filters}`).then((data) => ({
    data: data.data,
    headers: data.headers,
  }));
};

// API FOR LOGIN USERS FROM SOCIAL AUTH
export const loginSocialAuthUserApi = (payload) => {
  let body = new FormData();
  // eslint-disable-next-line  array-callback-return
  Object.entries(payload).map((filter) => {
    body.append(`${filter[0]}`, filter[1]);
  });

  return api.post(`/users/social-auth`, body).then((data) => data.data);
};

// API FOR LOGIN USERS FROM SOCIAL AUTH
export const getLicenceUsersApi = (payload) => {
  let filters = "";
  // eslint-disable-next-line  array-callback-return
  Object.entries(payload).map((filter) => {
    if (filters) {
      filters = `${filters}&filter[${filter[0]}]=${filter[1]}`;
    } else {
      filters = `?filter[${filter[0]}]=${filter[1]}`;
    }
  });
  // let body = new FormData();
  // Object.entries(payload).map((filter) => {
  //   body.append(`${filter[0]}`, filter[1]);
  // });

  return api.get(`/users${filters}`).then((data) => data.data);
};
// API GETTING LICENCE CATALOGS
export const getLicenceCatalogsApi = (payload) => {
  let filters = "";
  // eslint-disable-next-line  array-callback-return
  Object.entries(payload).map((filter) => {
    if (filters) {
      filters = `${filters}&${filter[0]}=${filter[1]}`;
    } else {
      filters = `?${filter[0]}=${filter[1]}`;
    }
  });
  return api
    .get(`/catalogs/get-licence-catalogs-no-pagination${filters}`)
    .then((data) => data.data);
};

// ADMIN APPROVE BLOG MODERATION API
export const approveBlogModerationApi = (payload) => {
  return api
    .post(`/moderations/approve-blog/${payload.blogId}`)
    .then((data) => data.data);
};
// ADMIN DISAPPROVE BLOG MODERATION API
export const disapproveBlogModerationApi = (payload) => {
  return api
    .post(`/moderations/disapprove-blog/${payload.blogId}`)
    .then((data) => data.data);
};
// ADMIN REQUEST FOR EDIT BLOG MODERATION API
export const requestForEditBlogModerationApi = (payload) => {
  const body = new FormData();
  body.append("remark_request_for_edit", payload.remark_request_for_edit);
  return api
    .post(`/moderations/request-for-edit-blog/${payload.id}`, body)
    .then((data) => data.data);
};

// ADMIN APPROVE CATALOG MODERATION API
export const approveCatalogModerationApi = (payload) => {
  return api
    .post(`/moderations/approve-catalog/${payload.catalogId}`)
    .then((data) => data.data);
};
// ADMIN DISAPPROVE CATALOG MODERATION API
export const disapproveCatalogModerationApi = (payload) => {
  return api
    .post(`/catalogs/disable-catalog/${payload.catalogId}`)
    .then((data) => data.data);
};
// ADMIN REQUEST FOR EDIT CATALOG MODERATION API
export const requestForEditCatalogModerationApi = (payload) => {
  const body = new FormData();
  body.append("remark_request_for_edit", payload.remark_request_for_edit);
  return api
    .post(`/moderations/request-for-edit-catalog/${payload.id}`, body)
    .then((data) => data.data);
};

// ADMIN APPROVE PRODUCT MODERATION API
export const approveProductModerationApi = (payload) => {
  return api
    .post(`/moderations/approve-product/${payload.id}`)
    .then((data) => data.data);
};
// ADMIN DISAPPROVE PRODUCT MODERATION API
export const disapproveProductModerationApi = (payload) => {
  return api
    .post(`/moderations/disapprove-product/${payload.id}`)
    .then((data) => data.data);
};
// ADMIN REQUEST FOR EDIT PRODUCT MODERATION API
export const requestForEditProductModerationApi = (payload) => {
  const body = new FormData();
  body.append("remark_request_for_edit", payload.remark_request_for_edit);
  return api
    .post(`/moderations/request-for-edit-product/${payload.id}`, body)
    .then((data) => data.data);
};

// API FOR GETTING ADMIN MODERATION NUMBERS
export const getModerationNumbersApi = (payload) => {
  return api.get(`/moderations/moderation-numbers`).then((data) => data.data);
};

// API FOR GETTING LICENCE COMMENTS ON  MODERATION
export const getLicenceCommentsOnModerationApi = (payload) => {
  let filters = "";
  // eslint-disable-next-line  array-callback-return
  Object.entries(payload).map((filter) => {
    if (filter[0] !== "page") {
      if (filters) {
        filters = `${filters}&filter[${filter[0]}]=${filter[1]}`;
      } else {
        filters = `?filter[${filter[0]}]=${filter[1]}`;
      }
    }
  });
  if (payload.page) {
    if (filters) {
      filters = `${filters}&page=${payload.page}`;
    } else {
      filters = `${filters}?page=${payload.page}`;
    }
  }
  return api
    .get(`/moderations/comments-on-moderation${filters}`)
    .then((data) => ({
      data: data.data,
      headers: data.headers,
    }));
};

// ADMIN APPROVE COMMENT MODERATION API
export const approveCommentModerationApi = (payload) => {
  return api
    .post(`/moderations/approve-comment/${payload.id}`)
    .then((data) => data.data);
};
// ADMIN DISAPPROVE COMMENT MODERATION API
export const disapproveCommentModerationApi = (payload) => {
  return api
    .post(`/moderations/disapprove-comment/${payload.id}`)
    .then((data) => data.data);
};

// ADMIN GET ALL LICENCE USERS API
export const getAllLicenceUsersApi = (payload) => {
  let filters = "";
  // eslint-disable-next-line  array-callback-return
  Object.entries(payload).map((filter) => {
    if (filter[0] !== "page") {
      if (filters) {
        filters = `${filters}&filter[${filter[0]}]=${filter[1]}`;
      } else {
        filters = `?filter[${filter[0]}]=${filter[1]}`;
      }
    }
  });
  if (payload.page) {
    if (filters) {
      filters = `${filters}&page=${payload.page}`;
    } else {
      filters = `${filters}?page=${payload.page}`;
    }
  }
  return api.get(`/users/admin-get-licence-users${filters}`).then((data) => ({
    data: data.data,
    headers: data.headers,
  }));
};

// ADMIN GET ALL LICENCE USERS API
export const adminBlockUserApi = (payload) => {
  return api
    .post(`/users/admin-block-user/${payload.id}`)
    .then((data) => data.data);
};

// ADMIN GET ALL LICENCE USERS API
export const adminUpdateUserUsernameApi = (payload) => {
  let body = new FormData();
  body.append("username", payload.username);

  return api
    .post(`/users/admin-update-user-username/${payload.id}`, body)
    .then((data) => data.data);
};

// ADMIN GET ALL LICENCE USERS API
export const adminUpdateUserEmailApi = (payload) => {
  let body = new FormData();
  body.append("email", payload.email);

  return api
    .post(`/users/admin-update-user-email/${payload.id}`, body)
    .then((data) => data.data);
};

// ADMIN GET ALL LICENCE USERS API
export const adminUpdateUserRoleApi = (payload) => {
  let body = new FormData();
  body.append("role", payload.role);
  body.append("status", payload.status);

  return api
    .post(`/users/admin-update-user-role/${payload.id}`, body)
    .then((data) => data.data);
};

// ADMIN GET ALL LICENCE USERS API
export const adminActivateUserApi = (payload) => {
  return api
    .post(`/users/admin-activate-user/${payload.id}`)
    .then((data) => data.data);
};

// ADMIN GET ALL LICENCE USERS API
export const adminCreateUserApi = (payload) => {
  let body = new FormData();
  const params = [
    "username",
    "email",
    "password",
    "role",
    "name",
    "companyName",
    "surname",
    "public_email",
    "gender",
    "phone",
    "birthdate",
    "bio",
    "avatar",
    "country",
    "city",
    "address",
    "return_model",
  ];
  // eslint-disable-next-line  array-callback-return
  params.map((param) => {
    if (payload[param]) {
      if (param === "avatar") {
        if (payload[param].isStock) {
          body.append("stock_image", payload[param].download_location);
        } else {
          body.append(param, payload[param]);
        }
      } else if (param === "companyName") {
        body.append("username", payload[param]);
      } else {
        body.append(param, payload[param]);
      }
    }
  });
  return api.post(`/users/admin-create-user`, body).then((data) => data.data);
};

// ADMIN GET ALL LICENCE USERS API
export const adminUpdateUserDataApi = (payload) => {
  console.log("PAYLOAD", payload);
  let body = new FormData();
  const params = [
    "username",
    "email",
    // "password",
    // commented becouse when we want to edit for example
    // only name of user, we needed to update password
    "role",
    "name",
    "companyName",
    "surname",
    "public_email",
    "gender",
    "phone",
    "birthdate",
    "bio",
    "avatar",
    "country",
    "city",
    "address",
  ];

  if (payload.password) {
    body.append("password", payload.password);
  }

  // eslint-disable-next-line  array-callback-return
  params.map((param) => {
    if (payload[param]) {
      if (param === "avatar") {
        if (payload[param].isStock) {
          body.append("stock_image", payload[param].download_location);
        }
        if (!payload[param].oldPhoto) {
          body.append(param, payload[param]);
        }
      } else if (param === "companyName") {
        body.append("username", payload[param]);
      } else {
        body.append(param, payload[param]);
      }
    }
    if (param === "surname") {
      if (payload[param]) {
        body.append(param, payload[param]);
      } else {
        body.append(param, "");
      }
    }
  });

  return api
    .post(`/users/admin-update-user/${payload.id}`, body)
    .then((data) => data.data);
};

// ADMIN UPDATE SITE DATA API
export const adminUpdateSiteInfoDataApi = (payload) => {
  let body = new FormData();
  const params = [
    "site_name",
    "description",
    "icon",
    "site_image",
    "twitter_profile_url",
    "instagram_profile_url",
    "facebook_profile_url",
    "button_text",
    "link_text",
  ];
  // eslint-disable-next-line  array-callback-return
  params.map((param) => {
    if (payload[param]) {
      if (param === "icon") {
        if (payload[param].isStock) {
          return body.append("stock_icon", payload[param].download_location);
        } else {
          return body.append(param, payload[param]);
        }
      }
      if (param === "site_image") {
        if (payload[param].isStock) {
          return body.append(
            "stock_site_image",
            payload[param].download_location
          );
        } else {
          return body.append(param, payload[param]);
        }
      } else {
        return body.append(param, payload[param]);
      }
    }
  });
  return api
    .post(`/licences/update-licence-data`, body)
    .then((data) => data.data);
};

// ADMIN UPDATE AUTH GREETING MESSAGES API
export const adminUpdateAuthGreetinMessagesApi = (payload) => {
  let body = new FormData();
  const params = ["sign_in_msg", "register_msg", "client_register_msg"];
  // eslint-disable-next-line  array-callback-return
  params.map((param) => {
    // if (payload[param]) {
    body.append(param, payload[param]);
    // }
  });
  return api
    .post(`/licences/update-licence-greeting-messages`, body)
    .then((data) => data.data);
};

// ADMIN UPDATE TERMS AND CONDITIONS API
export const adminUpdateTermsAndConditinosApi = (payload) => {
  let body = new FormData();
  const params = ["terms_and_conditions"];
  // eslint-disable-next-line  array-callback-return
  params.map((param) => {
    if (payload[param]) {
      body.append(param, payload[param]);
    }
  });
  return api
    .post(`/licences/update-licence-terms-and-conditions`, body)
    .then((data) => data.data);
};

// GET PAGE WIDGETS API
export const getPageWidgetsApi = (payload) => {
  let filters = "";
  // eslint-disable-next-line  array-callback-return
  Object.entries(payload).map((filter) => {
    if (filters) {
      filters = `${filters}&${filter[0]}=${filter[1]}`;
    } else {
      filters = `?${filter[0]}=${filter[1]}`;
    }
  });

  return api
    .get(`/content-widgets/get-page-widgets${filters}`)
    .then((data) => data.data);
};

// GET PAGE WIDGETS API
export const changeWidgetPositionApi = (payload) => {
  let body = new FormData();
  const params = [
    "widget_id",
    "column_number",
    "new_position",
    "update_validity_check",
    "delete_validity_check",
  ];
  // eslint-disable-next-line  array-callback-return
  params.map((param) => body.append(param, payload[param]));
  return api
    .post(`/content-widgets/change-widget-position`, body)
    .then((data) => data.data);
};
// API FOR GETTING LICENCE SERVICE MAIL
export const getLicenceContactServiceMailsApi = (payload) => {
  return api
    .get("/licence-contacts/get-licence-contact-services")
    .then((data) => data.data);
};
// API FOR DELETING LICENCE SERVICE MAIL
export const deleteLicenceServiceMailApi = (payload) => {
  return api
    .post(`/licence-contacts/delete-contact-service/${payload.id}`)
    .then((data) => data.data);
};
// API FOR CREATING LICENCE SERVICE MAIL
export const createLicenceContactServiceMailApi = (payload) => {
  let body = new FormData();
  const params = ["service_name", "email", "description"];
  params.map((param) => body.append(param, payload[param]));

  return api
    .post(`/licence-contacts/create-contact-service`, body)
    .then((data) => data.data);
};
// API FOR CREATING WIDGET
export const createWidgetApi = (payload) => {
  let body = new FormData();
  let tags = [];
  // console.log("payload", payload.selected_tags.length);
  payload.selected_tags.length &&
    // eslint-disable-next-line  array-callback-return
    payload.selected_tags.map((tag) => {
      tags = [...tags, tag.id];
    });
  const params = [
    "widget_id",
    "page_name",
    "content_type_id",
    "category_id",
    "criteria",
    "limit",
    "widget_title",
    "column_number",
    "page_content_id",
    "page_category_id",
    "is_tag_related",
    "is_contact_form",
    "selected_tags",
    "widget_img",
    "widget_description",
  ];
  // eslint-disable-next-line  array-callback-return
  params.map((param) => {
    if (param === "selected_tags") {
      if (tags.length) {
        // eslint-disable-next-line  array-callback-return
        return body.append(param, tags);
      }
      // eslint-disable-next-line  array-callback-return
      return;
    }
    if (param === "widget_img") {
      if (payload[param].isStock) {
        body.append(`widget_stock_image`, payload[param].download_location);
      } else {
        body.append(`widget_image`, payload[param]);
      }
    } else {
      payload[param] && body.append(param, payload[param]);
    }
  });

  return api
    .post(`/content-widgets/add-widget-to-page`, body)
    .then((data) => data.data);
};
// API FOR GETTING WIDGET SETTING
export const getWidgetSettingsApi = (payload) => {
  return api
    .get(`/content-widgets/get-widget-settings/${payload}`)
    .then((data) => data.data);
};
export const getWidgetsInfoApi = () => {
  return api.get(`/content-widgets/get-widgets`).then((data) => data.data);
};
// API FOR UPDATING PAGE WIDGET
export const updatePageWidgetApi = (payload) => {
  let body = new FormData();
  let tags = [];
  // console.log("payload.selected_tags", payload.selected_tags);

  payload.selected_tags.length &&
    payload.selected_tags.map((tag) => (tags = [...tags, tag.id]));
  const params = [
    "widget_id",
    "page_name",
    "content_type_id",
    "category_id",
    "criteria",
    "limit",
    "widget_title",
    "column_number",
    "page_content_id",
    "page_category_id",
    "is_tag_related",
    "is_contact_form",
    "selected_tags",
    "widget_img",
    "widget_description",
  ];
  // eslint-disable-next-line  array-callback-return
  params.map((param) => {
    if (param === "selected_tags") {
      // if (tags.lenght) {
      return body.append(param, tags);
    }
    if (param === "widget_img") {
      if (payload[param].isStock) {
        body.append(`widget_stock_image`, payload[param].download_location);
      } else if (payload[param].preview) {
        body.append(`widget_image`, payload[param]);
      } else if (payload[param].oldImage) {
        body.append(`widget_old_image`, payload[param]);
      }
    } else {
      payload[param] && body.append(param, payload[param]);
    }
  });
  return api
    .post(`/content-widgets/update-page-widget/${payload.id}`, body)
    .then((data) => data.data);
};
// API FOR SUBMITTING WIDGET CONTACT FORM
export const submitWidgetFormApi = (payload) => {
  let body = new FormData();
  const params = ["contact_service_id", "submited_by", "message", "attachment"];
  params.map((param) => payload[param] && body.append(param, payload[param]));
  return api
    .post(`/licence-contacts/submit-contact-form`, body)
    .then((data) => data.data);
};
// API FOR UPDATING BIDDING ITEM DURATION
export const updateBiddingDurationApi = (payload) => {
  let body = new FormData();
  const params = ["duration"];
  params.map((param) => payload[param] && body.append(param, payload[param]));
  return api
    .post(`/biddings/update-bidding-duration`, body)
    .then((data) => data.data);
};
// API FOR GETTING  BIDDING ITEMS
export const getBiddingItemsApi = (payload) => {
  let filters = "";
  // eslint-disable-next-line  array-callback-return
  Object.entries(payload).map((filter) => {
    if (filter[0] !== "page" && filter[0] !== "page_count" && filter[1]) {
      if (filters) {
        filters = `${filters}&filter[${filter[0]}]=${filter[1]}`;
      } else {
        filters = `?filter[${filter[0]}]=${filter[1]}`;
      }
    }
  });
  if (payload.page) {
    if (filters) {
      filters = `${filters}&page=${payload.page}`;
    } else {
      filters = `${filters}?page=${payload.page}`;
    }
  }
  if (payload.page_count) {
    if (filters) {
      filters = `${filters}&page_count=${payload.page_count}`;
    } else {
      filters = `${filters}?page_count=${payload.page_count}`;
    }
  }

  return api.get(`/bidding-items${filters}`).then((data) => ({
    data: data.data,
    headers: data.headers,
  }));
};
// API FOR DISABLING  BIDDING ITEM
export const disableBiddingItemApi = (payload) => {
  return api
    .post(`/bidding-items/disable-bidding-item/${payload.id}`)
    .then((data) => data.data);
};

// API FOR CREATING BIDDING ITEM
export const createBiddngItemApi = (payload) => {
  const body = new FormData();
  const params = ["content_id", "catalog_id"];
  params.map((param) => payload[param] && body.append(param, payload[param]));
  return api
    .post(`/bidding-items/create-bidding-item`, body)
    .then((data) => data.data);
};

// API FROM CHANGING LOGIN REGISTRATION STATUS
export const changleLoginRegistrationStatusApi = () => {
  return api
    .post(`/licences/change-login-registration-status`)
    .then((data) => data.data);
};

// API FROM CHANGING SEARCH STATUS
export const changeSearchStatusApi = () => {
  return api.post(`/licences/change-search-status`).then((data) => data.data);
};

// API FOR CREATING STATIC PAGE BY THE ADMIN
export const adminCreateStaticPageApi = (payload) => {
  let body = new FormData();
  let stock_gallery_index = 0;
  let gallery_index = 0;
  let tags = "";
  const params = ["title", "content_text", "image", "gallery_position"];
  // eslint-disable-next-line  array-callback-return
  params.map((param) => {
    if (param === "image") {
      if (payload[param].isStock) {
        body.append(`stock_image`, payload[param].download_location);
      } else {
        body.append(`image`, payload[param]);
      }
    } else {
      body.append(param, payload[param]);
    }
  });
  // eslint-disable-next-line  array-callback-return
  payload.video_gallery.map((video, i) => {
    body.append(`video_gallery[${i}]`, video);
  });
  // eslint-disable-next-line  array-callback-return
  payload.gallery.map((img, i) => {
    if (img.isStock) {
      body.append(
        `stock_gallery[${stock_gallery_index}]`,
        img.download_location
      );
      stock_gallery_index++;
    } else {
      body.append(`gallery[${gallery_index}]`, img);
      gallery_index++;
    }
  });
  // eslint-disable-next-line  array-callback-return
  payload.tags.map((tag) => {
    if (tags.length > 1) {
      tags = `${tags},${tag.name}`;
    } else {
      tags = tag.name;
    }
  });
  body.append(`tags`, tags);

  // console.log("tags", tags);

  // console.log("body", body);
  return api
    .post(`/contents/admin-create-page`, body)
    .then((data) => data.data);
};

// API FOR CREATING STATIC PAGE DRAFT BY THE ADMIN
export const adminCreateStaticPageDraftApi = (payload) => {
  let body = new FormData();
  let stock_gallery_index = 0;
  let gallery_index = 0;
  let tags = "";
  const params = ["title", "content_text", "image", "gallery_position"];
  // eslint-disable-next-line  array-callback-return
  params.map((param) => {
    if (param === "image") {
      if (payload[param].isStock) {
        body.append(`stock_image`, payload[param].download_location);
      } else {
        body.append(`image`, payload[param]);
      }
    } else {
      body.append(param, payload[param]);
    }
  });
  // eslint-disable-next-line  array-callback-return
  payload.video_gallery.map((video, i) => {
    body.append(`video_gallery[${i}]`, video);
  });
  // eslint-disable-next-line  array-callback-return
  payload.gallery.map((img, i) => {
    if (img.isStock) {
      body.append(
        `stock_gallery[${stock_gallery_index}]`,
        img.download_location
      );
      stock_gallery_index++;
    } else {
      body.append(`gallery[${gallery_index}]`, img);
      gallery_index++;
    }
  });
  // eslint-disable-next-line  array-callback-return
  payload.tags.map((tag) => {
    if (tags.length > 1) {
      tags = `${tags},${tag.name}`;
    } else {
      tags = tag.name;
    }
  });
  body.append(`tags`, tags);

  // console.log("tags", tags);

  // console.log("body", body);
  return api
    .post(`/contents/admin-create-page-draft`, body)
    .then((data) => data.data);
};

// API FOR UPDATING STATIC PAGE BY THE ADMIN
export const adminUpdateStaticPageApi = (payload) => {
  let newImgIndex = 0;
  let stock_gallery_index = 0;
  let idIndex = 0;

  let body = new FormData();
  let tags = "";
  const params = ["title", "image", "gallery_position", "created_at"];
  // eslint-disable-next-line  array-callback-return

  params.map((param) => payload[param] && body.append(param, payload[param]));

  body.append("content_text", payload.content_text);

  params.map((param) => {
    if (param === "image") {
      if (payload[param].isStock) {
        body.append(`stock_image`, payload[param].download_location);
      } else {
        body.append(`image`, payload[param]);
      }
    } else if (payload[param]) {
      body.append(param, payload[param]);
    }
  });
  payload.video_gallery &&
    // eslint-disable-next-line  array-callback-return
    payload.video_gallery.map((video, i) => {
      if (video.id) {
        body.append(`video_gallery[${i}]`, video.video_url);
      } else {
        body.append(`video_gallery[${i}]`, video);
      }
    });
  // eslint-disable-next-line  array-callback-return
  payload.gallery &&
    payload.gallery.map((img, i) => {
      if (img.name) {
        body.append(`gallery[${newImgIndex}]`, img);
        newImgIndex++;
        // eslint-disable-next-line  array-callback-return
        return;
      }
      if (img.isStock) {
        body.append(
          `stock_gallery[${stock_gallery_index}]`,
          img.download_location
        );
        stock_gallery_index++;
        // eslint-disable-next-line  array-callback-return
        return;
      } else {
        body.append(`old_gallery[${idIndex}]`, img.id);
        idIndex++;
      }
    });
  // eslint-disable-next-line  array-callback-return
  payload.tags.map((tag) => {
    if (tags.length > 1) {
      tags = `${tags},${tag.name}`;
    } else {
      tags = tag.name;
    }
  });
  body.append(`tags`, tags);

  // console.log("tags", tags);

  // console.log("body", body);
  return api
    .post(`/contents/admin-update-page/${payload.pageId}`, body)
    .then((data) => data.data);
};

// API FOR UPDATING STATIC PAGE BY THE ADMIN
export const adminUpdateStaticPageDraftApi = (payload) => {
  let newImgIndex = 0;
  let stock_gallery_index = 0;
  let idIndex = 0;

  let body = new FormData();
  let tags = "";
  const params = ["title", "content_text", "image", "gallery_position"];
  // eslint-disable-next-line  array-callback-return
  params.map((param) => {
    if (param === "image") {
      if (payload[param].isStock) {
        body.append(`stock_image`, payload[param].download_location);
      } else {
        body.append(`image`, payload[param]);
      }
    } else {
      body.append(param, payload[param]);
    }
  });
  // eslint-disable-next-line  array-callback-return
  payload.video_gallery.map((video, i) => {
    body.append(`video_gallery[${i}]`, video);
  });
  // eslint-disable-next-line  array-callback-return
  payload.gallery.map((img, i) => {
    if (img.name) {
      body.append(`new_gallery[${newImgIndex}]`, img);
      newImgIndex++;
      // eslint-disable-next-line  array-callback-return
      return;
    }
    if (img.isStock) {
      body.append(
        `stock_gallery[${stock_gallery_index}]`,
        img.download_location
      );
      stock_gallery_index++;
      // eslint-disable-next-line  array-callback-return
      return;
    } else {
      body.append(`old_gallery[${idIndex}]`, img.id);
      idIndex++;
    }
  });
  // eslint-disable-next-line  array-callback-return
  payload.tags.map((tag) => {
    if (tags.length > 1) {
      tags = `${tags},${tag.name}`;
    } else {
      tags = tag.name;
    }
  });
  body.append(`tags`, tags);

  // console.log("tags", tags);

  // console.log("body", body);
  return api
    .post(`/contents/admin-update-page-draft/${payload.draftId}`, body)
    .then((data) => data.data);
};
// API FOR PUBLISHING STATIC PAGE DRAFT BY THE ADMIN
export const adminPublishStaticPageDraftApi = (payload) => {
  return api.post(`/moderations/admin-publish-page-draft/${payload.draftId}`);
};
// API FOR DELETING STATIC PAGE DRAFT BY THE ADMIN
export const adminDeleteStaticPageDraftApi = (payload) => {
  return api.post(`/contents/admin-delete-page-draft/${payload.blogId}`);
};
// API FOR DELETING STATIC PAGE BY THE ADMIN
export const adminDeleteStaticPageApi = (payload) => {
  return api.post(`/contents/admin-delete-page/${payload.blogId}`);
};
// API FOR DELETING PAGE WIDGET BY THE ADMIN
export const adminDeletePageWidgetApi = (payload) => {
  let body = new FormData();
  body.append("delete_validity_check", payload.delete_validity_check);
  return api
    .post(`/content-widgets/delete-page-widget/${payload.id}`, body)
    .then((data) => data.data);
};
// API FOR GETTING PUBLIC PLANNER ITEMS
export const getPublicPlannerItemsApi = (payload) => {
  return api
    .get(`/planners/public-planner-categories/${payload}`)
    .then((data) => data.data);
};
// API TO LOGIN SALES USER
export const loginSalesUserApi = (credentials) => {
  let body = new FormData();
  const params = ["email", "password"];
  params.map((param) => body.append(param, credentials[param]));
  return api.post(`/users/login-sales-user`, body).then((data) => data.data);
};

// API TO REGISTER SALES USER
export const registerSalesUserApi = (credentials) => {
  let body = new FormData();
  const params = [
    "email",
    "password",
    "name",
    "phone",
    "bio",
    "avatar",
    "country",
    "city",
    "address",
  ];

  params.map((param) => body.append(param, credentials[param]));
  body.append("username", credentials.email);
  return api.post(`/users/register-sales-user`, body).then((data) => data.data);
};
// API FOR GETTING SALES USER LICENCES
export const getSalesUsresLicencesApi = (payload) => {
  let expands = "";
  if (payload) {
    // eslint-disable-next-line  array-callback-return
    payload.expands.map((expand) => {
      if (expands) {
        expands = `${expands},${expand}`;
      } else {
        expands = `?expand=${expand}`;
      }
    });
  }
  return api
    .get(
      `/licences/get-sales-user-licences${expands}${
        payload.pageCount ? `&page_count=${payload.pageCount}` : ""
      }${payload.page ? `&page=${payload.page}` : ""}`
    )
    .then((data) => ({
      data: data.data,
      headers: data.headers,
    }));
};
// API FOR GETTING ALL LICENCES ON SUPERADMIN PROFIL PAGE
export const getAllLicencesApi = (payload) => {
  // console.log("payLOADDD",payload)
  let expands = "";
  if (payload) {
    // eslint-disable-next-line  array-callback-return
    payload.expands.map((expand) => {
      if (expands) {
        expands = `${expands},${expand}`;
      } else {
        expands = `?expand=${expand}`;
      }
    });
    // console.log("EXPANDS", expands);
  }
  return api
    .get(
      `/licences/get-all-licences${expands}${
        payload.pageCount ? `&page_count=${payload.pageCount}` : ""
      }${payload.page ? `&page=${payload.page}` : ""}`
    )
    .then((data) => ({
      data: data.data,
      headers: data.headers,
    }));
};

// API FOR GETTING ALL LICENCES ON SUPERADMIN PROFIL PAGE
export const getAllPartnersLicencesApi = (payload) => {
  let expands = "";
  if (payload) {
    // eslint-disable-next-line  array-callback-return
    payload.expands.map((expand) => {
      if (expands) {
        expands = `${expands},${expand}`;
      } else {
        expands = `?expand=${expand}`;
      }
    });
  }
  return api
    .get(
      `/licences/get-all-partners-licences${expands}${
        payload.pageCount ? `&page_count=${payload.pageCount}` : ""
      }${payload.page ? `&page=${payload.page}` : ""}`
    )
    .then((data) => ({
      data: data.data,
      headers: data.headers,
    }));
};

// API FOR UPDATING AUTH USER PROFILE NAME
export const changeUserProfileNameApi = (payload) => {
  const body = new FormData();

  body.append("name", payload.name);
  return api
    .post(`/profiles/sales-user-update-name`, body)
    .then((data) => data.data);
};
// API FOR UPDATING AUTH USER PROFILE PHONE
export const changeUserProfilePhoneApi = (payload) => {
  const body = new FormData();

  body.append("phone", payload.phone);
  return api
    .post(`/profiles/sales-user-update-phone`, body)
    .then((data) => data.data);
};

// API FOR SENDING PAYMENT SUCCESS MAIL
export const sendPaymentSuccessMailApi = (payload) => {
  const body = new FormData();

  body.append("transactionId", payload);
  return api
    .post("/payments/send-payment-success-mail", body)
    .then((data) => data.data);
};

// API FOR UPDATING AUTH USER PROFILE NAME
export const salesUserUpdateLicenceLogoApi = (payload) => {
  const body = new FormData();

  body.append("icon", payload.icon);

  return api
    .post(
      `/licences/sales-user-update-licence-logo/${payload.licence_id}`,
      body
    )
    .then((data) => data.data);
};

// API FOR UPDATING AUTH USER PROFILE NAME
export const salesUserUpdateLicenceLogoFromStockApi = (payload) => {
  const body = new FormData();

  body.append("download_url", payload.icon.download_location);

  return api
    .post(
      `/licences/sales-user-update-licence-stock-logo/${payload.licence_id}`,
      body
    )
    .then((data) => data.data);
};

// API FOR UPDATING LICENCE DESCRIPTION BY SALES USER
export const salesUserUpdateLicenceDescriptionApi = (payload) => {
  const body = new FormData();

  body.append("description", payload.description);

  return api
    .post(
      `/licences/sales-user-update-licence-description/${payload.licence_id}`,
      body
    )
    .then((data) => data.data);
};

// API FOR UPDATING LICENCE DESCRIPTION BY SALES USER
export const adminAddAndroidAppLinkApi = (payload) => {
  let body = new FormData();

  body.append("androidAppLink", payload.androidAppLink);
  body.append("licence_id", payload.licence_id);

  // const params = ["licence_id", "androidAppLink"];
  // params.map((param) => payload[param] && body.append(param, payload[param]));

  return api
    .post("/android-links/admin-add-licence-android-app-link", body)
    .then((data) => data.data);
};

// API FOR CREATING VIRTUAL HOST BY LICENCE ID
export const createVirtualHostApi = (payload) => {
  let body = new FormData();

  body.append("id", payload.id);

  return api
    .post("/virtual-hosts/create-virtual-host", body)
    .then((data) => data.data);
};

export const updateSingleAppApi = (payload) => {
  let body = new FormData();
  body.append("id", payload.id);

  return api
    .post(`/update-single-apps/update-single-app`, body)
    .then((data) => data.data);
};

// API FOR CREATING SSL CERTIFICATE AS SUPERADMIN
export const createSslCertificateApi = (payload) => {
  let body = new FormData();

  body.append("id", payload.id);

  return api
    .post("/licences/create-ssl-certificate", body)
    .then((data) => data.data);
};

// API FOR UPDATING LICENCE LOCATION BY SALES USER
export const salesUserUpdateLicenceLocationApi = (payload) => {
  const body = new FormData();

  body.append("country", payload.country);
  body.append("city", payload.city);
  body.append("address", payload.address);

  return api
    .post(
      `/licences/sales-user-update-licence-location/${payload.licence_id}`,
      body
    )
    .then((data) => data.data);
};

// API FOR UPDATING LICENCE PHONE BY SALES USER
export const salesUserUpdateLicencePhoneApi = (payload) => {
  const body = new FormData();

  body.append("phone", payload.phone);

  return api
    .post(
      `/licences/sales-user-update-licence-phone/${payload.licence_id}`,
      body
    )
    .then((data) => data.data);
};

// API FOR CREATING VIRTUAL HOST BY LICENCE ID
// export const createVirtualHostApi = (payload) => {
//   const body = new FormData();
//   body.append("id", payload.id);
//   return (
//     api
//       .post("/licences/create-virtual-host", body)
//       // .post(`/licences/create-virtual-host`, body)
//       .then((data) => data.data)
//   );
// };
// export const createVirtualHostApi = (payload) => {
//   return (
//     api
//       .post(`/licences/create-virtual-host`)
//       // .post(`/licences/create-virtual-host`, body)
//       .then((data) => data.data)
//   );
// };

// export const approveProductModerationApi = (payload) => {
//   return api
//     .post(`/moderations/approve-product/${payload.id}`)
//     .then((data) => data.data);
// };

// API FOR UPDATING LICENCE PHONE BY SALES USER
export const salesUserUpdateLicenceStatusApi = (payload) => {
  return api
    .post(`/licences/sales-user-update-licence-status/${payload.licence_id}`)
    .then((data) => data.data);
};

// API FOR GETTING ALL TEMPLATES
export const getAllTemplatesApi = (payload) => {
  return api.get(`/templates`).then((data) => data.data);
};

// API FOR TO CHECK IF TITLE OF APII IS UNIQUE
export const createLicenceSubDomainApi = (payload) => {
  const body = new FormData();
  body.append("name", payload.name);
  return api
    .post(`/licences/create-licence-subdomain`, body)
    .then((data) => data.data);
};

// API FOR CHECKING IF DOMAIN IS AVAILABLE
export const createLicenceDomainApi = (payload) => {
  const body = new FormData();
  body.append("name", payload.name);
  return api
    .post(`/licences/create-licence-domain`, body)
    .then((data) => data.data);
};

// API FOR GETTING ALL DOMAIN PRICES
export const getDomainPricesApi = () => {
  return api.get(`/licences/get-domain-prices`).then((data) => data.data);
};

// API FOR GETTING EXCHANGE RATES FROM NBS
export const getExchangeRateApi = () => {
  const apiId = "e76edde514970854b3697a635047c098";
  return axios
    .get(`https://api.kursna-lista.info/${apiId}/kursna_lista`)
    .then((data) => ({
      data: data.data,
    }));
};

// API FOR CHECKING IF DOMAIN IS AVAILABLE
export const checkDomainApi = (payload) => {
  const body = new FormData();
  body.append("name", payload.name);
  return api.post(`/licences/check-domain`, body).then((data) => data.data);
};

// API FOR CHECKING IF DOMAIN IS AVAILABLE
export const getDomainApi = (payload) => {
  const body = new FormData();
  body.append("domain", payload.domain);
  return api.post(`/licences/get-domain`, body).then((data) => data.data);
};

// API FOR CHECKING IF DOMAIN IS AVAILABLE
export const getDomainInvoiceApi = (payload) => {
  const body = new FormData();
  body.append("reference_no", payload.refNo);
  return api
    .post(`/licences/get-domain-invoice`, body)
    .then((data) => data.data);
};

// API FOR TO CHECK IF TITLE OF APII IS UNIQUE
export const createLicenceApi = (payload) => {
  const body = new FormData();
  const params = [
    "licence_type_id",
    "template_id",
    "title_of_app",
    "description",
    "language_id",
    "gpdc_category_id",
    // "icon",
    "phone",
    "country",
    "city",
    "address",
    // "site_image",
    "title_of_app_unique",
  ];
  params.map((param) => payload[param] && body.append(param, payload[param]));

  if (payload.icon.download_location) {
    body.append("stock_icon", payload.icon.download_location);
  } else {
    body.append("base64_icon", payload.icon.preview);
  }
  // else {
  // body.append("icon", payload.icon);
  // }
  body.append("gpdc_category_id", 1);
  return api.post(`/licences/create-licence`, body).then((data) => data.data);
};

// API FOR REQUESTING PASSWORD RESET LINK
export const requesetPasswordResetApi = (payload) => {
  const body = new FormData();
  body.append("email", payload.email);
  return api.post(`/users/request-reset-licence-user-password`, body);
};

// API TO RESET USER PASSWORD
export const passwordResetApi = (payload) => {
  const body = new FormData();
  const params = ["email", "password_reset_token", "password"];
  params.map((param) => body.append(param, payload[param]));
  return api.post(`/users/reset-licence-user-password`, body);
};

// API FOR REQUESTING PASSWORD RESET LINK
export const sendMailToLicenceUserApi = (payload) => {
  const body = new FormData();
  body.append("id", payload.id);
  return api
    .post("/users/send-mail-to-licence-user", body)
    .then((data) => data.data);
};

// API TO VALIDATE USER ACCOUNT
export const validateUserAccountApi = (payload) => {
  const body = new FormData();
  const params = ["email", "activation_token"];
  params.map((param) => body.append(param, payload[param]));
  return api.post(`/users/validate-user-account`, body);
};

// INIT CHECKOUT PROCESS
export const initCheckoutApi = (payload) => {
  return api
    .post("/payments/sales-licence-payment", payload)
    .then((data) => data.data);
};

export const buildPaidNativeAppApi = (purchaseData) => {
  console.log("payload iz api poziva", purchaseData);
  return api
    .post("/licences/build-paid-native-app", purchaseData)
    .then((data) => data.data);
};

export const getTransactionDataApi = (payload) => {
  // return api.post(`/transaction-data`)
  return (
    api
      .get(`/payments/transaction-status/${payload}`)
      // .get(`http://localhost:3000/all`)
      .then((data) => data.data)
  );
};

// export const adminDeleteBlogApi = (payload) => {
//   return api.post(`/contents/admin-delete-product/${payload.blogId}`);
// };

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// UNSPLASH API START
// GET UNSPLASH COLLECTIONS API
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const getUnsplashCollectionsApi = (payload) => {
  let filters = "";
  // eslint-disable-next-line  array-callback-return
  Object.entries(payload).map((filter) => {
    // console.log("filter[1]", filter[1]);
    if (filter[1]) {
      // if (filter[0] !== "page") {
      //   if (filters) {
      filters = `${filters}&${filter[0]}=${filter[1]}`;
      //   } else {
      //     filters = `&filter[${filter[0]}]=${filter[1]}`;
      // //   }
      // } else {
      //   filters = `${filters}&page=${filter[1]}`;
      // }
    }
  });

  return axios
    .get(
      `https://api.unsplash.com/topics?client_id=_yUkp9YGXubRMm7Zkbjahi0AOnlsoKHcJDGhbS-7IcY${filters}`
    )
    .then((data) => ({
      data: data.data,
      headers: data.headers,
    }));
};

// GET UNSPLASH COLLECTION PHOTOS API
export const getUnsplashCollectionPhotosApi = (payload) => {
  let filters = "";
  // eslint-disable-next-line  array-callback-return
  Object.entries(payload).map((filter) => {
    // console.log("filter[1]", filter[1]);
    if (filter[1] !== "topicId") {
      filters = `${filters}&[${filter[0]}]=${filter[1]}`;
    }
  });

  return axios
    .get(
      `https://api.unsplash.com/topics/${payload.topicId}/photos?client_id=_yUkp9YGXubRMm7Zkbjahi0AOnlsoKHcJDGhbS-7IcY${filters}`
    )
    .then((data) => ({
      data: data.data,
      headers: data.headers,
    }));
};
// GET UNSPLASH SEARCH PARAMETAR PHOTOS API
export const getUnsplashSearchParametarPhotosApi = (payload) => {
  let filters = "";
  // eslint-disable-next-line  array-callback-return
  Object.entries(payload).map((filter) => {
    // console.log("filter[1]", filter[1]);
    if (filter[1]) {
      filters = `${filters}&${filter[0]}=${filter[1]}`;
    }
  });

  return axios
    .get(
      `https://api.unsplash.com/search/photos?client_id=_yUkp9YGXubRMm7Zkbjahi0AOnlsoKHcJDGhbS-7IcY${filters}`
    )
    .then((data) => ({
      data: data.data,
      headers: data.headers,
    }));
};

// GET UNSPLASH PAGINATION LINK
export const getUnsplashPaginationLinkApi = (payload) => {
  return axios.get(`${payload.link}`).then((data) => ({
    data: data.data,
    headers: data.headers,
  }));
};

// UNSPLASH API END
