import constants from "./actionTypes";

const initState = {
  loading: false,
  staticPageDetails: {},
  staticPagesList: [],
  staticPagesListPagination: {},
};

export default (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case constants.ADMIN_UPDATE_STATIC_PAGE:
      return {
        ...state,
        loader: true,
      };
    case constants.ADMIN_UPDATE_STATIC_PAGE_SUCCESS:
      return {
        ...state,
        loader: false,
      };

    case constants.ACTIVATE_STATIC_CONTENT_LOADER:
      return {
        ...state,
        loading: true,
      };
    case constants.GET_STATIC_PAGE_DETAILS:
      return {
        ...state,
        loading: true,
      };
    case constants.GET_STATIC_PAGE_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        staticPageDetails: payload,
      };
    case constants.ADMIN_CREATE_PAGE:
      return {
        ...state,
        loading: true,
      };
    case constants.ADMIN_CREATE_PAGE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case constants.GET_STATIC_PAGES_LIST_SUCCESS:
      return {
        ...state,
        staticPagesList: payload,
        loading: false,
      };
    case constants.SET_STATIC_PAGES_PAGINATION:
      return {
        ...state,
        staticPagesListPagination: payload,
      };
    default:
      return {
        ...state,
      };
  }
};
