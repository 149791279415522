import constants from "./actionTypes";
import { all, takeLatest, put, call } from "redux-saga/effects";
import { getAllTemplatesSuccess } from "./actions";
import {
  getAllTemplatesApi,
  updateSingleAppApi,
} from "../../services/ApiCalls";
import { NotificationManager } from "react-notifications";

// SAGA FOR GETTING ALL GURU TEMPLATES
// RECIVES TYPE FROM ACTION
// TYPE : constants.GET_ALL_TEMPLATES
function* getAllTemplatesSaga(action) {
  try {
    const templates = yield call(getAllTemplatesApi);
    yield put(getAllTemplatesSuccess(templates));
  } catch (e) {
    console.log("error", e);
  }
}

function* updateSingleAndroidAppSaga(action) {
  try {
    const updtSingleApp = yield call(updateSingleAppApi, action.payload);
    // console.log("updtSingleApp", action.payload);

    NotificationManager.success(updtSingleApp.message, "", 2000);
  } catch (error) {
    console.log("error", error);
    console.log("OVDE USAO");
  }
}

function* templatesSaga() {
  yield all([takeLatest(constants.GET_ALL_TEMPLATES, getAllTemplatesSaga)]);
  yield all([
    takeLatest(constants.UPDATE_SINGLE_APP, updateSingleAndroidAppSaga),
  ]);
}

export default templatesSaga;
