const FETCH_SINGLE_PLANNER_ITEMS = "FETCH_SINGLE_PLANNER_ITEMS";
const FETCH_SINGLE_PLANNER_ITEMS_SUCCESS = "FETCH_SINGLE_PLANNER_ITEMS_SUCCESS";
const FETCH_SINGLE_PLANNER_ITEMS_FAIL = "FETCH_SINGLE_PLANNER_ITEMS_FAIL";
const SET_SHOW_SINGLE_PLANNER_OPTIONS = "SET_SHOW_SINGLE_PLANNER_OPTIONS";
const DELETE_PLANNER_ITEM = "DELETE_PLANNER_ITEM";
const DELETE_PLANNER_ITEM_SUCCESS = "DELETE_PLANNER_ITEM_SUCCESS";
const DELETE_PLANNER_ITEM_FAIL = "DELETE_PLANNER_ITEM_FAIL";
const SET_PRICE_SUM = "SET_PRICE_SUM";
const ADD_NEW_TASK_TO_PLANNER = "ADD_NEW_TASK_TO_PLANNER";
const ADD_NEW_TASK_TO_PLANNER_SUCCESS = "ADD_NEW_TASK_TO_PLANNER_SUCCESS";
const ADD_NEW_TASK_TO_PLANNER_FAI = "ADD_NEW_TASK_TO_PLANNER_FAI;";
const UPDATE_PLANNER_ITEM_SCHADULED_DATE_TIME =
  "UPDATE_PLANNER_ITEM_SCHADULED_DATE_TIME";
const UPDATE_PLANNER_ITEM_SCHADULED_DATE_TIME_SUCCESS =
  "UPDATE_PLANNER_ITEM_SCHADULED_DATE_TIME_SUCCESS";
const UPDATE_PLANNER_ITEM_SCHADULED_DATE_TIME_FAIL =
  "UPDATE_PLANNER_ITEM_SCHADULED_DATE_TIME_FAIL";
const RESET_USE_TIME_AND_DATE = "RESET_USE_TIME_AND_DATE";
const CHANGE_PLANNER_ITEM_PRICE = "CHANGE_PLANNER_ITEM_PRICE";
const CHANGE_PLANNER_ITEM_PRICE_SUCCESS = "CHANGE_PLANNER_ITEM_PRICE_SUCCESS";
const CHANGE_PLANNER_ITEM_PRICE_FAIL = "CHANGE_PLANNER_ITEM_PRICE_FAIL";
const REDIRECT_AFTER_NEW_TASK_CREATION = "REDIRECT_AFTER_NEW_TASK_CREATION";
const ADD_TO_PRICE_SUM = "ADD_TO_PRICE_SUM";
const REDUCE_PRICE_SUM = "REDUCE_PRICE_SUM";
const CHANGE_CALCULATE_PRICE = "CHANGE_CALCULATE_PRICE";
const CHANGE_CALCULATE_PRICE_SUCCESS = "CHANGE_CALCULATE_PRICE_SUCCESS";
const CHANGE_CALCULATE_PRICE_FAIL = "CHANGE_CALCULATE_PRICE_FAIL";
const SWITCH_TO_ANOTHER_PLANNER = "SWITCH_TO_ANOTHER_PLANNER";
const SWITCH_TO_ANOTHER_PLANNER_SUCCESS = "SWITCH_TO_ANOTHER_PLANNER_SUCCESS";
const SWITCH_TO_ANOTHER_PLANNER_FAIL = "SWITCH_TO_ANOTHER_PLANNER_FAIL";
const UPDATE_PLANNER_TASK = "UPDATE_PLANNER_TASK";
const UPDATE_PLANNER_TASK_SUCCESS = "UPDATE_PLANNER_TASK_SUCCESS";
const UPDATE_PLANNER_TASK_FAIL = "UPDATE_PLANNER_TASK_FAIL";
const RESET_PLANNER_ITEMS = "RESET_PLANNER_ITEMS";
const SAVE_PLANNER_ITEM_REMARK = "SAVE_PLANNER_ITEM_REMARK";
const SAVE_PLANNER_ITEM_REMARK_SUCCESS = "SAVE_PLANNER_ITEM_REMARK_SUCCESS";
const SAVE_PLANNER_ITEM_REMARK_FAIL = "SAVE_PLANNER_ITEM_REMARK_FAIL";
const SHOW_PLANNER_ITEM_PRICE_PUBLICLY = "SHOW_PLANNER_ITEM_PRICE_PUBLICLY";
const SHOW_PLANNER_ITEM_PRICE_PUBLICLY_SUCCESS =
  "SHOW_PLANNER_ITEM_PRICE_PUBLICLY_SUCCESS";
const SHOW_PLANNER_ITEM_PRICE_PUBLICLY_FAIL =
  "SHOW_PLANNER_ITEM_PRICE_PUBLICLY_FAIL";
const SHOW_PLANNER_ITEM_PUBLICLY = "SHOW_PLANNER_ITEM_PUBLICLY";
const SHOW_PLANNER_ITEM_PUBLICLY_SUCCESS = "SHOW_PLANNER_ITEM_PUBLICLY_SUCCESS";
const SHOW_PLANNER_ITEM_PUBLICLY_FAIL = "SHOW_PLANNER_ITEM_PUBLICLY_FAIL";
const TOGGLE_PUBLIC_PLANNER = "TOGGLE_PUBLIC_PLANNER";
const TOGGLE_PUBLIC_PLANNER_SUCCESS = "TOGGLE_PUBLIC_PLANNER_SUCCESS";
const TOGGLE_PUBLIC_PLANNER_FAIL = "TOGGLE_PUBLIC_PLANNER_FAIL";
export default {
  FETCH_SINGLE_PLANNER_ITEMS,
  FETCH_SINGLE_PLANNER_ITEMS_SUCCESS,
  FETCH_SINGLE_PLANNER_ITEMS_FAIL,
  SET_SHOW_SINGLE_PLANNER_OPTIONS,
  DELETE_PLANNER_ITEM,
  DELETE_PLANNER_ITEM_SUCCESS,
  DELETE_PLANNER_ITEM_FAIL,
  SET_PRICE_SUM,
  ADD_NEW_TASK_TO_PLANNER,
  ADD_NEW_TASK_TO_PLANNER_SUCCESS,
  ADD_NEW_TASK_TO_PLANNER_FAI,
  UPDATE_PLANNER_ITEM_SCHADULED_DATE_TIME,
  UPDATE_PLANNER_ITEM_SCHADULED_DATE_TIME_SUCCESS,
  UPDATE_PLANNER_ITEM_SCHADULED_DATE_TIME_FAIL,
  RESET_USE_TIME_AND_DATE,
  CHANGE_PLANNER_ITEM_PRICE,
  CHANGE_PLANNER_ITEM_PRICE_SUCCESS,
  CHANGE_PLANNER_ITEM_PRICE_FAIL,
  REDIRECT_AFTER_NEW_TASK_CREATION,
  ADD_TO_PRICE_SUM,
  REDUCE_PRICE_SUM,
  CHANGE_CALCULATE_PRICE,
  CHANGE_CALCULATE_PRICE_SUCCESS,
  CHANGE_CALCULATE_PRICE_FAIL,
  RESET_PLANNER_ITEMS,
  SWITCH_TO_ANOTHER_PLANNER,
  SWITCH_TO_ANOTHER_PLANNER_SUCCESS,
  SWITCH_TO_ANOTHER_PLANNER_FAIL,
  UPDATE_PLANNER_TASK,
  UPDATE_PLANNER_TASK_SUCCESS,
  UPDATE_PLANNER_TASK_FAIL,
  SAVE_PLANNER_ITEM_REMARK,
  SAVE_PLANNER_ITEM_REMARK_SUCCESS,
  SAVE_PLANNER_ITEM_REMARK_FAIL,
  SHOW_PLANNER_ITEM_PRICE_PUBLICLY,
  SHOW_PLANNER_ITEM_PRICE_PUBLICLY_SUCCESS,
  SHOW_PLANNER_ITEM_PRICE_PUBLICLY_FAIL,
  SHOW_PLANNER_ITEM_PUBLICLY,
  SHOW_PLANNER_ITEM_PUBLICLY_SUCCESS,
  SHOW_PLANNER_ITEM_PUBLICLY_FAIL,
  TOGGLE_PUBLIC_PLANNER,
  TOGGLE_PUBLIC_PLANNER_SUCCESS,
  TOGGLE_PUBLIC_PLANNER_FAIL,
};
