import constants from "./actionTypes";

// ACTION FOR CREATING CATEGORIE BY ADMIN
// RECIVES PAYLOAD FROM THE INPUT FIELDS THAT ADMIN ENTERED
export const adminCreateCategorie = (payload) => ({
  payload,
  type: constants.ADMIN_CERATE_CATEGORIE,
});

// ACTION FOR SUCCESSFULLY CREATING CATEGORIE BY ADMIN
// RECIVES CREATED CATEGORIE FROM THE SERVER AS A PAYOAD
export const adminCreateCategorieSuccess = (payload) => ({
  payload,
  type: constants.ADMIN_CERATE_CATEGORIE_SUCCESS,
});

// ACTION FOR UNSUCCESSFULLY CREATING CATEGORIE BY ADMIN
// RECIVES ERROR STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYOAD
export const adminCreateCategorieFail = (payload) => ({
  payload,
  type: constants.ADMIN_CERATE_CATEGORIE_FAIL,
});

// ACTION FOR UPDATING CATEGORIE BY ADMIN
// RECIVES PAYLOAD FROM THE INPUT FIELDS THAT ADMIN ENTERED
export const adminUpdateCategorie = (payload) => ({
  payload,
  type: constants.ADMIN_UPDATE_CATEGORIE,
});

// ACTION FOR SUCCESSFULLY UPDATE CATEGORIE BY ADMIN
// RECIVES UPDATED CATEGORIE FROM THE SERVER AS A PAYOAD
export const adminUpdateCategorieSuccess = (payload) => ({
  payload,
  type: constants.ADMIN_UPDATE_CATEGORIE_SUCCESS,
});

// ACTION FOR UNSUCCESSFULLY UPDATE CATEGORIE BY ADMIN
// RECIVES ERROR STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYOAD
export const adminUpdateCategorieFail = (payload) => ({
  payload,
  type: constants.ADMIN_UPDATE_CATEGORIE_FAIL,
});

// ACTION FOR DELETE CATEGORIE BY ADMIN
// RECEIVES CATEGORIE ID AS PAYLOAD
export const adminDeleteCategorie = (payload) => ({
  payload,
  type: constants.ADMIN_DELETE_CATEGORIE,
});

// ACTION FOR UPDATING ATTRIBUTE BY ADMIN
// RECIVES PAYLOAD FROM THE INPUT FIELDS THAT ADMIN ENTERED
export const adminUpdateAttribute = (payload) => ({
  payload,
  type: constants.ADMIN_UPDATE_ATTRIBUTE,
});

// ACTION FOR DELETING ATTRIBUTE BY ADMIN
// RECEIVES ATTRIBUTE ID AS PAYLOAD
export const adminDeleteAttribute = (payload) => ({
  payload,
  type: constants.ADMIN_DELETE_ATTRIBUTE,
});

// ACTION FOR DELETING LINK BETWEEN ATTRIBUTE AND CATEGORY BY ADMIN
// RECEIVES ATTRIBUTE ID AS PAYLOAD
export const adminDeleteAttributeCategoryLink = (payload) => ({
  payload,
  type: constants.ADMIN_DELETE_ATTRIBUTE_CATEGORY_LINK,
});

// ACTION FOR GETING LICENCE CATEGORIE
export const getLicenceCat = (payload) => ({
  type: constants.GET_LICENCE_CATEGORIES,
  payload,
});

// ACTION FOR SUCCESSFUL LICENCE CATEGORIE FETCH
// RECIVES ALL LICENCE CATEGORIES FROM THE SERVER AS A PAYOAD
export const getLicenceCatSuccess = (payload) => ({
  payload,
  type: constants.GET_LICENCE_CATEGORIES_SUCCESS,
});

// ACTION FOR UNSUCCESSFULL LICENCE CATEGORIE FETCH
// RECIVES ERROR STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYOAD
export const getLicenceCatFail = (payload) => ({
  payload,
  type: constants.GET_LICENCE_CATEGORIES_FAIL,
});

// ACTION FOR GETING LICENCE ATTRIBUTES FOR ALL CATS
export const getLicenceAttributes = (payload) => ({
  type: constants.GET_LICENCE_ATTRIBUTES,
  payload,
});

// ACTION FOR SUCCESSFUL LICENCE ATTRIBUTES FETCH
// RECIVES ALL LICENCE ATTRIBUTES FROM THE SERVER AS A PAYOAD
export const getLicenceAttributesSuccess = (payload) => ({
  payload,
  type: constants.GET_LICENCE_ATTRIBUTES_SUCCESS,
});

// ACTION FOR UNSUCCESSFULL LICENCE CATEGORIE FETCH
// RECIVES ERROR STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYOAD
export const getLicenceAttributesFail = (payload) => ({
  payload,
  type: constants.GET_LICENCE_ATTRIBUTES_FAIL,
});

// ACTION FOR GETING ATTRIBUTES OF THE CURRENT CAT
export const getCurretCatAttibutes = (payload) => ({
  payload,
  type: constants.GET_CURRENT_CAT_ATTIRBUTES,
});

// ACTION FOR ADDING NEW ATTRIBUTE TO THE CURRENT CAT
// RECIVES NEW ATTRIBUTE DATA FROM THE FORM THAT USER INSERT AS A PAYOAD
export const addNewCurrentCatAttribute = (payload) => ({
  payload,
  type: constants.ADD_NEW_CURRENT_CAT_ATTIRBUTE,
});

// ACTION FOR EDITING ATTRIBUTE TO THE CURRENT CAT
// RECIVES EDITED DATA FROM THE FORM THAT USER INSERT AS A PAYLOAD
export const updateCurrentCatAttribute = (payload) => ({
  payload,
  type: constants.UPDATE_CURRENT_CAT_ATTRIBUTE,
});

// ACTION FOR DELETING ATTRIBUTE TO THE CURRENT CAT
// RECIVES ATTRIBUTE ID FROM LISTED CURRENT CATEGORY ATTRIBUTE AS A PAYLOAD
export const deleteCurrentCatAttribute = (payload) => ({
  payload,
  type: constants.DELETE_CURRENT_CAT_ATTRIBUTE,
});

// ACTION FOR RESETING CURRENT CATEGORY ATTRIBUTES
export const resetCurrentCatAttributes = () => ({
  type: constants.RESET_CURRENT_CAT_ATTRIBUTES,
});

// ACTION FOR SELECTING CATEGORIE
// RECIVES CAT ID AS A PAYOAD
export const selectCategorieAction = (payload) => ({
  payload,
  type: constants.SELECTED_CATEGORIE,
});

// ACTION FOR GETING LICENCE CONTENT TYPE IN ORDER
export const getLicenceContentTypeInOrder = (payload) => ({
  payload,
  type: constants.GET_LICENCE_CONTENT_TYPE_IN_ORDER,
});

// ACTION FOR UNSUCCESSFULL FETCH FOR THE LICENCE CONTENT TYPE IN ORDER
// RECIVES ERROR STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYOAD
export const getLicenceContentTypeInOrderFail = (payload) => ({
  payload,
  type: constants.GET_LICENCE_CONTENT_TYPE_IN_ORDER_FALI,
});

// ACTION FOR SUCCESSFUL  FETCH OF THE LICENCE CONTENT TYPE IN ORDER
// RECIVES LICENCE CONTENT TYPE IN ORDER FROM THE SERVER AS A PAYOAD
export const getLicenceContentTypeInOrderSuccess = (payload) => ({
  payload,
  type: constants.GET_LICENCE_CONTENT_TYPE_IN_ORDER_SUCCESS,
});
// ACTION FOR GETING LICENCE CONTENT TYPE IN ORDER
export const getAllLicenceContentTypeInOrder = (payload) => ({
  payload,
  type: constants.GET_ALL_LICENCE_CONTENT_TYPE_IN_ORDER,
});

// ACTION FOR UNSUCCESSFULL FETCH FOR THE LICENCE CONTENT TYPE IN ORDER
// RECIVES ERROR STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYOAD
export const getAllLicenceContentTypeInOrderFail = (payload) => ({
  payload,
  type: constants.GET_ALL_LICENCE_CONTENT_TYPE_IN_ORDER_FALI,
});

// ACTION FOR SUCCESSFUL  FETCH OF THE LICENCE CONTENT TYPE IN ORDER
// RECIVES LICENCE CONTENT TYPE IN ORDER FROM THE SERVER AS A PAYOAD
export const getAllLicenceContentTypeInOrderSuccess = (payload) => ({
  payload,
  type: constants.GET_ALL_LICENCE_CONTENT_TYPE_IN_ORDER_SUCCESS,
});

// ACTION FOR SETTING SELECTED CONTENT TYPE
// RECIVES SELECTED CONTENT TYPE AS A PAYOAD
export const setSelectedContentTypeOrder = (payload) => ({
  payload,
  type: constants.SET_SELECTED_CONTENT_TYPE_ORDER,
});

// ACTION FOR UPDATING SELECTED CONTENT TYPE
// RECIVES SELECTED CONTENT TYPE CATEGORY ID AS A PAYLOAD
export const setSelectedContentTypeOrderSuccess = (payload) => ({
  payload,
  type: constants.SET_SELECTED_CONTENT_TYPE_ORDER_SUCCESS,
});

// ACTION UPDATING CATEGORIE ORDER
// RECIVES ARRAY OF IDS WHIT THE NEW ORDER THAT NEEDS TO BE SAVED ON A SERVER AS A PAYLOAD
export const updateCategorieOrder = (payload) => ({
  payload,
  type: constants.UPDATE_CATEGORIE_ORDER,
});

// ACTION FOR SUCCESSFUL  CATEGORIE ORDER UPDATE
// RECIVES SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYOAD
export const updateCategorieOrderSuccess = (payload) => ({
  payload,
  type: constants.UPDATE_CATEGORIE_ORDER_SUCCESS,
});

// ACTION FOR UNSUCCESSFUL  CATEGORIE ORDER UPDATE
// RECIVES ERROR STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYOAD
export const updateCategorieOrderFail = (payload) => ({
  payload,
  type: constants.UPDATE_CATEGORIE_ORDER_FAIL,
});

// ACTION UPDATING CONTENT TYPE ORDER
// RECIVES ARRAY OF IDS WHIT THE NEW ORDER THAT NEEDS TO BE SAVED ON A SERVER AS A PAYLOAD
export const updateContentTypeOrder = (payload) => ({
  payload,
  type: constants.UPDATE_CONTENT_TYPE_ORDER,
});

// ACTION FOR SUCCESSFUL CONTENT TYPE ORDER UPDATE
// RECIVES SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYOAD
export const updateContentTypeOrderSuccess = (payload) => ({
  payload,
  type: constants.UPDATE_CONTENT_TYPE_ORDER_SUCCESS,
});

// ACTION FOR UNSUCCESSFUL  CATEGORIE ORDER UPDATE
// RECIVES ERROR STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYOAD
export const updateContentTypeOrderFail = (payload) => ({
  payload,
  type: constants.UPDATE_CONTENT_TYPE_ORDER_FALI,
});

export const setNewCat = (payload) => ({
  payload,
  type: constants.SET_NEW_CAT,
});
