const LICENCE_USER_CREATE_BLOG = "LICENCE_USER_CREATE_BLOG";
const LICENCE_USER_CREATE_BLOG_SUCCESS = "LICENCE_USER_CREATE_BLOG_SUCCESS";
const LICENCE_USER_CREATE_BLOG_FAIL = "LICENCE_USER_CREATE_BLOG_FAIL";
const GET_LICENCE_TAGS = "GET_LICENCE_TAGS";
const GET_LICENCE_TAGS_SUCCESS = "GET_LICENCE_TAGS_SUCCESS";
const GET_LICENCE_TAGS_FAIL = "GET_LICENCE_TAGS_FAIL";
const LICENCE_USER_DELETE_BLOG = "LICENCE_USER_DELETE_BLOG";
const LICENCE_USER_DELETE_BLOG_SUCCESS = "LICENCE_USER_DELETE_BLOG_SUCCESS";
const LICENCE_USER_DELETE_BLOG_FAIL = "LICENCE_USER_DELETE_BLOG_FAIL";
const LICENCE_USER_UPDATE_BLOG = "LICENCE_USER_UPDATE_BLOG";
const LICENCE_USER_UPDATE_BLOG_SUCCESS = "LICENCE_USER_UPDATE_BLOG_SUCCESS";
const LICENCE_USER_UPDATE_BLOG_FAIL = "LICENCE_USER_UPDATE_BLOG_FAIL";
const GET_SINGLE_BLOG = "GET_SINGLE_BLOG";
const GET_SINGLE_BLOG_SUCCESS = "GET_SINGLE_BLOG_SUCCESS";
const GET_SINGLE_BLOG_FAIL = "GET_SINGLE_BLOG_FAIL";
const ADMIN_UPDATE_BLOG = "ADMIN_UPDATE_BLOG";
const ADMIN_UPDATE_BLOG_SUCCESS = "ADMIN_UPDATE_BLOG_SUCCESS";
const ADMIN_UPDATE_BLOG_FAIL = "ADMIN_UPDATE_BLOG_FAIL";
const GET_SINGLE_BLOG_BY_SLUG = "GET_SINGLE_BLOG_BY_SLUG";
const GET_SINGLE_BLOG_BY_SLUG_SUCCESS = "GET_SINGLE_BLOG_BY_SLUG_SUCCESS";
const GET_SINGLE_BLOG_BY_SLUG_FAIL = "GET_SINGLE_BLOG_BY_SLUG_FAIL";
const PUBLISH_BLOG_DRAFT = "PUBLISH_BLOG_DRAFT";
const PUBLISH_BLOG_DRAFT_SUCCESS = "PUBLISH_BLOG_DRAFT_SUCCESS";
const PUBLISH_BLOG_DRAFT_FAIL = "PUBLISH_BLOG_DRAFT_FAIL";
const LICENCE_USER_CREATE_BLOG_DRAFT = "LICENCE_USER_CREATE_BLOG_DRAFT";
const LICENCE_USER_CREATE_BLOG_DRAFT_SUCCESS =
  "LICENCE_USER_CREATE_BLOG_DRAFT_SUCCESS";
const LICENCE_USER_CREATE_BLOG_DRAFT_FAIL =
  "LICENCE_USER_CREATE_BLOG_DRAFT_FAIL";
const LICENCE_USER_UPDATE_BLOG_DRAFT = "LICENCE_USER_UPDATE_BLOG_DRAFT";
const LICENCE_USER_UPDATE_BLOG_DRAFT_SUCCESS =
  "LICENCE_USER_UPDATE_BLOG_DRAFT_SUCCESS";
const LICENCE_USER_UPDATE_BLOG_DRAFT_FAIL =
  "LICENCE_USER_UPDATE_BLOG_DRAFT_FAIL";
const ADMIN_CREATE_BLOG = "ADMIN_CREATE_BLOG";
const ADMIN_CREATE_BLOG_SUCCESS = "ADMIN_CREATE_BLOG_SUCCESS";
const ADMIN_CREATE_BLOG_FAIL = "ADMIN_CREATE_BLOG_FAIL";
const APPROVE_BLOG_MODERATION = "APPROVE_BLOG_MODERATION";
const APPROVE_BLOG_MODERATION_SUCCESS = "APPROVE_BLOG_MODERATION_SUCCESS";
const APPROVE_BLOG_MODERATION_FAIL = "APPROVE_BLOG_MODERATION_FAIL";
const ADMIN_DISAPPROVE_BLOG_MODERATION = "ADMIN_DISAPPROVE_BLOG_MODERATION";
const ADMIN_DISAPPROVE_BLOG_MODERATION_SUCCESS =
  "ADMIN_DISAPPROVE_BLOG_MODERATION_SUCCESS";
const ADMIN_DISAPPROVE_BLOG_MODERATION_FAIL =
  "ADMIN_DISAPPROVE_BLOG_MODERATION_FAIL";
const REQUEST_FOR_EDIT_BLOG_MODERATION = "REQUEST_FOR_EDIT_BLOG_MODERATION";
const REQUEST_FOR_EDIT_BLOG_MODERATION_SUCCESS =
  "REQUEST_FOR_EDIT_BLOG_MODERATION_SUCCESS";
const REQUEST_FOR_EDIT_BLOG_MODERATION_FAIL =
  "REQUEST_FOR_EDIT_BLOG_MODERATION_FAIL";
const ACTIVATE_SINGLE_LOADER = "ACTIVATE_SINGLE_LOADER";

export default {
  LICENCE_USER_CREATE_BLOG,
  LICENCE_USER_CREATE_BLOG_SUCCESS,
  LICENCE_USER_CREATE_BLOG_FAIL,
  GET_LICENCE_TAGS,
  GET_LICENCE_TAGS_SUCCESS,
  GET_LICENCE_TAGS_FAIL,
  LICENCE_USER_DELETE_BLOG,
  LICENCE_USER_DELETE_BLOG_SUCCESS,
  LICENCE_USER_DELETE_BLOG_FAIL,
  LICENCE_USER_UPDATE_BLOG,
  LICENCE_USER_UPDATE_BLOG_SUCCESS,
  LICENCE_USER_UPDATE_BLOG_FAIL,
  GET_SINGLE_BLOG,
  GET_SINGLE_BLOG_SUCCESS,
  GET_SINGLE_BLOG_FAIL,
  ADMIN_UPDATE_BLOG,
  ADMIN_UPDATE_BLOG_SUCCESS,
  ADMIN_UPDATE_BLOG_FAIL,
  GET_SINGLE_BLOG_BY_SLUG,
  GET_SINGLE_BLOG_BY_SLUG_SUCCESS,
  GET_SINGLE_BLOG_BY_SLUG_FAIL,
  PUBLISH_BLOG_DRAFT,
  PUBLISH_BLOG_DRAFT_SUCCESS,
  PUBLISH_BLOG_DRAFT_FAIL,
  LICENCE_USER_CREATE_BLOG_DRAFT,
  LICENCE_USER_CREATE_BLOG_DRAFT_SUCCESS,
  LICENCE_USER_CREATE_BLOG_DRAFT_FAIL,
  LICENCE_USER_UPDATE_BLOG_DRAFT,
  LICENCE_USER_UPDATE_BLOG_DRAFT_SUCCESS,
  LICENCE_USER_UPDATE_BLOG_DRAFT_FAIL,
  ADMIN_CREATE_BLOG,
  ADMIN_CREATE_BLOG_SUCCESS,
  ADMIN_CREATE_BLOG_FAIL,
  APPROVE_BLOG_MODERATION,
  APPROVE_BLOG_MODERATION_SUCCESS,
  APPROVE_BLOG_MODERATION_FAIL,
  ADMIN_DISAPPROVE_BLOG_MODERATION,
  ADMIN_DISAPPROVE_BLOG_MODERATION_SUCCESS,
  ADMIN_DISAPPROVE_BLOG_MODERATION_FAIL,
  REQUEST_FOR_EDIT_BLOG_MODERATION,
  REQUEST_FOR_EDIT_BLOG_MODERATION_SUCCESS,
  REQUEST_FOR_EDIT_BLOG_MODERATION_FAIL,
  ACTIVATE_SINGLE_LOADER,
};
