import constants from "./actionTypes";
// FUNCTION THAT DINAMICLY IMPORT TRANSLATION FILES BASE OF THE SELECTED LENGUAGE
const setLanguage = (language) => {
  let messages = {};
  switch (language) {
    case "en":
      messages = Object.assign(messages, require(`../../translation/en.json`));
      break;
    default:
    case "srp":
      messages = Object.assign(messages, require(`../../translation/srp.json`));
      break;
  }
  return messages;
};

const initialState = {
  locale: "srp",
  messages: setLanguage("srp"),
};

// INITIALIZE REDUCER FUNCTION
const translation = (state = initialState, action) => {
  if (action === undefined) return state;
  switch (action.type) {
    // ACTION THAT SETS NEW APP LENGUAGE
    case constants.UPDATE_LANGUAGE:
      return {
        locale: action.payload.locale,
        messages: setLanguage(action.payload.locale),
      };
    default:
      return state;
  }
};

// const translation = (state = initialState, action) => {
//   const { type, payload } = action;
//   if (action === undefined) return state;
//   switch (type) {
//     // ACTION THAT SETS NEW APP LENGUAGE
//     case constants.UPDATE_LANGUAGE:
//       return {
//         locale: payload,
//         // locale: payload.country_iso_code,
//         messages: setLanguage(payload),
//       };
//       console.log("payload", payload);
//     default:
//       return state;
//   }
// };

export default translation;
