import { getCurretCatAttibutes, getLicenceAttributesSuccess } from "./actions";
import store from "../index.js";

export const setCurrentCatAttributes = (selectedCat, licenceAttributes) => {
  function comparer(otherArray) {
    return function (current) {
      return (
        otherArray.filter(function (other) {
          return other.id === current.id;
        }).length === 0
      );
    };
  }

  const currentCatAttributes = selectedCat.attributes;
  const filterRestAttributes = licenceAttributes.filter(
    comparer(selectedCat.attributes)
  );
  const restAttributes = filterRestAttributes.map((attribute) => {
    return { ...attribute, existingAttribute: true };
  });
  // console.log("selectedCat", selectedCat);
  // console.log("restAttributes", restAttributes);

  store.dispatch(getLicenceAttributesSuccess(restAttributes));
  store.dispatch(getCurretCatAttibutes(currentCatAttributes));
};

export const removeContentTypeCategory = (state, payload) => {
  let newState = [...state];
  newState[0].content_type_categories =
    newState[0].content_type_categories.filter(
      (contentType) => contentType.id !== payload
    );
  return newState;
};
