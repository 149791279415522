import constants from "./actionTypes";

// ACTION FOR GETTING USER SEARCH TERM CONTENT
// RECIVES SEARCH TERM CONTENT AS A PAYLOAD
export const getUserSearchTearm = (payload) => ({
  type: constants.GET_uSER_SEARCH_TERM,
  payload,
});
// SUCCESS ACTION FOR GETTING USER SEARCH TERM CONTENT
// SEARCH CONTENT DATA FROM THE SERVER AS A PAYLOAD
export const getUserSearchTearmSuccess = (payload) => ({
  type: constants.GET_uSER_SEARCH_TERM_SUCCESS,
  payload,
});
// FAIL ACTION FOR GETTING USER SEARCH TERM CONTENT
// RECIVES ERROR STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const getUserSearchTearmFail = (payload) => ({
  type: constants.GET_uSER_SEARCH_TERM_FAIL,
  payload,
});

// ACTION FOR SETTING SEARCH TERM CONTENT PAGINATION
// RECIVES PAGINATION OBJECT FROM THE SERVER AS A PAYLOAD
export const setSearchTermPagination = (payload) => ({
  type: constants.SET_CONTENT_SEARCH_TERM_PAGINATION,
  payload,
});
