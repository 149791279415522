import constants from "./actionTypes";

// ACTION FOR UPDATIONG LICENCE BIDDING DURATION
// RECIVE NEW BIDDING DURATION AS A PAYLOAD
export const licenceUpdateBiddingDuration = (payload) => ({
  type: constants.LICENCE_UPDATE_BIDDING_DURATION,
  payload,
});

// SUCCESS ACTION FOR UPDATIONG LICENCE BIDDING DURATION
// RECIVE SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const licenceUpdateBiddingDurationSuccess = (payload) => ({
  type: constants.LICENCE_UPDATE_BIDDING_DURATION_SUCCESS,
  payload,
});

// FAIL ACTION FOR UPDATIONG LICENCE BIDDING DURATION
// RECIVE FAIL STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const licenceUpdateBiddingDurationFail = (payload) => ({
  type: constants.LICENCE_UPDATE_BIDDING_DURATION_FAIL,
  payload,
});

// ACTION FOR GETTING LICENCE BIDDING ITEMS
// RECIVE FILTER STATUS FOR LICENCE BIDDING ITEMS
export const getBiddingItems = (payload) => ({
  type: constants.GET_BIDDING_ITEMS,
  payload,
});

// SUCCESS ACTION FOR GETTING LICENCE BIDDING ITEMS
// RECIVE BIDDING DATA FROM THE SERVER AS A PAYLOAD
export const getBiddingItemsSuccess = (payload) => ({
  type: constants.GET_BIDDING_ITEMS_SUCCESS,
  payload,
});

// FAIL ACTION FOR GETTING LICENCE BIDDING ITEMS
// RECIVE FAIL STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const getBiddingItemsFail = (payload) => ({
  type: constants.GET_BIDDING_ITEMS_FAIL,
  payload,
});

// ACTION TO DISABLE BIDDING ITEM
// RECIVE BIDDING ITEM ID AS A PAYLAOD
export const disableBiddingItem = (payload) => ({
  type: constants.DISABLE_BIDDING_ITEM,
  payload,
});

// SUCCESS ACTION TO DISABLE BIDDING ITEM
// RECIVE SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const disableBiddingItemSuccess = (payload) => ({
  type: constants.DISABLE_BIDDING_ITEM_SUCCESS,
  payload,
});

// FAIL ACTION TO DISABLE BIDDING ITEM
// RECIVE FAIL STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const disableBiddingItemFail = (payload) => ({
  type: constants.DISABLE_BIDDING_ITEM_FAIL,
  payload,
});

// ACTION FOR SETTING BIDDING PAGINATION
// RECIVE RESPONSE HEADERS AS A PAYLAOD FROM THE SERVER
export const setBiddingPagination = (payload) => ({
  type: constants.SET_BIDDING_PAGINATION,
  payload,
});

// // ACTION FOR GETTING LICENCE BIDDING DURATION
// export const getBiddingDuration = (payload) => ({
//   type: constants.GET_BIDDING_DURATION,
//   payload,
// });

// // SUCCESS ACTION FOR GETTING LICENCE BIDDING DURATION
// // RECIVE LICENCE BIDDING DURATION
// export const getBiddingDurationSuccess = (payload) => ({
//   type: constants.GET_BIDDING_DURATION_SUCCESS,
//   payload,
// });

// // FAIL ACTION FOR GETTING LICENCE BIDDING DURATION
// // RECIVE FAIL STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
// export const getBiddingDurationFail = (payload) => ({
//   type: constants.GET_BIDDING_DURATION_FAIL,
//   payload,
// });

// ACTION TO CREATE BIDDING ITEM
// RECIVE CATALOG OR CONTENT ID
export const createBiddingItem = (payload) => ({
  type: constants.CREATE_BIDDING_ITEM,
  payload,
});

// SUCCESS ACTION TO CREATE BIDDING ITEM
// RECIVE SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const createBiddingItemSuccess = (payload) => ({
  type: constants.CREATE_BIDDING_ITEM_SUCCESS,
  payload,
});

// FAIL ACTION TO CREATE BIDDING ITEM
// RECIVE FAIL STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const createBiddingItemFail = (payload) => ({
  type: constants.CREATE_BIDDING_ITEM_FAIL,
  payload,
});
