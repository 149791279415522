import constants from "./actionTypes";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getBlogsApi,
  getCategoryBySlugApi,
  getContentByCategoryIdApi,
  getLicenceUserBlogsApi,
} from "../../services/ApiCalls";
import {
  fetchBlogsSuccess,
  setPagination,
  fetchBlogListByCategorySlugSuccess,
  fetchBlogsByAuthUserSuccess,
  setPaginationForAuthUserBlogs,
} from "./actions";

// SAGA FOR FETCHING BLOGS
// RECIVES TYPE FROM ACTION
// TYPE : constants.FETCH_BLOGS
function* fetchBlogsSaga(action) {
  try {
    const blogs = yield call(getBlogsApi);
    const setPaginationObj = {
      currentPage: parseInt(blogs.headers["x-pagination-current-page"]),
      pageCount: parseInt(blogs.headers["x-pagination-page-count"]),
      perPage: parseInt(blogs.headers["x-pagination-per-page"]),
      totlCount: parseInt(blogs.headers["x-pagination-total-count"]),
    };
    yield put(fetchBlogsSuccess(blogs.data));
    yield put(setPagination(setPaginationObj));
  } catch (e) {
    console.log(e.message);
  }
}

// SAGA FOR FETCHING BLOGS BY AUTH USER
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.FETCH_BLOGS_BY_AUTH_USER
// PAYLOAD : AUTH USER ID
function* fetchBlogsByAuthuserSaga(action) {
  try {
    const authUserBlogs = yield call(getLicenceUserBlogsApi, action.payload);
    const setPaginationObj = {
      currentPage: parseInt(authUserBlogs.headers["x-pagination-current-page"]),
      pageCount: parseInt(authUserBlogs.headers["x-pagination-page-count"]),
      perPage: parseInt(authUserBlogs.headers["x-pagination-per-page"]),
      totlCount: parseInt(authUserBlogs.headers["x-pagination-total-count"]),
    };
    yield put(setPaginationForAuthUserBlogs(setPaginationObj));

    // console.log("authUserBlogs", authUserBlogs);
    yield put(fetchBlogsByAuthUserSuccess(authUserBlogs.data));
  } catch (e) {
    console.log(e.message);
  }
}

function* fetchBlogListByCategorySlugSaga(action) {
  try {
    const category = yield call(getCategoryBySlugApi, action.payload);
    const payload2 = {
      categoryId: category.data[0].id,
      page: action.payload.page,
    };
    const blogList = yield call(getContentByCategoryIdApi, payload2);
    const data = [category, blogList];
    const setPaginationObj = {
      currentPage: parseInt(blogList.headers["x-pagination-current-page"]),
      pageCount: parseInt(blogList.headers["x-pagination-page-count"]),
      perPage: parseInt(blogList.headers["x-pagination-per-page"]),
      totlCount: parseInt(blogList.headers["x-pagination-total-count"]),
    };
    yield put(fetchBlogListByCategorySlugSuccess(data));
    yield put(setPagination(setPaginationObj));
    window.scrollTo(0, 0);
  } catch (e) {
    console.log(e);
  }
}

// COMBINE ALL OF THE FUNCTION ABOVE IN ONE FUNCTION
function* blogsSaga() {
  yield all([
    takeLatest(constants.FETCH_BLOGS, fetchBlogsSaga),
    takeLatest(
      constants.FETCH_BLOG_LIST_BY_CATEGORY_SLUG,
      fetchBlogListByCategorySlugSaga
    ),
    // takeLatest(constants.GET_SINGLE_BLOG, singleBlogSaga),
    // takeLatest(constants.ADMIN_UPDATE_BLOG, adminUpdateBlogSaga),
    takeLatest(constants.FETCH_BLOGS_BY_AUTH_USER, fetchBlogsByAuthuserSaga),
  ]);
}

// EXPORT THAT FUNCTION AS A DEFAULT EXPORT
export default blogsSaga;
