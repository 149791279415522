import constants from "./actionTypes";

// INITIAL STATE OF THIS REDUCER
const initState = {
  comment_loader: false,
  comments_list: [],
  commentOnModeration: [],
  commentOnModerationPagination: {},
};

// REDUCER FUNCTION
export default (state = initState, action) => {
  const { payload, type } = action;
  switch (type) {
    case constants.GET_COMMENTS_ON_MODERATION:
      return {
        ...state,
        comment_loader: true,
      };

      case constants.ACTIVATE_COMMENT_LOADER:
        return {
          ...state,
          comment_loader: true,
        };
  
    case constants.SET_COMMENTS_ON_MODERATION_PAGINATION:
      return {
        ...state,
        commentOnModerationPagination: payload,
      };
    case constants.GET_COMMENTS_ON_MODERATION_SUCCESS:
      return {
        ...state,
        comment_loader: false,
        commentOnModeration: payload,
      };
    case constants.FETCH_CONTENT_COMMENTS:
      return {
        ...state,
        comment_loader: true,
      };

    case constants.FETCH_CONTENT_COMMENTS_SUCCESS:
      return {
        ...state,
        comments_list: payload,
        comment_loader: false,
      };

    case constants.FETCH_CONTENT_COMMENTS_FAIL:
      return {
        ...state,
        //singleArticle: payload,
        comment_loader: false,
      };

    //  IF NONE OF THE CASE ABOVE IS TRIGGERED RETURN DEFAULT STATE
    default:
      return {
        ...state,
      };
  }
};
