import constants from "./actionTypes";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getApprovedContentCommentsByContentId,
  postContentComment,
  getLicenceCommentsOnModerationApi,
  approveCommentModerationApi,
  disapproveCommentModerationApi,
} from "../../services/ApiCalls";
import {
  fetchContentCommentsSuccess,
  fetchContentComments,
  getLicenceCommentsOnModerationSuccess,
  setPaginationForCommentsOnModeration,
  getLicenceCommentsOnModeration,
} from "./actions";
import { getModerationNumbers } from "../layout/actions";
import { NotificationManager } from "react-notifications";

function* fetchContentCommentsSaga(action) {
  try {
    const commentsList = yield call(
      getApprovedContentCommentsByContentId,
      action.payload
    );
    yield put(fetchContentCommentsSuccess(commentsList.data));
  } catch (e) {
    console.log(e);
  }
}

function* postContentContentSaga(action) {
  try {
    // console.log(action);
    const comment = yield call(postContentComment, action.payload);
    yield put(fetchContentComments(action.payload.content_id));
    NotificationManager.success(comment.message, "", 3000);
  } catch (e) {
    NotificationManager.error(e.response.data.message, "", 3000);
  }
}

// SAGA FOR GETTING LICENCE COMMENTS ON MODERATION
// RECIVES TYPE FROM ACTION
// TYPE : constants.GET_COMMENTS_ON_MODERATION
function* getCommentsOnModerationSaga(action) {
  try {
    // console.log(action);
    const commentsOnModeration = yield call(
      getLicenceCommentsOnModerationApi,
      action.payload
    );
    const setPaginationObj = {
      currentPage: parseInt(
        commentsOnModeration.headers["x-pagination-current-page"]
      ),
      pageCount: parseInt(
        commentsOnModeration.headers["x-pagination-page-count"]
      ),
      perPage: parseInt(commentsOnModeration.headers["x-pagination-per-page"]),
      totlCount: parseInt(
        commentsOnModeration.headers["x-pagination-total-count"]
      ),
    };
    yield put(setPaginationForCommentsOnModeration(setPaginationObj));
    yield put(getLicenceCommentsOnModerationSuccess(commentsOnModeration.data));
    // NotificationManager.success(comment.message, "", 3000);
  } catch (e) {
    NotificationManager.error(e.response.data.message, "", 3000);
  }
}

// SAGA FOR APPROVING SINGLE COMMENT MODERATION
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.APPROVE_COMMENT_MODERATION
// PAYLOAD : COMMENT ID THAT NEEDS TO BE APPROVED
function* approveCommentModerationSaga(action) {
  try {
    const approveComment = yield call(
      approveCommentModerationApi,
      action.payload
    );
    const payload = {
      page: 1,
    };
    yield put(getModerationNumbers());
    yield put(getLicenceCommentsOnModeration(payload));
    NotificationManager.success(approveComment.message, "", 3000);
  } catch (e) {
    console.log(e.message);
  }
}

// SAGA FOR DISAPPROVING SINGLE COMMENT MODERATION
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.ADMIN_DISAPPROVE_COMMENT_MODERATION
// PAYLOAD : COMMENT ID THAT NEEDS TO BE DISAPPROVED
function* disapproveCommentModerationSaga(action) {
  try {
    // console.log("dosao do disapproveBlogModerationSaga");
    // // console.log("Dosao do adminUpdateBlogSaga", action.payload);
    // yield put(activateBlogsLoader());
    const disapproveComment = yield call(
      disapproveCommentModerationApi,
      action.payload
    );
    const payload = {
      page: 1,
    };
    yield put(getModerationNumbers());
    yield put(getLicenceCommentsOnModeration(payload));
    NotificationManager.success(disapproveComment.message, "", 3000);
  } catch (e) {
    console.log(e.message);
  }
}

// COMBINE ALL OF THE FUNCTION ABOVE IN ONE FUNCTION
function* commentsSaga() {
  yield all([
    takeLatest(constants.FETCH_CONTENT_COMMENTS, fetchContentCommentsSaga),
    takeLatest(constants.POST_CONTENT_COMMENT, postContentContentSaga),
    takeLatest(
      constants.GET_COMMENTS_ON_MODERATION,
      getCommentsOnModerationSaga
    ),
    takeLatest(
      constants.APPROVE_COMMENT_MODERATION,
      approveCommentModerationSaga
    ),

    takeLatest(
      constants.ADMIN_DISAPPROVE_COMMENT_MODERATION,
      disapproveCommentModerationSaga
    ),
  ]);
}

// EXPORT THAT FUNCTION AS A DEFAULT EXPORT
export default commentsSaga;
