// import constants from "./actionTypes";

const initState = {
  loader: false,
};

export default (state = initState, action) => {
  const { type } = action;
  switch (type) {
    default:
      return { ...state };
  }
};
