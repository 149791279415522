import constants from "./actionTypes";

// INITIAL STATE OF THIS REDUCER
const initState = {
  currentUser: {},
  message: "",
  editMode: false,
  templateId: "",
  languageId: "",
  loader: false,
  logoutLoader: false,
};

// REDUCER FUNCTION
export default (state = initState, action) => {
  const { payload, type } = action;
  switch (type) {
    // TRIGGER USER LOGIN
    case constants.LOGIN_USER:
      return {
        ...state,
        loader: true,
      };

    case constants.LOGIN_SALES_USER:
      return {
        ...state,
        loader: true,
      };
    case constants.LOGIN_SALES_USER_SUCCESS:
      return {
        ...state,
        currentUser: payload,
        loader: false,
      };
    case constants.LOGIN_SALES_USER_FAIL:
      return {
        ...state,
        loader: false,
      };
    // TRIGGER USER LOGIN
    case constants.LOGOUT_USER:
      return {
        ...state,
        logoutLoader: true,
      };
    case constants.LOGOUT_USER_SUCCESS:
      return {
        ...state,
        logoutLoader: false,
        currentUser: {},
      };
    // SET USER CREDENTIALS TO BE PAYLOAD RECIVED FROM API
    case constants.LOGIN_USER_SUCCESS:
      return {
        ...state,
        currentUser: payload,
        loader: false,
      };
    // SET ERROR MESSAGE RECIVED FROM THE API
    case constants.LOGIN_USER_FAIL:
      return {
        ...state,
        message: payload,
        loader: false,
      };
    // TRIGGER LICENCE USER REGISTRATION
    case constants.REGISTER_LICENCE_USER:
      return {
        ...state,
        loader: true,
      };
    // SET ERROR MESSAGE RECIVED FROM THE API
    case constants.REGISTER_LICENCE_USER_FAIL:
      return {
        ...state,
        message: payload,
        loader: false,
      };
    // SET USER CREDENTIALS FROM PAYLOAD RECIVED FROM API
    case constants.REGISTER_LICENCE_USER_SUCCESS:
      return {
        ...state,
        // currentUser: payload,
        loader: false,
      };

    case constants.REGISTER_SALES_USER:
      return {
        ...state,
        currentUser: payload,
        loader: true,
      };

    // SET USER CREDENTIALS FROM PAYLOAD RECIVED FROM API
    case constants.REGISTER_SALES_USER_SUCCESS:
      return {
        ...state,
        currentUser: payload,
        loader: false,
      };

    // SET ERROR MESSAGE RECIVED FROM THE API
    case constants.REGISTER_SALES_USER_FAIL:
      return {
        ...state,
        message: payload,
        loader: false,
      };

    case constants.REGISTER_LICENCE_CLIENT:
      return {
        ...state,
        currentUser: payload,
        loader: true,
      };
    // SET USER CREDENTIALS FROM PAYLOAD RECIVED FROM API
    case constants.REGISTER_LICENCE_CLIENT_SUCCESS:
      return {
        ...state,
        // currentUser: payload,
        loader: false,
      };
    // SET ERROR MESSAGE RECIVED FROM THE API
    case constants.REGISTER_LICENCE_CLIENT_FAIL:
      return {
        ...state,
        // currentUser: payload,
        message: payload,
        loader: false,
      };
    // SET USER CREDENTIALS FROM PAYLOAD RECIVED FROM API
    case constants.LOGIN_LICENCE_USER_SUCCESS:
      return {
        ...state,
        currentUser: payload,
      };
    // SET ERROR MESSAGE RECIVED FROM THE API
    case constants.LOGIN_LICENCE_USER_FAIL:
      return {
        ...state,
        message: payload,
      };
    // SET USER CREDENTIALS FROM PAYLOAD RECIVED FROM API
    case constants.GET_AUTH_USER_SUCCESS:
      return {
        ...state,
        currentUser: payload,
      };

    case constants.GET_LICENCE_TEMPLATE_SUCCESS:
      return {
        ...state,
        templateId: payload,
      };

    case constants.GET_LICENCE_LANGUAGE_SUCCESS:
      return {
        ...state,
        languageId: payload,
      };
    //  IF NONE OF THE CASE ABOVE IS TRIGGERED RETURN DEFAULT STATE
    default:
      return {
        ...state,
      };
  }
};
