import constants from "./actionTypes";
import { all, takeLatest, put, call } from "redux-saga/effects";
import {
  getPageWidgetsApi,
  changeWidgetPositionApi,
  getLicenceContactServiceMailsApi,
  deleteLicenceServiceMailApi,
  createLicenceContactServiceMailApi,
  createWidgetApi,
  getLicenceCategoriesApi,
  getWidgetSettingsApi,
  getWidgetsInfoApi,
  updatePageWidgetApi,
  submitWidgetFormApi,
  adminDeletePageWidgetApi,
} from "../../services/ApiCalls";
import {
  getPageWidgetsSuccess,
  getPageWidgetsFail,
  getLIcenceServiceMailsSuccess,
  getLIcenceServiceMails,
  // createWidget,
  getPageWidgets,
  getWidgetContentTypeCatsSuccess,
  getWIdgetSettingsDataSuccess,
  getWidgetInfoDataSuccess,
  // submitFormWIdget,
} from "./actions";
import { NotificationManager } from "react-notifications";

const setPayload = (payload) => {
  const { type, pageName } = payload;
  switch (type) {
    case "homepage":
      return {
        page_name: pageName,
      };
    case "categorieList":
      return {
        page_category_id: pageName,
      };
    case "singleContent":
      return {
        page_content_id: pageName,
      };
    default:
      return "";
  }
};

// SAGA FOR GETTING PAGE WIDGETS
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.GET_PAGE_WIDGETS
// PAYLOAD : PAGE NAME OR ID
function* getPageWidgetsSaga(action) {
  try {
    const pageWidgets = yield call(getPageWidgetsApi, action.payload);
    if (pageWidgets.length !== 0) {
      yield put(getPageWidgetsSuccess(pageWidgets));
    } else {
      const payload = { one: [], two: [], three: [] };
      yield put(getPageWidgetsSuccess(payload));
    }

    // yield put(getPageWidgetsFail());

    // console.log("pageWidgets", pageWidgets);
  } catch (e) {
    yield put(getPageWidgetsFail());
  }
}
// SAGA FOR CHANGING WIDGET POSITION
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.CHANGE_WIDGET_POSITION
// PAYLOAD :  NEW POSITION COLUMN NUMBER AND WIDGET ID
function* changeWidgetPositionSaga(action) {
  try {
    const changeWidgetPosition = yield call(
      changeWidgetPositionApi,
      action.payload
    );
    // console.log("action.payload", action.payload);
    NotificationManager.success(changeWidgetPosition.message, "", 3000);

    const payload = setPayload(action.payload);
    yield put(getPageWidgets(payload));
  } catch (e) {
    NotificationManager.error(e.response.data.message, "", 3000);
  }
}

// SAGA FOR GETTING LICENCE SERVICE MAILS
// RECIVES TYPE  FROM ACTION
// TYPE : constants.GET_LICENCE_SERVICE_MAILS
function* getLicenceServiceMailsSaga(action) {
  try {
    const licenceServiceMails = yield call(getLicenceContactServiceMailsApi);
    // console.log("licenceServiceMails", licenceServiceMails);
    yield put(getLIcenceServiceMailsSuccess(licenceServiceMails));
    // NotificationManager.success(changeWidgetPosition.message, "", 3000);
  } catch (e) {
    console.log("error", e);
    // NotificationManager.error(e.response.data.message, "", 3000);
  }
}

// SAGA FOR HANDLING LICENCE SREVICE MAIL DELETE
// RECIVES TYPE AND PAYLOAD FROM ACTION
// PAYLOAD :  SERVICE MAIL ID
// TYPE : constants.DELETE_LICENCE_SERVICE_MAIL
function* deleteLicenceServiceSaga(action) {
  try {
    const deletedServiceMail = yield call(
      deleteLicenceServiceMailApi,
      action.payload
    );
    // console.log("licenceServiceMails", licenceServiceMails);
    // yield put(getLIcenceServiceMailsSuccess(licenceServiceMails));
    yield put(getLIcenceServiceMails());
    NotificationManager.success(deletedServiceMail.message, "", 3000);
  } catch (e) {
    console.log("error", e);
    // NotificationManager.error(e.response.data.message, "", 3000);
  }
}

// SAGA FOR CREATING CONTACT SERVICE MAIL
// RECIVES TYPE AND PAYLOAD FROM ACTION
// PAYLOAD :  NEW CONTACT SERVICE MAIL DATA
// TYPE : constants.CREATE_CONTACT_SERVICE_EMAIL
function* createContactServiceEmailSaga(action) {
  try {
    const serviceMail = yield call(
      createLicenceContactServiceMailApi,
      action.payload
    );
    // console.log("serviceMail", serviceMail);
    // yield put(getLIcenceServiceMailsSuccess(licenceServiceMails));
    yield put(getLIcenceServiceMails());

    NotificationManager.success(serviceMail.message, "", 3000);
  } catch (e) {
    console.log("error", e);
    NotificationManager.error(e.response.data.message, "", 3000);
  }
}

// SAGA FOR CREATING PAGE WIDGET
// RECIVES TYPE AND PAYLOAD FROM ACTION
// PAYLOAD :  NEW WIDGET DATA
// TYPE : constants.CREATE_WIDGET
function* createWidgetSaga(action) {
  try {
    const newPageWidget = yield call(createWidgetApi, action.payload);
    // console.log("newPageWidget", newPageWidget);
    // yield put(getLIcenceServiceMailsSuccess(licenceServiceMails));
    // yield put(getLIcenceServiceMails());\
    const payload = {};
    // console.log("action.payload.type", action.payload.type);
    if (parseInt(action.payload.pageName)) {
      if (action.payload.type === "categorieList") {
        payload.page_category_id = action.payload.pageName;
      } else {
        payload.page_content_id = action.payload.pageName;
      }
    } else {
      payload.page_name = action.payload.pageName;
    }
    NotificationManager.success(newPageWidget.message, "", 3000);

    yield put(getPageWidgets(payload));
    window.scroll(0, 0);
  } catch (e) {
    console.log("error", e);
    NotificationManager.error(e.response.data.message, "", 3000);
  }
}

// SAGA FOR CREATING CONTACT SERVICE MAIL
// RECIVES TYPE AND PAYLOAD FROM ACTION
// PAYLOAD :  NEW CONTACT SERVICE MAIL DATA
// TYPE : constants.GET_WIDGET_CONTET_TYPE_CATS
function* getWidgetContentTypeCatsSaga(action) {
  try {
    const cats = yield call(getLicenceCategoriesApi, action.payload);
    // console.log("cats", cats);
    yield put(getWidgetContentTypeCatsSuccess(cats));
    // yield put(getLIcenceServiceMails());

    // NotificationManager.success(serviceMail.message, "", 3000);
  } catch (e) {
    console.log("error", e);
    // NotificationManager.error(e.response.data.message, "", 3000);
  }
}

// SAGA FOR GETTING WIDGET SETTINGS DATA
// RECIVES TYPE AND PAYLOAD FROM ACTION
// PAYLOAD :  RECIVE ID TO FETCH SETTIGS DATA
// TYPE : constants.GET_EDIT_WIDGET_CONTENT_DATA_SUCCESS
function* getWIdgetSettingsDataSaga(action) {
  try {
    // console.log("getWIdgetSettingsDataSaga");
    const widgetSettings = yield call(getWidgetSettingsApi, action.payload);
    // console.log("widgetSettings", widgetSettings);
    yield put(getWIdgetSettingsDataSuccess(widgetSettings));
    // yield put(getLIcenceServiceMails());

    // NotificationManager.success(serviceMail.message, "", 3000);
  } catch (e) {
    console.log("error", e);
    // NotificationManager.error(e.response.data.message, "", 3000);
  }
}

// SAGA FOR GETTING WIDGET SETTINGS DATA
// RECIVES TYPE FROM ACTION
// TYPE : constants.GET_WIDGETS_INFO_DATA
function* getWidgetsInfoDataSaga(action) {
  try {
    // console.log("getWIdgetSettingsDataSaga");
    const widgetsInfoData = yield call(getWidgetsInfoApi, action.payload);
    // console.log("widgetsInfoData", widgetsInfoData);
    yield put(getWidgetInfoDataSuccess(widgetsInfoData));
    // yield put(getLIcenceServiceMails());

    // NotificationManager.success(serviceMail.message, "", 3000);
  } catch (e) {
    console.log("error", e);
    // NotificationManager.error(e.response.data.message, "", 3000);
  }
}

// SAGA FOR GETTING WIDGET SETTINGS DATA
// RECIVES TYPE AND PAYLOAD FROM ACTION
// PAYLOAD :  UPDATED PAGE WIDGET DATA
// TYPE : constants.UPDATE_PAGE_WIDGET
function* updatePageWidgetSaga(action) {
  try {
    // console.log("getWIdgetSettingsDataSaga");
    const updatedWidget = yield call(updatePageWidgetApi, action.payload);
    // console.log("widgetSettings", widgetSettings);
    // yield put(getWIdgetSettingsDataSuccess(widgetSettings));
    // yield put(getLIcenceServiceMails());
    const payload = {};
    // console.log("action.payload", action.payload);

    if (parseInt(action.payload.pageName)) {
      if (action.payload.type === "categorieList") {
        payload.page_category_id = action.payload.pageName;
      } else {
        payload.page_content_id = action.payload.pageName;
      }
    } else {
      payload.page_name = action.payload.pageName;
    }
    NotificationManager.success(updatedWidget.message, "", 3000);

    yield put(getPageWidgets(payload));
  } catch (e) {
    console.log("error", e);
    // NotificationManager.error(e.response.data.message, "", 3000);
  }
}

// SAGA FOR GETTING WIDGET SETTINGS DATA
// RECIVES TYPE AND PAYLOAD FROM ACTION
// PAYLOAD :  UPDATED PAGE WIDGET DATA
// TYPE : constants.SUBMIT_FORM_WIDGET
function* submitFormWIdgetSaga(action) {
  try {
    // console.log("getWIdgetSettingsDataSaga");
    // yield call(submitWidgetFormApi, action.payload);
    // console.log("submitForm", submitForm);
    const serviceMail = yield call(submitWidgetFormApi, action.payload);
    NotificationManager.success(serviceMail.message, "", 3000);
  } catch (e) {
    console.log("error", e);
    NotificationManager.error(e.response.data.message, "", 3000);
  }
}

// SAGA FOR GETTING WIDGET SETTINGS DATA
// RECIVES TYPE AND PAYLOAD FROM ACTION
// PAYLOAD : WIDGET DATA THAT NEEDS TO BE DELETED
// TYPE : constants.ADMIN_DELETE_PAGE_WIDGET
function* adminDeletePageWidgetSaga(action) {
  try {
    // console.log("getWIdgetSettingsDataSaga");
    const deletePageWidget = yield call(
      adminDeletePageWidgetApi,
      action.payload
    );
    const payload = {};

    if (parseInt(action.payload.pageName)) {
      if (action.payload.type === "categorieList") {
        payload.page_category_id = action.payload.pageName;
      } else {
        payload.page_content_id = action.payload.pageName;
      }
    } else {
      payload.page_name = action.payload.pageName;
    }
    NotificationManager.success(deletePageWidget.message, "", 3000);
    // console.log("deletePageWidget", deletePageWidget);
    yield put(getPageWidgets(payload));
  } catch (e) {
    console.log("error", e);
    // NotificationManager.error(e.response.data.message, "", 3000);
  }
}

function* widgetsSaga() {
  yield all([
    takeLatest(constants.GET_PAGE_WIDGETS, getPageWidgetsSaga),
    takeLatest(constants.CHANGE_WIDGET_POSITION, changeWidgetPositionSaga),
    takeLatest(constants.GET_LICENCE_SERVICE_MAILS, getLicenceServiceMailsSaga),
    takeLatest(constants.DELETE_LICENCE_SERVICE_MAIL, deleteLicenceServiceSaga),
    takeLatest(constants.GET_WIDGETS_INFO_DATA, getWidgetsInfoDataSaga),
    takeLatest(constants.UPDATE_PAGE_WIDGET, updatePageWidgetSaga),
    takeLatest(constants.SUBMIT_FORM_WIDGET, submitFormWIdgetSaga),
    takeLatest(
      constants.GET_EDIT_WIDGET_CONTENT_DATA,
      getWIdgetSettingsDataSaga
    ),
    takeLatest(
      constants.GET_WIDGET_CONTET_TYPE_CATS,
      getWidgetContentTypeCatsSaga
    ),
    takeLatest(constants.CREATE_WIDGET, createWidgetSaga),
    takeLatest(constants.ADMIN_DELETE_PAGE_WIDGET, adminDeletePageWidgetSaga),
    takeLatest(
      constants.CREATE_CONTACT_SERVICE_EMAIL,
      createContactServiceEmailSaga
    ),
  ]);
}

export default widgetsSaga;
