import constants from "./actionTypes";

// ACTION FOR FETCHING BLOGS
// DO NOT CONTAIN PAYLOAD
export const fetchBlogs = () => ({
  type: constants.FETCH_BLOGS,
});

// ACTION FOR FETCHING BLOGS SUCCESS
// RECIVES FETCHED BLOGS FROM SERVER AS A PAYLOAD
export const fetchBlogsSuccess = (payload) => ({
  payload,
  type: constants.FETCH_BLOGS_SUCCESS,
});

// ACTION FOR FETCHING BLOGS FAIL
// RECIVES ERROR MESSAGE WITH STATUS CODE FROM SERVER AS A PAYLOAD
export const fetchBlogsFail = (payload) => ({
  payload,
  type: constants.FETCH_BLOGS_FAIL,
});

// ACTION FOR SETTING PAGINATION
// RECIVES HEDERS FROM THE SERVER RESPONSE TO FETCHING BLOGS AS A PAYOAD
export const setPagination = (payload) => ({
  payload,
  type: constants.SET_PAGINATION,
});

// ACTION SETTING BLOG CONTENT TYPE ORDER
// RECIVE BLOG CONTENT TYPE IN ORDER AS A PAYLOAD FROM SERVER
export const setSelectedContentTypeCategoriesBlog = (payload) => ({
  payload,
  type: constants.SET_SELECTED_CONTENT_TYPE_CATEGORIES_BLOGS,
});
// ACTION FOR FETCHING BLOGS BY SLUG
// RECEVE SLUG NAME FROM THE URL AS A PAYLOAD
export const fetchBlogListByCategorySlug = (payload) => ({
  payload,
  type: constants.FETCH_BLOG_LIST_BY_CATEGORY_SLUG,
});
// SUCCESS ACTION FOR FETCHING BLOGS BY SLUG
// RECEVE BLOGS DATA WITH PAGINATION OBJECT FROM THE SERVER AS A PAYLOAD
export const fetchBlogListByCategorySlugSuccess = (payload) => ({
  payload,
  type: constants.FETCH_BLOG_LIST_BY_CATEGORY_SLUG_SUCCESS,
});
// FAIL ACTION FOR FETCHING BLOGS
// RECEVE ERROR STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const fetchBlogListByCategorySlugFail = (payload) => ({
  payload,
  type: constants.FETCH_BLOG_LIST_BY_CATEGORY_SLUG_FAIL,
});

// ACTION FOR FETCHING BLOGS BY THE AUTH USER
// RECIVE AUTH USER ID AS A PAYLOAD
export const fetchBlogsByAuthUser = (payload) => ({
  payload,
  type: constants.FETCH_BLOGS_BY_AUTH_USER,
});
// ACTION FOR FETCHING BLOGS BY THE AUTH USER SUCCESS
// RECIVE BLOGS DATA FROM THE SERVER AS A PAYLOAD
export const fetchBlogsByAuthUserSuccess = (payload) => ({
  payload,
  type: constants.FETCH_BLOGS_BY_AUTH_USER_SUCCESS,
});
// ACTION FOR FETCHING BLOGS BY THE AUTH USER FAIL
// RECIVE ERROR MESSAGE AND THE STATUS CODE AS A PAYLOAD
export const fetchBlogsByAuthUserFail = (payload) => ({
  payload,
  type: constants.FETCH_BLOGS_BY_AUTH_USER_FAIL,
});

// ACTION FOR SETTING PAGINATION OF AUTH USER BLOGS
// RECIVES HEDERS FROM THE SERVER RESPONSE TO FETCHING BLOGS THAT AUTH USER CREATED AS A PAYOAD
export const setPaginationForAuthUserBlogs = (payload) => ({
  payload,
  type: constants.SET_PAGINATION_FOR_AUTH_USER_BLOGS,
});

// ACTION FOR ACIVATING BLOGS LOADER
export const activateBlogsLoader = () => ({
  type: constants.ACTIVATE_BLOGS_LOADER,
});
