import { all, takeLatest, call, put, select } from "redux-saga/effects";
import constants from "./actionTypes";
import {
  licenceUserCreateBlogApi,
  // getLicenceTagsApi,
  licenceUserDeleteBlogApi,
  licenceUserUpdateBlogApi,
  getSingleBlogApi,
  adnimUpdatBlogApi,
  getSingleBlogSlugApi,
  licenceUserCreateBlogDraftApi,
  licenceUserUpdateBlogDraftApi,
  publishBlogDraftApi,
  adminCreateBlogApi,
  approveBlogModerationApi,
  disapproveBlogModerationApi,
  requestForEditBlogModerationApi,
} from "../../services/ApiCalls";
import {
  // licenceUserCreateBlogSuccess,
  // getLicenceTagsSuccess,
  getSingleBlogSuccess,
  // getSingleBlogBySlug,
  // adminUpdateBlogSuccess,
  getSingleBlogBySlugSuccess,
  // activateSingleLoader,
} from "./actions";
import { fetchBlogsByAuthUser, activateBlogsLoader } from "../blogs/actions";
import { getModerationNumbers } from "../layout/actions";
import { NotificationManager } from "react-notifications";

// SAGA FOR HANDLING BLOG CREATION BY LICENCE USER
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.LICENCE_USER_CREATE_BLOG
// PAYLOAD : NEW BLOG DATA THAT LICENCE USER ENTERED IN THE FORM
function* licenceUserCreateBlogSaga(action) {
  try {
    const state = yield select();
    yield put(activateBlogsLoader());

    yield call(licenceUserCreateBlogApi, action.payload);
    NotificationManager.success(
      state.translation.messages.sendOnModerationMessage[1],
      "",
      3000
    );
    const payload = {
      author_id: action.payload.userId,
      content_type_id: 3,
      page: 1,
    };
    yield put(fetchBlogsByAuthUser(payload));

    // console.log("licenceUserBlog", licenceUserBlog);
  } catch (e) {}
}

// SAGA FOR HANDLING BLOG CREATION BY LICENCE USER
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.ADMIN_CREATE_BLOG
// PAYLOAD : NEW BLOG DATA THAT LICENCE USER ENTERED IN THE FORM
function* adminCreateBlogSaga(action) {
  try {
    const state = yield select();
    yield put(activateBlogsLoader());

    yield call(adminCreateBlogApi, action.payload);
    NotificationManager.success(
      state.translation.messages.createBlogSuccessMessage[1],
      "",
      3000
    );
    const payload = {
      content_type_id: 3,
      page: 1,
    };
    yield put(fetchBlogsByAuthUser(payload));
  } catch (e) {}
}

// SAGA FOR GETTING LICENCE TAGS
// RECIVES TYPE FROM ACTION
// TYPE : constants.GET_LICENCE_TAGS
// function* getLicenceTagsSaga(action) {
//   try {
//     const licenceTags = yield call(getLicenceTagsApi);
//     yield put(getLicenceTagsSuccess(licenceTags));
//     // console.log("licenceTags", licenceTags);
//   } catch (e) {}
// }

// SAGA FOR GETTING LICENCE TAGS
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.LICENCE_USER_DELETE_BLOG
// PAYLOAD : BLOG ID THAT NEEDS TO BE DELETED
function* licenceUserDeleteBlogSaga(action) {
  try {
    yield put(activateBlogsLoader());

    const deleteBlog = yield call(licenceUserDeleteBlogApi, action.payload);
    if (deleteBlog.status === 200) {
      const payload = {
        // author_id: action.payload.userId,
        content_type_id: 3,
        page: 1,
      };
      yield put(fetchBlogsByAuthUser(payload));
    }
    console.log("deleteBlog", deleteBlog);
    // yield put(getLicenceTagsSuccess(licenceTags));
    // console.log("licenceTags", licenceTags);
  } catch (e) {}
}

// SAGA FOR HANDLING BLOG UPDATE BY LICENCE USER
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.LICENCE_USER_UPDATE_BLOG
// PAYLOAD : BLOG DATA THAT LICENCE USER UPDATED
function* licenceUserUpdateBlogSaga(action) {
  try {
    const state = yield select();
    yield put(activateBlogsLoader());

    // console.log("Dosao ovde");
    yield call(licenceUserUpdateBlogApi, action.payload);
    NotificationManager.success(
      state.translation.messages.sendOnModerationMessage[1],
      "",
      3000
    );
    const payload = {
      author_id: action.payload.userId,
      content_type_id: 3,
      page: 1,
    };
    yield put(fetchBlogsByAuthUser(payload));
    // console.log("updatedBlog", updatedBlog);
  } catch (e) {}
}

// SAGA FOR PUBLISHING BLOG DRAFT
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.ADMIN_CREATE_BLOG
// PAYLOAD : BLOG DATA THAT LICENCE USER WHAT TO PUBLISH
function* publishBlogDraftSaga(action) {
  try {
    yield put(activateBlogsLoader());
    yield call(licenceUserUpdateBlogDraftApi, action.payload);
    yield call(publishBlogDraftApi, action.payload);
    const payload = {
      author_id: action.payload.userId,
      content_type_id: 3,
      page: 1,
    };
    action.payload.adminRole && delete payload.author_id;

    yield put(fetchBlogsByAuthUser(payload));
    // console.log("publishDraft", publishDraft);
  } catch (e) {
    console.log("error", e);
  }
}

// SAGA FOR CREATING BLOG DRAFT BY LICENCE USER
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.LICENCE_USER_CREATE_BLOG_DRAFT
// PAYLOAD : BLOG DATA THAT LICENCE USER UPDATED
function* licenceUserCreateBlogDraftSaga(action) {
  try {
    const state = yield select();
    yield put(activateBlogsLoader());
    // console.log("Dosao ovde");
    yield call(licenceUserCreateBlogDraftApi, action.payload);
    NotificationManager.success(
      state.translation.messages.saveAsDraftBlogSuccessMessage[1],
      "",
      3000
    );
    const payload = {
      author_id: action.payload.userId,
      content_type_id: 3,
      page: 1,
    };
    action.payload.adminRole && delete payload.author_id;
    console.log("payload", payload);
    yield put(fetchBlogsByAuthUser(payload));

    // console.log("blogDraft", blogDraft);
  } catch (e) {}
}

// SAGA FOR CREATING BLOG DRAFT BY LICENCE USER
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.LICENCE_USER_UPDATE_BLOG_DRAFT
// PAYLOAD : BLOG DATA THAT LICENCE USER UPDATED
function* licenceUserUpdateBlogDraftSaga(action) {
  try {
    const state = yield select();
    yield put(activateBlogsLoader());

    yield call(licenceUserUpdateBlogDraftApi, action.payload);
    NotificationManager.success(
      state.translation.messages.updateDraftBlogSuccessMessage[1],
      "",
      3000
    );
    const payload = {
      author_id: action.payload.userId,
      content_type_id: 3,
      page: 1,
    };
    action.payload.adminRole && delete payload.author_id;

    yield put(fetchBlogsByAuthUser(payload));

    // console.log("updateDraft", updateDraft);
  } catch (e) {
    console.log("error", e);
  }
}
// SAGA FOR FETCHING SINGLE BLOG
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.GET_SINGLE_BLOG
// PAYLOAD : BLOG ID FROM THE URL
function* getsingleBlogSaga(action) {
  try {
    const blog = yield call(getSingleBlogApi, action.payload);
    yield put(getSingleBlogSuccess(blog));
  } catch (e) {
    console.log(e.message);
  }
}

// SAGA FOR UPDATING SINGLE BLOG
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.ADMIN_UPDATE_BLOG
// PAYLOAD : BLOG DATA THAT ADMIN UPDATE IN THE FORM
function* adminUpdateBlogSaga(action) {
  try {
    const state = yield select();
    const updatedBlog = yield call(adnimUpdatBlogApi, action.payload);
    NotificationManager.success(
      state.translation.messages.updateBlogSuccessMessage[1],
      "",
      3000
    );
    // console.log("updatedBlog", updatedBlog);
    if (action.payload.fetchSingleContent) {
      // yield put(activateSingleLoader());
      yield put(getSingleBlogBySlugSuccess(updatedBlog));
      NotificationManager.success(action.payload.message, "", 3000);
    } else {
      yield put(activateBlogsLoader());

      const payload = {
        content_type_id: 3,
        page: 1,
      };
      yield put(fetchBlogsByAuthUser(payload));
    }
  } catch (e) {
    console.log(e.message);
  }
}

// SAGA FOR APPROVING SINGLE BLOG MODERATION
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.APPROVE_BLOG_MODERATION
// PAYLOAD : BLOG ID THAT NEEDS TO BE APPROVED
function* approveBlogModerationSaga(action) {
  try {
    const state = yield select();
    // console.log("Dosao do adminUpdateBlogSaga", action.payload);
    yield put(activateBlogsLoader());

    yield call(approveBlogModerationApi, action.payload);
    NotificationManager.success(
      state.translation.messages.approvedContent[1],
      "",
      3000
    );
    const payload = {
      content_type_id: 3,
      status: "on_moderation",
      page: 1,
    };
    yield put(getModerationNumbers());

    yield put(fetchBlogsByAuthUser(payload));
  } catch (e) {
    console.log(e.message);
  }
}

// SAGA FOR SENDING SINGLE BLOG MODERATION ON EDIT
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.REQUEST_FOR_EDIT_BLOG_MODERATION
// PAYLOAD : BLOG ID THAT NEEDS TO BE APPROVED
function* requestForEditBlogModerationSaga(action) {
  try {
    const state = yield select();
    // console.log("Dosao do adminUpdateBlogSaga", action.payload);
    yield put(activateBlogsLoader());

    yield call(requestForEditBlogModerationApi, action.payload);
    NotificationManager.success(
      state.translation.messages.requestForEditMessage[1],
      "",
      3000
    );
    const payload = {
      content_type_id: 3,
      status: "on_moderation",
      page: 1,
    };
    yield put(getModerationNumbers());

    yield put(fetchBlogsByAuthUser(payload));
  } catch (e) {
    console.log(e.message);
  }
}
// SAGA FOR DISAPPROVING SINGLE BLOG MODERATION
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.DISAPPROVE_BLOG_MODERATION
// PAYLOAD : BLOG ID THAT NEEDS TO BE DISAPPROVED
function* disapproveBlogModerationSaga(action) {
  try {
    const state = yield select();
    // console.log("Dosao do adminUpdateBlogSaga", action.payload);
    yield put(activateBlogsLoader());

    yield call(disapproveBlogModerationApi, action.payload);
    NotificationManager.success(
      state.translation.messages.disapprovedContent[1],
      "",
      3000
    );
    const payload = {
      content_type_id: 3,
      status: "on_moderation",
      page: 1,
    };
    yield put(getModerationNumbers());

    yield put(fetchBlogsByAuthUser(payload));
  } catch (e) {
    console.log(e.message);
  }
}

function* fetchSingleBlogBySlugSaga(action) {
  try {
    const singleBlog = yield call(getSingleBlogSlugApi, action.payload);
    yield put(getSingleBlogBySlugSuccess(singleBlog.data[0]));
  } catch (e) {
    console.log(e);
  }
}

// COMBINE ALL OF THE FUNCTION ABOVE IN ONE FUNCTION
function* singleBlogSaga() {
  yield all([
    takeLatest(constants.LICENCE_USER_CREATE_BLOG, licenceUserCreateBlogSaga),
    // takeLatest(constants.GET_LICENCE_TAGS, getLicenceTagsSaga),
    takeLatest(constants.LICENCE_USER_DELETE_BLOG, licenceUserDeleteBlogSaga),
    takeLatest(
      constants.LICENCE_USER_CREATE_BLOG_DRAFT,
      licenceUserCreateBlogDraftSaga
    ),
    takeLatest(
      constants.LICENCE_USER_UPDATE_BLOG_DRAFT,
      licenceUserUpdateBlogDraftSaga
    ),
    takeLatest(constants.GET_SINGLE_BLOG, getsingleBlogSaga),
    takeLatest(constants.ADMIN_UPDATE_BLOG, adminUpdateBlogSaga),
    takeLatest(constants.APPROVE_BLOG_MODERATION, approveBlogModerationSaga),
    takeLatest(
      constants.REQUEST_FOR_EDIT_BLOG_MODERATION,
      requestForEditBlogModerationSaga
    ),
    takeLatest(
      constants.ADMIN_DISAPPROVE_BLOG_MODERATION,
      disapproveBlogModerationSaga
    ),
    takeLatest(constants.LICENCE_USER_UPDATE_BLOG, licenceUserUpdateBlogSaga),
    takeLatest(constants.PUBLISH_BLOG_DRAFT, publishBlogDraftSaga),
    takeLatest(constants.ADMIN_CREATE_BLOG, adminCreateBlogSaga),
    takeLatest(constants.GET_SINGLE_BLOG_BY_SLUG, fetchSingleBlogBySlugSaga),
  ]);
}
// EXPORT THAT FUNCTION AS A DEFAULT EXPORT
export default singleBlogSaga;
