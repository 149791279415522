import constants from "./actionTypes";
import { removeContentTypeCategory } from "./utills";

const initState = {
  renderCat: false,
  licenceCategories: [],
  licenceAttributes: [],
  allLicenceCategories: [],
  currentCatAttributes: [],
  selectedCategorie: {},
  contentTypeOrder: [],
  selectedContentTypeOrder: [],
  selectedContentTypeOrderArticle: [],
  error: "",
  loading: false,
  newCat: {},
  authClientCatalogs: [],
  authClientCatalogsPagination: {
    currentPage: "",
    pageCount: "",
    perPage: "",
    totlCount: "",
  },
};

export default (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case constants.ADMIN_UPDATE_CATEGORIE:
      return {
        ...state,
        renderCat: true,
        loading: true,
      };
    // TRIGGER ADMIN CATEGORIE CREATING
    case constants.ADMIN_CERATE_CATEGORIE:
      return {
        ...state,
        loading: false,
      };
    case constants.GET_LICENCE_CATEGORIES:
      return {
        ...state,
        loading: true,
      };
    case constants.GET_LICENCE_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        licenceCategories: payload,
      };
    case constants.GET_LICENCE_CATEGORIES_FAIL:
      return {
        ...state,
        loading: false,
      };
    case constants.GET_LICENCE_ATTRIBUTES_SUCCESS:
      return {
        ...state,
        licenceAttributes: payload,
      };
    case constants.GET_LICENCE_ATTRIBUTES_FAIL:
      return {
        ...state,
        error: payload,
      };
    case constants.GET_CURRENT_CAT_ATTIRBUTES:
      return {
        ...state,
        currentCatAttributes: payload,
      };
    case constants.RESET_CURRENT_CAT_ATTRIBUTES:
      return {
        ...state,
        currentCatAttributes: [],
      };
    case constants.ADD_NEW_CURRENT_CAT_ATTIRBUTE:
      return {
        ...state,
        currentCatAttributes: [...state.currentCatAttributes, payload],
      };

    case constants.UPDATE_CURRENT_CAT_ATTRIBUTE:
      return {
        ...state,
        currentCatAttributes: [
          ...state.currentCatAttributes.filter(
            (attribute) => attribute.id !== payload.id
          ),
          payload,
        ],
      };

    case constants.DELETE_CURRENT_CAT_ATTRIBUTE:
      return {
        ...state,
        currentCatAttributes: [
          ...state.currentCatAttributes.filter(
            (attribute) => attribute.id !== payload
          ),
        ],
      };

    case constants.GET_LICENCE_CONTENT_TYPE_IN_ORDER_SUCCESS:
      return {
        ...state,
        contentTypeOrder: payload,
      };
    case constants.GET_ALL_LICENCE_CONTENT_TYPE_IN_ORDER_SUCCESS:
      return {
        ...state,
        allLicenceCategories: payload,
      };

    case constants.SET_SELECTED_CONTENT_TYPE_ORDER:
      return {
        ...state,
        selectedContentTypeOrder: [payload],
      };

    case constants.SET_SELECTED_CONTENT_TYPE_ORDER_SUCCESS:
      return {
        ...state,
        selectedContentTypeOrder: removeContentTypeCategory(
          state.selectedContentTypeOrder,
          payload
        ),
      };

    case constants.SET_SELECTED_CONTENT_TYPE_ORDER_ARTICLE:
      return {
        ...state,
        selectedContentTypeOrderArticle: [payload],
      };
    case constants.SELECTED_CATEGORIE:
      return {
        ...state,
        selectedCategorie: state.licenceCategories.filter(
          (cat) => cat.id === payload
        )[0],
      };
    case constants.SET_NEW_CAT:
      return {
        ...state,
        newCat: payload,
      };

    default:
      return state;
  }
};
