const GET_ALL_LICENCES = "GET_ALL_LICENCES";
const GET_ALL_LICENCES_SUCCESS = "GET_ALL_LICENCES_SUCCESS";
const GET_ALL_LICENCES_FAIL = "GET_ALL_LICENCES_FAIL";
const GET_SALES_USER_LICENCES = "GET_SALES_USER_LICENCES";
const GET_SALES_USER_LICENCES_SUCCESS = "GET_SALES_USER_LICENCES_SUCCESS";
const GET_SALES_USER_LICENCES_FAIL = "GET_SALES_USER_LICENCES_FAIL";
const UPDATE_LICENCE_LOGO = "UPDATE_LICENCE_LOGO";
const UPDATE_LICENCE_LOGO_SUCCESS = "UPDATE_LICENCE_LOGO_SUCCESS";
const UPDATE_LICENCE_LOGO_FAIL = "UPDATE_LICENCE_LOGO_FAIL";
const UPDATE_LICENCE_DESCRIPTION = "UPDATE_LICENCE_DESCRIPTION";
const UPDATE_LICENCE_DESCRIPTION_SUCCESS = "UPDATE_LICENCE_DESCRIPTION_SUCCESS";
const UPDATE_LICENCE_DESCRIPTION_FAIL = "UPDATE_LICENCE_DESCRIPTION_FAIL";
const ADD_ANDROID_APP_LINK = "ADD_ANDROID_APP_LINK";
const ADD_ANDROID_APP_LINK_SUCCESS = "ADD_ANDROID_APP_LINK_SUCCESS";
const ADD_ANDROID_APP_LINK_FAIL = "ADD_ANDROID_APP_LINK_FAIL";
const UPDATE_LICENCE_ADDRESS = "UPDATE_LICENCE_ADDRESS";
const UPDATE_LICENCE_ADDRESS_SUCCESS = "UPDATE_LICENCE_ADDRESS_SUCCESS";
const UPDATE_LICENCE_ADDRESS_FAIL = "UPDATE_LICENCE_ADDRESS_FAIL";
const UPDATE_LICENCE_PHONE = "UPDATE_LICENCE_PHONE";
const UPDATE_LICENCE_PHONE_SUCCESS = "UPDATE_LICENCE_PHONE_SUCCESS";
const UPDATE_LICENCE_PHONE_FAIL = "UPDATE_LICENCE_PHONE_FAIL";
const CREATE_VIRTUAL_HOST = "CREATE_VIRTUAL_HOST";
const CREATE_VIRTUAL_HOST_SUCCESS = "CREATE_VIRTUAL_HOST_SUCCESS";
const CREATE_VIRTUAL_HOST_FAIL = "CREATE_VIRTUAL_HOST_FAIL";
const CREATE_SSL_CERTIFICATE = "CREATE_SSL_CERTIFICATE";
const CREATE_SSL_CERTIFICATE_SUCCESS = "CREATE_SSL_CERTIFICATE_SUCCESS";
const CREATE_SSL_CERTIFICATE_FAIL = "CREATE_SSL_CERTIFICATE_FAIL";
const UPDATE_LICENCE_STATUS = "UPDATE_LICENCE_STATUS";
const UPDATE_LICENCE_STATUS_SUCCESS = "UPDATE_LICENCE_STATUS_SUCCESS";
const UPDATE_LICENCE_STATUS_FAIL = "UPDATE_LICENCE_STATUS_FAIL";
const SET_USER_LICENCES_PAGINATION = "SET_USER_LICENCES_PAGINATION";
const UPDATE_NEW_LICENCE_DATA = "UPDATE_NEW_LICENCE_DATA";
const UPDATE_NEW_LICENCE_DATA_SUCCESS = "UPDATE_NEW_LICENCE_DATA_SUCCESS";
const GET_NEW_LICENCE_DATA = "GET_NEW_LICENCE_DATA";
const GET_NEW_LICENCE_DATA_SUCCESS = "GET_NEW_LICENCE_DATA_SUCCESS";
const CEATE_LICENCE_SUB_DOMAIN = "CEATE_LICENCE_SUB_DOMAIN";
const CEATE_LICENCE_SUB_DOMAIN_SUCCESS = "CEATE_LICENCE_SUB_DOMAIN_SUCCESS";
const CEATE_LICENCE_SUB_DOMAIN_FAIL = "CEATE_LICENCE_SUB_DOMAIN_FAIL";
const CREATE_LICENCE_DOMAIN = "CEATE_LICENCE_DOMAIN";
const CREATE_LICENCE_DOMAIN_SUCCESS = "CEATE_LICENCE_DOMAIN_SUCCESS";
const CREATE_LICENCE_DOMAIN_FAIL = "CEATE_LICENCE_DOMAIN_FAIL";
const CREATE_LICENCE = "CREATE_LICENCE";
const CREATE_LICENCE_SUCCESS = "CREATE_LICENCE_SUCCESS";
const CREATE_LICENCE_FAIL = "CREATE_LICENCE_FAIL";
const ACTIVATE_LICENCE_LOADER = "ACTIVATE_LICENCE_LOADER";
const CLOSE_LICENCE_LOADER = "CLOSE_LICENCE_LOADER";
const GET_DOMAIN_PRICES = "GET_DOMAIN_PRICES";
const GET_DOMAIN_PRICES_SUCCESS = "GET_DOMAIN_PRICES_SUCCESS";
const CHECK_DOMAIN = "CHECK_DOMAIN";
const GET_DOMAIN = "GET_DOMAIN";
const GET_DOMAIN_INVOICE = "GET_DOMAIN_INVOICE";
const GET_EXCHANGE_RATE = "GET_EXCHANGE_RATE";
const GET_EXCHANGE_RATE_SUCCESS = "GET_EXCHANGE_RATE_SUCCESS";
const GET_EXCHANGE_RATE_FAIL = "GET_EXCHANGE_RATE_FAIL";
const SEND_MAIL_TO_LICENCE_USER = "SEND_MAIL_TO_LICENCE_USER";
const SEND_MAIL_TO_LICENCE_USER_SUCCESS = "SEND_MAIL_TO_LICENCE_USER_SUCCESS";
const SEND_MAIL_TO_LICENCE_USER_FAIL = "SEND_MAIL_TO_LICENCE_USER_FAIL";
const GET_ALL_PARTNERS_LICENCES = "GET_ALL_PARTNERS_LICENCES";
const GET_ALL_PARTNERS_LICENCES_SUCCESS = "GET_ALL_PARTNERS_LICENCES_SUCCESS";
const BUILD_PAID_NATIVE_APP = "BUILD_PAID_NATIVE_APP";
const BUILD_PAID_NATIVE_APP_SUCCESS = "BUILD_PAID_NATIVE_APP_SUCCESS";
const BUILD_PAID_NATIVE_APP_FAIL = "BUILD_PAID_NATIVE_APP_FAIL";

export default {
  SEND_MAIL_TO_LICENCE_USER,
  SEND_MAIL_TO_LICENCE_USER_SUCCESS,
  SEND_MAIL_TO_LICENCE_USER_FAIL,
  GET_ALL_LICENCES,
  GET_ALL_LICENCES_SUCCESS,
  GET_ALL_LICENCES_FAIL,
  GET_SALES_USER_LICENCES,
  GET_SALES_USER_LICENCES_SUCCESS,
  GET_SALES_USER_LICENCES_FAIL,
  UPDATE_LICENCE_LOGO,
  UPDATE_LICENCE_LOGO_SUCCESS,
  UPDATE_LICENCE_LOGO_FAIL,
  UPDATE_LICENCE_DESCRIPTION,
  UPDATE_LICENCE_DESCRIPTION_SUCCESS,
  UPDATE_LICENCE_DESCRIPTION_FAIL,
  ADD_ANDROID_APP_LINK,
  ADD_ANDROID_APP_LINK_SUCCESS,
  ADD_ANDROID_APP_LINK_FAIL,
  UPDATE_LICENCE_ADDRESS,
  UPDATE_LICENCE_ADDRESS_SUCCESS,
  UPDATE_LICENCE_ADDRESS_FAIL,
  UPDATE_LICENCE_PHONE,
  CREATE_VIRTUAL_HOST,
  CREATE_VIRTUAL_HOST_SUCCESS,
  CREATE_VIRTUAL_HOST_FAIL,
  UPDATE_LICENCE_PHONE_SUCCESS,
  UPDATE_LICENCE_PHONE_FAIL,
  UPDATE_LICENCE_STATUS,
  UPDATE_LICENCE_STATUS_SUCCESS,
  UPDATE_LICENCE_STATUS_FAIL,
  SET_USER_LICENCES_PAGINATION,
  UPDATE_NEW_LICENCE_DATA,
  UPDATE_NEW_LICENCE_DATA_SUCCESS,
  GET_NEW_LICENCE_DATA,
  GET_NEW_LICENCE_DATA_SUCCESS,
  CEATE_LICENCE_SUB_DOMAIN,
  CEATE_LICENCE_SUB_DOMAIN_SUCCESS,
  CEATE_LICENCE_SUB_DOMAIN_FAIL,
  CREATE_LICENCE_DOMAIN,
  CREATE_LICENCE_DOMAIN_SUCCESS,
  CREATE_LICENCE_DOMAIN_FAIL,
  CREATE_LICENCE,
  CREATE_LICENCE_SUCCESS,
  CREATE_LICENCE_FAIL,
  ACTIVATE_LICENCE_LOADER,
  CLOSE_LICENCE_LOADER,
  GET_DOMAIN_PRICES,
  GET_DOMAIN_PRICES_SUCCESS,
  CHECK_DOMAIN,
  GET_DOMAIN,
  GET_DOMAIN_INVOICE,
  GET_EXCHANGE_RATE,
  GET_EXCHANGE_RATE_SUCCESS,
  GET_EXCHANGE_RATE_FAIL,
  CREATE_SSL_CERTIFICATE,
  CREATE_SSL_CERTIFICATE_SUCCESS,
  CREATE_SSL_CERTIFICATE_FAIL,
  GET_ALL_PARTNERS_LICENCES,
  GET_ALL_PARTNERS_LICENCES_SUCCESS,
  BUILD_PAID_NATIVE_APP,
  BUILD_PAID_NATIVE_APP_SUCCESS,
  BUILD_PAID_NATIVE_APP_FAIL,
};
