import constants from "./actionTypes";
import { call, all, put, takeLatest } from "redux-saga/effects";
import { getUserSearchTermContentApi } from "../../services/ApiCalls";
import { getUserSearchTearmSuccess, setSearchTermPagination } from "./actions";

function* getUserSearchTermSaga(action) {
  try {
    const contentSearchTerm = yield call(
      getUserSearchTermContentApi,
      action.payload
    );

    const setPaginationObj = {
      currentPage: parseInt(
        contentSearchTerm.headers["x-pagination-current-page"]
      ),
      pageCount: parseInt(contentSearchTerm.headers["x-pagination-page-count"]),
      perPage: parseInt(contentSearchTerm.headers["x-pagination-per-page"]),
      totlCount: parseInt(
        contentSearchTerm.headers["x-pagination-total-count"]
      ),
    };

    yield put(getUserSearchTearmSuccess(contentSearchTerm.data));
    yield put(setSearchTermPagination(setPaginationObj));
    // console.log("contentSearchTerm", contentSearchTerm);
  } catch (e) {
    console.log("error", e);
  }
}

function* searchSaga() {
  yield all([
    takeLatest(constants.GET_uSER_SEARCH_TERM, getUserSearchTermSaga),
  ]);
}

export default searchSaga;
