// import { retry } from "redux-saga/effects";
import constants from "./actionTypes";

const initState = {
  licenceUsers: [],
  allLicenceUsers: [],
  allLicenceUsersPagination: {
    currentPage: "",
    pageCount: "",
    perPage: "",
    totlCount: "",
  },
  allBackendLicenceUsers: [],
  backendUsersPagination: {
    currentPage: "",
    pageCount: "",
    perPage: "",
    totlCount: "",
  },
  allFrontEndLicenceUsers: [],
  frontEndUsersPagination: {
    currentPage: "",
    pageCount: "",
    perPage: "",
    totlCount: "",
  },
  newUser: {},
  loader: false,
  backendUsers: ["administrator", "moderator", "editor"],
  salesUser: ["sales-user"],

  // allLicences: [],
};

export default (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case constants.ACTIVATE_COMMENT_LOADER:
      return {
        ...state,
        loader: true,
      };

    case constants.ADMIN_ACTIVATE_USER_SUCCESS:
      return {
        ...state,
        loader: false,
      };
    case constants.ADMIN_CREATE_USER:
      return {
        ...state,
        loader: true,
      };
    case constants.ADMIN_CREATE_USER_SUCCESS:
      return {
        ...state,
        loader: false,
      };
    case constants.ADMIN_CREATE_USER_FAIL:
      return {
        ...state,
        loader: false,
      };
    case constants.ADMIN_BLOCK_USER_SUCCESS:
      return {
        ...state,
        loader: false,
      };
    case constants.SET_NEW_USER:
      return {
        ...state,
        newUser: payload,
      };
    case constants.RESET_BACKEND_USERS:
      return {
        ...state,
        // backendUsersPagination: [...state.allBackendLicenceUsers, ...payload],
        allBackendLicenceUsers: [],
        backendUsersPagination: initState.backendUsersPagination,
      };
    case constants.RESET_FRONT_END_USERS:
      return {
        ...state,
        allFrontEndLicenceUsers: [],
        frontEndUsersPagination: initState.frontEndUsersPagination,
      };
    case constants.SET_PAGINATION_BACKEND:
      return {
        ...state,
        // backendUsersPagination: [...state.allBackendLicenceUsers, ...payload],
        backendUsersPagination: payload,
      };
    case constants.SET_PAGINATION_FRONT_END:
      return {
        ...state,
        frontEndUsersPagination: payload,
      };
    case constants.GET_LICENCE_USERS:
      return {
        ...state,
        loader: true,
      };
    case constants.GET_ALL_BACKEND_LICENCE_USERS:
      return {
        ...state,
        loader: true,
      };
    case constants.GET_ALL_BACKEND_LICENCE_USERS_SUCCESS:
      return {
        ...state,
        loader: false,
        allBackendLicenceUsers: [...state.allBackendLicenceUsers, ...payload],

        // allBackendLicenceUsers: payload,
      };
    case constants.GET_ALL_FRONT_END_LICENCE_USERS:
      return {
        ...state,
        loader: true,
      };
    case constants.GET_ALL_FRONT_END_LICENCE_USERS_SUCCESS:
      return {
        ...state,
        loader: false,
        allFrontEndLicenceUsers: [...state.allFrontEndLicenceUsers, ...payload],
        // allFrontEndLicenceUsers: payload,
      };
    case constants.GET_LICENCE_USERS_SUCCESS:
      return {
        ...state,
        loader: false,
        licenceUsers: payload,
      };
    case constants.GET_ALL_LICENCE_USERS:
      return {
        ...state,
        loader: true,
      };
    case constants.GET_ALL_LICENCE_USERS_SUCCESS:
      return {
        ...state,
        loader: false,
        allLicenceUsers: [...state.allLicenceUsers, ...payload],
      };
    case constants.RESET_LICENCE_USERS:
      return {
        ...state,
        allLicenceUsers: [],
        allLicenceUsersPagination: initState.allLicenceUsersPagination,
      };
    case constants.SET_PAGINATION_ALL_LICENCE_USERS:
      return {
        ...state,
        allLicenceUsersPagination: payload,
      };
    default:
      return { ...state };
  }
};
