import constants from "./actionTypes";
import {
  all,
  call,
  put,
  takeLatest,
  takeEvery,
  select,
} from "redux-saga/effects";
import {
  fetchSinglePlannerItemsApi,
  deletePlannerItemApi,
  addNewTaskToPlannerApi,
  updatePlannerItemDateAndTimeApi,
  changePlannerItemPriceApi,
  changeClaculatePriceApi,
  switchToAnotherPlannerApi,
  updatePlannerTaskApi,
  savePlannerItemRemarkApi,
  showPlannerItemPricePublicyApi,
  showPlannerItemPublicyApi,
  togglePublicPlannerApi,
} from "../../services/ApiCalls";
import {
  fetchSinglePlannerItemsSuccess,
  // setShowSinglePlannerOptions,
  // deletePlannerItemSuccess,
  // updatePlannerItemDateAndTimeSuccess,
  fetchSinglePlannerItems,
  // setPriceSum,
  // redirectAfterNewTaskCreation,
  // changeCalculatePriceSuccess,
  addToPriceSum,
  reducePriceSum,
} from "./actions";
import { NotificationManager } from "react-notifications";
import { calculatePrice } from "./utils";

// import { NotificationManager } from "react-notifications";

// SAGA FOR FETCHING SINGE PLANNER DATA
// RECIVES TYPE AND PAYLOAD FROM THE ACTION
// TYPE : constants.FETCH_SINGLE_PLANNER_ITEMS
// PAYLOAD : PLANNER ID
function* fetchSinglePlannerSaga(action) {
  try {
    const singlePlanner = yield call(
      fetchSinglePlannerItemsApi,
      action.payload
    );
    calculatePrice(singlePlanner);
    // let showSinglePlannerOptions = {};
    // let priceSumRSD = { priceName: "RSD", priceAmount: 0 };
    // let priceSumEUR = { priceName: "EUR", priceAmount: 0 };

    // singlePlanner.map((planner) => {
    //   showSinglePlannerOptions[planner.id] = false;
    //   if (planner.price) {
    //     planner.price = parseFloat(planner.price);

    //     if (planner.calculate_price === "yes") {
    //       // console.log("planner.price", planner.price);
    //       if (planner.currency.id === 1) {
    //         priceSumRSD.priceAmount = priceSumRSD.priceAmount + planner.price;
    //       } else if (planner.currency.id === 2) {
    //         priceSumEUR.priceAmount = priceSumEUR.priceAmount + planner.price;
    //       }
    //     }
    //   }
    // });
    // console.log("showSinglePlannerOptions: ", showSinglePlannerOptions);

    // yield put(setPriceSum(priceSumRSD));
    yield put(fetchSinglePlannerItemsSuccess(singlePlanner));
    // yield put(setShowSinglePlannerOptions(showSinglePlannerOptions));
    // yield put(setPriceSum(priceSumEUR));
  } catch (e) {
    console.log(e.message);
  }
}
// SAGA FOR DELETING ITEM FROM PLANNER
// RECIVES TYPE AND PAYLOAD FROM THE ACTION
// TYPE : constants.DELETE_PLANNER_ITEM
// PAYLOAD : IS OBJECT THAT HAVE PLANNER ID AND PLANNER ITEM ID THAT NEEDS TO BE DELETED
function* deletePlannerItemSaga(action) {
  try {
    const deletePlannerItem = yield call(deletePlannerItemApi, action.payload);
    const okStatus = 200;
    if (deletePlannerItem.status === okStatus) {
      yield put(fetchSinglePlannerItems(action.payload.plannerId));
    }
  } catch (e) {
    console.log(e.message);
  }
}

// SAGA TO MOVE ONE PLANNER ITEM TO AHOTHER PLANNER
// RECIVES TYPE AND PAYLOAD FROM THE ACTION
// TYPE : constants.SWITCH_TO_ANOTHER_PLANNER
// PAYLOAD : PLANNER ITEM ID AND PLANNER ID
function* switchToAnotherPlannerSaga(action) {
  try {
    const switchToAnotherPlanner = yield call(
      switchToAnotherPlannerApi,
      action.payload
    );
    const okStatus = 200;
    if (
      switchToAnotherPlanner.status === okStatus &&
      action.payload.fetchPlannerItems
    ) {
      yield put(fetchSinglePlannerItems(action.payload.fetchPlannerId));
      NotificationManager.success(switchToAnotherPlanner.message, "", 3000);
    }
  } catch (e) {
    console.log(e.message);
  }
}
// SAGA FOR ADDING NEW TASK TO PLANNER
// RECIVES TYPE AND PAYLOAD FROM THE ACTION
// TYPE : constants.ADD_NEW_TASK_TO_PLANNER
// PAYLOAD : IS OBJECT THAT HAVE PLANNER ID AND NEW TASK DATA
function* addNewTaskToPlannerSaga(action) {
  try {
    const addNewTask = yield call(addNewTaskToPlannerApi, action.payload);
    // console.log("action.payload", action.payload);
    const okStatus = 200;
    if (addNewTask.status === okStatus && action.payload.fetchPlannerItems) {
      yield put(fetchSinglePlannerItems(action.payload.selectedPlanner));
    }
  } catch (e) {
    console.log(e.message);
  }
}

// SAGA FOR UPDATING PLANNER TASK
// RECIVES TYPE AND PAYLOAD FROM THE ACTION
// TYPE : constants.UPDATE_PLANNER_TASK
// PAYLOAD : IS OBJECT THAT HAVE PLANNER ID AND TASK DATA
function* updatePlannerTaskSaga(action) {
  try {
    const updateTask = yield call(updatePlannerTaskApi, action.payload);
    // console.log("updateTask", updateTask);

    // console.log("addNewTask", addNewTask);
    const okStatus = 200;
    if (updateTask.status === okStatus && action.payload.fetchPlannerItems) {
      yield put(fetchSinglePlannerItems(action.payload.selectedPlanner));
    }
  } catch (e) {
    console.log(e.message);
  }
}
// SAGA FOR UPDATING PLANNER ITEM DATE AND TIME
// RECIVES TYPE AND PAYLOAD FROM THE ACTION
// TYPE : constants.UPDATE_PLANNER_ITEM_SCHADULED_DATE_TIME
// PAYLOAD : IS OBJECT THAT HAVE PLANNER ITEM ID AND NEW DATE AND TIME
function* updatePlannerItemTimeAndDateSaga(action) {
  try {
    yield call(updatePlannerItemDateAndTimeApi, action.payload);
    // const okStatus = 200;
    // if (updateTimeAndDate.status === okStatus) {
    //   yield put(fetchSinglePlannerItems(action.payload.plannerId));
    // }
    // console.log(updateTimeAndDate, "updateTimeAndDate");

    // const okStatus = 200;
    // if (deletePlannerItem.status === okStatus) {
    //   yield put(deletePlannerItemSuccess(action.payload.plannerItemId));
    // }
  } catch (e) {
    console.log(e.message);
  }
}
// SAGA FOR UPDATING PLANNER ITEM PRICE
// RECIVES TYPE AND PAYLOAD FROM THE ACTION
// TYPE : constants.CHANGE_PLANNER_ITEM_PRICE
// PAYLOAD : IS OBJECT THAT HAVE PLANNER ITEM ID, PRICE AND CURRENCY ID
function* changePlannerItemPriceSaga(action) {
  try {
    yield call(changePlannerItemPriceApi, action.payload);
    // const okStatus = 200;
    // if (price.status === okStatus) {
    //   yield put(fetchSinglePlannerItems(action.payload.plannerId));
    // }

    // const okStatus = 200;
    // if (deletePlannerItem.status === okStatus) {
    //   yield put(deletePlannerItemSuccess(action.payload.plannerItemId));
    // }
  } catch (e) {
    console.log(e.message);
  }
}

// SAGA FOR UPDATING PLANNER ITEM REMARK
// RECIVES TYPE AND PAYLOAD FROM THE ACTION
// TYPE : constants.SAVE_PLANNER_ITEM_REMARK
// PAYLOAD : IS OBJECT THAT HAVE PLANNER ITEM REMARK AND PLANNER ITEM ID
function* savePlannerItemRemarkSaga(action) {
  try {
    yield call(savePlannerItemRemarkApi, action.payload);
    // const okStatus = 200;
    // if (price.status === okStatus) {
    //   yield put(fetchSinglePlannerItems(action.payload.plannerId));
    // }

    // const okStatus = 200;
    // if (deletePlannerItem.status === okStatus) {
    //   yield put(deletePlannerItemSuccess(action.payload.plannerItemId));
    // }
  } catch (e) {
    console.log(e.message);
  }
}

// SAGA FOR SETTING CALCULATE PRICE PROPERTY OF THE SINGLE PLANNER ITEM
// RECIVES TYPE AND PAYLOAD FROM THE ACTION
// TYPE : constants.CHANGE_CALCULATE_PRICE
// PAYLOAD : IS OBJECT THAT HAVE PLANNER ITEM ID, AND CALCULATE VALUE (1 = YES) || (0 = NO)
function* changeCaculatePriceSaga(action) {
  try {
    const state = yield select();
    const changeCalculatePrice = yield call(
      changeClaculatePriceApi,
      action.payload
    );
    const okStatus = 200;
    if (changeCalculatePrice.status === okStatus) {
      const { amout, currencyName } = action.payload;
      const priceSum = {
        amout,
        currencyName,
      };

      console.log("PRICE SUM SAGA", priceSum);
      if (action.payload.value === 1) {
        // NotificationManager.success(changeCalculatePrice.message, "", 2000);
        NotificationManager.success(
          state.translation.messages.successfullAddedTaskPriceToSum[1],
          "",
          3000
        );
      } else {
        NotificationManager.warning(
          state.translation.messages.successfullRemovedTaskPriceFromSum[1],
          "",
          3000
        );
      }

      // console.log(action.payload.value, "action.payload.value");
      // if (action.payload.value === 1) {
      //   yield put(addToPriceSum(priceSum));
      // } else {
      //   yield put(reducePriceSum(priceSum));
      // }
      // yield put(changeCalculatePriceSuccess(action.payload.plannerItemId));
    }
    // console.log("changeCalculatePrice ", changeCalculatePrice);
    // const okStatus = 200;
    // if (deletePlannerItem.status === okStatus) {
    //   yield put(deletePlannerItemSuccess(action.payload.plannerItemId));
    // }
  } catch (e) {
    console.log(e.message);
  }
}

// SAGA FOR UPDATING PLANNER ITEM REMARK
// RECIVES TYPE AND PAYLOAD FROM THE ACTION
// TYPE : constants.SHOW_PLANNER_ITEM_PRICE_PUBLICLY
// PAYLOAD : IS OBJECT THAT HAVE PLANNER ITEM REMARK AND PLANNER ITEM ID
function* showPlannerItemPricePubliclySaga(action) {
  try {
    const price = yield call(showPlannerItemPricePublicyApi, action.payload);
    NotificationManager.success(price.message, "", 2000);

    // const okStatus = 200;
    // if (price.status === okStatus) {
    //   yield put(fetchSinglePlannerItems(action.payload.plannerId));
    // }

    // const okStatus = 200;
    // if (deletePlannerItem.status === okStatus) {
    //   yield put(deletePlannerItemSuccess(action.payload.plannerItemId));
    // }
  } catch (e) {
    console.log(e.message);
  }
}

// SAGA FOR UPDATING PLANNER ITEM REMARK
// RECIVES TYPE AND PAYLOAD FROM THE ACTION
// TYPE : constants.SHOW_PLANNER_ITEM_PUBLICLY
// PAYLOAD : IS OBJECT THAT HAVE PLANNER ITEM REMARK AND PLANNER ITEM ID
function* showPlannerItemPubliclySaga(action) {
  try {
    const item = yield call(showPlannerItemPublicyApi, action.payload);
    NotificationManager.success(item.message, "", 2000);
    // const okStatus = 200;
    // if (price.status === okStatus) {
    //   yield put(fetchSinglePlannerItems(action.payload.plannerId));
    // }

    // const okStatus = 200;
    // if (deletePlannerItem.status === okStatus) {
    //   yield put(deletePlannerItemSuccess(action.payload.plannerItemId));
    // }
  } catch (e) {
    console.log(e.message);
  }
}

// SAGA FOR UPDATING PLANNER ITEM REMARK
// RECIVES TYPE AND PAYLOAD FROM THE ACTION
// TYPE : constants.TOGGLE_PUBLIC_PLANNER
// PAYLOAD : IS OBJECT THAT HAVE PLANNER ITEM REMARK AND PLANNER ITEM ID
function* togglePublicPlannerSaga(action) {
  try {
    const state = yield select();

    const item = yield call(togglePublicPlannerApi, action.payload);
    console.log("PAYLOAD SAGA", action.payload);
    if (action.payload.isPublic === false) {
      NotificationManager.success(
        state.translation.messages.succesfullyPublishedPlanner[1],
        "",
        3000
      );
    } else {
      NotificationManager.warning(
        state.translation.messages.succesfullyUnpublishedPlanner[1],
        "",
        3000
      );
    }
    // const okStatus = 200;
    // if (price.status === okStatus) {
    //   yield put(fetchSinglePlannerItems(action.payload.plannerId));
    // }

    // const okStatus = 200;
    // if (deletePlannerItem.status === okStatus) {
    //   yield put(deletePlannerItemSuccess(action.payload.plannerItemId));
    // }
  } catch (e) {
    console.log(e.message);
  }
}

function* singlePlannerSaga() {
  yield all([
    takeLatest(constants.FETCH_SINGLE_PLANNER_ITEMS, fetchSinglePlannerSaga),
    takeLatest(constants.DELETE_PLANNER_ITEM, deletePlannerItemSaga),
    takeLatest(constants.SWITCH_TO_ANOTHER_PLANNER, switchToAnotherPlannerSaga),
    takeLatest(constants.ADD_NEW_TASK_TO_PLANNER, addNewTaskToPlannerSaga),
    takeLatest(constants.UPDATE_PLANNER_TASK, updatePlannerTaskSaga),
    takeLatest(constants.TOGGLE_PUBLIC_PLANNER, togglePublicPlannerSaga),
    takeEvery(
      constants.UPDATE_PLANNER_ITEM_SCHADULED_DATE_TIME,
      updatePlannerItemTimeAndDateSaga
    ),
    takeEvery(constants.CHANGE_PLANNER_ITEM_PRICE, changePlannerItemPriceSaga),
    takeEvery(constants.SAVE_PLANNER_ITEM_REMARK, savePlannerItemRemarkSaga),
    takeEvery(constants.CHANGE_CALCULATE_PRICE, changeCaculatePriceSaga),
    takeEvery(
      constants.SHOW_PLANNER_ITEM_PRICE_PUBLICLY,
      showPlannerItemPricePubliclySaga
    ),
    takeEvery(
      constants.SHOW_PLANNER_ITEM_PUBLICLY,
      showPlannerItemPubliclySaga
    ),
  ]);
}

export default singlePlannerSaga;
