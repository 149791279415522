import constants from "./actionTypes";

// ACTION FOR GETTING ALL TEMPLATES FROM THE SERVER
export const getAllTemplates = () => ({
  type: constants.GET_ALL_TEMPLATES,
});
// SUCCESS ACTION FOR GETTING ALL TEMPLATES FROM THE SERVER
// RECIVE TEMPLATES ARRAY FROM THE SERVER AS A PAYLOAD
export const getAllTemplatesSuccess = (payload) => ({
  type: constants.GET_ALL_TEMPLATES_SUCCESS,
  payload,
});
// ACTION FOR GETTING ALL TEMPLATES FROM THE SERVER
// RECIVE ERROR STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const getAllTemplatesFail = (payload) => ({
  type: constants.GET_ALL_TEMPLATES_FAIL,
  payload,
});
// ACTION FOR SETTING ACITVE TEMPLATE ACTION BTNS
// RECIVE UNIQUE TEMPLATE NAME
export const setDisplayTemplateActionBtns = (payload) => ({
  type: constants.SET_DISPLAY_TEMPLATE_ACTION_BTNS,
  payload,
});
// ACTION FOR UPDATING ALL ANDROID APPS WITH DESIRED TEMPLATE
export const updateSingleApp = (payload) => ({
  type: constants.UPDATE_SINGLE_APP,
  payload,
});
// ACTION FOR SUCCESS UPDATING ALL ANDROID APPS WITH DESIRED TEMPLATE
export const updateSingleAppSuccess = (payload) => ({
  type: constants.UPDATE_SINGLE_APP_SUCCESS,
  payload,
});
// ACTION FOR FAIL UPDATING ALL ANDROID APPS WITH DESIRED TEMPLATE
export const updateSingleAppFail = (payload) => ({
  type: constants.UPDATE_SINGLE_APP_FAIL,
  payload,
});
