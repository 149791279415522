const LICENCE_UPDATE_BIDDING_DURATION = "LICENCE_UPDATE_BIDDING_DURATION";
const LICENCE_UPDATE_BIDDING_DURATION_SUCCESS =
  "LICENCE_UPDATE_BIDDING_DURATION_SUCCESS";
const LICENCE_UPDATE_BIDDING_DURATION_FAIL =
  "LICENCE_UPDATE_BIDDING_DURATION_FAIL";
const GET_BIDDING_ITEMS = "GET_BIDDING_ITEMS";
const GET_BIDDING_ITEMS_SUCCESS = "GET_BIDDING_ITEMS_SUCCESS";
const GET_BIDDING_ITEMS_FAIL = "GET_BIDDING_ITEMS_FAIL";
const DISABLE_BIDDING_ITEM = "DISABLE_BIDDING_ITEM";
const DISABLE_BIDDING_ITEM_SUCCESS = "DISABLE_BIDDING_ITEM_SUCCESS";
const DISABLE_BIDDING_ITEM_FAIL = "DISABLE_BIDDING_ITEM_FAIL";
const GET_BIDDING_DURATION = "GET_BIDDING_DURATION";
const GET_BIDDING_DURATION_SUCCESS = "GET_BIDDING_DURATION_SUCCESS";
const GET_BIDDING_DURATION_FAIL = "GET_BIDDING_DURATION_FAIL";
const SET_BIDDING_PAGINATION = "SET_BIDDING_PAGINATION";
const CREATE_BIDDING_ITEM = "CREATE_BIDDING_ITEM";
const CREATE_BIDDING_ITEM_SUCCESS = "CREATE_BIDDING_ITEM_SUCCESS";
const CREATE_BIDDING_ITEM_FAIL = "CREATE_BIDDING_ITEM_FAIL";

export default {
  LICENCE_UPDATE_BIDDING_DURATION,
  LICENCE_UPDATE_BIDDING_DURATION_SUCCESS,
  LICENCE_UPDATE_BIDDING_DURATION_FAIL,
  GET_BIDDING_ITEMS,
  GET_BIDDING_ITEMS_SUCCESS,
  GET_BIDDING_ITEMS_FAIL,
  DISABLE_BIDDING_ITEM,
  DISABLE_BIDDING_ITEM_SUCCESS,
  DISABLE_BIDDING_ITEM_FAIL,
  GET_BIDDING_DURATION,
  GET_BIDDING_DURATION_SUCCESS,
  GET_BIDDING_DURATION_FAIL,
  SET_BIDDING_PAGINATION,
  CREATE_BIDDING_ITEM,
  CREATE_BIDDING_ITEM_SUCCESS,
  CREATE_BIDDING_ITEM_FAIL,
};
