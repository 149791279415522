import constants from "./actionTypes";

const initState = {
  licenceTags: [],
  loading: false,
};

export default (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case constants.GET_LICENCE_TAGS:
      return {
        ...state,
        loading: true,
      };
    case constants.GET_LICENCE_TAGS_SUCCESS:
      return {
        ...state,
        licenceTags: payload,
        loading: true,
      };
    default:
      return {
        ...state,
      };
  }
};
