// Unquote string (utility)
export function unquote(value) {
  if (value.charAt(0) === '"' && value.charAt(value.length - 1) === '"')
    return value.substring(1, value.length - 1);
  return value;
}

// Parse a Link header
export function parseLinkHeader(header) {
  // eslint-disable-next-line no-useless-escape
  var linkexp = /<[^>]*>\s*(\s*;\s*[^\(\)<>@,;:"\/\[\]\?={} \t]+=(([^\(\)<>@,;:"\/\[\]\?={} \t]+)|("[^"]*")))*(,|$)/g;
  // eslint-disable-next-line no-useless-escape
  var paramexp = /[^\(\)<>@,;:"\/\[\]\?={} \t]+=(([^\(\)<>@,;:"\/\[\]\?={} \t]+)|("[^"]*"))/g;

console.log("header", header);

  var matches = header.match(linkexp);
  // var matches = header.link;
  var rels = {};
  for (var i = 0; i < matches.length; i++) {
    var split = matches[i].split(">");
    var href = split[0].substring(1);
    var ps = split[1];
    var link = {};
    link.href = href;
    var s = ps.match(paramexp);
    for (var j = 0; j < s.length; j++) {
      var p = s[j];
      var paramsplit = p.split("=");
      var name = paramsplit[0];
      link[name] = unquote(paramsplit[1]);
    }

    if (link.rel !== undefined) {
      rels[link.rel] = link;
    }
  }

  return rels;
}
