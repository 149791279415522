import constants from "./actionTypes";

const initState = {
  loading: true,
  homePagePositionLoading: true,
  homePageProductsLoading: true,
  homePagePositionOneContent: [],
  homePageProductList: [],
};

export default (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {

    case constants.FETCH_HOME_PAGE_POSITION_ONE:
      return {
        ...state,
        homePagePositionLoading: true,
      };

    case constants.FETCH_HOME_PAGE_POSITION_ONE_SUCCESS:
      return {
        ...state,
        homePagePositionOneContent: payload,
        homePagePositionLoading: false,
      };

    case constants.FETCH_HOME_PAGE_PRODUCT_LIST:
      return {
        ...state,
        homePageProductsLoading: true,
      };

    case constants.FETCH_HOME_PAGE_PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        homePageProductList: payload,
        homePageProductsLoading: false,
      };

    default:
      return state;
  }
};
