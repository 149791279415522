import constants from "./actionTypes";
// ACTION FOR FETCHING COMMENT BY CONTENT ID
// RECIVES CONTENT ID AS A PAYLOAD
export const fetchContentComments = (payload) => ({
  payload,
  type: constants.FETCH_CONTENT_COMMENTS,
});
// SUCCESS ACTION FOR FETCHING COMMENT BY CONTENT ID
// RECIVES APPROVED COMMENT FOR CONTENT ID FROM THE SERVER AS A PAYLOAD
export const fetchContentCommentsSuccess = (payload) => ({
  payload,
  type: constants.FETCH_CONTENT_COMMENTS_SUCCESS,
});
// FAIL ACTION FOR FETCHING COMMENT BY CONTENT ID
// RECIVES ERROR STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const fetchContentCommentsFail = (payload) => ({
  payload,
  type: constants.FETCH_CONTENT_COMMENTS_FAIL,
});
// ACTION FOR POSITING COMMENT
// RECIVES COMMENT DATA AND CONTENT ID AS A PAYLOAD
export const postContentComment = (payload) => ({
  payload,
  type: constants.POST_CONTENT_COMMENT,
});
// SUCCESS ACTION FOR POSITING COMMENT
// RECIVES SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const postContentCommentSuccess = (payload) => ({
  payload,
  type: constants.POST_CONTENT_COMMENT_SUCCESS,
});
// FAIL ACTION FOR POSITING COMMENT
// RECIVES FAIL STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const postContentCommentFail = (payload) => ({
  payload,
  type: constants.POST_CONTENT_COMMENT_FAIL,
});

// ACTION FOR GETTING ALL COMMENTS ON MODERATION
export const getLicenceCommentsOnModeration = (payload) => ({
  type: constants.GET_COMMENTS_ON_MODERATION,
  payload,
});
// ACTION FOR GETTING ALL COMMENTS ON MODERATION SUCCESS
// RECIVE ALL LICENCE COMMENTS ON MODERATION STATUS FROM SERVER AS A PAYLOAD
export const getLicenceCommentsOnModerationSuccess = (payload) => ({
  payload,
  type: constants.GET_COMMENTS_ON_MODERATION_SUCCESS,
});
// ACTION FOR GETTING ALL COMMENTS ON MODERATION FAIL
// RECIVE ERROR STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const getLicenceCommentsOnModerationFail = (payload) => ({
  payload,
  type: constants.GET_COMMENTS_ON_MODERATION_FAIL,
});

// ACTION FOR SETTING PAGINATION FOR COMMENTS ON MODERATION
// RECIVE PAGINATION OBJECT AS A PYALOAD
export const setPaginationForCommentsOnModeration = (payload) => ({
  payload,
  type: constants.SET_COMMENTS_ON_MODERATION_PAGINATION,
});

// ACTION FOR APPROVING COMMENT MODERATION BY ADMIN
// RECEVE COMMENT ID THAT NEEDS TO BE APPROVED AS A PAYLOAD
export const approveCommentModeration = (payload) => ({
  payload,
  type: constants.APPROVE_COMMENT_MODERATION,
});
// SUCCESS FOR APPROVING COMMENT MODERATION BY ADMIN
// RECEVE SUCCESS STATUS AND THE MESSAGE AS A PAYLOAD
export const approveCommentModerationSuccess = (payload) => ({
  payload,
  type: constants.APPROVE_COMMENT_MODERATION_SUCCESS,
});
// FAIL FOR APPROVING COMMENT MODERATION BY ADMIN
// RECEVE ERROR STATUS AND THE MESSAGE AS A PAYLOAD
export const approveCommentModerationFail = (payload) => ({
  payload,
  type: constants.APPROVE_COMMENT_MODERATION_FAIL,
});

// ACTION FOR APPROVING COMMENT MODERATION BY ADMIN
// RECEVE COMMENT ID THAT NEEDS TO BE APPROVED AS A PAYLOAD
export const disapproveCommentModeration = (payload) => ({
  payload,
  type: constants.ADMIN_DISAPPROVE_COMMENT_MODERATION,
});
// SUCCESS FOR APPROVING COMMENT MODERATION BY ADMIN
// RECEVE SUCCESS STATUS AND THE MESSAGE AS A PAYLOAD
export const disapproveCommentModerationSuccess = (payload) => ({
  payload,
  type: constants.ADMIN_DISAPPROVE_COMMENT_MODERATION_SUCCESS,
});
// FAIL FOR APPROVING COMMENT MODERATION BY ADMIN
// RECEVE ERROR STATUS AND THE MESSAGE AS A PAYLOAD
export const disapproveCommentModerationFail = (payload) => ({
  payload,
  type: constants.ADMIN_DISAPPROVE_COMMENT_MODERATION_FAIL,
});
// ACTION FOR REQUESTING EDIT ON COMMENT MODERATION
// RECEVE COMMENT ID THAT NEEDS TO BE EDITED AD REMARK MESSAGE AS A PAYLOAD
export const requestForEditCommentModeration = (payload) => ({
  payload,
  type: constants.REQUEST_FOR_EDIT_COMMENT_MODERATION,
});
// SUCCESS FOR REQUESTING EDIT ON COMMENT MODERATION
// RECEVE SUCCESS STATUS AND THE MESSAGE AS A PAYLOAD
export const requestForEditCommentModerationSuccess = (payload) => ({
  payload,
  type: constants.REQUEST_FOR_EDIT_COMMENT_MODERATION_SUCCESS,
});
// FAIL FOR REQUESTING EDIT ON COMMENT MODERATION
// RECEVE ERROR STATUS AND THE MESSAGE AS A PAYLOAD
export const requestForEditCommentModerationFail = (payload) => ({
  payload,
  type: constants.REQUEST_FOR_EDIT_COMMENT_MODERATION_FAIL,
});

// ACTION FOR ACTIVATING COMMENT LOADER
export const activateCommentLoader = () => ({
  type: constants.ACTIVATE_COMMENT_LOADER,
});
